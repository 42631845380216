import {
  GET_ADMINS,
  GET_ADMINS_SUCCESS,
  GET_ADMINS_FAIL,
  GET_ADMINS_DETAIL,
  GET_ADMINS_DETAIL_SUCCESS,
  GET_ADMINS_DETAIL_FAIL,
  CREATE_ADMINS,
  CREATE_ADMINS_SUCCESS,
  CREATE_ADMINS_FAIL,
  UPDATE_ADMINS,
  UPDATE_ADMINS_SUCCESS,
  UPDATE_ADMINS_FAIL,
  DELETE_ADMINS,
  DELETE_ADMINS_SUCCESS,
  DELETE_ADMINS_FAIL,
} from "./actiontypes"

export const getAdmins = (searchText, page) => ({
  type: GET_ADMINS,
  payload: { searchText, page },
})
export const getAdminsSuccess = admins => ({
  type: GET_ADMINS_SUCCESS,
  payload: admins,
})
export const getAdminsFail = error => ({
  type: GET_ADMINS_FAIL,
  payload: error,
})

export const getAdminsDetail = adminsId => ({
  type: GET_ADMINS_DETAIL,
  adminsId,
})

export const getAdminsDetailSuccess = adminsDetail => ({
  type: GET_ADMINS_DETAIL_SUCCESS,
  payload: adminsDetail,
})

export const getAdminsDetailFail = error => ({
  type: GET_ADMINS_DETAIL_FAIL,
  payload: error,
})

export const createAdmins = (admins, history) => ({
  type: CREATE_ADMINS,
  payload: { admins, history },
})

export const createAdminsSuccess = admins => ({
  type: CREATE_ADMINS_SUCCESS,
  payload: admins,
})

export const createAdminsFail = error => ({
  type: CREATE_ADMINS_FAIL,
  payload: error,
})

export const updateAdmins = (admins, clientId) => ({
  type: UPDATE_ADMINS,
  payload: { admins, clientId },
})

export const updateAdminsSuccess = admins => ({
  type: UPDATE_ADMINS_SUCCESS,
  payload: admins,
})

export const updateAdminsFail = error => ({
  type: UPDATE_ADMINS_FAIL,
  payload: error,
})

export const deleteAdmins = (adminsId, history) => ({
  type: DELETE_ADMINS,
  payload: { adminsId, history },
})

export const deleteAdminsSuccess = admins => ({
  type: DELETE_ADMINS_SUCCESS,
  payload: admins,
})

export const deleteAdminsFail = error => ({
  type: DELETE_ADMINS_FAIL,
  payload: error,
})
