import {
  GET_ACTIVITY_LOGS,
  GET_ACTIVITY_LOGS_SUCCESS,
  GET_ACTIVITY_LOGS_FAIL,
  GET_ACTIVITY_LOG_DETAIL,
  GET_ACTIVITY_LOG_DETAIL_SUCCESS,
  GET_ACTIVITY_LOG_DETAIL_FAIL,
} from "./actionTypes"

export const getActivityLogs = (searchText, page) => ({
  type: GET_ACTIVITY_LOGS,
  payload: { searchText, page },
})

export const getActivityLogsSuccess = logs => ({
  type: GET_ACTIVITY_LOGS_SUCCESS,
  payload: logs,
})
export const getActivityLogsFail = error => ({
  type: GET_ACTIVITY_LOGS_FAIL,
  payload: error,
})

export const getActivityLogDetail = logId => ({
  type: GET_ACTIVITY_LOG_DETAIL,
  logId,
})

export const getActivityLogDetailSuccess = logDetail => ({
  type: GET_ACTIVITY_LOG_DETAIL_SUCCESS,
  payload: logDetail,
})

export const getActivityLogDetailFail = error => ({
  type: GET_ACTIVITY_LOG_DETAIL_FAIL,
  payload: error,
})
