import {
  GET_MAHALL_NOTIFICATIONS,
  GET_MAHALL_NOTIFICATIONS_SUCCESS,
  GET_MAHALL_NOTIFICATIONS_FAIL,
  GET_MAHALL_NOTIFICATIONS_CARDS,
  GET_MAHALL_NOTIFICATIONS_CARDS_SUCCESS,
  GET_MAHALL_NOTIFICATIONS_CARDS_FAIL,
  GET_MAHALL_NOTIFICATION_DETAIL,
  GET_MAHALL_NOTIFICATION_DETAIL_SUCCESS,
  GET_MAHALL_NOTIFICATION_DETAIL_FAIL,
  CREATE_MAHALL_NOTIFICATION,
  CREATE_MAHALL_NOTIFICATION_SUCCESS,
  CREATE_MAHALL_NOTIFICATION_FAIL,
  UPDATE_MAHALL_NOTIFICATION,
  UPDATE_MAHALL_NOTIFICATION_SUCCESS,
  UPDATE_MAHALL_NOTIFICATION_FAIL,
  DELETE_MAHALL_NOTIFICATION,
  DELETE_MAHALL_NOTIFICATION_SUCCESS,
  DELETE_MAHALL_NOTIFICATION_FAIL,
  GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS,
  GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS_SUCCESS,
  GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS_FAIL,
  GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS_CARDS,
  GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS_CARDS_SUCCESS,
  GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS_CARDS_FAIL,
  GET_FAMILY_MEMBER_COLLECTION_NOTIFICATION_DETAIL,
  GET_FAMILY_MEMBER_COLLECTION_NOTIFICATION_DETAIL_SUCCESS,
  GET_FAMILY_MEMBER_COLLECTION_NOTIFICATION_DETAIL_FAIL,
  CREATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION,
  CREATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_SUCCESS,
  CREATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_FAIL,
  UPDATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION,
  UPDATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_SUCCESS,
  UPDATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_FAIL,
  DELETE_FAMILY_MEMBER_COLLECTION_NOTIFICATION,
  DELETE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_SUCCESS,
  DELETE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_FAIL,
  GET_INDIVIDUAL_NOTIFICATIONS,
  GET_INDIVIDUAL_NOTIFICATIONS_SUCCESS,
  GET_INDIVIDUAL_NOTIFICATIONS_FAIL,
  GET_INDIVIDUAL_NOTIFICATIONS_CARDS,
  GET_INDIVIDUAL_NOTIFICATIONS_CARDS_SUCCESS,
  GET_INDIVIDUAL_NOTIFICATIONS_CARDS_FAIL,
  GET_INDIVIDUAL_NOTIFICATION_DETAIL,
  GET_INDIVIDUAL_NOTIFICATION_DETAIL_SUCCESS,
  GET_INDIVIDUAL_NOTIFICATION_DETAIL_FAIL,
  CREATE_INDIVIDUAL_NOTIFICATION,
  CREATE_INDIVIDUAL_NOTIFICATION_SUCCESS,
  CREATE_INDIVIDUAL_NOTIFICATION_FAIL,
  UPDATE_INDIVIDUAL_NOTIFICATION,
  UPDATE_INDIVIDUAL_NOTIFICATION_SUCCESS,
  UPDATE_INDIVIDUAL_NOTIFICATION_FAIL,
  DELETE_INDIVIDUAL_NOTIFICATION,
  DELETE_INDIVIDUAL_NOTIFICATION_SUCCESS,
  DELETE_INDIVIDUAL_NOTIFICATION_FAIL,
} from "./actiontypes"

// ------------------------------------MAHALL NOTIFICATIONS----------------------------------------

export const getMahallNotifications = (searchText, page) => ({
  type: GET_MAHALL_NOTIFICATIONS,
  payload: { searchText, page },
})

export const getMahallNotificationsSuccess = mahallNotifications => ({
  type: GET_MAHALL_NOTIFICATIONS_SUCCESS,
  payload: mahallNotifications,
})

export const getMahallNotificationsFail = error => ({
  type: GET_MAHALL_NOTIFICATIONS_FAIL,
  payload: error,
})

export const getMahallNotificationsCards = () => ({
  type: GET_MAHALL_NOTIFICATIONS_CARDS,
})

export const getMahallNotificationsCardsSuccess = mahallNotificationsCards => ({
  type: GET_MAHALL_NOTIFICATIONS_CARDS_SUCCESS,
  payload: mahallNotificationsCards,
})

export const getMahallNotificationsCardsFail = error => ({
  type: GET_MAHALL_NOTIFICATIONS_CARDS_FAIL,
  payload: error,
})

export const getMahallNotificationDetail = mahallNotificationId => ({
  type: GET_MAHALL_NOTIFICATION_DETAIL,
  mahallNotificationId,
})

export const getMahallNotificationDetailSuccess = mahallNotificationDetail => ({
  type: GET_MAHALL_NOTIFICATION_DETAIL_SUCCESS,
  payload: mahallNotificationDetail,
})

export const getMahallNotificationDetailFail = error => ({
  type: GET_MAHALL_NOTIFICATION_DETAIL_FAIL,
  payload: error,
})

export const createMahallNotification = (mahallNotification, history) => ({
  type: CREATE_MAHALL_NOTIFICATION,
  payload: { mahallNotification, history },
})

export const createMahallNotificationSuccess = mahallNotification => ({
  type: CREATE_MAHALL_NOTIFICATION_SUCCESS,
  payload: mahallNotification,
})

export const createMahallNotificationFail = error => ({
  type: CREATE_MAHALL_NOTIFICATION_FAIL,
  payload: error,
})

export const updateMahallNotification = (
  mahallNotification,
  mahallNotificationId
) => ({
  type: UPDATE_MAHALL_NOTIFICATION,
  payload: { mahallNotification, mahallNotificationId },
})

export const updateMahallNotificationSuccess = mahallNotification => ({
  type: UPDATE_MAHALL_NOTIFICATION_SUCCESS,
  payload: mahallNotification,
})

export const updateMahallNotificationFail = error => ({
  type: UPDATE_MAHALL_NOTIFICATION_FAIL,
  payload: error,
})

export const deleteMahallNotification = (mahallNotificationId, history) => ({
  type: DELETE_MAHALL_NOTIFICATION,
  payload: { mahallNotificationId, history },
})

export const deleteMahallNotificationSuccess = mahallNotification => ({
  type: DELETE_MAHALL_NOTIFICATION_SUCCESS,
  payload: mahallNotification,
})

export const deleteMahallNotificationFail = error => ({
  type: DELETE_MAHALL_NOTIFICATION_FAIL,
  payload: error,
})

// ------------------------------------FAMILY MEMBER COLLECTION NOTIFICATIONS----------------------------------------

export const getFamilyMemberCollectionNotifications = (
  searchText,
  page,
  collectionId
) => ({
  type: GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS,
  payload: { searchText, page, collectionId },
})

export const getFamilyMemberCollectionNotificationsSuccess =
  familyMemberCollectionNotifications => ({
    type: GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS_SUCCESS,
    payload: familyMemberCollectionNotifications,
  })

export const getFamilyMemberCollectionNotificationsFail = error => ({
  type: GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS_FAIL,
  payload: error,
})

export const getFamilyMemberCollectionNotificationsCards = () => ({
  type: GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS_CARDS,
})

export const getFamilyMemberCollectionNotificationsCardsSuccess =
  familyMemberCollectionNotificationsCards => ({
    type: GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS_CARDS_SUCCESS,
    payload: familyMemberCollectionNotificationsCards,
  })

export const getFamilyMemberCollectionNotificationsCardsFail = error => ({
  type: GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS_CARDS_FAIL,
  payload: error,
})

export const getFamilyMemberCollectionNotificationDetail =
  familyMemberCollectionNotificationId => ({
    type: GET_FAMILY_MEMBER_COLLECTION_NOTIFICATION_DETAIL,
    familyMemberCollectionNotificationId,
  })

export const getFamilyMemberCollectionNotificationDetailSuccess =
  familyMemberCollectionNotificationDetail => ({
    type: GET_FAMILY_MEMBER_COLLECTION_NOTIFICATION_DETAIL_SUCCESS,
    payload: familyMemberCollectionNotificationDetail,
  })

export const getFamilyMemberCollectionNotificationDetailFail = error => ({
  type: GET_FAMILY_MEMBER_COLLECTION_NOTIFICATION_DETAIL_FAIL,
  payload: error,
})

export const createFamilyMemberCollectionNotification = (
  familyMemberCollectionNotification,
  history
) => ({
  type: CREATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION,
  payload: { familyMemberCollectionNotification, history },
})

export const createFamilyMemberCollectionNotificationSuccess =
  familyMemberCollectionNotification => ({
    type: CREATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_SUCCESS,
    payload: familyMemberCollectionNotification,
  })

export const createFamilyMemberCollectionNotificationFail = error => ({
  type: CREATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_FAIL,
  payload: error,
})

export const updateFamilyMemberCollectionNotification = (
  familyMemberCollectionNotification,
  familyMemberCollectionNotificationId
) => ({
  type: UPDATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION,
  payload: {
    familyMemberCollectionNotification,
    familyMemberCollectionNotificationId,
  },
})

export const updateFamilyMemberCollectionNotificationSuccess =
  familyMemberCollectionNotification => ({
    type: UPDATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_SUCCESS,
    payload: familyMemberCollectionNotification,
  })

export const updateFamilyMemberCollectionNotificationFail = error => ({
  type: UPDATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_FAIL,
  payload: error,
})

export const deleteFamilyMemberCollectionNotification = (
  familyMemberCollectionNotificationId,
  history
) => ({
  type: DELETE_FAMILY_MEMBER_COLLECTION_NOTIFICATION,
  payload: { familyMemberCollectionNotificationId, history },
})

export const deleteFamilyMemberCollectionNotificationSuccess =
  familyMemberCollectionNotification => ({
    type: DELETE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_SUCCESS,
    payload: familyMemberCollectionNotification,
  })

export const deleteFamilyMemberCollectionNotificationFail = error => ({
  type: DELETE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_FAIL,
  payload: error,
})

// ------------------------------------INDIVIDUAL NOTIFICATIONS----------------------------------------

export const getIndividualNotifications = (searchText, page) => ({
  type: GET_INDIVIDUAL_NOTIFICATIONS,
  payload: { searchText, page },
})

export const getIndividualNotificationsSuccess = individualNotifications => ({
  type: GET_INDIVIDUAL_NOTIFICATIONS_SUCCESS,
  payload: individualNotifications,
})

export const getIndividualNotificationsFail = error => ({
  type: GET_INDIVIDUAL_NOTIFICATIONS_FAIL,
  payload: error,
})

export const getIndividualNotificationsCards = () => ({
  type: GET_INDIVIDUAL_NOTIFICATIONS_CARDS,
})

export const getIndividualNotificationsCardsSuccess =
  individualNotificationsCards => ({
    type: GET_INDIVIDUAL_NOTIFICATIONS_CARDS_SUCCESS,
    payload: individualNotificationsCards,
  })

export const getIndividualNotificationsCardsFail = error => ({
  type: GET_INDIVIDUAL_NOTIFICATIONS_CARDS_FAIL,
  payload: error,
})

export const getIndividualNotificationDetail = individualNotificationId => ({
  type: GET_INDIVIDUAL_NOTIFICATION_DETAIL,
  individualNotificationId,
})

export const getIndividualNotificationDetailSuccess =
  individualNotificationDetail => ({
    type: GET_INDIVIDUAL_NOTIFICATION_DETAIL_SUCCESS,
    payload: individualNotificationDetail,
  })

export const getIndividualNotificationDetailFail = error => ({
  type: GET_INDIVIDUAL_NOTIFICATION_DETAIL_FAIL,
  payload: error,
})

export const createIndividualNotification = (
  individualNotification,
  history
) => ({
  type: CREATE_INDIVIDUAL_NOTIFICATION,
  payload: { individualNotification, history },
})

export const createIndividualNotificationSuccess = individualNotification => ({
  type: CREATE_INDIVIDUAL_NOTIFICATION_SUCCESS,
  payload: individualNotification,
})

export const createIndividualNotificationFail = error => ({
  type: CREATE_INDIVIDUAL_NOTIFICATION_FAIL,
  payload: error,
})

export const updateIndividualNotification = (
  individualNotification,
  individualNotificationId
) => ({
  type: UPDATE_INDIVIDUAL_NOTIFICATION,
  payload: { individualNotification, individualNotificationId },
})

export const updateIndividualNotificationSuccess = individualNotification => ({
  type: UPDATE_INDIVIDUAL_NOTIFICATION_SUCCESS,
  payload: individualNotification,
})

export const updateIndividualNotificationFail = error => ({
  type: UPDATE_INDIVIDUAL_NOTIFICATION_FAIL,
  payload: error,
})

export const deleteIndividualNotification = (
  individualNotificationId,
  history
) => ({
  type: DELETE_INDIVIDUAL_NOTIFICATION,
  payload: { individualNotificationId, history },
})

export const deleteIndividualNotificationSuccess = individualNotification => ({
  type: DELETE_INDIVIDUAL_NOTIFICATION_SUCCESS,
  payload: individualNotification,
})

export const deleteIndividualNotificationFail = error => ({
  type: DELETE_INDIVIDUAL_NOTIFICATION_FAIL,
  payload: error,
})
