/* EDUCATIONS */
export const GET_EDUCATION_FAMILY_MEMBER = "GET_EDUCATION_FAMILY_MEMBER"
export const GET_EDUCATION_FAMILY_MEMBER_SUCCESS =
  "GET_EDUCATION_FAMILY_MEMBER_SUCCESS"
export const GET_EDUCATION_FAMILY_MEMBER_FAIL =
  "GET_EDUCATION_FAMILY_MEMBER_FAIL"

/* EDUCATIONS DETAIL*/
export const GET_EDUCATION_FAMILY_MEMBER_DETAIL =
  "GET_EDUCATION_FAMILY_MEMBER_DETAIL"
export const GET_EDUCATION_FAMILY_MEMBER_DETAIL_SUCCESS =
  "GET_EDUCATION_FAMILY_MEMBER_DETAIL_SUCCESS"
export const GET_EDUCATION_FAMILY_MEMBER_DETAIL_FAIL =
  "GET_EDUCATION_FAMILY_MEMBER_DETAIL_FAIL"

/**
 * add EDUCATION
 */
export const CREATE_EDUCATION_FAMILY_MEMBER = "CREATE_EDUCATION_FAMILY_MEMBER"
export const CREATE_EDUCATION_FAMILY_MEMBER_SUCCESS =
  "CREATE_EDUCATION_FAMILY_MEMBER_SUCCESS"
export const CREATE_EDUCATION_FAMILY_MEMBER_FAIL =
  "CREATE_EDUCATION_FAMILY_MEMBER_FAIL"

/**
 * Edit EDUCATION
 */
export const UPDATE_EDUCATION_FAMILY_MEMBER = "UPDATE_EDUCATION_FAMILY_MEMBER"
export const UPDATE_EDUCATION_FAMILY_MEMBER_SUCCESS =
  "UPDATE_EDUCATION_FAMILY_MEMBER_SUCCESS"
export const UPDATE_EDUCATION_FAMILY_MEMBER_FAIL =
  "UPDATE_EDUCATION_FAMILY_MEMBER_FAIL"

/**
 * Delete EDUCATION
 */
export const DELETE_EDUCATION_FAMILY_MEMBER = "DELETE_EDUCATION_FAMILY_MEMBER"
export const DELETE_EDUCATION_FAMILY_MEMBER_SUCCESS =
  "DELETE_EDUCATION_FAMILY_MEMBER_SUCCESS"
export const DELETE_EDUCATION_FAMILY_MEMBER_FAIL =
  "DELETE_EDUCATION_FAMILY_MEMBER_FAIL"
