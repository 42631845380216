/* JOBS */
export const GET_JOB_FAMILY_MEMBER = "GET_JOB_FAMILY_MEMBER"
export const GET_JOB_FAMILY_MEMBER_SUCCESS = "GET_JOB_FAMILY_MEMBER_SUCCESS"
export const GET_JOB_FAMILY_MEMBER_FAIL = "GET_JOB_FAMILY_MEMBER_FAIL"

/* JOBS DETAIL*/
export const GET_JOB_FAMILY_MEMBER_DETAIL = "GET_JOB_FAMILY_MEMBER_DETAIL"
export const GET_JOB_FAMILY_MEMBER_DETAIL_SUCCESS =
  "GET_JOB_FAMILY_MEMBER_DETAIL_SUCCESS"
export const GET_JOB_FAMILY_MEMBER_DETAIL_FAIL =
  "GET_JOB_FAMILY_MEMBER_DETAIL_FAIL"

/**
 * add JOB_FAMILY_MEMBER
 */
export const CREATE_JOB_FAMILY_MEMBER = "CREATE_JOB_FAMILY_MEMBER"
export const CREATE_JOB_FAMILY_MEMBER_SUCCESS =
  "CREATE_JOB_FAMILY_MEMBER_SUCCESS"
export const CREATE_JOB_FAMILY_MEMBER_FAIL = "CREATE_JOB_FAMILY_MEMBER_FAIL"

/**
 * Edit JOB_FAMILY_MEMBER
 */
export const UPDATE_JOB_FAMILY_MEMBER = "UPDATE_JOB_FAMILY_MEMBER"
export const UPDATE_JOB_FAMILY_MEMBER_SUCCESS =
  "UPDATE_JOB_FAMILY_MEMBER_SUCCESS"
export const UPDATE_JOB_FAMILY_MEMBER_FAIL = "UPDATE_JOB_FAMILY_MEMBER_FAIL"

/**
 * Delete JOB_FAMILY_MEMBER
 */
export const DELETE_JOB_FAMILY_MEMBER = "DELETE_JOB_FAMILY_MEMBER"
export const DELETE_JOB_FAMILY_MEMBER_SUCCESS =
  "DELETE_JOB_FAMILY_MEMBER_SUCCESS"
export const DELETE_JOB_FAMILY_MEMBER_FAIL = "DELETE_JOB_FAMILY_MEMBER_FAIL"
