import {
  GET_EDUCATION_FAMILY_MEMBER,
  GET_EDUCATION_FAMILY_MEMBER_SUCCESS,
  GET_EDUCATION_FAMILY_MEMBER_FAIL,
  GET_EDUCATION_FAMILY_MEMBER_DETAIL,
  GET_EDUCATION_FAMILY_MEMBER_DETAIL_SUCCESS,
  GET_EDUCATION_FAMILY_MEMBER_DETAIL_FAIL,
  CREATE_EDUCATION_FAMILY_MEMBER,
  CREATE_EDUCATION_FAMILY_MEMBER_SUCCESS,
  CREATE_EDUCATION_FAMILY_MEMBER_FAIL,
  UPDATE_EDUCATION_FAMILY_MEMBER,
  UPDATE_EDUCATION_FAMILY_MEMBER_SUCCESS,
  UPDATE_EDUCATION_FAMILY_MEMBER_FAIL,
  DELETE_EDUCATION_FAMILY_MEMBER,
  DELETE_EDUCATION_FAMILY_MEMBER_SUCCESS,
  DELETE_EDUCATION_FAMILY_MEMBER_FAIL,
} from "./actionTypes"

export const getEducationFamilyMember = (searchText, page) => ({
  type: GET_EDUCATION_FAMILY_MEMBER,
  payload: { searchText, page },
})

export const getEducationFamilyMemberSuccess = educations => ({
  type: GET_EDUCATION_FAMILY_MEMBER_SUCCESS,
  payload: educations,
})
export const getEducationFamilyMemberFail = error => ({
  type: GET_EDUCATION_FAMILY_MEMBER_FAIL,
  payload: error,
})

export const getEducationFamilyMemberDetail = educationId => ({
  type: GET_EDUCATION_FAMILY_MEMBER_DETAIL,
  educationId,
})

export const getEducationFamilyMemberDetailSuccess = educationDetail => ({
  type: GET_EDUCATION_FAMILY_MEMBER_DETAIL_SUCCESS,
  payload: educationDetail,
})

export const getEducationFamilyMemberDetailFail = error => ({
  type: GET_EDUCATION_FAMILY_MEMBER_DETAIL_FAIL,
  payload: error,
})

export const createEducationFamilyMember = (education, history) => ({
  type: CREATE_EDUCATION_FAMILY_MEMBER,
  payload: { education, history },
})

export const createEducationFamilyMemberSuccess = education => ({
  type: CREATE_EDUCATION_FAMILY_MEMBER_SUCCESS,
  payload: education,
})

export const createEducationFamilyMemberFail = error => ({
  type: CREATE_EDUCATION_FAMILY_MEMBER_FAIL,
  payload: error,
})

export const updateEducationFamilyMember = (
  education,
  educationId,
  history
) => ({
  type: UPDATE_EDUCATION_FAMILY_MEMBER,
  payload: { education, educationId, history },
})

export const updateEducationFamilyMemberSuccess = education => ({
  type: UPDATE_EDUCATION_FAMILY_MEMBER_SUCCESS,
  payload: education,
})

export const updateEducationFamilyMemberFail = error => ({
  type: UPDATE_EDUCATION_FAMILY_MEMBER_FAIL,
  payload: error,
})

export const deleteEducationFamilyMember = (educationId, history) => ({
  type: DELETE_EDUCATION_FAMILY_MEMBER,
  payload: { educationId, history },
})

export const deleteEducationFamilyMemberSuccess = education => ({
  type: DELETE_EDUCATION_FAMILY_MEMBER_SUCCESS,
  payload: education,
})

export const deleteEducationFamilyMemberFail = error => ({
  type: DELETE_EDUCATION_FAMILY_MEMBER_FAIL,
  payload: error,
})
