/* FEEDS */
export const GET_FEEDS = "GET_FEEDS"
export const GET_FEEDS_SUCCESS = "GET_FEEDS_SUCCESS"
export const GET_FEEDS_FAIL = "GET_FEEDS_FAIL"

/* FEED DETAIL*/
export const GET_FEED_DETAIL = "GET_FEED_DETAIL"
export const GET_FEED_DETAIL_SUCCESS = "GET_FEED_DETAIL_SUCCESS"
export const GET_FEED_DETAIL_FAIL = "GET_FEED_DETAIL_FAIL"

/**
 * add FEED
 */
export const CREATE_FEED = "CREATE_FEED"
export const CREATE_FEED_SUCCESS = "CREATE_FEED_SUCCESS"
export const CREATE_FEED_FAIL = "CREATE_FEED_FAIL"

/**
 * Edit FEED
 */
export const UPDATE_FEED = "UPDATE_FEED"
export const UPDATE_FEED_SUCCESS = "UPDATE_FEED_SUCCESS"
export const UPDATE_FEED_FAIL = "UPDATE_FEED_FAIL"

/**
 * Delete FEED
 */
export const DELETE_FEED = "DELETE_FEED"
export const DELETE_FEED_SUCCESS = "DELETE_FEED_SUCCESS"
export const DELETE_FEED_FAIL = "DELETE_FEED_FAIL"
