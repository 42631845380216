import {
  GET_GROUP,
  GET_GROUP_SUCCESS,
  GET_GROUP_FAIL,
  GET_GROUP_DETAIL,
  GET_GROUP_DETAIL_SUCCESS,
  GET_GROUP_DETAIL_FAIL,
  CREATE_GROUP,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_FAIL,
  UPDATE_GROUP,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAIL,
  DELETE_GROUP,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAIL,
} from "./actionTypes"

export const getGroup = (searchText, page) => ({
  type: GET_GROUP,
  payload: { searchText, page },
})

export const getGroupSuccess = groups => ({
  type: GET_GROUP_SUCCESS,
  payload: groups,
})
export const getGroupFail = error => ({
  type: GET_GROUP_FAIL,
  payload: error,
})

export const getGroupDetail = groupId => ({
  type: GET_GROUP_DETAIL,
  groupId,
})

export const getGroupDetailSuccess = groupDetail => ({
  type: GET_GROUP_DETAIL_SUCCESS,
  payload: groupDetail,
})

export const getGroupDetailFail = error => ({
  type: GET_GROUP_DETAIL_FAIL,
  payload: error,
})

export const createGroup = (group, history) => ({
  type: CREATE_GROUP,
  payload: { group, history },
})

export const createGroupSuccess = group => ({
  type: CREATE_GROUP_SUCCESS,
  payload: group,
})

export const createGroupFail = error => ({
  type: CREATE_GROUP_FAIL,
  payload: error,
})

export const updateGroup = (group, groupId, history) => ({
  type: UPDATE_GROUP,
  payload: { group, groupId, history },
})

export const updateGroupSuccess = group => ({
  type: UPDATE_GROUP_SUCCESS,
  payload: group,
})

export const updateGroupFail = error => ({
  type: UPDATE_GROUP_FAIL,
  payload: error,
})

export const deleteGroup = (groupId, history) => ({
  type: DELETE_GROUP,
  payload: { groupId, history },
})

export const deleteGroupSuccess = group => ({
  type: DELETE_GROUP_SUCCESS,
  payload: group,
})

export const deleteGroupFail = error => ({
  type: DELETE_GROUP_FAIL,
  payload: error,
})
