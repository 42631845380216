import {
  GET_BANNERS,
  GET_BANNERS_SUCCESS,
  GET_BANNERS_FAIL,
  GET_BANNER_DETAIL,
  GET_BANNER_DETAIL_SUCCESS,
  GET_BANNER_DETAIL_FAIL,
  CREATE_BANNER,
  CREATE_BANNER_SUCCESS,
  CREATE_BANNER_FAIL,
  UPDATE_BANNER,
  UPDATE_BANNER_SUCCESS,
  UPDATE_BANNER_FAIL,
  DELETE_BANNER,
  DELETE_BANNER_SUCCESS,
  DELETE_BANNER_FAIL,
} from "./actionTypes"

export const getBanners = (searchText, page) => ({
  type: GET_BANNERS,
  payload: { searchText, page },
})
export const getBannersSuccess = banner => ({
  type: GET_BANNERS_SUCCESS,
  payload: banner,
})
export const getBannersFail = error => ({
  type: GET_BANNERS_FAIL,
  payload: error,
})

// export const getVarisangyaCards = () => ({
//   type: GET_VARISANGYA_CARDS,
// })
// export const getVarisangyaCardsSuccess = varisangyaCards => ({
//   type: GET_VARISANGYA_CARDS_SUCCESS,
//   payload: varisangyaCards,
// })
// export const getVarisangyaCardsFail = error => ({
//   type: GET_VARISANGYA_CARDS_FAIL,
//   payload: error,
// })

export const getBannerDetail = bannerId => ({
  type: GET_BANNER_DETAIL,
  bannerId,
})

export const getBannerDetailSuccess = bannerDetail => ({
  type: GET_BANNER_DETAIL_SUCCESS,
  payload: bannerDetail,
})

export const getBannerDetailFail = error => ({
  type: GET_BANNER_DETAIL_FAIL,
  payload: error,
})

export const createBanner = (banner, history) => ({
  type: CREATE_BANNER,
  payload: { banner, history },
})

export const createBannerSuccess = banner => ({
  type: CREATE_BANNER_SUCCESS,
  payload: banner,
})

export const createBannerFail = error => ({
  type: CREATE_BANNER_FAIL,
  payload: error,
})

export const updateBanner = (banner, bannerId, history) => ({
  type: UPDATE_BANNER,
  payload: { banner, bannerId, history },
})

export const updateBannerSuccess = banner => ({
  type: UPDATE_BANNER_SUCCESS,
  payload: banner,
})

export const updateBannerFail = error => ({
  type: UPDATE_BANNER_FAIL,
  payload: error,
})

export const deleteBanner = (bannerId, history) => ({
  type: DELETE_BANNER,
  payload: { bannerId, history },
})

export const deleteBannerSuccess = banner => ({
  type: DELETE_BANNER_SUCCESS,
  payload: banner,
})

export const deleteBannerFail = error => ({
  type: DELETE_BANNER_FAIL,
  payload: error,
})
