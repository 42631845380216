import {
  GET_MASTER_ACCOUNT_ITEMS,
  GET_MASTER_ACCOUNT_ITEMS_SUCCESS,
  GET_MASTER_ACCOUNT_ITEMS_FAIL,
  GET_MASTER_ACCOUNT_ITEMS_FOR_DOWNLOAD,
  GET_MASTER_ACCOUNT_ITEMS_FOR_DOWNLOAD_SUCCESS,
  GET_MASTER_ACCOUNT_ITEMS_FOR_DOWNLOAD_FAIL,
} from "./actionTypes"

export const getMasterAccountItems = (
  searchText,
  page,
  date,
  sort,
  instituteType
) => ({
  type: GET_MASTER_ACCOUNT_ITEMS,
  payload: { searchText, page, date, sort, instituteType },
})

export const getMasterAccountItemsSuccess = masterAccountItems => ({
  type: GET_MASTER_ACCOUNT_ITEMS_SUCCESS,
  payload: masterAccountItems,
})
export const getMasterAccountItemsFail = error => ({
  type: GET_MASTER_ACCOUNT_ITEMS_FAIL,
  payload: error,
})

export const getMasterAccountItemsForDownload = (
  date,
  sort,
  instituteType
) => ({
  type: GET_MASTER_ACCOUNT_ITEMS_FOR_DOWNLOAD,
  payload: { date, sort, instituteType },
})

export const getMasterAccountItemsForDownloadSuccess =
  masterAccountItemsForDownload => ({
    type: GET_MASTER_ACCOUNT_ITEMS_FOR_DOWNLOAD_SUCCESS,
    payload: masterAccountItemsForDownload,
  })
export const getMasterAccountItemsForDownloadFail = error => ({
  type: GET_MASTER_ACCOUNT_ITEMS_FOR_DOWNLOAD_FAIL,
  payload: error,
})
