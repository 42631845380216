import {
  GET_COMMITTEES,
  GET_COMMITTEES_SUCCESS,
  GET_COMMITTEES_FAIL,
  GET_COMMITTEE_DROPDOWN,
  GET_COMMITTEE_DROPDOWN_SUCCESS,
  GET_COMMITTEE_DROPDOWN_FAIL,
  GET_COMMITTEE_DETAIL,
  GET_COMMITTEE_DETAIL_SUCCESS,
  GET_COMMITTEE_DETAIL_FAIL,
  CREATE_COMMITTEE,
  CREATE_COMMITTEE_SUCCESS,
  CREATE_COMMITTEE_FAIL,
  UPDATE_COMMITTEE,
  UPDATE_COMMITTEE_SUCCESS,
  UPDATE_COMMITTEE_FAIL,
  DELETE_COMMITTEE,
  DELETE_COMMITTEE_SUCCESS,
  DELETE_COMMITTEE_FAIL,
} from "./actionTypes"

export const getCommittees = (instituteId, page, searchText) => ({
  type: GET_COMMITTEES,
  payload: { instituteId, page, searchText },
})

export const getCommitteesSuccess = committees => ({
  type: GET_COMMITTEES_SUCCESS,
  payload: committees,
})
export const getCommitteesFail = error => ({
  type: GET_COMMITTEES_FAIL,
  payload: error,
})

export const getCommitteeDropdown = searchText => ({
  type: GET_COMMITTEE_DROPDOWN,
  payload: searchText,
})

export const getCommitteeDropdownSuccess = committees => ({
  type: GET_COMMITTEE_DROPDOWN_SUCCESS,
  payload: committees,
})
export const getCommitteeDropdownFail = error => ({
  type: GET_COMMITTEE_DROPDOWN_FAIL,
  payload: error,
})

export const getCommitteeDetail = committeeId => ({
  type: GET_COMMITTEE_DETAIL,
  committeeId,
})

export const getCommitteeDetailSuccess = committeeDetail => ({
  type: GET_COMMITTEE_DETAIL_SUCCESS,
  payload: committeeDetail,
})

export const getCommitteeDetailFail = error => ({
  type: GET_COMMITTEE_DETAIL_FAIL,
  payload: error,
})

export const createCommittee = committee => ({
  type: CREATE_COMMITTEE,
  payload: { committee },
})

export const createCommitteeSuccess = committee => ({
  type: CREATE_COMMITTEE_SUCCESS,
  payload: committee,
})

export const createCommitteeFail = error => ({
  type: CREATE_COMMITTEE_FAIL,
  payload: error,
})

export const updateCommittee = (committee, committeeId) => ({
  type: UPDATE_COMMITTEE,
  payload: { committee, committeeId },
})

export const updateCommitteeSuccess = committee => ({
  type: UPDATE_COMMITTEE_SUCCESS,
  payload: committee,
})

export const updateCommitteeFail = error => ({
  type: UPDATE_COMMITTEE_FAIL,
  payload: error,
})

export const deleteCommittee = (committeeId, history) => ({
  type: DELETE_COMMITTEE,
  payload: { committeeId, history },
})

export const deleteCommitteeSuccess = committee => ({
  type: DELETE_COMMITTEE_SUCCESS,
  payload: committee,
})

export const deleteCommitteeFail = error => ({
  type: DELETE_COMMITTEE_FAIL,
  payload: error,
})
