import {
  GET_FAMILY_MEMBER_VARISANGYAS,
  GET_FAMILY_MEMBER_VARISANGYAS_SUCCESS,
  GET_FAMILY_MEMBER_VARISANGYAS_FAIL,
  GET_FAMILY_MEMBER_VARISANGYA_CARDS,
  GET_FAMILY_MEMBER_VARISANGYA_CARDS_SUCCESS,
  GET_FAMILY_MEMBER_VARISANGYA_CARDS_FAIL,
  GET_FAMILY_MEMBER_VARISANGYA_DETAIL,
  GET_FAMILY_MEMBER_VARISANGYA_DETAIL_SUCCESS,
  GET_FAMILY_MEMBER_VARISANGYA_DETAIL_FAIL,
  CREATE_FAMILY_MEMBER_VARISANGYA,
  CREATE_FAMILY_MEMBER_VARISANGYA_SUCCESS,
  CREATE_FAMILY_MEMBER_VARISANGYA_FAIL,
  UPDATE_FAMILY_MEMBER_VARISANGYA,
  UPDATE_FAMILY_MEMBER_VARISANGYA_SUCCESS,
  UPDATE_FAMILY_MEMBER_VARISANGYA_FAIL,
  DELETE_FAMILY_MEMBER_VARISANGYA,
  DELETE_FAMILY_MEMBER_VARISANGYA_SUCCESS,
  DELETE_FAMILY_MEMBER_VARISANGYA_FAIL,
  AUTO_GENERATE_FAMILY_MEMBER_VARISANGYAS,
  AUTO_GENERATE_FAMILY_MEMBER_VARISANGYAS_SUCCESS,
  AUTO_GENERATE_FAMILY_MEMBER_VARISANGYAS_FAIL,
  REFRESH_FAMILY_MEMBER_VARISANGYA,
  REFRESH_FAMILY_MEMBER_VARISANGYA_SUCCESS,
  REFRESH_FAMILY_MEMBER_VARISANGYA_FAIL,
  UPDATE_FAMILY_MEMBER_VARISANGYA_AMOUNT_BY_FILTER,
  UPDATE_FAMILY_MEMBER_VARISANGYA_AMOUNT_BY_FILTER_SUCCESS,
  UPDATE_FAMILY_MEMBER_VARISANGYA_AMOUNT_BY_FILTER_FAIL,
} from "./actionTypes"

export const getFamilyMemberVarisangyas = (searchText, page, limit) => ({
  type: GET_FAMILY_MEMBER_VARISANGYAS,
  payload: { searchText, page, limit },
})
export const getFamilyMemberVarisangyasSuccess = familyMemberVarisangya => ({
  type: GET_FAMILY_MEMBER_VARISANGYAS_SUCCESS,
  payload: familyMemberVarisangya,
})
export const getFamilyMemberVarisangyasFail = error => ({
  type: GET_FAMILY_MEMBER_VARISANGYAS_FAIL,
  payload: error,
})

export const getFamilyMemberVarisangyaCards = () => ({
  type: GET_FAMILY_MEMBER_VARISANGYA_CARDS,
})
export const getFamilyMemberVarisangyaCardsSuccess =
  familyMemberVarisangyaCards => ({
    type: GET_FAMILY_MEMBER_VARISANGYA_CARDS_SUCCESS,
    payload: familyMemberVarisangyaCards,
  })
export const getFamilyMemberVarisangyaCardsFail = error => ({
  type: GET_FAMILY_MEMBER_VARISANGYA_CARDS_FAIL,
  payload: error,
})

export const getFamilyMemberVarisangyaDetail = familyMemberVarisangyaId => ({
  type: GET_FAMILY_MEMBER_VARISANGYA_DETAIL,
  familyMemberVarisangyaId,
})

export const getFamilyMemberVarisangyaDetailSuccess =
  familyMemberVarisangyaDetail => ({
    type: GET_FAMILY_MEMBER_VARISANGYA_DETAIL_SUCCESS,
    payload: familyMemberVarisangyaDetail,
  })

export const getFamilyMemberVarisangyaDetailFail = error => ({
  type: GET_FAMILY_MEMBER_VARISANGYA_DETAIL_FAIL,
  payload: error,
})

export const createFamilyMemberVarisangya = (
  familyMemberVarisangya,
  history
) => ({
  type: CREATE_FAMILY_MEMBER_VARISANGYA,
  payload: { familyMemberVarisangya, history },
})

export const createFamilyMemberVarisangyaSuccess = familyMemberVarisangya => ({
  type: CREATE_FAMILY_MEMBER_VARISANGYA_SUCCESS,
  payload: familyMemberVarisangya,
})

export const createFamilyMemberVarisangyaFail = error => ({
  type: CREATE_FAMILY_MEMBER_VARISANGYA_FAIL,
  payload: error,
})

export const updateFamilyMemberVarisangya = (
  familyMemberVarisangya,
  familyMemberVarisangyaId,
  history
) => ({
  type: UPDATE_FAMILY_MEMBER_VARISANGYA,
  payload: { familyMemberVarisangya, familyMemberVarisangyaId, history },
})

export const updateFamilyMemberVarisangyaSuccess = familyMemberVarisangya => ({
  type: UPDATE_FAMILY_MEMBER_VARISANGYA_SUCCESS,
  payload: familyMemberVarisangya,
})

export const updateFamilyMemberVarisangyaFail = error => ({
  type: UPDATE_FAMILY_MEMBER_VARISANGYA_FAIL,
  payload: error,
})

export const deleteFamilyMemberVarisangya = (
  familyMemberVarisangyaId,
  history
) => ({
  type: DELETE_FAMILY_MEMBER_VARISANGYA,
  payload: { familyMemberVarisangyaId, history },
})

export const deleteFamilyMemberVarisangyaSuccess = familyMemberVarisangya => ({
  type: DELETE_FAMILY_MEMBER_VARISANGYA_SUCCESS,
  payload: familyMemberVarisangya,
})

export const deleteFamilyMemberVarisangyaFail = error => ({
  type: DELETE_FAMILY_MEMBER_VARISANGYA_FAIL,
  payload: error,
})

export const autoGenerateFamilyMemberVarisangyas = () => ({
  type: AUTO_GENERATE_FAMILY_MEMBER_VARISANGYAS,
})

export const autoGenerateFamilyMemberVarisangyasSuccess =
  familyMemberVarisangya => ({
    type: AUTO_GENERATE_FAMILY_MEMBER_VARISANGYAS_SUCCESS,
    payload: familyMemberVarisangya,
  })

export const autoGenerateFamilyMemberVarisangyasFail = error => ({
  type: AUTO_GENERATE_FAMILY_MEMBER_VARISANGYAS_FAIL,
  payload: error,
})

export const refreshFamilyMemberVarisangya = familyMemberVarisangyaId => ({
  type: REFRESH_FAMILY_MEMBER_VARISANGYA,
  payload: { familyMemberVarisangyaId },
})

export const refreshFamilyMemberVarisangyaSuccess = familyMemberVarisangya => ({
  type: REFRESH_FAMILY_MEMBER_VARISANGYA_SUCCESS,
  payload: familyMemberVarisangya,
})

export const refreshFamilyMemberVarisangyaFail = error => ({
  type: REFRESH_FAMILY_MEMBER_VARISANGYA_FAIL,
  payload: error,
})

export const updateFamilyMemberVarisangyaAmountByFilter = (
  familyMemberVarisangyaAmuontByFilter,
  history
) => ({
  type: UPDATE_FAMILY_MEMBER_VARISANGYA_AMOUNT_BY_FILTER,
  payload: { familyMemberVarisangyaAmuontByFilter, history },
})

export const updateFamilyMemberVarisangyaAmountByFilterSuccess =
  familyMemberVarisangyaAmuontByFilter => ({
    type: UPDATE_FAMILY_MEMBER_VARISANGYA_AMOUNT_BY_FILTER_SUCCESS,
    payload: familyMemberVarisangyaAmuontByFilter,
  })

export const updateFamilyMemberVarisangyaAmountByFilterFail = error => ({
  type: UPDATE_FAMILY_MEMBER_VARISANGYA_AMOUNT_BY_FILTER_FAIL,
  payload: error,
})
