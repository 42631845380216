import {
  GET_USER_DETAIL,
  GET_USER_DETAIL_SUCCESS,
  GET_USER_DETAIL_FAIL,
  CHECK_USER_PHONE_NUMBER,
  CHECK_USER_PHONE_NUMBER_SUCCESS,
  CHECK_USER_PHONE_NUMBER_FAIL,
  CHECK_FAMILY_MEMBER_PHONE_NUMBER,
  CHECK_FAMILY_MEMBER_PHONE_NUMBER_SUCCESS,
  CHECK_FAMILY_MEMBER_PHONE_NUMBER_FAIL,
  CHECK_SURVEY_USER_PHONE_NUMBER,
  CHECK_SURVEY_USER_PHONE_NUMBER_SUCCESS,
  CHECK_SURVEY_USER_PHONE_NUMBER_FAIL,
  CHECK_GROUP_ADMIN_PHONE_NUMBER,
  CHECK_GROUP_ADMIN_PHONE_NUMBER_SUCCESS,
  CHECK_GROUP_ADMIN_PHONE_NUMBER_FAIL,
  CHECK_INSTITUTE_USER_PHONE_NUMBER,
  CHECK_INSTITUTE_USER__PHONE_NUMBER_SUCCESS,
  CHECK_INSTITUTE_USER__PHONE_NUMBER_FAIL,
  CHECK_CUSTOM_ID,
  CHECK_CUSTOM_ID_SUCCESS,
  CHECK_CUSTOM_ID_FAIL,
  CHECK_FAMILY_MEMBER_PHONE_NUMBER_UPDATE,
  CHECK_FAMILY_MEMBER_PHONE_NUMBER_UPDATE_SUCCESS,
  CHECK_FAMILY_MEMBER_PHONE_NUMBER_UPDATE_FAIL,
  SET_USER_ROLE,
  SET_USER_ROLE_SUCCESS,
  SET_USER_ROLE_FAIL,
} from "./actionTypes"

export const getUserDetail = () => ({
  type: GET_USER_DETAIL,
})

export const getUserDetailSuccess = UserDetails => ({
  type: GET_USER_DETAIL_SUCCESS,
  payload: UserDetails,
})

export const getUserDetailFail = error => ({
  type: GET_USER_DETAIL_FAIL,
  payload: error,
})

export const checkUserPhoneNumber = checkData => ({
  type: CHECK_USER_PHONE_NUMBER,
  payload: { checkData },
})

export const checkUserPhoneNumberSuccess = userPhoneNumberAvailability => ({
  type: CHECK_USER_PHONE_NUMBER_SUCCESS,
  payload: userPhoneNumberAvailability,
})

export const checkUserPhoneNumberFail = error => ({
  type: CHECK_USER_PHONE_NUMBER_FAIL,
  payload: error,
})

export const checkFamilyMemberPhoneNumber = checkData => ({
  type: CHECK_FAMILY_MEMBER_PHONE_NUMBER,
  payload: { checkData },
})

export const checkFamilyMemberPhoneNumberSuccess =
  familyMemberPhoneNumberAvailability => ({
    type: CHECK_FAMILY_MEMBER_PHONE_NUMBER_SUCCESS,
    payload: familyMemberPhoneNumberAvailability,
  })

export const checkFamilyMemberPhoneNumberFail = error => ({
  type: CHECK_FAMILY_MEMBER_PHONE_NUMBER_FAIL,
  payload: error,
})

export const checkFamilyMemberPhoneNumberUpdate = (
  checkData,
  familyMemberId
) => ({
  type: CHECK_FAMILY_MEMBER_PHONE_NUMBER_UPDATE,
  payload: { checkData, familyMemberId },
})

export const checkFamilyMemberPhoneNumberUpdateSuccess =
  familyMemberPhoneNumberUpdateAvailability => ({
    type: CHECK_FAMILY_MEMBER_PHONE_NUMBER_UPDATE_SUCCESS,
    payload: familyMemberPhoneNumberUpdateAvailability,
  })

export const checkFamilyMemberPhoneNumberUpdateFail = error => ({
  type: CHECK_FAMILY_MEMBER_PHONE_NUMBER_UPDATE_FAIL,
  payload: error,
})

export const checkCustomId = checkData => ({
  type: CHECK_CUSTOM_ID,
  payload: { checkData },
})

export const checkCustomIdSuccess = CustomIdAvailability => ({
  type: CHECK_CUSTOM_ID_SUCCESS,
  payload: CustomIdAvailability,
})

export const checkCustomIdFail = error => ({
  type: CHECK_CUSTOM_ID_FAIL,
  payload: error,
})

export const checkSurveyUserPhoneNumber = checkData => ({
  type: CHECK_SURVEY_USER_PHONE_NUMBER,
  payload: { checkData },
})

export const checkSurveyUserPhoneNumberSuccess =
  surveyUserPhoneNumberAvailability => ({
    type: CHECK_SURVEY_USER_PHONE_NUMBER_SUCCESS,
    payload: surveyUserPhoneNumberAvailability,
  })

export const checkSurveyUserPhoneNumberFail = error => ({
  type: CHECK_SURVEY_USER_PHONE_NUMBER_FAIL,
  payload: error,
})

export const checkGroupAdminPhoneNumber = checkData => ({
  type: CHECK_GROUP_ADMIN_PHONE_NUMBER,
  payload: { checkData },
})

export const checkGroupAdminPhoneNumberSuccess =
  groupAdminPhoneNumberAvailability => ({
    type: CHECK_GROUP_ADMIN_PHONE_NUMBER_SUCCESS,
    payload: groupAdminPhoneNumberAvailability,
  })

export const checkGroupAdminPhoneNumberFail = error => ({
  type: CHECK_GROUP_ADMIN_PHONE_NUMBER_FAIL,
  payload: error,
})

export const checkInstituteUserPhoneNumber = checkData => ({
  type: CHECK_INSTITUTE_USER_PHONE_NUMBER,
  payload: { checkData },
})

export const checkInstituteUserPhoneNumberSuccess =
  instituteUserPhoneNumberAvailability => ({
    type: CHECK_INSTITUTE_USER__PHONE_NUMBER_SUCCESS,
    payload: instituteUserPhoneNumberAvailability,
  })

export const checkInstituteUserPhoneNumberFail = error => ({
  type: CHECK_INSTITUTE_USER__PHONE_NUMBER_FAIL,
  payload: error,
})

export const setUserRole = (userRole, history) => ({
  type: SET_USER_ROLE,
  payload: { userRole, history },
})

export const setUserRoleSuccess = userData => ({
  type: SET_USER_ROLE_SUCCESS,
  payload: userData,
})

export const setUserRoleFail = error => ({
  type: SET_USER_ROLE_FAIL,
  payload: error,
})
