/* STATES */
export const GET_STATES = "GET_STATES"
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS"
export const GET_STATES_FAIL = "GET_STATES_FAIL"

/* STATES DETAIL*/
export const GET_STATE_DETAIL = "GET_STATE_DETAIL"
export const GET_STATE_DETAIL_SUCCESS = "GET_STATE_DETAIL_SUCCESS"
export const GET_STATE_DETAIL_FAIL = "GET_STATE_DETAIL_FAIL"

/**
 * add STATE
 */
export const CREATE_STATE = "CREATE_STATE"
export const CREATE_STATE_SUCCESS = "CREATE_STATE_SUCCESS"
export const CREATE_STATE_FAIL = "CREATE_STATE_FAIL"

/**
 * Edit STATE
 */
export const UPDATE_STATE = "UPDATE_STATE"
export const UPDATE_STATE_SUCCESS = "UPDATE_STATE_SUCCESS"
export const UPDATE_STATE_FAIL = "UPDATE_STATE_FAIL"

/**
 * Delete STATE
 */
export const DELETE_STATE = "DELETE_STATE"
export const DELETE_STATE_SUCCESS = "DELETE_STATE_SUCCESS"
export const DELETE_STATE_FAIL = "DELETE_STATE_FAIL"
