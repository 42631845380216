/* BANNERS */
export const GET_BANNERS = "GET_BANNERS"
export const GET_BANNERS_SUCCESS = "GET_BANNERS_SUCCESS"
export const GET_BANNERS_FAIL = "GET_BANNERS_FAIL"

/* BANNERS CARDS*/
// export const GET_VARISANGYA_CARDS = "GET_VARISANGYA_CARDS"
// export const GET_VARISANGYA_CARDS_SUCCESS = "GET_VARISANGYA_CARDS_SUCCESS"
// export const GET_VARISANGYA_CARDS_FAIL = "GET_VARISANGYA_CARDS_FAIL"

/* BANNER  DETAIL*/
export const GET_BANNER_DETAIL = "GET_BANNER_DETAIL"
export const GET_BANNER_DETAIL_SUCCESS = "GET_BANNER_DETAIL_SUCCESS"
export const GET_BANNER_DETAIL_FAIL = "GET_BANNER_DETAIL_FAIL"

/**
 * add BANNER
 */
export const CREATE_BANNER = "CREATE_BANNER"
export const CREATE_BANNER_SUCCESS = "CREATE_BANNER_SUCCESS"
export const CREATE_BANNER_FAIL = "CREATE_BANNER_FAIL"

/**
 * Edit BANNER
 */
export const UPDATE_BANNER = "UPDATE_BANNER"
export const UPDATE_BANNER_SUCCESS = "UPDATE_BANNER_SUCCESS"
export const UPDATE_BANNER_FAIL = "UPDATE_BANNER_FAIL"

/**
 * Delete BANNER
 */
export const DELETE_BANNER = "DELETE_BANNER"
export const DELETE_BANNER_SUCCESS = "DELETE_BANNER_SUCCESS"
export const DELETE_BANNER_FAIL = "DELETE_BANNER_FAIL"
