import {
  GET_FAMILY_MEMBER_COLLECTIONS,
  GET_FAMILY_MEMBER_COLLECTIONS_SUCCESS,
  GET_FAMILY_MEMBER_COLLECTIONS_FAIL,
  GET_FAMILY_MEMBER_COLLECTIONS_CARDS,
  GET_FAMILY_MEMBER_COLLECTIONS_CARDS_SUCCESS,
  GET_FAMILY_MEMBER_COLLECTIONS_CARDS_FAIL,
  GET_FAMILY_MEMBER_COLLECTION_DETAIL,
  GET_FAMILY_MEMBER_COLLECTION_DETAIL_SUCCESS,
  GET_FAMILY_MEMBER_COLLECTION_DETAIL_FAIL,
  CREATE_FAMILY_MEMBER_COLLECTION,
  CREATE_FAMILY_MEMBER_COLLECTION_SUCCESS,
  CREATE_FAMILY_MEMBER_COLLECTION_FAIL,
  UPDATE_FAMILY_MEMBER_COLLECTION,
  UPDATE_FAMILY_MEMBER_COLLECTION_SUCCESS,
  UPDATE_FAMILY_MEMBER_COLLECTION_FAIL,
  DELETE_FAMILY_MEMBER_COLLECTION,
  DELETE_FAMILY_MEMBER_COLLECTION_SUCCESS,
  DELETE_FAMILY_MEMBER_COLLECTION_FAIL,
  GET_FAMILY_MEMBER_COLLECTION_MEMBERS,
  GET_FAMILY_MEMBER_COLLECTION_MEMBERS_SUCCESS,
  GET_FAMILY_MEMBER_COLLECTION_MEMBERS_FAIL,
  GET_FAMILY_MEMBER_COLLECTION_MEMBERS_CARDS,
  GET_FAMILY_MEMBER_COLLECTION_MEMBERS_CARDS_SUCCESS,
  GET_FAMILY_MEMBER_COLLECTION_MEMBERS_CARDS_FAIL,
  GET_FAMILY_MEMBER_COLLECTION_MEMBER_DETAIL,
  GET_FAMILY_MEMBER_COLLECTION_MEMBER_DETAIL_SUCCESS,
  GET_FAMILY_MEMBER_COLLECTION_MEMBER_DETAIL_FAIL,
  CREATE_FAMILY_MEMBER_COLLECTION_MEMBER,
  CREATE_FAMILY_MEMBER_COLLECTION_MEMBER_SUCCESS,
  CREATE_FAMILY_MEMBER_COLLECTION_MEMBER_FAIL,
  UPDATE_FAMILY_MEMBER_COLLECTION_MEMBER,
  UPDATE_FAMILY_MEMBER_COLLECTION_MEMBER_SUCCESS,
  UPDATE_FAMILY_MEMBER_COLLECTION_MEMBER_FAIL,
  DELETE_FAMILY_MEMBER_COLLECTION_MEMBER,
  DELETE_FAMILY_MEMBER_COLLECTION_MEMBER_SUCCESS,
  DELETE_FAMILY_MEMBER_COLLECTION_MEMBER_FAIL,
  GET_FAMILY_MEMBER_FILTER_COLLECTIONS,
  GET_FAMILY_MEMBER_FILTER_COLLECTIONS_SUCCESS,
  GET_FAMILY_MEMBER_FILTER_COLLECTIONS_FAIL,
  GET_FAMILY_MEMBER_FILTER_COLLECTIONS_CARDS,
  GET_FAMILY_MEMBER_FILTER_COLLECTIONS_CARDS_SUCCESS,
  GET_FAMILY_MEMBER_FILTER_COLLECTIONS_CARDS_FAIL,
  GET_FAMILY_MEMBER_FILTER_COLLECTION_DETAIL,
  GET_FAMILY_MEMBER_FILTER_COLLECTION_DETAIL_SUCCESS,
  GET_FAMILY_MEMBER_FILTER_COLLECTION_DETAIL_FAIL,
  CREATE_FAMILY_MEMBER_FILTER_COLLECTION,
  CREATE_FAMILY_MEMBER_FILTER_COLLECTION_SUCCESS,
  CREATE_FAMILY_MEMBER_FILTER_COLLECTION_FAIL,
  UPDATE_FAMILY_MEMBER_FILTER_COLLECTION,
  UPDATE_FAMILY_MEMBER_FILTER_COLLECTION_SUCCESS,
  UPDATE_FAMILY_MEMBER_FILTER_COLLECTION_FAIL,
  DELETE_FAMILY_MEMBER_FILTER_COLLECTION,
  DELETE_FAMILY_MEMBER_FILTER_COLLECTION_SUCCESS,
  DELETE_FAMILY_MEMBER_FILTER_COLLECTION_FAIL,
} from "./actiontypes"

// ------------------------------------FAMILY MEMBER COLLECTIONS----------------------------------------

export const getFamilyMemberCollections = (searchText, page) => ({
  type: GET_FAMILY_MEMBER_COLLECTIONS,
  payload: { searchText, page },
})

export const getFamilyMemberCollectionsSuccess = familyMemberCollections => ({
  type: GET_FAMILY_MEMBER_COLLECTIONS_SUCCESS,
  payload: familyMemberCollections,
})

export const getFamilyMemberCollectionsFail = error => ({
  type: GET_FAMILY_MEMBER_COLLECTIONS_FAIL,
  payload: error,
})

export const getFamilyMemberCollectionsCards = () => ({
  type: GET_FAMILY_MEMBER_COLLECTIONS_CARDS,
})

export const getFamilyMemberCollectionsCardsSuccess =
  familyMemberCollectionsCards => ({
    type: GET_FAMILY_MEMBER_COLLECTIONS_CARDS_SUCCESS,
    payload: familyMemberCollectionsCards,
  })

export const getFamilyMemberCollectionsCardsFail = error => ({
  type: GET_FAMILY_MEMBER_COLLECTIONS_CARDS_FAIL,
  payload: error,
})

export const getFamilyMemberCollectionDetail = familyMemberCollectionId => ({
  type: GET_FAMILY_MEMBER_COLLECTION_DETAIL,
  familyMemberCollectionId,
})

export const getFamilyMemberCollectionDetailSuccess =
  familyMemberCollectionDetail => ({
    type: GET_FAMILY_MEMBER_COLLECTION_DETAIL_SUCCESS,
    payload: familyMemberCollectionDetail,
  })

export const getFamilyMemberCollectionDetailFail = error => ({
  type: GET_FAMILY_MEMBER_COLLECTION_DETAIL_FAIL,
  payload: error,
})

export const createFamilyMemberCollection = (
  familyMemberCollection,
  history
) => ({
  type: CREATE_FAMILY_MEMBER_COLLECTION,
  payload: { familyMemberCollection, history },
})

export const createFamilyMemberCollectionSuccess = familyMemberCollection => ({
  type: CREATE_FAMILY_MEMBER_COLLECTION_SUCCESS,
  payload: familyMemberCollection,
})

export const createFamilyMemberCollectionFail = error => ({
  type: CREATE_FAMILY_MEMBER_COLLECTION_FAIL,
  payload: error,
})

export const updateFamilyMemberCollection = (
  familyMemberCollection,
  familyMemberCollectionId
) => ({
  type: UPDATE_FAMILY_MEMBER_COLLECTION,
  payload: { familyMemberCollection, familyMemberCollectionId },
})

export const updateFamilyMemberCollectionSuccess = familyMemberCollection => ({
  type: UPDATE_FAMILY_MEMBER_COLLECTION_SUCCESS,
  payload: familyMemberCollection,
})

export const updateFamilyMemberCollectionFail = error => ({
  type: UPDATE_FAMILY_MEMBER_COLLECTION_FAIL,
  payload: error,
})

export const deleteFamilyMemberCollection = (
  familyMemberCollectionId,
  history
) => ({
  type: DELETE_FAMILY_MEMBER_COLLECTION,
  payload: { familyMemberCollectionId, history },
})

export const deleteFamilyMemberCollectionSuccess = familyMemberCollection => ({
  type: DELETE_FAMILY_MEMBER_COLLECTION_SUCCESS,
  payload: familyMemberCollection,
})

export const deleteFamilyMemberCollectionFail = error => ({
  type: DELETE_FAMILY_MEMBER_COLLECTION_FAIL,
  payload: error,
})

// ------------------------------------FAMILY MEMBER COLLECTION MEMBERS----------------------------------------

export const getFamilyMemberCollectionMembers = (
  searchText,
  page,
  familyMemberCollectionId
) => ({
  type: GET_FAMILY_MEMBER_COLLECTION_MEMBERS,
  payload: { searchText, page, familyMemberCollectionId },
})

export const getFamilyMemberCollectionMembersSuccess =
  familyMemberCollectionMembers => ({
    type: GET_FAMILY_MEMBER_COLLECTION_MEMBERS_SUCCESS,
    payload: familyMemberCollectionMembers,
  })

export const getFamilyMemberCollectionMembersFail = error => ({
  type: GET_FAMILY_MEMBER_COLLECTION_MEMBERS_FAIL,
  payload: error,
})

export const getFamilyMemberCollectionMembersCards = () => ({
  type: GET_FAMILY_MEMBER_COLLECTION_MEMBERS_CARDS,
})

export const getFamilyMemberCollectionMembersCardsSuccess =
  familyMemberCollectionMembersCards => ({
    type: GET_FAMILY_MEMBER_COLLECTION_MEMBERS_CARDS_SUCCESS,
    payload: familyMemberCollectionMembersCards,
  })

export const getFamilyMemberCollectionMembersCardsFail = error => ({
  type: GET_FAMILY_MEMBER_COLLECTION_MEMBERS_CARDS_FAIL,
  payload: error,
})

export const getFamilyMemberCollectionMemberDetail =
  familyMemberCollectionMemberId => ({
    type: GET_FAMILY_MEMBER_COLLECTION_MEMBER_DETAIL,
    familyMemberCollectionMemberId,
  })

export const getFamilyMemberCollectionMemberDetailSuccess =
  familyMemberCollectionMemberDetail => ({
    type: GET_FAMILY_MEMBER_COLLECTION_MEMBER_DETAIL_SUCCESS,
    payload: familyMemberCollectionMemberDetail,
  })

export const getFamilyMemberCollectionMemberDetailFail = error => ({
  type: GET_FAMILY_MEMBER_COLLECTION_MEMBER_DETAIL_FAIL,
  payload: error,
})

export const createFamilyMemberCollectionMember = (
  familyMemberCollectionMember,
  history
) => ({
  type: CREATE_FAMILY_MEMBER_COLLECTION_MEMBER,
  payload: { familyMemberCollectionMember, history },
})

export const createFamilyMemberCollectionMemberSuccess =
  familyMemberCollectionMember => ({
    type: CREATE_FAMILY_MEMBER_COLLECTION_MEMBER_SUCCESS,
    payload: familyMemberCollectionMember,
  })

export const createFamilyMemberCollectionMemberFail = error => ({
  type: CREATE_FAMILY_MEMBER_COLLECTION_MEMBER_FAIL,
  payload: error,
})

export const updateFamilyMemberCollectionMember = (
  familyMemberCollectionMember,
  familyMemberCollectionMemberId
) => ({
  type: UPDATE_FAMILY_MEMBER_COLLECTION_MEMBER,
  payload: { familyMemberCollectionMember, familyMemberCollectionMemberId },
})

export const updateFamilyMemberCollectionMemberSuccess =
  familyMemberCollectionMember => ({
    type: UPDATE_FAMILY_MEMBER_COLLECTION_MEMBER_SUCCESS,
    payload: familyMemberCollectionMember,
  })

export const updateFamilyMemberCollectionMemberFail = error => ({
  type: UPDATE_FAMILY_MEMBER_COLLECTION_MEMBER_FAIL,
  payload: error,
})

export const deleteFamilyMemberCollectionMember = (
  familyMemberCollectionMemberId,
  history
) => ({
  type: DELETE_FAMILY_MEMBER_COLLECTION_MEMBER,
  payload: { familyMemberCollectionMemberId, history },
})

export const deleteFamilyMemberCollectionMemberSuccess =
  familyMemberCollectionMember => ({
    type: DELETE_FAMILY_MEMBER_COLLECTION_MEMBER_SUCCESS,
    payload: familyMemberCollectionMember,
  })

export const deleteFamilyMemberCollectionMemberFail = error => ({
  type: DELETE_FAMILY_MEMBER_COLLECTION_MEMBER_FAIL,
  payload: error,
})

// ------------------------------------FAMILY MEMBER FILTER COLLECTION----------------------------------------

export const getFamilyMemberFilterCollections = (searchText, page) => ({
  type: GET_FAMILY_MEMBER_FILTER_COLLECTIONS,
  payload: { searchText, page },
})

export const getFamilyMemberFilterCollectionsSuccess =
  familyMemberFilterCollections => ({
    type: GET_FAMILY_MEMBER_FILTER_COLLECTIONS_SUCCESS,
    payload: familyMemberFilterCollections,
  })

export const getFamilyMemberFilterCollectionsFail = error => ({
  type: GET_FAMILY_MEMBER_FILTER_COLLECTIONS_FAIL,
  payload: error,
})

export const getFamilyMemberFilterCollectionsCards = () => ({
  type: GET_FAMILY_MEMBER_FILTER_COLLECTIONS_CARDS,
})

export const getFamilyMemberFilterCollectionsCardsSuccess =
  familyMemberFilterCollectionsCards => ({
    type: GET_FAMILY_MEMBER_FILTER_COLLECTIONS_CARDS_SUCCESS,
    payload: familyMemberFilterCollectionsCards,
  })

export const getFamilyMemberFilterCollectionsCardsFail = error => ({
  type: GET_FAMILY_MEMBER_FILTER_COLLECTIONS_CARDS_FAIL,
  payload: error,
})

export const getFamilyMemberFilterCollectionDetail =
  familyMemberFilterCollectionId => ({
    type: GET_FAMILY_MEMBER_FILTER_COLLECTION_DETAIL,
    familyMemberFilterCollectionId,
  })

export const getFamilyMemberFilterCollectionDetailSuccess =
  familyMemberFilterCollectionDetail => ({
    type: GET_FAMILY_MEMBER_FILTER_COLLECTION_DETAIL_SUCCESS,
    payload: familyMemberFilterCollectionDetail,
  })

export const getFamilyMemberFilterCollectionDetailFail = error => ({
  type: GET_FAMILY_MEMBER_FILTER_COLLECTION_DETAIL_FAIL,
  payload: error,
})

export const createFamilyMemberFilterCollection = (
  familyMemberFilterCollection,
  history
) => ({
  type: CREATE_FAMILY_MEMBER_FILTER_COLLECTION,
  payload: { familyMemberFilterCollection, history },
})

export const createFamilyMemberFilterCollectionSuccess =
  familyMemberFilterCollection => ({
    type: CREATE_FAMILY_MEMBER_FILTER_COLLECTION_SUCCESS,
    payload: familyMemberFilterCollection,
  })

export const createFamilyMemberFilterCollectionFail = error => ({
  type: CREATE_FAMILY_MEMBER_FILTER_COLLECTION_FAIL,
  payload: error,
})

export const updateFamilyMemberFilterCollection = (
  familyMemberFilterCollection,
  familyMemberFilterCollectionId
) => ({
  type: UPDATE_FAMILY_MEMBER_FILTER_COLLECTION,
  payload: { familyMemberFilterCollection, familyMemberFilterCollectionId },
})

export const updateFamilyMemberFilterCollectionSuccess =
  familyMemberFilterCollection => ({
    type: UPDATE_FAMILY_MEMBER_FILTER_COLLECTION_SUCCESS,
    payload: familyMemberFilterCollection,
  })

export const updateFamilyMemberFilterCollectionFail = error => ({
  type: UPDATE_FAMILY_MEMBER_FILTER_COLLECTION_FAIL,
  payload: error,
})

export const deleteFamilyMemberFilterCollection = (
  familyMemberFilterCollectionId,
  history
) => ({
  type: DELETE_FAMILY_MEMBER_FILTER_COLLECTION,
  payload: { familyMemberFilterCollectionId, history },
})

export const deleteFamilyMemberFilterCollectionSuccess =
  familyMemberFilterCollection => ({
    type: DELETE_FAMILY_MEMBER_FILTER_COLLECTION_SUCCESS,
    payload: familyMemberFilterCollection,
  })

export const deleteFamilyMemberFilterCollectionFail = error => ({
  type: DELETE_FAMILY_MEMBER_FILTER_COLLECTION_FAIL,
  payload: error,
})
