/* MAHALLS */
export const GET_MAHALLS = "GET_MAHALLS"
export const GET_MAHALLS_SUCCESS = "GET_MAHALLS_SUCCESS"
export const GET_MAHALLS_FAIL = "GET_MAHALLS_FAIL"

/* MAHALLS_FOR_DOWNLOAD */
export const GET_MAHALLS_FOR_DOWNLOAD = "GET_MAHALLS_FOR_DOWNLOAD"
export const GET_MAHALLS_FOR_DOWNLOAD_SUCCESS =
  "GET_MAHALLS_FOR_DOWNLOAD_SUCCESS"
export const GET_MAHALLS_FOR_DOWNLOAD_FAIL = "GET_MAHALLS_FOR_DOWNLOAD_FAIL"

/* MAHALLS CARDS*/
export const GET_MAHALLS_CARDS = "GET_MAHALLS_CARDS"
export const GET_MAHALLS_CARDS_SUCCESS = "GET_MAHALLS_CARDS_SUCCESS"
export const GET_MAHALLS_CARDS_FAIL = "GET_MAHALLS_CARDS_FAIL"

/* MAHALLS DETAIL*/
export const GET_MAHALL_DETAIL = "GET_MAHALL_DETAIL"
export const GET_MAHALL_DETAIL_SUCCESS = "GET_MAHALL_DETAIL_SUCCESS"
export const GET_MAHALL_DETAIL_FAIL = "GET_MAHALL_DETAIL_FAIL"

/* MAHALLS PREMIUM*/
export const PATCH_MAHALL_PREMIUM = "PATCH_MAHALL_PREMIUM"
export const PATCH_MAHALL_PREMIUM_SUCCESS = "PATCH_MAHALL_PREMIUM_SUCCESS"
export const PATCH_MAHALL_PREMIUM_FAIL = "PATCH_MAHALL_PREMIUM_FAIL"

/* MAHALLS_DROPDOWN*/
export const GET_MAHALL_DROPDOWN = "GET_MAHALL_DROPDOWN"
export const GET_MAHALL_DROPDOWN_SUCCESS = "GET_MAHALL_DROPDOWN_SUCCESS"
export const GET_MAHALL_DROPDOWN_FAIL = "GET_MAHALL_DROPDOWN_FAIL"

/**
 * add MAHALL
 */
export const CREATE_MAHALL = "CREATE_MAHALL"
export const CREATE_MAHALL_SUCCESS = "CREATE_MAHALL_SUCCESS"
export const CREATE_MAHALL_FAIL = "CREATE_MAHALL_FAIL"

/**
 * Edit MAHALL
 */
export const UPDATE_MAHALL = "UPDATE_MAHALL"
export const UPDATE_MAHALL_SUCCESS = "UPDATE_MAHALL_SUCCESS"
export const UPDATE_MAHALL_FAIL = "UPDATE_MAHALL_FAIL"

/**
 * Delete MAHALL
 */
export const DELETE_MAHALL = "DELETE_MAHALL"
export const DELETE_MAHALL_SUCCESS = "DELETE_MAHALL_SUCCESS"
export const DELETE_MAHALL_FAIL = "DELETE_MAHALL_FAIL"
