/* FAMILY_MEMBER_VARISANGYA_WALLET */
export const GET_FAMILY_MEMBER_VARISANGYA_WALLETS =
  "GET_FAMILY_MEMBER_VARISANGYA_WALLETS"
export const GET_FAMILY_MEMBER_VARISANGYA_WALLETS_SUCCESS =
  "GET_FAMILY_MEMBER_VARISANGYA_WALLETS_SUCCESS"
export const GET_FAMILY_MEMBER_VARISANGYA_WALLETS_FAIL =
  "GET_FAMILY_MEMBER_VARISANGYA_WALLETS_FAIL"

/**
 * add FAMILY_MEMBER_VARISANGYA_WALLET
 */
export const CREATE_FAMILY_MEMBER_VARISANGYA_WALLET =
  "CREATE_FAMILY_MEMBER_VARISANGYA_WALLET"
export const CREATE_FAMILY_MEMBER_VARISANGYA_WALLET_SUCCESS =
  "CREATE_FAMILY_MEMBER_VARISANGYA_WALLET_SUCCESS"
export const CREATE_FAMILY_MEMBER_VARISANGYA_WALLET_FAIL =
  "CREATE_FAMILY_MEMBER_VARISANGYA_WALLET_FAIL"

/**
 * FAMILY_MEMBER_VARISANGYA_WALLET_DETAIL
 */
export const GET_FAMILY_MEMBER_VARISANGYA_WALLET_DETAIL =
  "GET_FAMILY_MEMBER_VARISANGYA_WALLET_DETAIL"
export const GET_FAMILY_MEMBER_VARISANGYA_WALLET_DETAIL_SUCCESS =
  "GET_FAMILY_MEMBER_VARISANGYA_WALLET_DETAIL_SUCCESS"
export const GET_FAMILY_MEMBER_VARISANGYA_WALLET_DETAIL_FAIL =
  "GET_FAMILY_MEMBER_VARISANGYA_WALLET_DETAIL_FAIL"

/**
 * FAMILY_MEMBER_VARISANGYA_WALLET_VIEW
 */
export const GET_FAMILY_MEMBER_VARISANGYA_WALLET_VIEW =
  "GET_FAMILY_MEMBER_VARISANGYA_WALLET_VIEW"
export const GET_FAMILY_MEMBER_VARISANGYA_WALLET_VIEW_SUCCESS =
  "GET_FAMILY_MEMBER_VARISANGYA_WALLET_VIEW_SUCCESS"
export const GET_FAMILY_MEMBER_VARISANGYA_WALLET_VIEW_FAIL =
  "GET_FAMILY_MEMBER_VARISANGYA_WALLET_VIEW_FAIL"

/**
 * add CLEAR_FAMILY_MEMBER_VARISANGYA_WALLET_DETAIL
 */
export const CLEAR_FAMILY_MEMBER_VARISANGYA_WALLET_DETAIL =
  "CLEAR_FAMILY_MEMBER_VARISANGYA_WALLET_DETAIL"

export const DELETE_FAMILY__MEMBER_VARISANGYA_WALLET =
  "DELETE_FAMILY__MEMBER_VARISANGYA_WALLET"
export const DELETE_FAMILY__MEMBER_VARISANGYA_WALLET_SUCCESS =
  "DELETE_FAMILY__MEMBER_VARISANGYA_WALLET_SUCCESS"
export const DELETE_FAMILY__MEMBER_VARISANGYA_WALLET_FAIL =
  "DELETE_FAMILY__MEMBER_VARISANGYA_WALLET_FAIL"
