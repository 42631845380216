import {
  GET_JOB_FAMILY_MEMBER,
  GET_JOB_FAMILY_MEMBER_SUCCESS,
  GET_JOB_FAMILY_MEMBER_FAIL,
  GET_JOB_FAMILY_MEMBER_DETAIL,
  GET_JOB_FAMILY_MEMBER_DETAIL_SUCCESS,
  GET_JOB_FAMILY_MEMBER_DETAIL_FAIL,
  CREATE_JOB_FAMILY_MEMBER,
  CREATE_JOB_FAMILY_MEMBER_SUCCESS,
  CREATE_JOB_FAMILY_MEMBER_FAIL,
  UPDATE_JOB_FAMILY_MEMBER,
  UPDATE_JOB_FAMILY_MEMBER_SUCCESS,
  UPDATE_JOB_FAMILY_MEMBER_FAIL,
  DELETE_JOB_FAMILY_MEMBER,
  DELETE_JOB_FAMILY_MEMBER_SUCCESS,
  DELETE_JOB_FAMILY_MEMBER_FAIL,
} from "./actionTypes"

export const getJobFamilyMember = (searchText, page) => ({
  type: GET_JOB_FAMILY_MEMBER,
  payload: { searchText, page },
})

export const getJobFamilyMemberSuccess = jobs => ({
  type: GET_JOB_FAMILY_MEMBER_SUCCESS,
  payload: jobs,
})
export const getJobFamilyMemberFail = error => ({
  type: GET_JOB_FAMILY_MEMBER_FAIL,
  payload: error,
})

export const getJobFamilyMemberDetail = jobId => ({
  type: GET_JOB_FAMILY_MEMBER_DETAIL,
  jobId,
})

export const getJobFamilyMemberDetailSuccess = jobDetail => ({
  type: GET_JOB_FAMILY_MEMBER_DETAIL_SUCCESS,
  payload: jobDetail,
})

export const getJobFamilyMemberDetailFail = error => ({
  type: GET_JOB_FAMILY_MEMBER_DETAIL_FAIL,
  payload: error,
})

export const createJobFamilyMember = (job, history) => ({
  type: CREATE_JOB_FAMILY_MEMBER,
  payload: { job, history },
})

export const createJobFamilyMemberSuccess = job => ({
  type: CREATE_JOB_FAMILY_MEMBER_SUCCESS,
  payload: job,
})

export const createJobFamilyMemberFail = error => ({
  type: CREATE_JOB_FAMILY_MEMBER_FAIL,
  payload: error,
})

export const updateJobFamilyMember = (job, jobId, history) => ({
  type: UPDATE_JOB_FAMILY_MEMBER,
  payload: { job, jobId, history },
})

export const updateJobFamilyMemberSuccess = job => ({
  type: UPDATE_JOB_FAMILY_MEMBER_SUCCESS,
  payload: job,
})

export const updateJobFamilyMemberFail = error => ({
  type: UPDATE_JOB_FAMILY_MEMBER_FAIL,
  payload: error,
})

export const deleteJobFamilyMember = (jobId, history) => ({
  type: DELETE_JOB_FAMILY_MEMBER,
  payload: { jobId, history },
})

export const deleteJobFamilyMemberSuccess = job => ({
  type: DELETE_JOB_FAMILY_MEMBER_SUCCESS,
  payload: job,
})

export const deleteJobFamilyMemberFail = error => ({
  type: DELETE_JOB_FAMILY_MEMBER_FAIL,
  payload: error,
})
