import {
  CREATE_FAMILY_MEMBER_VARISANGYA_ITEM,
  CREATE_FAMILY_MEMBER_VARISANGYA_ITEM_SUCCESS,
  CREATE_FAMILY_MEMBER_VARISANGYA_ITEM_FAIL,
  DELETE_FAMILY_MEMBER_VARISANGYA_ITEM,
  DELETE_FAMILY_MEMBER_VARISANGYA_ITEM_SUCCESS,
  DELETE_FAMILY_MEMBER_VARISANGYA_ITEM_FAIL,
  GET_FAMILY_MEMBER_VARISANGYA_ITEMS,
  GET_FAMILY_MEMBER_VARISANGYA_ITEMS_SUCCESS,
  GET_FAMILY_MEMBER_VARISANGYA_ITEMS_FAIL,
} from "./actionTypes"

export const getFamilyMemberVarisangyaItems = (
  varisangyaId,
  searchText,
  page
) => ({
  type: GET_FAMILY_MEMBER_VARISANGYA_ITEMS,
  payload: { varisangyaId, searchText, page },
})
export const getFamilyMemberVarisangyaItemsSuccess =
  familyMemberVarisangyaItems => ({
    type: GET_FAMILY_MEMBER_VARISANGYA_ITEMS_SUCCESS,
    payload: familyMemberVarisangyaItems,
  })
export const getFamilyMemberVarisangyaItemsFail = error => ({
  type: GET_FAMILY_MEMBER_VARISANGYA_ITEMS_FAIL,
  payload: error,
})

export const createFamilyMemberVarisangyaItem = (
  familyMemberVarisangyaItem,
  history
) => ({
  type: CREATE_FAMILY_MEMBER_VARISANGYA_ITEM,
  payload: { familyMemberVarisangyaItem, history },
})

export const createFamilyMemberVarisangyaItemSuccess =
  familyMemberVarisangyaItem => ({
    type: CREATE_FAMILY_MEMBER_VARISANGYA_ITEM_SUCCESS,
    payload: familyMemberVarisangyaItem,
  })

export const createFamilyMemberVarisangyaItemFail = error => ({
  type: CREATE_FAMILY_MEMBER_VARISANGYA_ITEM_FAIL,
  payload: error,
})

export const deleteFamilyMemberVarisangyaItem =
  familyMemberVarisangyaItemId => ({
    type: DELETE_FAMILY_MEMBER_VARISANGYA_ITEM,
    payload: { familyMemberVarisangyaItemId },
  })

export const deleteFamilyMemberVarisangyaItemSuccess =
  familyMemberVarisangyaItemId => ({
    type: DELETE_FAMILY_MEMBER_VARISANGYA_ITEM_SUCCESS,
    payload: { familyMemberVarisangyaItemId },
  })

export const deleteFamilyMemberVarisangyaItemFail = error => ({
  type: DELETE_FAMILY_MEMBER_VARISANGYA_ITEM_FAIL,
  payload: error,
})
