/* VARISANGYAS */
export const GET_VARISANGYAS = "GET_VARISANGYAS"
export const GET_VARISANGYAS_SUCCESS = "GET_VARISANGYAS_SUCCESS"
export const GET_VARISANGYAS_FAIL = "GET_VARISANGYAS_FAIL"

/* VARISANGYAS CARDS*/
export const GET_VARISANGYA_CARDS = "GET_VARISANGYA_CARDS"
export const GET_VARISANGYA_CARDS_SUCCESS = "GET_VARISANGYA_CARDS_SUCCESS"
export const GET_VARISANGYA_CARDS_FAIL = "GET_VARISANGYA_CARDS_FAIL"

/* VARISANGYA  DETAIL*/
export const GET_VARISANGYA_DETAIL = "GET_VARISANGYA_DETAIL"
export const GET_VARISANGYA_DETAIL_SUCCESS = "GET_VARISANGYA_DETAIL_SUCCESS"
export const GET_VARISANGYA_DETAIL_FAIL = "GET_VARISANGYA_DETAIL_FAIL"

/**
 * add VARISANGYA
 */
export const CREATE_VARISANGYA = "CREATE_VARISANGYA"
export const CREATE_VARISANGYA_SUCCESS = "CREATE_VARISANGYA_SUCCESS"
export const CREATE_VARISANGYA_FAIL = "CREATE_VARISANGYA_FAIL"

/**
 * Edit VARISANGYA
 */
export const UPDATE_VARISANGYA = "UPDATE_VARISANGYA"
export const UPDATE_VARISANGYA_SUCCESS = "UPDATE_VARISANGYA_SUCCESS"
export const UPDATE_VARISANGYA_FAIL = "UPDATE_VARISANGYA_FAIL"

/**
 * Delete VARISANGYA
 */
export const DELETE_VARISANGYA = "DELETE_VARISANGYA"
export const DELETE_VARISANGYA_SUCCESS = "DELETE_VARISANGYA_SUCCESS"
export const DELETE_VARISANGYA_FAIL = "DELETE_VARISANGYA_FAIL"
