/* VARISANGYA_SPLIT_LEDGERS */
export const GET_VARISANGYA_SPLIT_LEDGERS = "GET_VARISANGYA_SPLIT_LEDGERS"
export const GET_VARISANGYA_SPLIT_LEDGERS_SUCCESS =
  "GET_VARISANGYA_SPLIT_LEDGERS_SUCCESS"
export const GET_VARISANGYA_SPLIT_LEDGERS_FAIL =
  "GET_VARISANGYA_SPLIT_LEDGERS_FAIL"

/**
 * add VARISANGYA_SPLIT_LEDGER
 */
export const CREATE_VARISANGYA_SPLIT_LEDGER = "CREATE_VARISANGYA_SPLIT_LEDGER"
export const CREATE_VARISANGYA_SPLIT_LEDGER_SUCCESS =
  "CREATE_VARISANGYA_SPLIT_LEDGER_SUCCESS"
export const CREATE_VARISANGYA_SPLIT_LEDGER_FAIL =
  "CREATE_VARISANGYA_SPLIT_LEDGER_FAIL"
