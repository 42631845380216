import {
  GET_DEFAULT_TYPE_LISTS,
  GET_DEFAULT_TYPE_LISTS_SUCCESS,
  GET_DEFAULT_TYPE_LISTS_FAIL,
  GET_MAHALL_TYPE_LISTS,
  GET_MAHALL_TYPE_LISTS_SUCCESS,
  GET_MAHALL_TYPE_LISTS_FAIL,
  // GET_TYPE_LIST_DETAIL,
  // GET_TYPE_LIST_DETAIL_SUCCESS,
  // GET_TYPE_LIST_DETAIL_FAIL,
  CREATE_DEFAULT_TYPE_LIST_ITEM,
  CREATE_DEFAULT_TYPE_LIST_ITEM_SUCCESS,
  CREATE_DEFAULT_TYPE_LIST_ITEM_FAIL,
  CREATE_MAHALL_TYPE_LIST_ITEM,
  CREATE_MAHALL_TYPE_LIST_ITEM_SUCCESS,
  CREATE_MAHALL_TYPE_LIST_ITEM_FAIL,
  UPDATE_DEFAULT_TYPE_LIST_ITEM,
  UPDATE_DEFAULT_TYPE_LIST_ITEM_SUCCESS,
  UPDATE_DEFAULT_TYPE_LIST_ITEM_FAIL,
  UPDATE_MAHALL_TYPE_LIST_ITEM,
  UPDATE_MAHALL_TYPE_LIST_ITEM_SUCCESS,
  UPDATE_MAHALL_TYPE_LIST_ITEM_FAIL,
  DELETE_DEFAULT_TYPE_LIST_ITEM,
  DELETE_DEFAULT_TYPE_LIST_ITEM_SUCCESS,
  DELETE_DEFAULT_TYPE_LIST_ITEM_FAIL,
  DELETE_MAHALL_TYPE_LIST_ITEM,
  DELETE_MAHALL_TYPE_LIST_ITEM_SUCCESS,
  DELETE_MAHALL_TYPE_LIST_ITEM_FAIL,
  GET_MAHALL_WARD_NUMBERS,
  GET_MAHALL_WARD_NUMBERS_SUCCESS,
  GET_MAHALL_WARD_NUMBERS_FAIL,
} from "./actionTypes"

export const getDefaultTypeLists = () => ({
  type: GET_DEFAULT_TYPE_LISTS,
})

export const getDefaultTypeListsSuccess = deafaultTypeLists => ({
  type: GET_DEFAULT_TYPE_LISTS_SUCCESS,
  payload: deafaultTypeLists,
})
export const getDefaultTypeListsFail = error => ({
  type: GET_DEFAULT_TYPE_LISTS_FAIL,
  payload: error,
})

export const getMahallTypeLists = mahall => ({
  type: GET_MAHALL_TYPE_LISTS,
  payload: { mahall },
})

export const getMahallTypeListsSuccess = mahallTypeLists => ({
  type: GET_MAHALL_TYPE_LISTS_SUCCESS,
  payload: mahallTypeLists,
})
export const getMahallTypeListsFail = error => ({
  type: GET_MAHALL_TYPE_LISTS_FAIL,
  payload: error,
})

export const createDefaultTypeListItem = (defaultTypeListItem, history) => ({
  type: CREATE_DEFAULT_TYPE_LIST_ITEM,
  payload: { defaultTypeListItem, history },
})

export const createDefaultTypeListItemSuccess = defaultTypeListItem => ({
  type: CREATE_DEFAULT_TYPE_LIST_ITEM_SUCCESS,
  payload: defaultTypeListItem,
})

export const createDefaultTypeListItemFail = error => ({
  type: CREATE_DEFAULT_TYPE_LIST_ITEM_FAIL,
  payload: error,
})

export const createMahallTypeListItem = (mahallTypeListItem, history) => ({
  type: CREATE_MAHALL_TYPE_LIST_ITEM,
  payload: { mahallTypeListItem, history },
})

export const createMahallTypeListItemSuccess = mahallTypeListItem => ({
  type: CREATE_MAHALL_TYPE_LIST_ITEM_SUCCESS,
  payload: mahallTypeListItem,
})

export const createMahallTypeListItemFail = error => ({
  type: CREATE_MAHALL_TYPE_LIST_ITEM_FAIL,
  payload: error,
})

export const updateDefaultTypeListItem = (
  defaultTypeListItem,
  defaultTypeListItemId,
  history
) => ({
  type: UPDATE_DEFAULT_TYPE_LIST_ITEM,
  payload: { defaultTypeListItem, defaultTypeListItemId, history },
})

export const updateDefaultTypeListItemSuccess = defaultTypeListItem => ({
  type: UPDATE_DEFAULT_TYPE_LIST_ITEM_SUCCESS,
  payload: defaultTypeListItem,
})

export const updateDefaultTypeListItemFail = error => ({
  type: UPDATE_DEFAULT_TYPE_LIST_ITEM_FAIL,
  payload: error,
})

export const updateMahallTypeListItem = (
  mahallTypeListItem,
  mahallTypeListItemId,
  history
) => ({
  type: UPDATE_MAHALL_TYPE_LIST_ITEM,
  payload: { mahallTypeListItem, mahallTypeListItemId, history },
})

export const updateMahallTypeListItemSuccess = mahallTypeListItem => ({
  type: UPDATE_MAHALL_TYPE_LIST_ITEM_SUCCESS,
  payload: mahallTypeListItem,
})

export const updateMahallTypeListItemFail = error => ({
  type: UPDATE_MAHALL_TYPE_LIST_ITEM_FAIL,
  payload: error,
})

export const deleteDefaultTypeListItem = (defaultTypeListItemId, history) => ({
  type: DELETE_DEFAULT_TYPE_LIST_ITEM,
  payload: { defaultTypeListItemId, history },
})

export const deleteDefaultTypeListItemSuccess = defaultTypeListItem => ({
  type: DELETE_DEFAULT_TYPE_LIST_ITEM_SUCCESS,
  payload: defaultTypeListItem,
})

export const deleteDefaultTypeListItemFail = error => ({
  type: DELETE_DEFAULT_TYPE_LIST_ITEM_FAIL,
  payload: error,
})

export const deleteMahallTypeListItem = (
  mahallTypeListItemId,
  history,
  mahall
) => ({
  type: DELETE_MAHALL_TYPE_LIST_ITEM,
  payload: { mahallTypeListItemId, history, mahall },
})

export const deleteMahallTypeListItemSuccess = mahallTypeListItem => ({
  type: DELETE_MAHALL_TYPE_LIST_ITEM_SUCCESS,
  payload: mahallTypeListItem,
})

export const deleteMahallTypeListItemFail = error => ({
  type: DELETE_MAHALL_TYPE_LIST_ITEM_FAIL,
  payload: error,
})

export const getMahallWardNumbers = mahall => ({
  type: GET_MAHALL_WARD_NUMBERS,
  payload: { mahall },
})

export const getMahallWardNumbersSuccess = mahallWardNumbers => ({
  type: GET_MAHALL_WARD_NUMBERS_SUCCESS,
  payload: mahallWardNumbers,
})
export const getMahallWardNumbersFail = error => ({
  type: GET_MAHALL_WARD_NUMBERS_FAIL,
  payload: error,
})
