/* NOC CERTIFICATE */
export const GET_NOC_CERTIFICATES = "GET_NOC_CERTIFICATES"
export const GET_NOC_CERTIFICATES_SUCCESS = "GET_NOC_CERTIFICATES_SUCCESS"
export const GET_NOC_CERTIFICATES_FAIL = "GET_NOC_CERTIFICATES_FAIL"

/* NOC CERTIFICATES FOR DOWNLOAD*/
export const GET_NOC_CERTIFICATES_FOR_DOWNLOAD =
  "GET_NOC_CERTIFICATES_FOR_DOWNLOAD"
export const GET_NOC_CERTIFICATES_FOR_DOWNLOAD_SUCCESS =
  "GET_NOC_CERTIFICATES_FOR_DOWNLOAD_SUCCESS"
export const GET_NOC_CERTIFICATES_FOR_DOWNLOAD_FAIL =
  "GET_NOC_CERTIFICATES_FOR_DOWNLOAD_FAIL"

/* NOC CERTIFICATE DETAIL*/
export const GET_NOC_CERTIFICATE_DETAIL = "GET_NOC_CERTIFICATE_DETAIL"
export const GET_NOC_CERTIFICATE_DETAIL_SUCCESS =
  "GET_NOC_CERTIFICATE_DETAIL_SUCCESS"
export const GET_NOC_CERTIFICATE_DETAIL_FAIL = "GET_NOC_CERTIFICATE_DETAIL_FAIL"

/**
 * add NOC CERTIFICATE
 */
export const CREATE_NOC_CERTIFICATE = "CREATE_NOC_CERTIFICATE"
export const CREATE_NOC_CERTIFICATE_SUCCESS = "CREATE_NOC_CERTIFICATE_SUCCESS"
export const CREATE_NOC_CERTIFICATE_FAIL = "CREATE_NOC_CERTIFICATE_FAIL"

/**
 * Edit NOC CERTIFICATE
 */
export const UPDATE_NOC_CERTIFICATE = "UPDATE_NOC_CERTIFICATE"
export const UPDATE_NOC_CERTIFICATE_SUCCESS = "UPDATE_NOC_CERTIFICATE_SUCCESS"
export const UPDATE_NOC_CERTIFICATE_FAIL = "UPDATE_NOC_CERTIFICATE_FAIL"

/**
 * Delete NOC CERTIFICATE
 */
export const DELETE_NOC_CERTIFICATE = "DELETE_NOC_CERTIFICATE"
export const DELETE_NOC_CERTIFICATE_SUCCESS = "DELETE_NOC_CERTIFICATE_SUCCESS"
export const DELETE_NOC_CERTIFICATE_FAIL = "DELETE_NOC_CERTIFICATE_FAIL"
