/* MAHALL */
export const GET_DEFAULT_TYPE_LISTS = "GET_DEFAULT_TYPE_LISTS"
export const GET_DEFAULT_TYPE_LISTS_SUCCESS = "GET_DEFAULT_TYPE_LISTS_SUCCESS"
export const GET_DEFAULT_TYPE_LISTS_FAIL = "GET_DEFAULT_TYPE_LISTS_FAIL"

export const GET_MAHALL_TYPE_LISTS = "GET_MAHALL_TYPE_LISTS"
export const GET_MAHALL_TYPE_LISTS_SUCCESS = "GET_MAHALL_TYPE_LISTS_SUCCESS"
export const GET_MAHALL_TYPE_LISTS_FAIL = "GET_MAHALL_TYPE_LISTS_FAIL"

export const CREATE_DEFAULT_TYPE_LIST_ITEM = "CREATE_DEFAULT_TYPE_LIST_ITEM"
export const CREATE_DEFAULT_TYPE_LIST_ITEM_SUCCESS =
  "CREATE_DEFAULT_TYPE_LIST_ITEM_SUCCESS"
export const CREATE_DEFAULT_TYPE_LIST_ITEM_FAIL =
  "CREATE_DEFAULT_TYPE_LIST_ITEM_FAIL"

export const CREATE_MAHALL_TYPE_LIST_ITEM = "CREATE_MAHALL_TYPE_LIST_ITEM"
export const CREATE_MAHALL_TYPE_LIST_ITEM_SUCCESS =
  "CREATE_MAHALL_TYPE_LIST_ITEM_SUCCESS"
export const CREATE_MAHALL_TYPE_LIST_ITEM_FAIL =
  "CREATE_MAHALL_TYPE_LIST_ITEM_FAIL"

export const UPDATE_DEFAULT_TYPE_LIST_ITEM = "UPDATE_DEFAULT_TYPE_LIST_ITEM"
export const UPDATE_DEFAULT_TYPE_LIST_ITEM_SUCCESS =
  "UPDATE_DEFAULT_TYPE_LIST_ITEM_SUCCESS"
export const UPDATE_DEFAULT_TYPE_LIST_ITEM_FAIL =
  "UPDATE_DEFAULT_TYPE_LIST_ITEM_FAIL"

export const UPDATE_MAHALL_TYPE_LIST_ITEM = "UPDATE_MAHALL_TYPE_LIST_ITEM"
export const UPDATE_MAHALL_TYPE_LIST_ITEM_SUCCESS =
  "UPDATE_MAHALL_TYPE_LIST_ITEM_SUCCESS"
export const UPDATE_MAHALL_TYPE_LIST_ITEM_FAIL =
  "UPDATE_MAHALL_TYPE_LIST_ITEM_FAIL"

export const DELETE_DEFAULT_TYPE_LIST_ITEM = "DELETE_DEFAULT_TYPE_LIST_ITEM"
export const DELETE_DEFAULT_TYPE_LIST_ITEM_SUCCESS =
  "DELETE_DEFAULT_TYPE_LIST_ITEM_SUCCESS"
export const DELETE_DEFAULT_TYPE_LIST_ITEM_FAIL =
  "DELETE_DEFAULT_TYPE_LIST_ITEM_FAIL"

export const DELETE_MAHALL_TYPE_LIST_ITEM = "DELETE_MAHALL_TYPE_LIST_ITEM"
export const DELETE_MAHALL_TYPE_LIST_ITEM_SUCCESS =
  "DELETE_MAHALL_TYPE_LIST_ITEM_SUCCESS"
export const DELETE_MAHALL_TYPE_LIST_ITEM_FAIL =
  "DELETE_MAHALL_TYPE_LIST_ITEM_FAIL"

export const GET_MAHALL_WARD_NUMBERS = "GET_MAHALL_WARD_NUMBERS"
export const GET_MAHALL_WARD_NUMBERS_SUCCESS = "GET_MAHALL_WARD_NUMBERS_SUCCESS"
export const GET_MAHALL_WARD_NUMBERS_FAIL = "GET_MAHALL_WARD_NUMBERS_FAIL"
