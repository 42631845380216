import {
  GET_FAMILY_VARISANGYA_WALLETS,
  GET_FAMILY_VARISANGYA_WALLETS_SUCCESS,
  GET_FAMILY_VARISANGYA_WALLETS_FAIL,
  CREATE_FAMILY_VARISANGYA_WALLET,
  CREATE_FAMILY_VARISANGYA_WALLET_SUCCESS,
  CREATE_FAMILY_VARISANGYA_WALLET_FAIL,
  GET_FAMILY_VARISANGYA_WALLET_DETAIL,
  GET_FAMILY_VARISANGYA_WALLET_DETAIL_SUCCESS,
  GET_FAMILY_VARISANGYA_WALLET_DETAIL_FAIL,
  CLEAR_FAMILY_VARISANGYA_WALLET_DETAIL,
  GET_FAMILY_VARISANGYA_WALLET_VIEW,
  GET_FAMILY_VARISANGYA_WALLET_VIEW_SUCCESS,
  GET_FAMILY_VARISANGYA_WALLET_VIEW_FAIL,
  DELETE_FAMILY_VARISANGYA_WALLET,
  DELETE_FAMILY_VARISANGYA_WALLET_FAIL,
  DELETE_FAMILY_VARISANGYA_WALLET_SUCCESS,
} from "./actionTypes"

export const deleteFamilyVarisangyaWallet = transactionId => ({
  type: DELETE_FAMILY_VARISANGYA_WALLET,
  payload: { transactionId },
})
export const deleteFamilyVarisangyaWalletSuccess = res => ({
  type: DELETE_FAMILY_VARISANGYA_WALLET_SUCCESS,
  payload: res,
})
export const deleteFamilyVarisangyaWalletFail = error => ({
  type: DELETE_FAMILY_VARISANGYA_WALLET_FAIL,
  payload: error,
})

export const getFamilyVarisangyaWallets = (searchText, page, sort) => ({
  type: GET_FAMILY_VARISANGYA_WALLETS,
  payload: { searchText, page, sort },
})
export const getFamilyVarisangyaWalletsSuccess = familyVarisangyaWallets => ({
  type: GET_FAMILY_VARISANGYA_WALLETS_SUCCESS,
  payload: familyVarisangyaWallets,
})
export const getFamilyVarisangyaWalletsFail = error => ({
  type: GET_FAMILY_VARISANGYA_WALLETS_FAIL,
  payload: error,
})

export const createFamilyVarisangyaWallet = (
  familyVarisangyaWallet,
  history
) => ({
  type: CREATE_FAMILY_VARISANGYA_WALLET,
  payload: { familyVarisangyaWallet, history },
})

export const createFamilyVarisangyaWalletSuccess = familyVarisangyaWallet => ({
  type: CREATE_FAMILY_VARISANGYA_WALLET_SUCCESS,
  payload: familyVarisangyaWallet,
})

export const createFamilyVarisangyaWalletFail = error => ({
  type: CREATE_FAMILY_VARISANGYA_WALLET_FAIL,
  payload: error,
})

export const getFamilyVarisangyaWalletDetail = (
  familyId,
  page,
  searchText,
  sort
) => ({
  type: GET_FAMILY_VARISANGYA_WALLET_DETAIL,
  payload: { familyId, page, searchText, sort },
})

export const getFamilyVarisangyaWalletDetailSuccess =
  familyVarisangyaWalletDetail => ({
    type: GET_FAMILY_VARISANGYA_WALLET_DETAIL_SUCCESS,
    payload: familyVarisangyaWalletDetail,
  })

export const getFamilyVarisangyaWalletDetailFail = error => ({
  type: GET_FAMILY_VARISANGYA_WALLET_DETAIL_FAIL,
  payload: error,
})

export const clearFamilyVarisangyaWalletDetail = () => ({
  type: CLEAR_FAMILY_VARISANGYA_WALLET_DETAIL,
})

export const getFamilyVarisangyaWalletView = familyVarisangyaWalletId => ({
  type: GET_FAMILY_VARISANGYA_WALLET_VIEW,
  familyVarisangyaWalletId,
})

export const getFamilyVarisangyaWalletViewSuccess =
  familyVarisangyaWalletView => ({
    type: GET_FAMILY_VARISANGYA_WALLET_VIEW_SUCCESS,
    payload: familyVarisangyaWalletView,
  })

export const getFamilyVarisangyaWalletViewFail = error => ({
  type: GET_FAMILY_VARISANGYA_WALLET_VIEW_FAIL,
  payload: error,
})
