/* COMMITTEES */
export const GET_COMMITTEES = "GET_COMMITTEES"
export const GET_COMMITTEES_SUCCESS = "GET_COMMITTEES_SUCCESS"
export const GET_COMMITTEES_FAIL = "GET_COMMITTEES_FAIL"

/* COMMITTEE_DROPDOWN */
export const GET_COMMITTEE_DROPDOWN = "GET_COMMITTEE_DROPDOWN"
export const GET_COMMITTEE_DROPDOWN_SUCCESS = "GET_COMMITTEE_DROPDOWN_SUCCESS"
export const GET_COMMITTEE_DROPDOWN_FAIL = "GET_COMMITTEE_DROPDOWN_FAIL"

/* COMMITTEES DETAIL*/
export const GET_COMMITTEE_DETAIL = "GET_COMMITTEE_DETAIL"
export const GET_COMMITTEE_DETAIL_SUCCESS = "GET_COMMITTEE_DETAIL_SUCCESS"
export const GET_COMMITTEE_DETAIL_FAIL = "GET_COMMITTEE_DETAIL_FAIL"

/**
 * add COMMITTEE
 */
export const CREATE_COMMITTEE = "CREATE_COMMITTEE"
export const CREATE_COMMITTEE_SUCCESS = "CREATE_COMMITTEE_SUCCESS"
export const CREATE_COMMITTEE_FAIL = "CREATE_COMMITTEE_FAIL"

/**
 * Edit COMMITTEE
 */
export const UPDATE_COMMITTEE = "UPDATE_COMMITTEE"
export const UPDATE_COMMITTEE_SUCCESS = "UPDATE_COMMITTEE_SUCCESS"
export const UPDATE_COMMITTEE_FAIL = "UPDATE_COMMITTEE_FAIL"

/**
 * Delete COMMITTEE
 */
export const DELETE_COMMITTEE = "DELETE_COMMITTEE"
export const DELETE_COMMITTEE_SUCCESS = "DELETE_COMMITTEE_SUCCESS"
export const DELETE_COMMITTEE_FAIL = "DELETE_COMMITTEE_FAIL"
