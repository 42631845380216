/* GROUP_ADMINS */
export const GET_GROUP_ADMIN = "GET_GROUP_ADMIN"
export const GET_GROUP_ADMIN_SUCCESS = "GET_GROUP_ADMIN_SUCCESS"
export const GET_GROUP_ADMIN_FAIL = "GET_GROUP_ADMIN_FAIL"

/* GROUP_ADMINS DETAIL*/
export const GET_GROUP_ADMIN_DETAIL = "GET_GROUP_ADMIN_DETAIL"
export const GET_GROUP_ADMIN_DETAIL_SUCCESS = "GET_GROUP_ADMIN_DETAIL_SUCCESS"
export const GET_GROUP_ADMIN_DETAIL_FAIL = "GET_GROUP_ADMIN_DETAIL_FAIL"

/* GROUP_ADMIN_STATUS*/
export const CHANGE_GROUP_ADMIN_STATUS = "CHANGE_GROUP_ADMIN_STATUS"
export const CHANGE_GROUP_ADMIN_STATUS_SUCCESS =
  "CHANGE_GROUP_ADMIN_STATUS_SUCCESS"
export const CHANGE_GROUP_ADMIN_STATUS_FAIL = "CHANGE_GROUP_ADMIN_STATUS_FAIL"

/**
 * add GROUP_ADMIN
 */
export const CREATE_GROUP_ADMIN = "CREATE_GROUP_ADMIN"
export const CREATE_GROUP_ADMIN_SUCCESS = "CREATE_GROUP_ADMIN_SUCCESS"
export const CREATE_GROUP_ADMIN_FAIL = "CREATE_GROUP_ADMIN_FAIL"

/**
 * Edit GROUP_ADMIN
 */
export const UPDATE_GROUP_ADMIN = "UPDATE_GROUP_ADMIN"
export const UPDATE_GROUP_ADMIN_SUCCESS = "UPDATE_GROUP_ADMIN_SUCCESS"
export const UPDATE_GROUP_ADMIN_FAIL = "UPDATE_GROUP_ADMIN_FAIL"

/**
 * Delete GROUP_ADMIN
 */
export const DELETE_GROUP_ADMIN = "DELETE_GROUP_ADMIN"
export const DELETE_GROUP_ADMIN_SUCCESS = "DELETE_GROUP_ADMIN_SUCCESS"
export const DELETE_GROUP_ADMIN_FAIL = "DELETE_GROUP_ADMIN_FAIL"
