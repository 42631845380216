import {
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAIL,
  GET_COUNTRY_DETAIL,
  GET_COUNTRY_DETAIL_SUCCESS,
  GET_COUNTRY_DETAIL_FAIL,
  CREATE_COUNTRY,
  CREATE_COUNTRY_SUCCESS,
  CREATE_COUNTRY_FAIL,
  UPDATE_COUNTRY,
  UPDATE_COUNTRY_SUCCESS,
  UPDATE_COUNTRY_FAIL,
  DELETE_COUNTRY,
  DELETE_COUNTRY_SUCCESS,
  DELETE_COUNTRY_FAIL,
} from "./actionTypes"

export const getCountries = (searchText, page) => ({
  type: GET_COUNTRIES,
  payload: { searchText, page },
})

export const getCountriesSuccess = countries => ({
  type: GET_COUNTRIES_SUCCESS,
  payload: countries,
})
export const getCountriesFail = error => ({
  type: GET_COUNTRIES_FAIL,
  payload: error,
})

export const getCountryDetail = countryId => ({
  type: GET_COUNTRY_DETAIL,
  countryId,
})

export const getCountryDetailSuccess = countryDetail => ({
  type: GET_COUNTRY_DETAIL_SUCCESS,
  payload: countryDetail,
})

export const getCountryDetailFail = error => ({
  type: GET_COUNTRY_DETAIL_FAIL,
  payload: error,
})

export const createCountry = (country, history) => ({
  type: CREATE_COUNTRY,
  payload: { country, history },
})

export const createCountrySuccess = country => ({
  type: CREATE_COUNTRY_SUCCESS,
  payload: country,
})

export const createCountryFail = error => ({
  type: CREATE_COUNTRY_FAIL,
  payload: error,
})

export const updateCountry = (country, countryId, history) => ({
  type: UPDATE_COUNTRY,
  payload: { country, countryId, history },
})

export const updateCountrySuccess = country => ({
  type: UPDATE_COUNTRY_SUCCESS,
  payload: country,
})

export const updateCountryFail = error => ({
  type: UPDATE_COUNTRY_FAIL,
  payload: error,
})

export const deleteCountry = countryId => ({
  type: DELETE_COUNTRY,
  payload: { countryId },
})

export const deleteCountrySuccess = country => ({
  type: DELETE_COUNTRY_SUCCESS,
  payload: country,
})

export const deleteCountryFail = error => ({
  type: DELETE_COUNTRY_FAIL,
  payload: error,
})
