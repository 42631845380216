/* MAHALLUSERS */
export const GET_MAHALL_USERS = "GET_MAHALL_USERS"
export const GET_MAHALL_USERS_SUCCESS = "GET_MAHALL_USERS_SUCCESS"
export const GET_MAHALL_USERS_FAIL = "GET_MAHALL_USERS_FAIL"

/* MAHALLUSERS_FOR_DOWNLOAD */
export const GET_MAHALL_USERS_FOR_DOWNLOAD = "GET_MAHALL_USERS_FOR_DOWNLOAD"
export const GET_MAHALL_USERS_FOR_DOWNLOAD_SUCCESS =
  "GET_MAHALL_USERS_FOR_DOWNLOAD_SUCCESS"
export const GET_MAHALL_USERS_FOR_DOWNLOAD_FAIL =
  "GET_MAHALL_USERS_FOR_DOWNLOAD_FAIL"

/* MAHALLUSERS CARDS*/
export const GET_MAHALL_USERS_CARDS = "GET_MAHALL_USERS_CARDS"
export const GET_MAHALL_USERS_CARDS_SUCCESS = "GET_MAHALL_USERS_CARDS_SUCCESS"
export const GET_MAHALL_USERS_CARDS_FAIL = "GET_MAHALL_USERS_CARDS_FAIL"

/* MAHALL_USER_STATUS*/
export const CHANGE_MAHALL_USER_STATUS = "CHANGE_MAHALL_USER_STATUS"
export const CHANGE_MAHALL_USER_STATUS_SUCCESS =
  "CHANGE_MAHALL_USER_STATUS_SUCCESS"
export const CHANGE_MAHALL_USER_STATUS_FAIL = "CHANGE_MAHALL_USER_STATUS_FAIL"

/* MAHALLUSER_DROPDOWN */
export const GET_MAHALL_USER_DROPDOWN = "GET_MAHALL_USER_DROPDOWN"
export const GET_MAHALL_USER_DROPDOWN_SUCCESS =
  "GET_MAHALL_USER_DROPDOWN_SUCCESS"
export const GET_MAHALL_USER_DROPDOWN_FAIL = "GET_MAHALL_USER_DROPDOWN_FAIL"

/* MAHALLUSERS DETAIL*/
export const GET_MAHALL_USER_DETAIL = "GET_MAHALL_USER_DETAIL"
export const GET_MAHALL_USER_DETAIL_SUCCESS = "GET_MAHALL_USER_DETAIL_SUCCESS"
export const GET_MAHALL_USER_DETAIL_FAIL = "GET_MAHALL_USER_DETAIL_FAIL"

/* UPDATE_MAHALL_USERS DETAIL*/
export const UPDATE_MAHALL_USER_DETAIL = "UPDATE_MAHALL_USER_DETAIL"
export const UPDATE_MAHALL_USER_DETAIL_SUCCESS =
  "UPDATE_MAHALL_USER_DETAIL_SUCCESS"
export const UPDATE_MAHALL_USER_DETAIL_FAIL = "UPDATE_MAHALL_USER_DETAIL_FAIL"

/**
 * add MAHALLUSER
 */
export const CREATE_MAHALL_USER = "CREATE_MAHALL_USER"
export const CREATE_MAHALL_USER_SUCCESS = "CREATE_MAHALL_USER_SUCCESS"
export const CREATE_MAHALL_USER_FAIL = "CREATE_MAHALL_USER_FAIL"

/*OTP_VERIFICATION */
export const OTP_VERIFICATION = "OTP_VERIFICATION"
export const OTP_VERIFICATION_SUCCESS = "OTP_VERIFICATION_SUCCESS"
export const OTP_VERIFICATION_FAIL = "OTP_VERIFICATION_FAIL"

export const CREATE_USER = "CREATE_USER"
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS"
export const CREATE_USER_FAIL = "CREATE_USER_FAIL"

/**
 * Edit MAHALLUSER
 */
export const UPDATE_MAHALL_USER = "UPDATE_MAHALL_USER"
export const UPDATE_MAHALL_USER_SUCCESS = "UPDATE_MAHALL_USER_SUCCESS"
export const UPDATE_MAHALL_USER_FAIL = "UPDATE_MAHALL_USER_FAIL"

/**
 * Delete MAHALLUSER
 */
export const DELETE_MAHALL_USER = "DELETE_MAHALL_USER"
export const DELETE_MAHALL_USER_SUCCESS = "DELETE_MAHALL_USER_SUCCESS"
export const DELETE_MAHALL_USER_FAIL = "DELETE_MAHALL_USER_FAIL"
