/* GROUPS */
export const GET_GROUP = "GET_GROUP"
export const GET_GROUP_SUCCESS = "GET_GROUP_SUCCESS"
export const GET_GROUP_FAIL = "GET_GROUP_FAIL"

/* GROUPS DETAIL*/
export const GET_GROUP_DETAIL = "GET_GROUP_DETAIL"
export const GET_GROUP_DETAIL_SUCCESS = "GET_GROUP_DETAIL_SUCCESS"
export const GET_GROUP_DETAIL_FAIL = "GET_GROUP_DETAIL_FAIL"

/**
 * add GROUP
 */
export const CREATE_GROUP = "CREATE_GROUP"
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS"
export const CREATE_GROUP_FAIL = "CREATE_GROUP_FAIL"

/**
 * Edit GROUP
 */
export const UPDATE_GROUP = "UPDATE_GROUP"
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS"
export const UPDATE_GROUP_FAIL = "UPDATE_GROUP_FAIL"

/**
 * Delete GROUP
 */
export const DELETE_GROUP = "DELETE_GROUP"
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS"
export const DELETE_GROUP_FAIL = "DELETE_GROUP_FAIL"
