/* ASSET_ITEMS */
export const GET_ASSET_ITEMS = "GET_ASSET_ITEMS"
export const GET_ASSET_ITEMS_SUCCESS = "GET_ASSET_ITEMS_SUCCESS"
export const GET_ASSET_ITEMS_FAIL = "GET_ASSET_ITEMS_FAIL"

/* ASSET_ITEM  DETAIL*/
export const GET_ASSET_ITEM_DETAIL = "GET_ASSET_ITEM_DETAIL"
export const GET_ASSET_ITEM_DETAIL_SUCCESS = "GET_ASSET_ITEM_DETAIL_SUCCESS"
export const GET_ASSET_ITEM_DETAIL_FAIL = "GET_ASSET_ITEM_DETAIL_FAIL"

/**
 * add ASSET_ITEM
 */
export const CREATE_ASSET_ITEM = "CREATE_ASSET_ITEM"
export const CREATE_ASSET_ITEM_SUCCESS = "CREATE_ASSET_ITEM_SUCCESS"
export const CREATE_ASSET_ITEM_FAIL = "CREATE_ASSET_ITEM_FAIL"

/**
 * Edit ASSET_ITEM
 */
export const UPDATE_ASSET_ITEM = "UPDATE_ASSET_ITEM"
export const UPDATE_ASSET_ITEM_SUCCESS = "UPDATE_ASSET_ITEM_SUCCESS"
export const UPDATE_ASSET_ITEM_FAIL = "UPDATE_ASSET_ITEM_FAIL"

/**
 * Delete ASSET_ITEM
 */
export const DELETE_ASSET_ITEM = "DELETE_ASSET_ITEM"
export const DELETE_ASSET_ITEM_SUCCESS = "DELETE_ASSET_ITEM_SUCCESS"
export const DELETE_ASSET_ITEM_FAIL = "DELETE_ASSET_ITEM_FAIL"
