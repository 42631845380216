/* ZAKAT_ITEMS */
export const GET_ZAKAT_ITEMS = "GET_ZAKAT_ITEMS"
export const GET_ZAKAT_ITEMS_SUCCESS = "GET_ZAKAT_ITEMS_SUCCESS"
export const GET_ZAKAT_ITEMS_FAIL = "GET_ZAKAT_ITEMS_FAIL"

/* ZAKAT_ITEMS CARDS*/
export const GET_ZAKAT_ITEM_CARDS = "GET_ZAKAT_ITEM_CARDS"
export const GET_ZAKAT_ITEM_CARDS_SUCCESS = "GET_ZAKAT_ITEM_CARDS_SUCCESS"
export const GET_ZAKAT_ITEM_CARDS_FAIL = "GET_ZAKAT_ITEM_CARDS_FAIL"

/* ZAKAT_ITEM  DETAIL*/
export const GET_ZAKAT_ITEM_DETAIL = "GET_ZAKAT_ITEM_DETAIL"
export const GET_ZAKAT_ITEM_DETAIL_SUCCESS = "GET_ZAKAT_ITEM_DETAIL_SUCCESS"
export const GET_ZAKAT_ITEM_DETAIL_FAIL = "GET_ZAKAT_ITEM_DETAIL_FAIL"

/**
 * add ZAKAT_ITEM
 */
export const CREATE_ZAKAT_ITEM = "CREATE_ZAKAT_ITEM"
export const CREATE_ZAKAT_ITEM_SUCCESS = "CREATE_ZAKAT_ITEM_SUCCESS"
export const CREATE_ZAKAT_ITEM_FAIL = "CREATE_ZAKAT_ITEM_FAIL"

/**
 * Edit ZAKAT_ITEM
 */
export const UPDATE_ZAKAT_ITEM = "UPDATE_ZAKAT_ITEM"
export const UPDATE_ZAKAT_ITEM_SUCCESS = "UPDATE_ZAKAT_ITEM_SUCCESS"
export const UPDATE_ZAKAT_ITEM_FAIL = "UPDATE_ZAKAT_ITEM_FAIL"

/**
 * Delete ZAKAT_ITEM
 */
export const DELETE_ZAKAT_ITEM = "DELETE_ZAKAT_ITEM"
export const DELETE_ZAKAT_ITEM_SUCCESS = "DELETE_ZAKAT_ITEM_SUCCESS"
export const DELETE_ZAKAT_ITEM_FAIL = "DELETE_ZAKAT_ITEM_FAIL"
