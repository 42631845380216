import {
  GET_LSGS,
  GET_LSGS_SUCCESS,
  GET_LSGS_FAIL,
  GET_LSG_DROPDOWN,
  GET_LSG_DROPDOWN_SUCCESS,
  GET_LSG_DROPDOWN_FAIL,
  GET_LSG_DETAIL,
  GET_LSG_DETAIL_SUCCESS,
  GET_LSG_DETAIL_FAIL,
  CREATE_LSG,
  CREATE_LSG_SUCCESS,
  CREATE_LSG_FAIL,
  UPDATE_LSG,
  UPDATE_LSG_SUCCESS,
  UPDATE_LSG_FAIL,
  DELETE_LSG,
  DELETE_LSG_SUCCESS,
  DELETE_LSG_FAIL,
} from "./actionTypes"

export const getLsgs = (searchText, page) => ({
  type: GET_LSGS,
  payload: { searchText, page },
})

export const getLsgsSuccess = villages => ({
  type: GET_LSGS_SUCCESS,
  payload: villages,
})
export const getLsgsFail = error => ({
  type: GET_LSGS_FAIL,
  payload: error,
})

export const getLsgDropdown = searchText => ({
  type: GET_LSG_DROPDOWN,
  payload: searchText,
})

export const getLsgDropdownSuccess = lsgs => ({
  type: GET_LSG_DROPDOWN_SUCCESS,
  payload: lsgs,
})
export const getLsgDropdownFail = error => ({
  type: GET_LSG_DROPDOWN_FAIL,
  payload: error,
})

export const getLsgDetail = lsgId => ({
  type: GET_LSG_DETAIL,
  lsgId,
})

export const getLsgDetailSuccess = lsgDetail => ({
  type: GET_LSG_DETAIL_SUCCESS,
  payload: lsgDetail,
})

export const getLsgDetailFail = error => ({
  type: GET_LSG_DETAIL_FAIL,
  payload: error,
})

export const createLsg = (lsg, history) => ({
  type: CREATE_LSG,
  payload: { lsg, history },
})

export const createLsgSuccess = lsg => ({
  type: CREATE_LSG_SUCCESS,
  payload: lsg,
})

export const createLsgFail = error => ({
  type: CREATE_LSG_FAIL,
  payload: error,
})

export const updateLsg = (lsg, lsgId, history) => ({
  type: UPDATE_LSG,
  payload: { lsg, lsgId, history },
})

export const updateLsgSuccess = lsg => ({
  type: UPDATE_LSG_SUCCESS,
  payload: lsg,
})

export const updateLsgFail = error => ({
  type: UPDATE_LSG_FAIL,
  payload: error,
})

export const deleteLsg = (lsgId, history) => ({
  type: DELETE_LSG,
  payload: { lsgId, history },
})

export const deleteLsgSuccess = lsg => ({
  type: DELETE_LSG_SUCCESS,
  payload: lsg,
})

export const deleteLsgFail = error => ({
  type: DELETE_LSG_FAIL,
  payload: error,
})
