import {
  GET_COMMITTEE_MEMBERS,
  GET_COMMITTEE_MEMBERS_SUCCESS,
  GET_COMMITTEE_MEMBERS_FAIL,
  GET_COMMITTEE_MEMBER_DETAIL,
  GET_COMMITTEE_MEMBER_DETAIL_SUCCESS,
  GET_COMMITTEE_MEMBER_DETAIL_FAIL,
  CREATE_COMMITTEE_MEMBER,
  CREATE_COMMITTEE_MEMBER_SUCCESS,
  CREATE_COMMITTEE_MEMBER_FAIL,
  UPDATE_COMMITTEE_MEMBER,
  UPDATE_COMMITTEE_MEMBER_SUCCESS,
  UPDATE_COMMITTEE_MEMBER_FAIL,
  DELETE_COMMITTEE_MEMBER,
  DELETE_COMMITTEE_MEMBER_SUCCESS,
  DELETE_COMMITTEE_MEMBER_FAIL,
  GET_COMMITTEE_MEMBER_DROPDOWN,
  GET_COMMITTEE_MEMBER_DROPDOWN_SUCCESS,
  GET_COMMITTEE_MEMBER_DROPDOWN_FAIL,
} from "./actionTypes"

export const getCommitteeMembers = (committeeId, searchText, page) => ({
  type: GET_COMMITTEE_MEMBERS,
  payload: { committeeId, searchText, page },
})

export const getCommitteeMembersSuccess = committeeMembers => ({
  type: GET_COMMITTEE_MEMBERS_SUCCESS,
  payload: committeeMembers,
})
export const getCommitteeMembersFail = error => ({
  type: GET_COMMITTEE_MEMBERS_FAIL,
  payload: error,
})

export const getCommitteeMemberDetail = committeeMemberId => ({
  type: GET_COMMITTEE_MEMBER_DETAIL,
  committeeMemberId,
})

export const getCommitteeMemberDetailSuccess = committeeMemberDetail => ({
  type: GET_COMMITTEE_MEMBER_DETAIL_SUCCESS,
  payload: committeeMemberDetail,
})

export const getCommitteeMemberDetailFail = error => ({
  type: GET_COMMITTEE_MEMBER_DETAIL_FAIL,
  payload: error,
})

export const getCommitteeMemberDropdown = (committeeId, searchText) => ({
  type: GET_COMMITTEE_MEMBER_DROPDOWN,
  payload: { committeeId, searchText },
})

export const getCommitteeMemberDropdownSuccess = committeeMembers => ({
  type: GET_COMMITTEE_MEMBER_DROPDOWN_SUCCESS,
  payload: committeeMembers,
})

export const getCommitteeMemberDropdownFail = error => ({
  type: GET_COMMITTEE_MEMBER_DROPDOWN_FAIL,
  payload: error,
})

export const createCommitteeMember = committeeMember => ({
  type: CREATE_COMMITTEE_MEMBER,
  payload: { committeeMember },
})

export const createCommitteeMemberSuccess = committeeMember => ({
  type: CREATE_COMMITTEE_MEMBER_SUCCESS,
  payload: committeeMember,
})

export const createCommitteeMemberFail = error => ({
  type: CREATE_COMMITTEE_MEMBER_FAIL,
  payload: error,
})

export const updateCommitteeMember = (committeeMember, committeeMemberId) => ({
  type: UPDATE_COMMITTEE_MEMBER,
  payload: { committeeMember, committeeMemberId },
})

export const updateCommitteeMemberSuccess = committeeMember => ({
  type: UPDATE_COMMITTEE_MEMBER_SUCCESS,
  payload: committeeMember,
})

export const updateCommitteeMemberFail = error => ({
  type: UPDATE_COMMITTEE_MEMBER_FAIL,
  payload: error,
})

export const deleteCommitteeMember = (
  committeeMemberId,
  history,
  committeeId
) => ({
  type: DELETE_COMMITTEE_MEMBER,
  payload: { committeeMemberId, history, committeeId },
})

export const deleteCommitteeMemberSuccess = committeeMember => ({
  type: DELETE_COMMITTEE_MEMBER_SUCCESS,
  payload: committeeMember,
})

export const deleteCommitteeMemberFail = error => ({
  type: DELETE_COMMITTEE_MEMBER_FAIL,
  payload: error,
})
