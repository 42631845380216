/* COMMITTEE_MEETING_IMAGES */
export const GET_COMMITTEE_MEETING_IMAGES = "GET_COMMITTEE_MEETING_IMAGES"
export const GET_COMMITTEE_MEETING_IMAGES_SUCCESS =
  "GET_COMMITTEE_MEETING_IMAGES_SUCCESS"
export const GET_COMMITTEE_MEETING_IMAGES_FAIL =
  "GET_COMMITTEE_MEETING_IMAGES_FAIL"

/* COMMITTEE_MEETING_IMAGES DETAIL*/
export const GET_COMMITTEE_MEETING_IMAGE_DETAIL =
  "GET_COMMITTEE_MEETING_IMAGE_DETAIL"
export const GET_COMMITTEE_MEETING_IMAGE_DETAIL_SUCCESS =
  "GET_COMMITTEE_MEETING_IMAGE_DETAIL_SUCCESS"
export const GET_COMMITTEE_MEETING_IMAGE_DETAIL_FAIL =
  "GET_COMMITTEE_MEETING_IMAGE_DETAIL_FAIL"

/**
 * add COMMITTEE_MEETING_IMAGE
 */
export const CREATE_COMMITTEE_MEETING_IMAGE = "CREATE_COMMITTEE_MEETING_IMAGE"
export const CREATE_COMMITTEE_MEETING_IMAGE_SUCCESS =
  "CREATE_COMMITTEE_MEETING_IMAGE_SUCCESS"
export const CREATE_COMMITTEE_MEETING_IMAGE_FAIL =
  "CREATE_COMMITTEE_MEETING_IMAGE_FAIL"

/**
 * Edit COMMITTEE_MEETING_IMAGE
 */
export const UPDATE_COMMITTEE_MEETING_IMAGE = "UPDATE_COMMITTEE_MEETING_IMAGE"
export const UPDATE_COMMITTEE_MEETING_IMAGE_SUCCESS =
  "UPDATE_COMMITTEE_MEETING_IMAGE_SUCCESS"
export const UPDATE_COMMITTEE_MEETING_IMAGE_FAIL =
  "UPDATE_COMMITTEE_MEETING_IMAGE_FAIL"

/**
 * Delete COMMITTEE_MEETING_IMAGE
 */
export const DELETE_COMMITTEE_MEETING_IMAGE = "DELETE_COMMITTEE_MEETING_IMAGE"
export const DELETE_COMMITTEE_MEETING_IMAGE_SUCCESS =
  "DELETE_COMMITTEE_MEETING_IMAGE_SUCCESS"
export const DELETE_COMMITTEE_MEETING_IMAGE_FAIL =
  "DELETE_COMMITTEE_MEETING_IMAGE_FAIL"
