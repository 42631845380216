/* ZAKATS */
export const GET_ZAKATS = "GET_ZAKATS"
export const GET_ZAKATS_SUCCESS = "GET_ZAKATS_SUCCESS"
export const GET_ZAKATS_FAIL = "GET_ZAKATS_FAIL"

/* ZAKATS CARDS*/
export const GET_ZAKAT_CARDS = "GET_ZAKAT_CARDS"
export const GET_ZAKAT_CARDS_SUCCESS = "GET_ZAKAT_CARDS_SUCCESS"
export const GET_ZAKAT_CARDS_FAIL = "GET_ZAKAT_CARDS_FAIL"

/* ZAKAT  DETAIL*/
export const GET_ZAKAT_DETAIL = "GET_ZAKAT_DETAIL"
export const GET_ZAKAT_DETAIL_SUCCESS = "GET_ZAKAT_DETAIL_SUCCESS"
export const GET_ZAKAT_DETAIL_FAIL = "GET_ZAKAT_DETAIL_FAIL"

/**
 * add ZAKAT
 */
export const CREATE_ZAKAT = "CREATE_ZAKAT"
export const CREATE_ZAKAT_SUCCESS = "CREATE_ZAKAT_SUCCESS"
export const CREATE_ZAKAT_FAIL = "CREATE_ZAKAT_FAIL"

/**
 * Edit ZAKAT
 */
export const UPDATE_ZAKAT = "UPDATE_ZAKAT"
export const UPDATE_ZAKAT_SUCCESS = "UPDATE_ZAKAT_SUCCESS"
export const UPDATE_ZAKAT_FAIL = "UPDATE_ZAKAT_FAIL"

/**
 * Delete ZAKAT
 */
export const DELETE_ZAKAT = "DELETE_ZAKAT"
export const DELETE_ZAKAT_SUCCESS = "DELETE_ZAKAT_SUCCESS"
export const DELETE_ZAKAT_FAIL = "DELETE_ZAKAT_FAIL"
