import {
  GET_FEEDS,
  GET_FEEDS_SUCCESS,
  GET_FEEDS_FAIL,
  GET_FEED_DETAIL,
  GET_FEED_DETAIL_SUCCESS,
  GET_FEED_DETAIL_FAIL,
  CREATE_FEED,
  CREATE_FEED_SUCCESS,
  CREATE_FEED_FAIL,
  UPDATE_FEED,
  UPDATE_FEED_SUCCESS,
  UPDATE_FEED_FAIL,
  DELETE_FEED,
  DELETE_FEED_SUCCESS,
  DELETE_FEED_FAIL,
} from "./actionTypes"

export const getFeeds = (searchText, page) => ({
  type: GET_FEEDS,
  payload: { searchText, page },
})
export const getFeedsSuccess = feeds => ({
  type: GET_FEEDS_SUCCESS,
  payload: feeds,
})
export const getFeedsFail = error => ({
  type: GET_FEEDS_FAIL,
  payload: error,
})

export const getFeedDetail = feedId => ({
  type: GET_FEED_DETAIL,
  feedId,
})

export const getFeedDetailSuccess = feedDetail => ({
  type: GET_FEED_DETAIL_SUCCESS,
  payload: feedDetail,
})

export const getFeedDetailFail = error => ({
  type: GET_FEED_DETAIL_FAIL,
  payload: error,
})

export const createFeed = (feed, history) => ({
  type: CREATE_FEED,
  payload: { feed, history },
})

export const createFeedSuccess = feed => ({
  type: CREATE_FEED_SUCCESS,
  payload: feed,
})

export const createFeedFail = error => ({
  type: CREATE_FEED_FAIL,
  payload: error,
})

export const updateFeed = (feed, feedId, history) => ({
  type: UPDATE_FEED,
  payload: { feed, feedId, history },
})

export const updateFeedSuccess = feed => ({
  type: UPDATE_FEED_SUCCESS,
  payload: feed,
})

export const updateFeedFail = error => ({
  type: UPDATE_FEED_FAIL,
  payload: error,
})

export const deleteFeed = (feedId, history) => ({
  type: DELETE_FEED,
  payload: { feedId, history },
})

export const deleteFeedSuccess = feed => ({
  type: DELETE_FEED_SUCCESS,
  payload: feed,
})

export const deleteFeedFail = error => ({
  type: DELETE_FEED_FAIL,
  payload: error,
})
