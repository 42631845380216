import {
  GET_ZAKATS,
  GET_ZAKATS_SUCCESS,
  GET_ZAKATS_FAIL,
  GET_ZAKAT_CARDS,
  GET_ZAKAT_CARDS_SUCCESS,
  GET_ZAKAT_CARDS_FAIL,
  GET_ZAKAT_DETAIL,
  GET_ZAKAT_DETAIL_SUCCESS,
  GET_ZAKAT_DETAIL_FAIL,
  CREATE_ZAKAT,
  CREATE_ZAKAT_SUCCESS,
  CREATE_ZAKAT_FAIL,
  UPDATE_ZAKAT,
  UPDATE_ZAKAT_SUCCESS,
  UPDATE_ZAKAT_FAIL,
  DELETE_ZAKAT,
  DELETE_ZAKAT_SUCCESS,
  DELETE_ZAKAT_FAIL,
} from "./actionTypes"

export const getZakats = (searchText, page) => ({
  type: GET_ZAKATS,
  payload: { searchText, page },
})
export const getZakatsSuccess = zakat => ({
  type: GET_ZAKATS_SUCCESS,
  payload: zakat,
})
export const getZakatsFail = error => ({
  type: GET_ZAKATS_FAIL,
  payload: error,
})

export const getZakatCards = () => ({
  type: GET_ZAKAT_CARDS,
})
export const getZakatCardsSuccess = zakatCards => ({
  type: GET_ZAKAT_CARDS_SUCCESS,
  payload: zakatCards,
})
export const getZakatCardsFail = error => ({
  type: GET_ZAKAT_CARDS_FAIL,
  payload: error,
})

export const getZakatDetail = zakatId => ({
  type: GET_ZAKAT_DETAIL,
  zakatId,
})

export const getZakatDetailSuccess = zakatDetail => ({
  type: GET_ZAKAT_DETAIL_SUCCESS,
  payload: zakatDetail,
})

export const getZakatDetailFail = error => ({
  type: GET_ZAKAT_DETAIL_FAIL,
  payload: error,
})

export const createZakat = (zakat, history) => ({
  type: CREATE_ZAKAT,
  payload: { zakat, history },
})

export const createZakatSuccess = zakat => ({
  type: CREATE_ZAKAT_SUCCESS,
  payload: zakat,
})

export const createZakatFail = error => ({
  type: CREATE_ZAKAT_FAIL,
  payload: error,
})

export const updateZakat = (zakat, zakatId, history) => ({
  type: UPDATE_ZAKAT,
  payload: { zakat, zakatId, history },
})

export const updateZakatSuccess = zakat => ({
  type: UPDATE_ZAKAT_SUCCESS,
  payload: zakat,
})

export const updateZakatFail = error => ({
  type: UPDATE_ZAKAT_FAIL,
  payload: error,
})

export const deleteZakat = (zakatId, history) => ({
  type: DELETE_ZAKAT,
  payload: { zakatId, history },
})

export const deleteZakatSuccess = zakat => ({
  type: DELETE_ZAKAT_SUCCESS,
  payload: zakat,
})

export const deleteZakatFail = error => ({
  type: DELETE_ZAKAT_FAIL,
  payload: error,
})
