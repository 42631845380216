import {
  GET_COMMITTEE_MEETING_IMAGES,
  GET_COMMITTEE_MEETING_IMAGES_SUCCESS,
  GET_COMMITTEE_MEETING_IMAGES_FAIL,
  GET_COMMITTEE_MEETING_IMAGE_DETAIL,
  GET_COMMITTEE_MEETING_IMAGE_DETAIL_SUCCESS,
  GET_COMMITTEE_MEETING_IMAGE_DETAIL_FAIL,
  CREATE_COMMITTEE_MEETING_IMAGE,
  CREATE_COMMITTEE_MEETING_IMAGE_SUCCESS,
  CREATE_COMMITTEE_MEETING_IMAGE_FAIL,
  UPDATE_COMMITTEE_MEETING_IMAGE,
  UPDATE_COMMITTEE_MEETING_IMAGE_SUCCESS,
  UPDATE_COMMITTEE_MEETING_IMAGE_FAIL,
  DELETE_COMMITTEE_MEETING_IMAGE,
  DELETE_COMMITTEE_MEETING_IMAGE_SUCCESS,
  DELETE_COMMITTEE_MEETING_IMAGE_FAIL,
} from "./actionTypes"

export const getCommitteeMeetingImages = committeeMeetingId => ({
  type: GET_COMMITTEE_MEETING_IMAGES,
  payload: { committeeMeetingId },
})

export const getCommitteeMeetingImagesSuccess = committeeMeetingImages => ({
  type: GET_COMMITTEE_MEETING_IMAGES_SUCCESS,
  payload: committeeMeetingImages,
})
export const getCommitteeMeetingImagesFail = error => ({
  type: GET_COMMITTEE_MEETING_IMAGES_FAIL,
  payload: error,
})

export const getCommitteeMeetingImageDetail = committeeMeetingImageId => ({
  type: GET_COMMITTEE_MEETING_IMAGE_DETAIL,
  committeeMeetingImageId,
})

export const getCommitteeMeetingImageDetailSuccess =
  committeeMeetingImageDetail => ({
    type: GET_COMMITTEE_MEETING_IMAGE_DETAIL_SUCCESS,
    payload: committeeMeetingImageDetail,
  })

export const getCommitteeMeetingImageDetailFail = error => ({
  type: GET_COMMITTEE_MEETING_IMAGE_DETAIL_FAIL,
  payload: error,
})

export const createCommitteeMeetingImage = (
  committeeMeetingImage,
  history
) => ({
  type: CREATE_COMMITTEE_MEETING_IMAGE,
  payload: { committeeMeetingImage, history },
})

export const createCommitteeMeetingImageSuccess = committeeMeetingImage => ({
  type: CREATE_COMMITTEE_MEETING_IMAGE_SUCCESS,
  payload: committeeMeetingImage,
})

export const createCommitteeMeetingImageFail = error => ({
  type: CREATE_COMMITTEE_MEETING_IMAGE_FAIL,
  payload: error,
})

export const updateCommitteeMeetingImage = (
  committeeMeetingImage,
  committeeMeetingImageId,
  history
) => ({
  type: UPDATE_COMMITTEE_MEETING_IMAGE,
  payload: { committeeMeetingImage, committeeMeetingImageId, history },
})

export const updateCommitteeMeetingImageSuccess = committeeMeetingImage => ({
  type: UPDATE_COMMITTEE_MEETING_IMAGE_SUCCESS,
  payload: committeeMeetingImage,
})

export const updateCommitteeMeetingImageFail = error => ({
  type: UPDATE_COMMITTEE_MEETING_IMAGE_FAIL,
  payload: error,
})

export const deleteCommitteeMeetingImage = (
  committeeMeetingImageId,
  history,
  committeeMeetingId
) => ({
  type: DELETE_COMMITTEE_MEETING_IMAGE,
  payload: { committeeMeetingImageId, history, committeeMeetingId },
})

export const deleteCommitteeMeetingImageSuccess = committeeMeetingImage => ({
  type: DELETE_COMMITTEE_MEETING_IMAGE_SUCCESS,
  payload: committeeMeetingImage,
})

export const deleteCommitteeMeetingImageFail = error => ({
  type: DELETE_COMMITTEE_MEETING_IMAGE_FAIL,
  payload: error,
})
