/* COUNTRIES */
export const GET_COUNTRIES = "GET_COUNTRIES"
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS"
export const GET_COUNTRIES_FAIL = "GET_COUNTRIES_FAIL"

/* COUNTRYS DETAIL*/
export const GET_COUNTRY_DETAIL = "GET_COUNTRY_DETAIL"
export const GET_COUNTRY_DETAIL_SUCCESS = "GET_COUNTRY_DETAIL_SUCCESS"
export const GET_COUNTRY_DETAIL_FAIL = "GET_COUNTRY_DETAIL_FAIL"

/**
 * add COUNTRY
 */
export const CREATE_COUNTRY = "CREATE_COUNTRY"
export const CREATE_COUNTRY_SUCCESS = "CREATE_COUNTRY_SUCCESS"
export const CREATE_COUNTRY_FAIL = "CREATE_COUNTRY_FAIL"

/**
 * Edit COUNTRY
 */
export const UPDATE_COUNTRY = "UPDATE_COUNTRY"
export const UPDATE_COUNTRY_SUCCESS = "UPDATE_COUNTRY_SUCCESS"
export const UPDATE_COUNTRY_FAIL = "UPDATE_COUNTRY_FAIL"

/**
 * Delete COUNTRY
 */
export const DELETE_COUNTRY = "DELETE_COUNTRY"
export const DELETE_COUNTRY_SUCCESS = "DELETE_COUNTRY_SUCCESS"
export const DELETE_COUNTRY_FAIL = "DELETE_COUNTRY_FAIL"
