import {
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_FAIL,
  GET_DASHBOARD_DISEASE_TYPE_DATA,
  GET_DASHBOARD_DISEASE_TYPE_DATA_SUCCESS,
  GET_DASHBOARD_DISEASE_TYPE_DATA_FAIL,
  GET_MONTHLY_CHART,
  GET_MONTHLY_CHART_SUCCESS,
  GET_MONTHLY_CHART_FAIL,
  GET_YEARLY_CHART,
  GET_YEARLY_CHART_SUCCESS,
  GET_YEARLY_CHART_FAIL,
  GET_DASHBOARD_CARD,
  GET_DASHBOARD_CARD_SUCCESS,
  GET_DASHBOARD_CARD_FAIL,
} from "./actionTypes"

export const getYearlyChart = date => ({
  type: GET_YEARLY_CHART,
  payload: { date },
})

export const getYearlyChartSuccess = chartData => ({
  type: GET_YEARLY_CHART_SUCCESS,
  payload: chartData,
})
export const getYearlyChartFail = error => ({
  type: GET_YEARLY_CHART_FAIL,
  payload: error,
})

export const getMonthlyChart = date => ({
  type: GET_MONTHLY_CHART,
  payload: { date },
})

export const getMonthlyChartSuccess = chartData => ({
  type: GET_MONTHLY_CHART_SUCCESS,
  payload: chartData,
})
export const getMonthlyChartFail = error => ({
  type: GET_MONTHLY_CHART_FAIL,
  payload: error,
})

export const getDashboardData = () => ({
  type: GET_DASHBOARD_DATA,
})

export const getDashboardDataSuccess = dashboardData => ({
  type: GET_DASHBOARD_DATA_SUCCESS,
  payload: dashboardData,
})
export const getDashboardDataFail = error => ({
  type: GET_DASHBOARD_DATA_FAIL,
  payload: error,
})

export const getDashboardCard = () => ({
  type: GET_DASHBOARD_CARD,
})

export const getDashboardCardSuccess = dashboardCard => ({
  type: GET_DASHBOARD_CARD_SUCCESS,
  payload: dashboardCard,
})
export const getDashboardCardFail = error => ({
  type: GET_DASHBOARD_CARD_FAIL,
  payload: error,
})

export const getDashboardDiseaseTypeData = () => ({
  type: GET_DASHBOARD_DISEASE_TYPE_DATA,
})

export const getDashboardDiseaseTypeDataSuccess = dashboardData => ({
  type: GET_DASHBOARD_DISEASE_TYPE_DATA_SUCCESS,
  payload: dashboardData,
})
export const getDashboardDiseaseTypeDataFail = error => ({
  type: GET_DASHBOARD_DISEASE_TYPE_DATA_FAIL,
  payload: error,
})
