import {
  GET_UNAPPROVED_FAMILIES,
  GET_UNAPPROVED_FAMILIES_SUCCESS,
  GET_UNAPPROVED_FAMILIES_FAIL,
  GET_UNAPPROVED_FAMILY_DETAIL,
  GET_UNAPPROVED_FAMILY_DETAIL_SUCCESS,
  GET_UNAPPROVED_FAMILY_DETAIL_FAIL,
  UPDATE_UNAPPROVED_FAMILY,
  UPDATE_UNAPPROVED_FAMILY_SUCCESS,
  UPDATE_UNAPPROVED_FAMILY_FAIL,
  DELETE_UNAPPROVED_FAMILY,
  DELETE_UNAPPROVED_FAMILY_SUCCESS,
  DELETE_UNAPPROVED_FAMILY_FAIL,
  GET_UNAPPROVED_FAMILIES_CARDS,
  GET_UNAPPROVED_FAMILIES_CARDS_SUCCESS,
  GET_UNAPPROVED_FAMILIES_CARDS_FAIL,
  GET_UNAPPROVED_FAMILIES_FOR_DOWNLOAD,
  GET_UNAPPROVED_FAMILIES_FOR_DOWNLOAD_SUCCESS,
  GET_UNAPPROVED_FAMILIES_FOR_DOWNLOAD_FAIL,
} from "./actionTypes"

export const getUnapprovedFamilies = (searchText, page, mahallId) => ({
  type: GET_UNAPPROVED_FAMILIES,
  payload: { searchText, page, mahallId },
})

export const getUnapprovedFamiliesSuccess = unapprovedFamilies => ({
  type: GET_UNAPPROVED_FAMILIES_SUCCESS,
  payload: unapprovedFamilies,
})

export const getUnapprovedFamiliesFail = error => ({
  type: GET_UNAPPROVED_FAMILIES_FAIL,
  payload: error,
})

export const getUnapprovedFamiliesForDownload = (
  searchText,
  page,
  mahallId
) => ({
  type: GET_UNAPPROVED_FAMILIES_FOR_DOWNLOAD,
  payload: { searchText, page, mahallId },
})

export const getUnapprovedFamiliesForDownloadSuccess =
  unapprovedFamiliesForDownload => ({
    type: GET_UNAPPROVED_FAMILIES_FOR_DOWNLOAD_SUCCESS,
    payload: unapprovedFamiliesForDownload,
  })

export const getUnapprovedFamiliesForDownloadFail = error => ({
  type: GET_UNAPPROVED_FAMILIES_FOR_DOWNLOAD_FAIL,
  payload: error,
})

export const getUnapprovedFamilyDetail = unapprovedFamilyId => ({
  type: GET_UNAPPROVED_FAMILY_DETAIL,
  unapprovedFamilyId,
})

export const getUnapprovedFamilyDetailSuccess = unapprovedFamilyDetail => ({
  type: GET_UNAPPROVED_FAMILY_DETAIL_SUCCESS,
  payload: unapprovedFamilyDetail,
})

export const getUnapprovedFamilyDetailFail = error => ({
  type: GET_UNAPPROVED_FAMILY_DETAIL_FAIL,
  payload: error,
})

export const getUnapprovedFamiliesCards = () => ({
  type: GET_UNAPPROVED_FAMILIES_CARDS,
})

export const getUnapprovedFamiliesCardsSuccess = unapprovedFamilies => ({
  type: GET_UNAPPROVED_FAMILIES_CARDS_SUCCESS,
  payload: unapprovedFamilies,
})

export const getUnapprovedFamiliesCardsFail = error => ({
  type: GET_UNAPPROVED_FAMILIES_CARDS_FAIL,
  payload: error,
})

export const updateUnapprovedFamily = (
  unapprovedFamily,
  unapprovedFamilyId,
  history
) => ({
  type: UPDATE_UNAPPROVED_FAMILY,
  payload: { unapprovedFamily, unapprovedFamilyId, history },
})

export const updateUnapprovedFamilySuccess = unapprovedFamily => ({
  type: UPDATE_UNAPPROVED_FAMILY_SUCCESS,
  payload: unapprovedFamily,
})

export const updateUnapprovedFamilyFail = error => ({
  type: UPDATE_UNAPPROVED_FAMILY_FAIL,
  payload: error,
})

export const deleteUnapprovedFamily = (unapprovedFamilyId, history) => ({
  type: DELETE_UNAPPROVED_FAMILY,
  payload: { unapprovedFamilyId, history },
})

export const deleteUnapprovedFamilySuccess = unapprovedFamily => ({
  type: DELETE_UNAPPROVED_FAMILY_SUCCESS,
  payload: unapprovedFamily,
})

export const deleteUnapprovedFamilyFail = error => ({
  type: DELETE_UNAPPROVED_FAMILY_FAIL,
  payload: error,
})
