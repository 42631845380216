/* FAMILIES */
export const GET_FAMILIES = "GET_FAMILIES"
export const GET_FAMILIES_SUCCESS = "GET_FAMILIES_SUCCESS"
export const GET_FAMILIES_FAIL = "GET_FAMILIES_FAIL"

/* FAMILIES FOR DOWNLOAD */
export const GET_FAMILIES_FOR_DOWNLOAD = "GET_FAMILIES_FOR_DOWNLOAD"
export const GET_FAMILIES_FOR_DOWNLOAD_SUCCESS =
  "GET_FAMILIES_FOR_DOWNLOAD_SUCCESS"
export const GET_FAMILIES_FOR_DOWNLOAD_FAIL = "GET_FAMILIES_FOR_DOWNLOAD_FAIL"

/* FILTERED_FAMILIES */
export const GET_FILTERED_FAMILIES = "GET_FILTERED_FAMILIES"
export const GET_FILTERED_FAMILIES_SUCCESS = "GET_FILTERED_FAMILIES_SUCCESS"
export const GET_FILTERED_FAMILIES_FAIL = "GET_FILTERED_FAMILIES_FAIL"

/* FAMILIES CARDS*/
export const GET_FAMILIES_CARDS = "GET_FAMILIES_CARDS"
export const GET_FAMILIES_CARDS_SUCCESS = "GET_FAMILIES_CARDS_SUCCESS"
export const GET_FAMILIES_CARDS_FAIL = "GET_FAMILIES_CARDS_FAIL"

/* FAMILY_DROPDOWN */
export const GET_FAMILY_DROPDOWN = "GET_FAMILY_DROPDOWN"
export const GET_FAMILY_DROPDOWN_SUCCESS = "GET_FAMILY_DROPDOWN_SUCCESS"
export const GET_FAMILY_DROPDOWN_FAIL = "GET_FAMILY_DROPDOWN_FAIL"

/* ALL_FAMILY_DROPDOWN */
export const GET_ALL_FAMILY_DROPDOWN = "GET_ALL_FAMILY_DROPDOWN"
export const GET_ALL_FAMILY_DROPDOWN_SUCCESS = "GET_ALL_FAMILY_DROPDOWN_SUCCESS"
export const GET_ALL_FAMILY_DROPDOWN_FAIL = "GET_ALL_FAMILY_DROPDOWN_FAIL"

/* FAMILIES DETAIL*/
export const GET_FAMILY_DETAIL = "GET_FAMILY_DETAIL"
export const GET_FAMILY_DETAIL_SUCCESS = "GET_FAMILY_DETAIL_SUCCESS"
export const GET_FAMILY_DETAIL_FAIL = "GET_FAMILY_DETAIL_FAIL"

/**
 * add FAMILY
 */
export const CREATE_FAMILY = "CREATE_FAMILY"
export const CREATE_FAMILY_SUCCESS = "CREATE_FAMILY_SUCCESS"
export const CREATE_FAMILY_FAIL = "CREATE_FAMILY_FAIL"

/**
 * Edit FAMILY
 */
export const UPDATE_FAMILY = "UPDATE_FAMILY"
export const UPDATE_FAMILY_SUCCESS = "UPDATE_FAMILY_SUCCESS"
export const UPDATE_FAMILY_FAIL = "UPDATE_FAMILY_FAIL"

/**
 * Delete FAMILY
 */
export const DELETE_FAMILY = "DELETE_FAMILY"
export const DELETE_FAMILY_SUCCESS = "DELETE_FAMILY_SUCCESS"
export const DELETE_FAMILY_FAIL = "DELETE_FAMILY_FAIL"
