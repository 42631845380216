/* INSTITUTEUSERS */
export const GET_INSTITUTE_USERS = "GET_INSTITUTE_USERS"
export const GET_INSTITUTE_USERS_SUCCESS = "GET_INSTITUTE_USERS_SUCCESS"
export const GET_INSTITUTE_USERS_FAIL = "GET_INSTITUTE_USERS_FAIL"

/* INSTITUTEUSERS DETAIL*/
export const GET_INSTITUTE_USER_DETAIL = "GET_INSTITUTE_USER_DETAIL"
export const GET_INSTITUTE_USER_DETAIL_SUCCESS =
  "GET_INSTITUTE_USER_DETAIL_SUCCESS"
export const GET_INSTITUTE_USER_DETAIL_FAIL = "GET_INSTITUTE_USER_DETAIL_FAIL"

/**
 * add INSTITUTEUSER
 */
export const CREATE_INSTITUTE_USER = "CREATE_INSTITUTE_USER"
export const CREATE_INSTITUTE_USER_SUCCESS = "CREATE_INSTITUTE_USER_SUCCESS"
export const CREATE_INSTITUTE_USER_FAIL = "CREATE_INSTITUTE_USER_FAIL"

/**
 * Edit INSTITUTEUSER
 */
export const UPDATE_INSTITUTE_USER = "UPDATE_INSTITUTE_USER"
export const UPDATE_INSTITUTE_USER_SUCCESS = "UPDATE_INSTITUTE_USER_SUCCESS"
export const UPDATE_INSTITUTE_USER_FAIL = "UPDATE_INSTITUTE_USER_FAIL"

/**
 * Delete INSTITUTEUSER
 */
export const DELETE_INSTITUTE_USER = "DELETE_INSTITUTE_USER"
export const DELETE_INSTITUTE_USER_SUCCESS = "DELETE_INSTITUTE_USER_SUCCESS"
export const DELETE_INSTITUTE_USER_FAIL = "DELETE_INSTITUTE_USER_FAIL"
