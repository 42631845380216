/* ADMINS */
export const GET_ADMINS = "GET_ADMINS"
export const GET_ADMINS_SUCCESS = "GET_ADMINS_SUCCESS"
export const GET_ADMINS_FAIL = "GET_ADMINS_FAIL"

/* ADMINS DETAIL*/
export const GET_ADMINS_DETAIL = "GET_ADMINS_DETAIL"
export const GET_ADMINS_DETAIL_SUCCESS = "GET_ADMINS_DETAIL_SUCCESS"
export const GET_ADMINS_DETAIL_FAIL = "GET_ADMINS_DETAIL_FAIL"

/**
 * add ADMINS
 */
export const CREATE_ADMINS = "CREATE_ADMINS"
export const CREATE_ADMINS_SUCCESS = "CREATE_ADMINS_SUCCESS"
export const CREATE_ADMINS_FAIL = "CREATE_ADMINS_FAIL"

/**
 * Edit ADMINS
 */
export const UPDATE_ADMINS = "UPDATE_ADMINS"
export const UPDATE_ADMINS_SUCCESS = "UPDATE_ADMINS_SUCCESS"
export const UPDATE_ADMINS_FAIL = "UPDATE_ADMINS_FAIL"

/**
 * Delete ADMINS
 */
export const DELETE_ADMINS = "DELETE_ADMINS"
export const DELETE_ADMINS_SUCCESS = "DELETE_ADMINS_SUCCESS"
export const DELETE_ADMINS_FAIL = "DELETE_ADMINS_FAIL"
