import {
  GET_COMMITTEE_MEETINGS,
  GET_COMMITTEE_MEETINGS_SUCCESS,
  GET_COMMITTEE_MEETINGS_FAIL,
  GET_COMMITTEE_MEETING_DROPDOWN,
  GET_COMMITTEE_MEETING_DROPDOWN_SUCCESS,
  GET_COMMITTEE_MEETING_DROPDOWN_FAIL,
  GET_COMMITTEE_MEETING_DETAIL,
  GET_COMMITTEE_MEETING_DETAIL_SUCCESS,
  GET_COMMITTEE_MEETING_DETAIL_FAIL,
  CREATE_COMMITTEE_MEETING,
  CREATE_COMMITTEE_MEETING_SUCCESS,
  CREATE_COMMITTEE_MEETING_FAIL,
  UPDATE_COMMITTEE_MEETING,
  UPDATE_COMMITTEE_MEETING_SUCCESS,
  UPDATE_COMMITTEE_MEETING_FAIL,
  DELETE_COMMITTEE_MEETING,
  DELETE_COMMITTEE_MEETING_SUCCESS,
  DELETE_COMMITTEE_MEETING_FAIL,
} from "./actionTypes"

export const getCommitteeMeetings = (committeeId, searchText, page) => ({
  type: GET_COMMITTEE_MEETINGS,
  payload: { committeeId, searchText, page },
})

export const getCommitteeMeetingsSuccess = committeeMeetings => ({
  type: GET_COMMITTEE_MEETINGS_SUCCESS,
  payload: committeeMeetings,
})
export const getCommitteeMeetingsFail = error => ({
  type: GET_COMMITTEE_MEETINGS_FAIL,
  payload: error,
})

export const getCommitteeMeetingDropdown = (searchText, page) => ({
  type: GET_COMMITTEE_MEETING_DROPDOWN,
  payload: { searchText, page },
})

export const getCommitteeMeetingDropdownSuccess = searchText => ({
  type: GET_COMMITTEE_MEETING_DROPDOWN_SUCCESS,
  payload: searchText,
})
export const getCommitteeMeetingDropdownFail = error => ({
  type: GET_COMMITTEE_MEETING_DROPDOWN_FAIL,
  payload: error,
})

export const getCommitteeMeetingDetail = committeeMeetingId => ({
  type: GET_COMMITTEE_MEETING_DETAIL,
  committeeMeetingId,
})

export const getCommitteeMeetingDetailSuccess = committeeMeetingDetail => ({
  type: GET_COMMITTEE_MEETING_DETAIL_SUCCESS,
  payload: committeeMeetingDetail,
})

export const getCommitteeMeetingDetailFail = error => ({
  type: GET_COMMITTEE_MEETING_DETAIL_FAIL,
  payload: error,
})

export const createCommitteeMeeting = (committeeMeeting, history) => ({
  type: CREATE_COMMITTEE_MEETING,
  payload: { committeeMeeting, history },
})

export const createCommitteeMeetingSuccess = committeeMeeting => ({
  type: CREATE_COMMITTEE_MEETING_SUCCESS,
  payload: committeeMeeting,
})

export const createCommitteeMeetingFail = error => ({
  type: CREATE_COMMITTEE_MEETING_FAIL,
  payload: error,
})

export const updateCommitteeMeeting = (
  committeeMeeting,
  committeeMeetingId,
  history
) => ({
  type: UPDATE_COMMITTEE_MEETING,
  payload: { committeeMeeting, committeeMeetingId, history },
})

export const updateCommitteeMeetingSuccess = committeeMeeting => ({
  type: UPDATE_COMMITTEE_MEETING_SUCCESS,
  payload: committeeMeeting,
})

export const updateCommitteeMeetingFail = error => ({
  type: UPDATE_COMMITTEE_MEETING_FAIL,
  payload: error,
})

export const deleteCommitteeMeeting = (
  committeeMeetingId,
  committeeId,
  history
) => ({
  type: DELETE_COMMITTEE_MEETING,
  payload: { committeeMeetingId, committeeId, history },
})

export const deleteCommitteeMeetingSuccess = committeeMeeting => ({
  type: DELETE_COMMITTEE_MEETING_SUCCESS,
  payload: committeeMeeting,
})

export const deleteCommitteeMeetingFail = error => ({
  type: DELETE_COMMITTEE_MEETING_FAIL,
  payload: error,
})
