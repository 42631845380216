/* INSTITUTES */
export const GET_GALLERIES = "GET_GALLERIES"
export const GET_GALLERIES_SUCCESS = "GET_GALLERIES_SUCCESS"
export const GET_GALLERIES_FAIL = "GET_GALLERIES_FAIL"

/* GALLERIES DETAIL*/
export const GET_GALLERY_DETAIL = "GET_GALLERY_DETAIL"
export const GET_GALLERY_DETAIL_SUCCESS = "GET_GALLERY_DETAIL_SUCCESS"
export const GET_GALLERY_DETAIL_FAIL = "GET_GALLERY_DETAIL_FAIL"

/**
 * add GALLERY
 */
export const CREATE_GALLERY = "CREATE_GALLERY"
export const CREATE_GALLERY_SUCCESS = "CREATE_GALLERY_SUCCESS"
export const CREATE_GALLERY_FAIL = "CREATE_GALLERY_FAIL"

/**
 * Edit GALLERY
 */
export const UPDATE_GALLERY = "UPDATE_GALLERY"
export const UPDATE_GALLERY_SUCCESS = "UPDATE_GALLERY_SUCCESS"
export const UPDATE_GALLERY_FAIL = "UPDATE_GALLERY_FAIL"

/**
 * Delete GALLERY
 */
export const DELETE_GALLERY = "DELETE_GALLERY"
export const DELETE_GALLERY_SUCCESS = "DELETE_GALLERY_SUCCESS"
export const DELETE_GALLERY_FAIL = "DELETE_GALLERY_FAIL"
