/* UNAPPROVED_FAMILIES */
export const GET_UNAPPROVED_FAMILIES = "GET_UNAPPROVED_FAMILIES"
export const GET_UNAPPROVED_FAMILIES_SUCCESS = "GET_UNAPPROVED_FAMILIES_SUCCESS"
export const GET_UNAPPROVED_FAMILIES_FAIL = "GET_UNAPPROVED_FAMILIES_FAIL"

/* UNAPPROVED_FAMILIES_FOR_DOWNLOAD */
export const GET_UNAPPROVED_FAMILIES_FOR_DOWNLOAD =
  "GET_UNAPPROVED_FAMILIES_FOR_DOWNLOAD"
export const GET_UNAPPROVED_FAMILIES_FOR_DOWNLOAD_SUCCESS =
  "GET_UNAPPROVED_FAMILIES_FOR_DOWNLOAD_SUCCESS"
export const GET_UNAPPROVED_FAMILIES_FOR_DOWNLOAD_FAIL =
  "GET_UNAPPROVED_FAMILIES_FOR_DOWNLOAD_FAIL"

/*UNAPPROVED_FAMILY DETAIL*/
export const GET_UNAPPROVED_FAMILY_DETAIL = "GET_UNAPPROVED_FAMILY_DETAIL"
export const GET_UNAPPROVED_FAMILY_DETAIL_SUCCESS =
  "GET_UNAPPROVED_FAMILY_DETAIL_SUCCESS"
export const GET_UNAPPROVED_FAMILY_DETAIL_FAIL =
  "GET_UNAPPROVED_FAMILY_DETAIL_FAIL"

/*UNAPPROVED_FAMILY CARDS*/
export const GET_UNAPPROVED_FAMILIES_CARDS = "GET_UNAPPROVED_FAMILIES_CARDS"
export const GET_UNAPPROVED_FAMILIES_CARDS_SUCCESS =
  "GET_UNAPPROVED_FAMILIES_CARDS_SUCCESS"
export const GET_UNAPPROVED_FAMILIES_CARDS_FAIL =
  "GET_UNAPPROVED_FAMILIES_CARDS_FAIL"

/**
 * Edit UNAPPROVED_FAMILY
 */
export const UPDATE_UNAPPROVED_FAMILY = "UPDATE_UNAPPROVED_FAMILY"
export const UPDATE_UNAPPROVED_FAMILY_SUCCESS =
  "UPDATE_UNAPPROVED_FAMILY_SUCCESS"
export const UPDATE_UNAPPROVED_FAMILY_FAIL = "UPDATE_UNAPPROVED_FAMILY_FAIL"

/**
 * Delete UNAPPROVED_FAMILY
 */
export const DELETE_UNAPPROVED_FAMILY = "DELETE_UNAPPROVED_FAMILY"
export const DELETE_UNAPPROVED_FAMILY_SUCCESS =
  "DELETE_UNAPPROVED_FAMILY_SUCCESS"
export const DELETE_UNAPPROVED_FAMILY_FAIL = "DELETE_UNAPPROVED_FAMILY_FAIL"
