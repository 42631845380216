import {
  GET_ZAKAT_ITEMS,
  GET_ZAKAT_ITEMS_SUCCESS,
  GET_ZAKAT_ITEMS_FAIL,
  GET_ZAKAT_ITEM_CARDS,
  GET_ZAKAT_ITEM_CARDS_SUCCESS,
  GET_ZAKAT_ITEM_CARDS_FAIL,
  GET_ZAKAT_ITEM_DETAIL,
  GET_ZAKAT_ITEM_DETAIL_SUCCESS,
  GET_ZAKAT_ITEM_DETAIL_FAIL,
  CREATE_ZAKAT_ITEM,
  CREATE_ZAKAT_ITEM_SUCCESS,
  CREATE_ZAKAT_ITEM_FAIL,
  UPDATE_ZAKAT_ITEM,
  UPDATE_ZAKAT_ITEM_SUCCESS,
  UPDATE_ZAKAT_ITEM_FAIL,
  DELETE_ZAKAT_ITEM,
  DELETE_ZAKAT_ITEM_SUCCESS,
  DELETE_ZAKAT_ITEM_FAIL,
} from "./actionTypes"

export const getZakatItems = (searchText, page, zakatId) => ({
  type: GET_ZAKAT_ITEMS,
  payload: { searchText, page, zakatId },
})
export const getZakatItemsSuccess = zakatItems => ({
  type: GET_ZAKAT_ITEMS_SUCCESS,
  payload: zakatItems,
})
export const getZakatItemsFail = error => ({
  type: GET_ZAKAT_ITEMS_FAIL,
  payload: error,
})

export const getZakatItemCards = () => ({
  type: GET_ZAKAT_ITEM_CARDS,
})
export const getZakatItemCardsSuccess = zakatItemCards => ({
  type: GET_ZAKAT_ITEM_CARDS_SUCCESS,
  payload: zakatItemCards,
})
export const getZakatItemCardsFail = error => ({
  type: GET_ZAKAT_ITEM_CARDS_FAIL,
  payload: error,
})

export const getZakatItemDetail = zakatItemId => ({
  type: GET_ZAKAT_ITEM_DETAIL,
  zakatItemId,
})

export const getZakatItemDetailSuccess = zakatItemDetail => ({
  type: GET_ZAKAT_ITEM_DETAIL_SUCCESS,
  payload: zakatItemDetail,
})

export const getZakatItemDetailFail = error => ({
  type: GET_ZAKAT_ITEM_DETAIL_FAIL,
  payload: error,
})

export const createZakatItem = (zakatItem, history) => ({
  type: CREATE_ZAKAT_ITEM,
  payload: { zakatItem, history },
})

export const createZakatItemSuccess = zakatItem => ({
  type: CREATE_ZAKAT_ITEM_SUCCESS,
  payload: zakatItem,
})

export const createZakatItemFail = error => ({
  type: CREATE_ZAKAT_ITEM_FAIL,
  payload: error,
})

export const updateZakatItem = (zakatItem, zakatItemId, history) => ({
  type: UPDATE_ZAKAT_ITEM,
  payload: { zakatItem, zakatItemId, history },
})

export const updateZakatItemSuccess = zakatItem => ({
  type: UPDATE_ZAKAT_ITEM_SUCCESS,
  payload: zakatItem,
})

export const updateZakatItemFail = error => ({
  type: UPDATE_ZAKAT_ITEM_FAIL,
  payload: error,
})

export const deleteZakatItem = (zakatItemId, history) => ({
  type: DELETE_ZAKAT_ITEM,
  payload: { zakatItemId, history },
})

export const deleteZakatItemSuccess = zakatItem => ({
  type: DELETE_ZAKAT_ITEM_SUCCESS,
  payload: zakatItem,
})

export const deleteZakatItemFail = error => ({
  type: DELETE_ZAKAT_ITEM_FAIL,
  payload: error,
})
