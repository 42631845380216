import {
  CREATE_FAMILY_VARISANGYA_ITEM,
  CREATE_FAMILY_VARISANGYA_ITEM_SUCCESS,
  CREATE_FAMILY_VARISANGYA_ITEM_FAIL,
  DELETE_FAMILY_VARISANGYA_ITEM,
  DELETE_FAMILY_VARISANGYA_ITEM_SUCCESS,
  DELETE_FAMILY_VARISANGYA_ITEM_FAIL,
  GET_FAMILY_VARISANGYA_ITEMS,
  GET_FAMILY_VARISANGYA_ITEMS_SUCCESS,
  GET_FAMILY_VARISANGYA_ITEMS_FAIL,
} from "./actionTypes"

export const getFamilyVarisangyaItems = (varisangyaId, searchText, page) => ({
  type: GET_FAMILY_VARISANGYA_ITEMS,
  payload: { varisangyaId, searchText, page },
})
export const getFamilyVarisangyaItemsSuccess = familyVarisangyaItems => ({
  type: GET_FAMILY_VARISANGYA_ITEMS_SUCCESS,
  payload: familyVarisangyaItems,
})
export const getFamilyVarisangyaItemsFail = error => ({
  type: GET_FAMILY_VARISANGYA_ITEMS_FAIL,
  payload: error,
})

export const createFamilyVarisangyaItem = (familyVarisangyaItem, history) => ({
  type: CREATE_FAMILY_VARISANGYA_ITEM,
  payload: { familyVarisangyaItem, history },
})

export const createFamilyVarisangyaItemSuccess = familyVarisangyaItem => ({
  type: CREATE_FAMILY_VARISANGYA_ITEM_SUCCESS,
  payload: familyVarisangyaItem,
})

export const createFamilyVarisangyaItemFail = error => ({
  type: CREATE_FAMILY_VARISANGYA_ITEM_FAIL,
  payload: error,
})

export const deleteFamilyVarisangyaItem = familyVarisangyaItemId => ({
  type: DELETE_FAMILY_VARISANGYA_ITEM,
  payload: { familyVarisangyaItemId },
})

export const deleteFamilyVarisangyaItemSuccess = familyVarisangyaItemId => ({
  type: DELETE_FAMILY_VARISANGYA_ITEM_SUCCESS,
  payload: { familyVarisangyaItemId },
})

export const deleteFamilyVarisangyaItemFail = error => ({
  type: DELETE_FAMILY_VARISANGYA_ITEM_FAIL,
  payload: error,
})
