/* NIKAH REGISTRATIONS */
export const GET_NIKAH_REGISTRATIONS = "GET_NIKAH_REGISTRATIONS"
export const GET_NIKAH_REGISTRATIONS_SUCCESS = "GET_NIKAH_REGISTRATIONS_SUCCESS"
export const GET_NIKAH_REGISTRATIONS_FAIL = "GET_NIKAH_REGISTRATIONS_FAIL"

/* NIKAH REGISTRATIONS CARDS*/
export const GET_NIKAH_REGISTRATIONS_CARDS = "GET_NIKAH_REGISTRATIONS_CARDS"
export const GET_NIKAH_REGISTRATIONS_CARDS_SUCCESS =
  "GET_NIKAH_REGISTRATIONS_CARDS_SUCCESS"
export const GET_NIKAH_REGISTRATIONS_CARDS_FAIL =
  "GET_NIKAH_REGISTRATIONS_CARDS_FAIL"

/* NIKAH REGISTRATIONS FOR DOWNLOAD*/
export const GET_NIKAH_REGISTRATIONS_FOR_DOWNLOAD =
  "GET_NIKAH_REGISTRATIONS_FOR_DOWNLOAD"
export const GET_NIKAH_REGISTRATIONS_FOR_DOWNLOAD_SUCCESS =
  "GET_NIKAH_REGISTRATIONS_FOR_DOWNLOAD_SUCCESS"
export const GET_NIKAH_REGISTRATIONS_FOR_DOWNLOAD_FAIL =
  "GET_NIKAH_REGISTRATIONS_FOR_DOWNLOAD_FAIL"

/* NIKAH REGISTRATION DETAIL*/
export const GET_NIKAH_REGISTRATION_DETAIL = "GET_NIKAH_REGISTRATION_DETAIL"
export const GET_NIKAH_REGISTRATION_DETAIL_SUCCESS =
  "GET_NIKAH_REGISTRATION_DETAIL_SUCCESS"
export const GET_NIKAH_REGISTRATION_DETAIL_FAIL =
  "GET_NIKAH_REGISTRATION_DETAIL_FAIL"

/**
 * add NIKAH REGISTRATION
 */
export const CREATE_NIKAH_REGISTRATION = "CREATE_NIKAH_REGISTRATION"
export const CREATE_NIKAH_REGISTRATION_SUCCESS =
  "CREATE_NIKAH_REGISTRATION_SUCCESS"
export const CREATE_NIKAH_REGISTRATION_FAIL = "CREATE_NIKAH_REGISTRATION_FAIL"

/**
 * Edit NIKAH REGISTRATION
 */
export const UPDATE_NIKAH_REGISTRATION = "UPDATE_NIKAH_REGISTRATION"
export const UPDATE_NIKAH_REGISTRATION_SUCCESS =
  "UPDATE_NIKAH_REGISTRATION_SUCCESS"
export const UPDATE_NIKAH_REGISTRATION_FAIL = "UPDATE_NIKAH_REGISTRATION_FAIL"

/**
 * Delete NIKAH REGISTRATION
 */
export const DELETE_NIKAH_REGISTRATION = "DELETE_NIKAH_REGISTRATION"
export const DELETE_NIKAH_REGISTRATION_SUCCESS =
  "DELETE_NIKAH_REGISTRATION_SUCCESS"
export const DELETE_NIKAH_REGISTRATION_FAIL = "DELETE_NIKAH_REGISTRATION_FAIL"
