import {
  GET_INSTITUTE_USERS,
  GET_INSTITUTE_USERS_SUCCESS,
  GET_INSTITUTE_USERS_FAIL,
  GET_INSTITUTE_USER_DETAIL,
  GET_INSTITUTE_USER_DETAIL_SUCCESS,
  GET_INSTITUTE_USER_DETAIL_FAIL,
  CREATE_INSTITUTE_USER,
  CREATE_INSTITUTE_USER_SUCCESS,
  CREATE_INSTITUTE_USER_FAIL,
  UPDATE_INSTITUTE_USER,
  UPDATE_INSTITUTE_USER_SUCCESS,
  UPDATE_INSTITUTE_USER_FAIL,
  DELETE_INSTITUTE_USER,
  DELETE_INSTITUTE_USER_SUCCESS,
  DELETE_INSTITUTE_USER_FAIL,
} from "./actionTypes"

export const getInstituteUsers = (searchText, page, is_madrasa) => ({
  type: GET_INSTITUTE_USERS,
  payload: { searchText, page, is_madrasa },
})

export const getInstituteUsersSuccess = instituteUsers => ({
  type: GET_INSTITUTE_USERS_SUCCESS,
  payload: instituteUsers,
})
export const getInstituteUsersFail = error => ({
  type: GET_INSTITUTE_USERS_FAIL,
  payload: error,
})

export const getInstituteUserDetail = instituteUserId => ({
  type: GET_INSTITUTE_USER_DETAIL,
  instituteUserId,
})

export const getInstituteUserDetailSuccess = instituteUserDetail => ({
  type: GET_INSTITUTE_USER_DETAIL_SUCCESS,
  payload: instituteUserDetail,
})

export const getInstituteUserDetailFail = error => ({
  type: GET_INSTITUTE_USER_DETAIL_FAIL,
  payload: error,
})

export const createInstituteUser = (instituteUser, history) => ({
  type: CREATE_INSTITUTE_USER,
  payload: { instituteUser, history },
})

export const createInstituteUserSuccess = instituteUser => ({
  type: CREATE_INSTITUTE_USER_SUCCESS,
  payload: instituteUser,
})

export const createInstituteUserFail = error => ({
  type: CREATE_INSTITUTE_USER_FAIL,
  payload: error,
})

export const updateInstituteUser = (
  instituteUser,
  instituteUserId,
  history
) => ({
  type: UPDATE_INSTITUTE_USER,
  payload: { instituteUser, instituteUserId, history },
})

export const updateInstituteUserSuccess = instituteUser => ({
  type: UPDATE_INSTITUTE_USER_SUCCESS,
  payload: instituteUser,
})

export const updateInstituteUserFail = error => ({
  type: UPDATE_INSTITUTE_USER_FAIL,
  payload: error,
})

export const deleteInstituteUser = (instituteUserId, history) => ({
  type: DELETE_INSTITUTE_USER,
  payload: { instituteUserId, history },
})

export const deleteInstituteUserSuccess = instituteUser => ({
  type: DELETE_INSTITUTE_USER_SUCCESS,
  payload: instituteUser,
})

export const deleteInstituteUserFail = error => ({
  type: DELETE_INSTITUTE_USER_FAIL,
  payload: error,
})
