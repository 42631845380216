/* UNAPPROVED_FAMILY MEMBERS */
export const GET_UNAPPROVED_FAMILY_MEMBERS = "GET_UNAPPROVED_FAMILY_MEMBERS"
export const GET_UNAPPROVED_FAMILY_MEMBERS_SUCCESS =
  "GET_UNAPPROVED_FAMILY_MEMBERS_SUCCESS"
export const GET_UNAPPROVED_FAMILY_MEMBERS_FAIL =
  "GET_UNAPPROVED_FAMILY_MEMBERS_FAIL"

/* UNAPPROVED_FAMILY MEMBERS DETAIL*/
export const GET_UNAPPROVED_FAMILY_MEMBER_DETAIL =
  "GET_UNAPPROVED_FAMILY_MEMBER_DETAIL"
export const GET_UNAPPROVED_FAMILY_MEMBER_DETAIL_SUCCESS =
  "GET_UNAPPROVED_FAMILY_MEMBER_DETAIL_SUCCESS"
export const GET_UNAPPROVED_FAMILY_MEMBER_DETAIL_FAIL =
  "GET_UNAPPROVED_FAMILY_MEMBER_DETAIL_FAIL"

/* UNAPPROVED_FAMILY MEMBERS DROPDOWN*/
export const GET_UNAPPROVED_FAMILY_MEMBER_DROPDOWN =
  "GET_UNAPPROVED_FAMILY_MEMBER_DROPDOWN"
export const GET_UNAPPROVED_FAMILY_MEMBER_DROPDOWN_SUCCESS =
  "GET_UNAPPROVED_FAMILY_MEMBER_DROPDOWN_SUCCESS"
export const GET_UNAPPROVED_FAMILY_MEMBER_DROPDOWN_FAIL =
  "GET_UNAPPROVED_FAMILY_MEMBER_DROPDOWN_FAIL"

/**
 * Edit UNAPPROVED_FAMILY MEMBER
 */
export const UPDATE_UNAPPROVED_FAMILY_MEMBER = "UPDATE_UNAPPROVED_FAMILY_MEMBER"
export const UPDATE_UNAPPROVED_FAMILY_MEMBER_SUCCESS =
  "UPDATE_UNAPPROVED_FAMILY_MEMBER_SUCCESS"
export const UPDATE_UNAPPROVED_FAMILY_MEMBER_FAIL =
  "UPDATE_UNAPPROVED_FAMILY_MEMBER_FAIL"

/**
 * Delete UNAPPROVED_FAMILY MEMBER
 */
export const DELETE_UNAPPROVED_FAMILY_MEMBER = "DELETE_UNAPPROVED_FAMILY_MEMBER"
export const DELETE_UNAPPROVED_FAMILY_MEMBER_SUCCESS =
  "DELETE_UNAPPROVED_FAMILY_MEMBER_SUCCESS"
export const DELETE_UNAPPROVED_FAMILY_MEMBER_FAIL =
  "DELETE_UNAPPROVED_FAMILY_MEMBER_FAIL"
