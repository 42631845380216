/* CONSTITUENCYS */
export const GET_CONSTITUENCIES = "GET_CONSTITUENCIES"
export const GET_CONSTITUENCIES_SUCCESS = "GET_CONSTITUENCIES_SUCCESS"
export const GET_CONSTITUENCIES_FAIL = "GET_CONSTITUENCIES_FAIL"

/* CONSTITUENCY_DROPDOWN */
export const GET_CONSTITUENCY_DROPDOWN = "GET_CONSTITUENCIE_DROPDOWN"
export const GET_CONSTITUENCY_DROPDOWN_SUCCESS =
  "GET_CONSTITUENCIE_DROPDOWN_SUCCESS"
export const GET_CONSTITUENCY_DROPDOWN_FAIL = "GET_CONSTITUENCIE_DROPDOWN_FAIL"

/* CONSTITUENCYS DETAIL*/
export const GET_CONSTITUENCY_DETAIL = "GET_CONSTITUENCY_DETAIL"
export const GET_CONSTITUENCY_DETAIL_SUCCESS = "GET_CONSTITUENCY_DETAIL_SUCCESS"
export const GET_CONSTITUENCY_DETAIL_FAIL = "GET_CONSTITUENCY_DETAIL_FAIL"

/**
 * add CONSTITUENCY
 */
export const CREATE_CONSTITUENCY = "CREATE_CONSTITUENCY"
export const CREATE_CONSTITUENCY_SUCCESS = "CREATE_CONSTITUENCY_SUCCESS"
export const CREATE_CONSTITUENCY_FAIL = "CREATE_CONSTITUENCY_FAIL"

/**
 * Edit CONSTITUENCY
 */
export const UPDATE_CONSTITUENCY = "UPDATE_CONSTITUENCY"
export const UPDATE_CONSTITUENCY_SUCCESS = "UPDATE_CONSTITUENCY_SUCCESS"
export const UPDATE_CONSTITUENCY_FAIL = "UPDATE_CONSTITUENCY_FAIL"

/**
 * Delete CONSTITUENCY
 */
export const DELETE_CONSTITUENCY = "DELETE_CONSTITUENCY"
export const DELETE_CONSTITUENCY_SUCCESS = "DELETE_CONSTITUENCY_SUCCESS"
export const DELETE_CONSTITUENCY_FAIL = "DELETE_CONSTITUENCY_FAIL"
