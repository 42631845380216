/* FINISHEDPRODCHART */
export const GET_MONTHLY_CHART = "GET_MONTHLY_CHART"
export const GET_MONTHLY_CHART_SUCCESS = "GET_MONTHLY_CHART_SUCCESS"
export const GET_MONTHLY_CHART_FAIL = "GET_MONTHLY_CHART_FAIL"

export const GET_YEARLY_CHART = "GET_YEARLY_CHART"
export const GET_YEARLY_CHART_SUCCESS = "GET_YEARLY_CHART_SUCCESS"
export const GET_YEARLY_CHART_FAIL = "GET_YEARLY_CHART_FAIL"

export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA"
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS"
export const GET_DASHBOARD_DATA_FAIL = "GET_DASHBOARD_DATA_FAIL"

export const GET_DASHBOARD_CARD = "GET_DASHBOARD_CARD"
export const GET_DASHBOARD_CARD_SUCCESS = "GET_DASHBOARD_CARD_SUCCESS"
export const GET_DASHBOARD_CARD_FAIL = "GET_DASHBOARD_CARD_FAIL"

export const GET_DASHBOARD_DISEASE_TYPE_DATA = "GET_DASHBOARD_DISEASE_TYPE_DATA"
export const GET_DASHBOARD_DISEASE_TYPE_DATA_SUCCESS =
  "GET_DASHBOARD_DISEASE_TYPE_DATA_SUCCESS"
export const GET_DASHBOARD_DISEASE_TYPE_DATA_FAIL =
  "GET_DASHBOARD_DISEASE_TYPE_DATA_FAIL"
