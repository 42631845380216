import {
  GET_DEATH_REGISTRATIONS,
  GET_DEATH_REGISTRATIONS_SUCCESS,
  GET_DEATH_REGISTRATIONS_FAIL,
  GET_DEATH_REGISTRATIONS_CARDS,
  GET_DEATH_REGISTRATIONS_CARDS_SUCCESS,
  GET_DEATH_REGISTRATIONS_CARDS_FAIL,
  GET_DEATH_REGISTRATION_DETAIL,
  GET_DEATH_REGISTRATION_DETAIL_SUCCESS,
  GET_DEATH_REGISTRATION_DETAIL_FAIL,
  CREATE_DEATH_REGISTRATION,
  CREATE_DEATH_REGISTRATION_SUCCESS,
  CREATE_DEATH_REGISTRATION_FAIL,
  UPDATE_DEATH_REGISTRATION,
  UPDATE_DEATH_REGISTRATION_SUCCESS,
  UPDATE_DEATH_REGISTRATION_FAIL,
  DELETE_DEATH_REGISTRATION,
  DELETE_DEATH_REGISTRATION_SUCCESS,
  DELETE_DEATH_REGISTRATION_FAIL,
  GET_DEATH_REGISTRATIONS_FOR_DOWNLOAD,
  GET_DEATH_REGISTRATIONS_FOR_DOWNLOAD_SUCCESS,
  GET_DEATH_REGISTRATIONS_FOR_DOWNLOAD_FAIL,
} from "./actionTypes"

export const getDeathRegistrations = (searchText, page) => ({
  type: GET_DEATH_REGISTRATIONS,
  payload: { searchText, page },
})
export const getDeathRegistrationsSuccess = deathRegistrations => ({
  type: GET_DEATH_REGISTRATIONS_SUCCESS,
  payload: deathRegistrations,
})
export const getDeathRegistrationsFail = error => ({
  type: GET_DEATH_REGISTRATIONS_FAIL,
  payload: error,
})

export const getDeathRegistrationsForDownload = () => ({
  type: GET_DEATH_REGISTRATIONS_FOR_DOWNLOAD,
})
export const getDeathRegistrationsForDownloadSuccess =
  deathRegistrationsForDownload => ({
    type: GET_DEATH_REGISTRATIONS_FOR_DOWNLOAD_SUCCESS,
    payload: deathRegistrationsForDownload,
  })
export const getDeathRegistrationsForDownloadFail = error => ({
  type: GET_DEATH_REGISTRATIONS_FOR_DOWNLOAD_FAIL,
  payload: error,
})

export const getDeathRegistrationsCards = () => ({
  type: GET_DEATH_REGISTRATIONS_CARDS,
})
export const getDeathRegistrationsCardsSuccess = deathRegistrationCards => ({
  type: GET_DEATH_REGISTRATIONS_CARDS_SUCCESS,
  payload: deathRegistrationCards,
})
export const getDeathRegistrationsCardsFail = error => ({
  type: GET_DEATH_REGISTRATIONS_CARDS_FAIL,
  payload: error,
})

export const getDeathRegistrationDetail = deathRegistrationId => ({
  type: GET_DEATH_REGISTRATION_DETAIL,
  deathRegistrationId,
})

export const getDeathRegistrationDetailSuccess = deathRegistrationDetail => ({
  type: GET_DEATH_REGISTRATION_DETAIL_SUCCESS,
  payload: deathRegistrationDetail,
})

export const getDeathRegistrationDetailFail = error => ({
  type: GET_DEATH_REGISTRATION_DETAIL_FAIL,
  payload: error,
})

export const createDeathRegistration = (deathRegistration, history) => ({
  type: CREATE_DEATH_REGISTRATION,
  payload: { deathRegistration, history },
})

export const createDeathRegistrationSuccess = deathRegistration => ({
  type: CREATE_DEATH_REGISTRATION_SUCCESS,
  payload: deathRegistration,
})

export const createDeathRegistrationFail = error => ({
  type: CREATE_DEATH_REGISTRATION_FAIL,
  payload: error,
})

export const updateDeathRegistration = (
  deathRegistration,
  deathRegistrationId,
  history
) => ({
  type: UPDATE_DEATH_REGISTRATION,
  payload: { deathRegistration, deathRegistrationId, history },
})

export const updateDeathRegistrationSuccess = deathRegistration => ({
  type: UPDATE_DEATH_REGISTRATION_SUCCESS,
  payload: deathRegistration,
})

export const updateDeathRegistrationFail = error => ({
  type: UPDATE_DEATH_REGISTRATION_FAIL,
  payload: error,
})

export const deleteDeathRegistration = (deathRegistrationId, history) => ({
  type: DELETE_DEATH_REGISTRATION,
  payload: { deathRegistrationId, history },
})

export const deleteDeathRegistrationSuccess = deathRegistration => ({
  type: DELETE_DEATH_REGISTRATION_SUCCESS,
  payload: deathRegistration,
})

export const deleteDeathRegistrationFail = error => ({
  type: DELETE_DEATH_REGISTRATION_FAIL,
  payload: error,
})
