import {
  GET_MAHALLS,
  GET_MAHALLS_SUCCESS,
  GET_MAHALLS_FAIL,
  GET_MAHALLS_CARDS,
  GET_MAHALLS_CARDS_SUCCESS,
  GET_MAHALLS_CARDS_FAIL,
  GET_MAHALL_DROPDOWN,
  GET_MAHALL_DROPDOWN_SUCCESS,
  GET_MAHALL_DROPDOWN_FAIL,
  GET_MAHALL_DETAIL,
  GET_MAHALL_DETAIL_SUCCESS,
  GET_MAHALL_DETAIL_FAIL,
  PATCH_MAHALL_PREMIUM,
  PATCH_MAHALL_PREMIUM_SUCCESS,
  PATCH_MAHALL_PREMIUM_FAIL,
  CREATE_MAHALL,
  CREATE_MAHALL_SUCCESS,
  CREATE_MAHALL_FAIL,
  UPDATE_MAHALL,
  UPDATE_MAHALL_SUCCESS,
  UPDATE_MAHALL_FAIL,
  DELETE_MAHALL,
  DELETE_MAHALL_SUCCESS,
  DELETE_MAHALL_FAIL,
  GET_MAHALLS_FOR_DOWNLOAD,
  GET_MAHALLS_FOR_DOWNLOAD_SUCCESS,
  GET_MAHALLS_FOR_DOWNLOAD_FAIL,
} from "./actiontypes"

export const getMahalls = (searchText, page) => ({
  type: GET_MAHALLS,
  payload: { searchText, page },
})

export const getMahallsSuccess = mahalls => ({
  type: GET_MAHALLS_SUCCESS,
  payload: mahalls,
})

export const getMahallsFail = error => ({
  type: GET_MAHALLS_FAIL,
  payload: error,
})

export const getMahallsForDownload = () => ({
  type: GET_MAHALLS_FOR_DOWNLOAD,
})

export const getMahallsForDownloadSuccess = mahallsForDownload => ({
  type: GET_MAHALLS_FOR_DOWNLOAD_SUCCESS,
  payload: mahallsForDownload,
})

export const getMahallsForDownloadFail = error => ({
  type: GET_MAHALLS_FOR_DOWNLOAD_FAIL,
  payload: error,
})

export const getMahallsCards = () => ({
  type: GET_MAHALLS_CARDS,
})

export const getMahallsCardsSuccess = mahallsCards => ({
  type: GET_MAHALLS_CARDS_SUCCESS,
  payload: mahallsCards,
})

export const getMahallsCardsFail = error => ({
  type: GET_MAHALLS_CARDS_FAIL,
  payload: error,
})

export const getMahallDetail = mahallId => ({
  type: GET_MAHALL_DETAIL,
  mahallId,
})

export const getMahallDetailSuccess = mahallDetail => ({
  type: GET_MAHALL_DETAIL_SUCCESS,
  payload: mahallDetail,
})

export const getMahallDetailFail = error => ({
  type: GET_MAHALL_DETAIL_FAIL,
  payload: error,
})

export const patchMahallPremium = (mahallId, data) => ({
  type: PATCH_MAHALL_PREMIUM,
  payload: { mahallId, data },
})

export const patchMahallPremiumSuccess = mahallDetail => ({
  type: PATCH_MAHALL_PREMIUM_SUCCESS,
  payload: mahallDetail,
})

export const patchMahallPremiumFail = error => ({
  type: PATCH_MAHALL_PREMIUM_FAIL,
  payload: error,
})

export const getMahallDropdown = searchText => ({
  type: GET_MAHALL_DROPDOWN,
  searchText,
})

export const getMahallDropdownSuccess = mahalls => ({
  type: GET_MAHALL_DROPDOWN_SUCCESS,
  payload: mahalls,
})

export const getMahallDropdownFail = error => ({
  type: GET_MAHALL_DROPDOWN_FAIL,
  payload: error,
})

export const createMahall = (mahall, history) => ({
  type: CREATE_MAHALL,
  payload: { mahall, history },
})

export const createMahallSuccess = mahall => ({
  type: CREATE_MAHALL_SUCCESS,
  payload: mahall,
})

export const createMahallFail = error => ({
  type: CREATE_MAHALL_FAIL,
  payload: error,
})

export const updateMahall = (mahall, mahallId) => ({
  type: UPDATE_MAHALL,
  payload: { mahall, mahallId },
})

export const updateMahallSuccess = mahall => ({
  type: UPDATE_MAHALL_SUCCESS,
  payload: mahall,
})

export const updateMahallFail = error => ({
  type: UPDATE_MAHALL_FAIL,
  payload: error,
})

export const deleteMahall = (mahallId, history) => ({
  type: DELETE_MAHALL,
  payload: { mahallId, history },
})

export const deleteMahallSuccess = mahall => ({
  type: DELETE_MAHALL_SUCCESS,
  payload: mahall,
})

export const deleteMahallFail = error => ({
  type: DELETE_MAHALL_FAIL,
  payload: error,
})
