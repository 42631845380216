/* COMMITTEEMEMBERS */
export const GET_COMMITTEE_MEMBERS = "GET_COMMITTEE_MEMBERS"
export const GET_COMMITTEE_MEMBERS_SUCCESS = "GET_COMMITTEE_MEMBERS_SUCCESS"
export const GET_COMMITTEE_MEMBERS_FAIL = "GET_COMMITTEE_MEMBERS_FAIL"

/* COMMITTEEMEMBERS DETAIL*/
export const GET_COMMITTEE_MEMBER_DETAIL = "GET_COMMITTEE_MEMBER_DETAIL"
export const GET_COMMITTEE_MEMBER_DETAIL_SUCCESS =
  "GET_COMMITTEE_MEMBER_DETAIL_SUCCESS"
export const GET_COMMITTEE_MEMBER_DETAIL_FAIL =
  "GET_COMMITTEE_MEMBER_DETAIL_FAIL"

/* COMMITTEEMEMBERS DROPDOWN*/
export const GET_COMMITTEE_MEMBER_DROPDOWN = "GET_COMMITTEE_MEMBER_DROPDOWN"
export const GET_COMMITTEE_MEMBER_DROPDOWN_SUCCESS =
  "GET_COMMITTEE_MEMBER_DROPDOWN_SUCCESS"
export const GET_COMMITTEE_MEMBER_DROPDOWN_FAIL =
  "GET_COMMITTEE_MEMBER_DROPDOWN_FAIL"

/**
 * add COMMITTEEMEMBER
 */
export const CREATE_COMMITTEE_MEMBER = "CREATE_COMMITTEE_MEMBER"
export const CREATE_COMMITTEE_MEMBER_SUCCESS = "CREATE_COMMITTEE_MEMBER_SUCCESS"
export const CREATE_COMMITTEE_MEMBER_FAIL = "CREATE_COMMITTEE_MEMBER_FAIL"

/**
 * Edit COMMITTEEMEMBER
 */
export const UPDATE_COMMITTEE_MEMBER = "UPDATE_COMMITTEE_MEMBER"
export const UPDATE_COMMITTEE_MEMBER_SUCCESS = "UPDATE_COMMITTEE_MEMBER_SUCCESS"
export const UPDATE_COMMITTEE_MEMBER_FAIL = "UPDATE_COMMITTEE_MEMBER_FAIL"

/**
 * Delete COMMITTEEMEMBER
 */
export const DELETE_COMMITTEE_MEMBER = "DELETE_COMMITTEE_MEMBER"
export const DELETE_COMMITTEE_MEMBER_SUCCESS = "DELETE_COMMITTEE_MEMBER_SUCCESS"
export const DELETE_COMMITTEE_MEMBER_FAIL = "DELETE_COMMITTEE_MEMBER_FAIL"
