/* DEATH REGISTRATIONS */
export const GET_DEATH_REGISTRATIONS = "GET_DEATH_REGISTRATIONS"
export const GET_DEATH_REGISTRATIONS_SUCCESS = "GET_DEATH_REGISTRATIONS_SUCCESS"
export const GET_DEATH_REGISTRATIONS_FAIL = "GET_DEATH_REGISTRATIONS_FAIL"

/* DEATH REGISTRATIONS FOR DOWNLOAD */
export const GET_DEATH_REGISTRATIONS_FOR_DOWNLOAD =
  "GET_DEATH_REGISTRATIONS_FOR_DOWNLOAD"
export const GET_DEATH_REGISTRATIONS_FOR_DOWNLOAD_SUCCESS =
  "GET_DEATH_REGISTRATIONS_FOR_DOWNLOAD_SUCCESS"
export const GET_DEATH_REGISTRATIONS_FOR_DOWNLOAD_FAIL =
  "GET_DEATH_REGISTRATIONS_FOR_DOWNLOAD_FAIL"

/* DEATH REGISTRATIONS CARDS*/
export const GET_DEATH_REGISTRATIONS_CARDS = "GET_DEATH_REGISTRATIONS_CARDS"
export const GET_DEATH_REGISTRATIONS_CARDS_SUCCESS =
  "GET_DEATH_REGISTRATIONS_CARDS_SUCCESS"
export const GET_DEATH_REGISTRATIONS_CARDS_FAIL =
  "GET_DEATH_REGISTRATIONS_CARDS_FAIL"

/* DEATH REGISTRATION DETAIL*/
export const GET_DEATH_REGISTRATION_DETAIL = "GET_DEATH_REGISTRATION_DETAIL"
export const GET_DEATH_REGISTRATION_DETAIL_SUCCESS =
  "GET_DEATH_REGISTRATION_DETAIL_SUCCESS"
export const GET_DEATH_REGISTRATION_DETAIL_FAIL =
  "GET_DEATH_REGISTRATION_DETAIL_FAIL"

/**
 * add DEATH REGISTRATION
 */
export const CREATE_DEATH_REGISTRATION = "CREATE_DEATH_REGISTRATION"
export const CREATE_DEATH_REGISTRATION_SUCCESS =
  "CREATE_DEATH_REGISTRATION_SUCCESS"
export const CREATE_DEATH_REGISTRATION_FAIL = "CREATE_DEATH_REGISTRATION_FAIL"

/**
 * Edit DEATH REGISTRATION
 */
export const UPDATE_DEATH_REGISTRATION = "UPDATE_DEATH_REGISTRATION"
export const UPDATE_DEATH_REGISTRATION_SUCCESS =
  "UPDATE_DEATH_REGISTRATION_SUCCESS"
export const UPDATE_DEATH_REGISTRATION_FAIL = "UPDATE_DEATH_REGISTRATION_FAIL"

/**
 * Delete DEATH REGISTRATION
 */
export const DELETE_DEATH_REGISTRATION = "DELETE_DEATH_REGISTRATION"
export const DELETE_DEATH_REGISTRATION_SUCCESS =
  "DELETE_DEATH_REGISTRATION_SUCCESS"
export const DELETE_DEATH_REGISTRATION_FAIL = "DELETE_DEATH_REGISTRATION_FAIL"
