/* GET_MASTER_ACCOUNT_ITEMS */
export const GET_MASTER_ACCOUNT_ITEMS = "GET_MASTER_ACCOUNT_ITEMS"
export const GET_MASTER_ACCOUNT_ITEMS_SUCCESS =
  "GET_MASTER_ACCOUNT_ITEMS_SUCCESS"
export const GET_MASTER_ACCOUNT_ITEMS_FAIL = "GET_MASTER_ACCOUNT_ITEMS_FAIL"

/* GET_MASTER_ACCOUNT_ITEMS_FOR_DOWNLOAD */
export const GET_MASTER_ACCOUNT_ITEMS_FOR_DOWNLOAD =
  "GET_MASTER_ACCOUNT_ITEMS_FOR_DOWNLOAD"
export const GET_MASTER_ACCOUNT_ITEMS_FOR_DOWNLOAD_SUCCESS =
  "GET_MASTER_ACCOUNT_ITEMS_FOR_DOWNLOAD_SUCCESS"
export const GET_MASTER_ACCOUNT_ITEMS_FOR_DOWNLOAD_FAIL =
  "GET_MASTER_ACCOUNT_ITEMS_FOR_DOWNLOAD_FAIL"
