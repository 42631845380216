import {
  GET_LEDGER_ITEMS,
  GET_LEDGER_ITEMS_SUCCESS,
  GET_LEDGER_ITEMS_FAIL,
  GET_LEDGER_ITEM_DETAIL,
  GET_LEDGER_ITEM_DETAIL_SUCCESS,
  GET_LEDGER_ITEM_DETAIL_FAIL,
  CREATE_LEDGER_ITEM,
  CREATE_LEDGER_ITEM_SUCCESS,
  CREATE_LEDGER_ITEM_FAIL,
  UPDATE_LEDGER_ITEM,
  UPDATE_LEDGER_ITEM_SUCCESS,
  UPDATE_LEDGER_ITEM_FAIL,
  DELETE_LEDGER_ITEM,
  DELETE_LEDGER_ITEM_SUCCESS,
  DELETE_LEDGER_ITEM_FAIL,
  GET_LEDGER_ITEMS_FOR_DOWNLOAD,
  GET_LEDGER_ITEMS_FOR_DOWNLOAD_SUCCESS,
  GET_LEDGER_ITEMS_FOR_DOWNLOAD_FAIL,
} from "./actionTypes"

export const getLedgerItems = (
  ledgerId,
  searchText,
  page,
  date,
  transactionType,
  instituteId,
  categoryId,
  memberId,
  walletId
) => ({
  type: GET_LEDGER_ITEMS,
  payload: {
    ledgerId,
    searchText,
    page,
    date,
    transactionType,
    instituteId,
    categoryId,
    memberId,
    walletId,
  },
})

export const getLedgerItemsSuccess = ledgerItems => ({
  type: GET_LEDGER_ITEMS_SUCCESS,
  payload: ledgerItems,
})
export const getLedgerItemsFail = error => ({
  type: GET_LEDGER_ITEMS_FAIL,
  payload: error,
})

export const getLedgerItemsForDownload = (
  date,
  searchText,
  transactionType,
  ledgerId,
  instituteId,
  categoryId,
  memberId,
  walletId
) => ({
  type: GET_LEDGER_ITEMS_FOR_DOWNLOAD,
  payload: {
    date,
    searchText,
    transactionType,
    ledgerId,
    instituteId,
    categoryId,
    memberId,
    walletId,
  },
})

export const getLedgerItemsForDownloadSuccess = ledgerItemsForDownload => ({
  type: GET_LEDGER_ITEMS_FOR_DOWNLOAD_SUCCESS,
  payload: ledgerItemsForDownload,
})

export const getLedgerItemsForDownloadFail = error => ({
  type: GET_LEDGER_ITEMS_FOR_DOWNLOAD_FAIL,
  payload: error,
})

export const getLedgerItemDetail = ledgerItemId => ({
  type: GET_LEDGER_ITEM_DETAIL,
  ledgerItemId,
})

export const getLedgerItemDetailSuccess = ledgerItemDetail => ({
  type: GET_LEDGER_ITEM_DETAIL_SUCCESS,
  payload: ledgerItemDetail,
})

export const getLedgerItemDetailFail = error => ({
  type: GET_LEDGER_ITEM_DETAIL_FAIL,
  payload: error,
})

export const createLedgerItem = (ledgerItem, history) => ({
  type: CREATE_LEDGER_ITEM,
  payload: { ledgerItem, history },
})

export const createLedgerItemSuccess = ledgerItem => ({
  type: CREATE_LEDGER_ITEM_SUCCESS,
  payload: ledgerItem,
})

export const createLedgerItemFail = error => ({
  type: CREATE_LEDGER_ITEM_FAIL,
  payload: error,
})

export const updateLedgerItem = (ledgerItem, ledgerItemId, history) => ({
  type: UPDATE_LEDGER_ITEM,
  payload: { ledgerItem, ledgerItemId, history },
})

export const updateLedgerItemSuccess = ledgerItem => ({
  type: UPDATE_LEDGER_ITEM_SUCCESS,
  payload: ledgerItem,
})

export const updateLedgerItemFail = error => ({
  type: UPDATE_LEDGER_ITEM_FAIL,
  payload: error,
})

export const deleteLedgerItem = (ledgerItemId, history, ledgerId) => ({
  type: DELETE_LEDGER_ITEM,
  payload: { ledgerItemId, history, ledgerId },
})

export const deleteLedgerItemSuccess = ledgerItem => ({
  type: DELETE_LEDGER_ITEM_SUCCESS,
  payload: ledgerItem,
})

export const deleteLedgerItemFail = error => ({
  type: DELETE_LEDGER_ITEM_FAIL,
  payload: error,
})
