import {
  GET_SURVEY_USER,
  GET_SURVEY_USER_SUCCESS,
  GET_SURVEY_USER_FAIL,
  GET_SURVEY_USER_DETAIL,
  GET_SURVEY_USER_DETAIL_SUCCESS,
  GET_SURVEY_USER_DETAIL_FAIL,
  CHANGE_SURVEY_USER_STATUS,
  CHANGE_SURVEY_USER_STATUS_SUCCESS,
  CHANGE_SURVEY_USER_STATUS_FAIL,
  CREATE_SURVEY_USER,
  CREATE_SURVEY_USER_SUCCESS,
  CREATE_SURVEY_USER_FAIL,
  UPDATE_SURVEY_USER,
  UPDATE_SURVEY_USER_SUCCESS,
  UPDATE_SURVEY_USER_FAIL,
  DELETE_SURVEY_USER,
  DELETE_SURVEY_USER_SUCCESS,
  DELETE_SURVEY_USER_FAIL,
} from "./actionTypes"

export const getSurveyUser = (searchText, page) => ({
  type: GET_SURVEY_USER,
  payload: { searchText, page },
})

export const getSurveyUserSuccess = surveyUsers => ({
  type: GET_SURVEY_USER_SUCCESS,
  payload: surveyUsers,
})
export const getSurveyUserFail = error => ({
  type: GET_SURVEY_USER_FAIL,
  payload: error,
})

export const getSurveyUserDetail = surveyUserId => ({
  type: GET_SURVEY_USER_DETAIL,
  surveyUserId,
})

export const getSurveyUserDetailSuccess = surveyUserDetail => ({
  type: GET_SURVEY_USER_DETAIL_SUCCESS,
  payload: surveyUserDetail,
})

export const getSurveyUserDetailFail = error => ({
  type: GET_SURVEY_USER_DETAIL_FAIL,
  payload: error,
})

export const changeSurveyUserStatus = (surveyUserId, checked) => ({
  type: CHANGE_SURVEY_USER_STATUS,
  payload: { surveyUserId, checked },
})
export const changeSurveyUserStatusSuccess = surveyUserStatus => ({
  type: CHANGE_SURVEY_USER_STATUS_SUCCESS,
  payload: surveyUserStatus,
})
export const changeSurveyUserStatusFail = error => ({
  type: CHANGE_SURVEY_USER_STATUS_FAIL,
  payload: error,
})

export const createSurveyUser = (surveyUser, history) => ({
  type: CREATE_SURVEY_USER,
  payload: { surveyUser, history },
})

export const createSurveyUserSuccess = surveyUser => ({
  type: CREATE_SURVEY_USER_SUCCESS,
  payload: surveyUser,
})

export const createSurveyUserFail = error => ({
  type: CREATE_SURVEY_USER_FAIL,
  payload: error,
})

export const updateSurveyUser = (surveyUser, surveyUserId, history) => ({
  type: UPDATE_SURVEY_USER,
  payload: { surveyUser, surveyUserId, history },
})

export const updateSurveyUserSuccess = surveyUser => ({
  type: UPDATE_SURVEY_USER_SUCCESS,
  payload: surveyUser,
})

export const updateSurveyUserFail = error => ({
  type: UPDATE_SURVEY_USER_FAIL,
  payload: error,
})

export const deleteSurveyUser = (surveyUserId, history) => ({
  type: DELETE_SURVEY_USER,
  payload: { surveyUserId, history },
})

export const deleteSurveyUserSuccess = surveyUser => ({
  type: DELETE_SURVEY_USER_SUCCESS,
  payload: surveyUser,
})

export const deleteSurveyUserFail = error => ({
  type: DELETE_SURVEY_USER_FAIL,
  payload: error,
})
