import {
  GET_FAMILY_MEMBER_VARISANGYA_WALLETS,
  GET_FAMILY_MEMBER_VARISANGYA_WALLETS_SUCCESS,
  GET_FAMILY_MEMBER_VARISANGYA_WALLETS_FAIL,
  CREATE_FAMILY_MEMBER_VARISANGYA_WALLET,
  CREATE_FAMILY_MEMBER_VARISANGYA_WALLET_SUCCESS,
  CREATE_FAMILY_MEMBER_VARISANGYA_WALLET_FAIL,
  GET_FAMILY_MEMBER_VARISANGYA_WALLET_DETAIL,
  GET_FAMILY_MEMBER_VARISANGYA_WALLET_DETAIL_SUCCESS,
  GET_FAMILY_MEMBER_VARISANGYA_WALLET_DETAIL_FAIL,
  CLEAR_FAMILY_MEMBER_VARISANGYA_WALLET_DETAIL,
  GET_FAMILY_MEMBER_VARISANGYA_WALLET_VIEW,
  GET_FAMILY_MEMBER_VARISANGYA_WALLET_VIEW_SUCCESS,
  GET_FAMILY_MEMBER_VARISANGYA_WALLET_VIEW_FAIL,
  DELETE_FAMILY__MEMBER_VARISANGYA_WALLET,
  DELETE_FAMILY__MEMBER_VARISANGYA_WALLET_SUCCESS,
  DELETE_FAMILY__MEMBER_VARISANGYA_WALLET_FAIL,
} from "./actionTypes"

export const deleteFamilyMemberVarisangyaWallet = transactionId => ({
  type: DELETE_FAMILY__MEMBER_VARISANGYA_WALLET,
  payload: { transactionId },
})
export const deleteFamilyMemberVarisangyaWalletSuccess = res => ({
  type: DELETE_FAMILY__MEMBER_VARISANGYA_WALLET_SUCCESS,
  payload: res,
})
export const deleteFamilyMemberVarisangyaWalletFail = error => ({
  type: DELETE_FAMILY__MEMBER_VARISANGYA_WALLET_FAIL,
  payload: error,
})

export const getFamilyMemberVarisangyaWallets = (searchText, page, sort) => ({
  type: GET_FAMILY_MEMBER_VARISANGYA_WALLETS,
  payload: { searchText, page, sort },
})
export const getFamilyMemberVarisangyaWalletsSuccess =
  familyMemberVarisangyaWallets => ({
    type: GET_FAMILY_MEMBER_VARISANGYA_WALLETS_SUCCESS,
    payload: familyMemberVarisangyaWallets,
  })
export const getFamilyMemberVarisangyaWalletsFail = error => ({
  type: GET_FAMILY_MEMBER_VARISANGYA_WALLETS_FAIL,
  payload: error,
})

export const createFamilyMemberVarisangyaWallet = (
  familyMemberVarisangyaWallet,
  history
) => ({
  type: CREATE_FAMILY_MEMBER_VARISANGYA_WALLET,
  payload: { familyMemberVarisangyaWallet, history },
})

export const createFamilyMemberVarisangyaWalletSuccess =
  familyMemberVarisangyaWallet => ({
    type: CREATE_FAMILY_MEMBER_VARISANGYA_WALLET_SUCCESS,
    payload: familyMemberVarisangyaWallet,
  })

export const createFamilyMemberVarisangyaWalletFail = error => ({
  type: CREATE_FAMILY_MEMBER_VARISANGYA_WALLET_FAIL,
  payload: error,
})

export const getFamilyMemberVarisangyaWalletDetail = (
  familyMemberId,
  page,
  searchText,
  sort
) => ({
  type: GET_FAMILY_MEMBER_VARISANGYA_WALLET_DETAIL,
  payload: { familyMemberId, page, searchText, sort },
})

export const getFamilyMemberVarisangyaWalletDetailSuccess =
  familyMemberVarisangyaWalletDetail => ({
    type: GET_FAMILY_MEMBER_VARISANGYA_WALLET_DETAIL_SUCCESS,
    payload: familyMemberVarisangyaWalletDetail,
  })

export const getFamilyMemberVarisangyaWalletDetailFail = error => ({
  type: GET_FAMILY_MEMBER_VARISANGYA_WALLET_DETAIL_FAIL,
  payload: error,
})

export const clearFamilyMemberVarisangyaWalletDetail = () => ({
  type: CLEAR_FAMILY_MEMBER_VARISANGYA_WALLET_DETAIL,
})

export const getFamilyMemberVarisangyaWalletView =
  familyMemberVarisangyaWalletId => ({
    type: GET_FAMILY_MEMBER_VARISANGYA_WALLET_VIEW,
    familyMemberVarisangyaWalletId,
  })

export const getFamilyMemberVarisangyaWalletViewSuccess =
  familyMemberVarisangyaWalletView => ({
    type: GET_FAMILY_MEMBER_VARISANGYA_WALLET_VIEW_SUCCESS,
    payload: familyMemberVarisangyaWalletView,
  })

export const getFamilyMemberVarisangyaWalletViewFail = error => ({
  type: GET_FAMILY_MEMBER_VARISANGYA_WALLET_VIEW_FAIL,
  payload: error,
})
