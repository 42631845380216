import {
  GET_INSTITUTE_ACCOUNTS,
  GET_INSTITUTE_ACCOUNTS_SUCCESS,
  GET_INSTITUTE_ACCOUNTS_FAIL,
  GET_INSTITUTE_ACCOUNT_DETAIL,
  GET_INSTITUTE_ACCOUNT_DETAIL_SUCCESS,
  GET_INSTITUTE_ACCOUNT_DETAIL_FAIL,
  CREATE_INSTITUTE_ACCOUNT,
  CREATE_INSTITUTE_ACCOUNT_SUCCESS,
  CREATE_INSTITUTE_ACCOUNT_FAIL,
  UPDATE_INSTITUTE_ACCOUNT,
  UPDATE_INSTITUTE_ACCOUNT_SUCCESS,
  UPDATE_INSTITUTE_ACCOUNT_FAIL,
  DELETE_INSTITUTE_ACCOUNT,
  DELETE_INSTITUTE_ACCOUNT_SUCCESS,
  DELETE_INSTITUTE_ACCOUNT_FAIL,
  GET_ACCOUNT_ITEM_DETAIL,
  GET_ACCOUNT_ITEM_DETAIL_SUCCESS,
  GET_ACCOUNT_ITEM_DETAIL_FAIL,
  CREATE_ACCOUNT_ITEM,
  CREATE_ACCOUNT_ITEM_SUCCESS,
  CREATE_ACCOUNT_ITEM_FAIL,
  UPDATE_ACCOUNT_ITEM,
  UPDATE_ACCOUNT_ITEM_SUCCESS,
  UPDATE_ACCOUNT_ITEM_FAIL,
  DELETE_ACCOUNT_ITEM,
  DELETE_ACCOUNT_ITEM_SUCCESS,
  DELETE_ACCOUNT_ITEM_FAIL,
} from "./actionTypes"

export const getInstituteAccounts = (searchText, page) => ({
  type: GET_INSTITUTE_ACCOUNTS,
  payload: { searchText, page },
})

export const getInstituteAccountsSuccess = instituteAccounts => ({
  type: GET_INSTITUTE_ACCOUNTS_SUCCESS,
  payload: instituteAccounts,
})
export const getInstituteAccountsFail = error => ({
  type: GET_INSTITUTE_ACCOUNTS_FAIL,
  payload: error,
})

export const getInstituteAccountDetail = instituteAccountId => ({
  type: GET_INSTITUTE_ACCOUNT_DETAIL,
  instituteAccountId,
})

export const getInstituteAccountDetailSuccess = instituteAccountDetail => ({
  type: GET_INSTITUTE_ACCOUNT_DETAIL_SUCCESS,
  payload: instituteAccountDetail,
})

export const getInstituteAccountDetailFail = error => ({
  type: GET_INSTITUTE_ACCOUNT_DETAIL_FAIL,
  payload: error,
})

export const createInstituteAccount = (instituteAccount, history) => ({
  type: CREATE_INSTITUTE_ACCOUNT,
  payload: { instituteAccount, history },
})

export const createInstituteAccountSuccess = instituteAccount => ({
  type: CREATE_INSTITUTE_ACCOUNT_SUCCESS,
  payload: instituteAccount,
})

export const createInstituteAccountFail = error => ({
  type: CREATE_INSTITUTE_ACCOUNT_FAIL,
  payload: error,
})

export const updateInstituteAccount = (
  instituteAccount,
  instituteAccountId
) => ({
  type: UPDATE_INSTITUTE_ACCOUNT,
  payload: { instituteAccount, instituteAccountId },
})

export const updateInstituteAccountSuccess = instituteAccount => ({
  type: UPDATE_INSTITUTE_ACCOUNT_SUCCESS,
  payload: instituteAccount,
})

export const updateInstituteAccountFail = error => ({
  type: UPDATE_INSTITUTE_ACCOUNT_FAIL,
  payload: error,
})

export const deleteInstituteAccount = (instituteAccountId, history) => ({
  type: DELETE_INSTITUTE_ACCOUNT,
  payload: { instituteAccountId, history },
})

export const deleteInstituteAccountSuccess = instituteAccount => ({
  type: DELETE_INSTITUTE_ACCOUNT_SUCCESS,
  payload: instituteAccount,
})

export const deleteInstituteAccountFail = error => ({
  type: DELETE_INSTITUTE_ACCOUNT_FAIL,
  payload: error,
})

export const getAccountItemDetail = accountItemId => ({
  type: GET_ACCOUNT_ITEM_DETAIL,
  accountItemId,
})

export const getAccountItemDetailSuccess = accountItemDetail => ({
  type: GET_ACCOUNT_ITEM_DETAIL_SUCCESS,
  payload: accountItemDetail,
})

export const getAccountItemDetailFail = error => ({
  type: GET_ACCOUNT_ITEM_DETAIL_FAIL,
  payload: error,
})

export const createAccountItem = (accountItem, history) => ({
  type: CREATE_ACCOUNT_ITEM,
  payload: { accountItem, history },
})

export const createAccountItemSuccess = accountItem => ({
  type: CREATE_ACCOUNT_ITEM_SUCCESS,
  payload: accountItem,
})

export const createAccountItemFail = error => ({
  type: CREATE_ACCOUNT_ITEM_FAIL,
  payload: error,
})

export const updateAccountItem = (accountItem, accountItemId) => ({
  type: UPDATE_ACCOUNT_ITEM,
  payload: { accountItem, accountItemId },
})

export const updateAccountItemSuccess = accountItem => ({
  type: UPDATE_ACCOUNT_ITEM_SUCCESS,
  payload: accountItem,
})

export const updateAccountItemFail = error => ({
  type: UPDATE_ACCOUNT_ITEM_FAIL,
  payload: error,
})

export const deleteAccountItem = (accountItemId, history) => ({
  type: DELETE_ACCOUNT_ITEM,
  payload: { accountItemId, history },
})

export const deleteAccountItemSuccess = accountItem => ({
  type: DELETE_ACCOUNT_ITEM_SUCCESS,
  payload: accountItem,
})

export const deleteAccountItemFail = error => ({
  type: DELETE_ACCOUNT_ITEM_FAIL,
  payload: error,
})
