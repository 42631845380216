import {
  GET_MAHALL_USERS,
  GET_MAHALL_USERS_SUCCESS,
  GET_MAHALL_USERS_FAIL,
  GET_MAHALL_USERS_CARDS,
  GET_MAHALL_USERS_CARDS_SUCCESS,
  GET_MAHALL_USERS_CARDS_FAIL,
  GET_MAHALL_USER_DROPDOWN,
  GET_MAHALL_USER_DROPDOWN_SUCCESS,
  GET_MAHALL_USER_DROPDOWN_FAIL,
  GET_MAHALL_USER_DETAIL,
  GET_MAHALL_USER_DETAIL_SUCCESS,
  GET_MAHALL_USER_DETAIL_FAIL,
  UPDATE_MAHALL_USER_DETAIL,
  UPDATE_MAHALL_USER_DETAIL_SUCCESS,
  UPDATE_MAHALL_USER_DETAIL_FAIL,
  OTP_VERIFICATION,
  OTP_VERIFICATION_SUCCESS,
  OTP_VERIFICATION_FAIL,
  CREATE_MAHALL_USER,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
  CREATE_MAHALL_USER_SUCCESS,
  CREATE_MAHALL_USER_FAIL,
  UPDATE_MAHALL_USER,
  UPDATE_MAHALL_USER_SUCCESS,
  UPDATE_MAHALL_USER_FAIL,
  DELETE_MAHALL_USER,
  DELETE_MAHALL_USER_SUCCESS,
  DELETE_MAHALL_USER_FAIL,
  CHANGE_MAHALL_USER_STATUS,
  CHANGE_MAHALL_USER_STATUS_SUCCESS,
  CHANGE_MAHALL_USER_STATUS_FAIL,
  GET_MAHALL_USERS_FOR_DOWNLOAD,
  GET_MAHALL_USERS_FOR_DOWNLOAD_SUCCESS,
  GET_MAHALL_USERS_FOR_DOWNLOAD_FAIL,
} from "./actionTypes"

export const getMahallUsers = (searchText, page) => ({
  type: GET_MAHALL_USERS,
  payload: { searchText, page },
})
export const getMahallUsersSuccess = mahallusers => ({
  type: GET_MAHALL_USERS_SUCCESS,
  payload: mahallusers,
})
export const getMahallUsersFail = error => ({
  type: GET_MAHALL_USERS_FAIL,
  payload: error,
})

export const getMahallUsersForDownload = () => ({
  type: GET_MAHALL_USERS_FOR_DOWNLOAD,
})
export const getMahallUsersForDownloadSuccess = mahallusersForDownload => ({
  type: GET_MAHALL_USERS_FOR_DOWNLOAD_SUCCESS,
  payload: mahallusersForDownload,
})
export const getMahallUsersForDownloadFail = error => ({
  type: GET_MAHALL_USERS_FOR_DOWNLOAD_FAIL,
  payload: error,
})

export const getMahallUsersCards = () => ({
  type: GET_MAHALL_USERS_CARDS,
})
export const getMahallUsersCardsSuccess = mahallusersCards => ({
  type: GET_MAHALL_USERS_CARDS_SUCCESS,
  payload: mahallusersCards,
})
export const getMahallUsersCardsFail = error => ({
  type: GET_MAHALL_USERS_CARDS_FAIL,
  payload: error,
})

export const changeMahallUserStatus = (mahallUserId, checked) => ({
  type: CHANGE_MAHALL_USER_STATUS,
  payload: { mahallUserId, checked },
})
export const changeMahallUserStatusSuccess = mahallUserStatus => ({
  type: CHANGE_MAHALL_USER_STATUS_SUCCESS,
  payload: mahallUserStatus,
})
export const changeMahallUserStatusFail = error => ({
  type: CHANGE_MAHALL_USER_STATUS_FAIL,
  payload: error,
})

export const getMahallUserDropdown = (searchText, page) => ({
  type: GET_MAHALL_USER_DROPDOWN,
  payload: { searchText, page },
})
export const getMahallUserDropdownSuccess = searchText => ({
  type: GET_MAHALL_USER_DROPDOWN_SUCCESS,
  payload: searchText,
})
export const getMahallUserDropdownFail = error => ({
  type: GET_MAHALL_USER_DROPDOWN_FAIL,
  payload: error,
})

export const otpVerification = phone => {
  console.log(phone)
  return {
    type: OTP_VERIFICATION,
    payload: { phone },
  }
}

export const otpVerificationSuccess = otp => {
  return {
    type: OTP_VERIFICATION_SUCCESS,
    payload: otp,
  }
}

export const otpVerificationFail = error => {
  return {
    type: OTP_VERIFICATION_FAIL,
    payload: error,
  }
}

export const getMahallUserDetail = mahallUserId => ({
  type: GET_MAHALL_USER_DETAIL,
  mahallUserId,
})

export const getMahallUserDetailSuccess = mahallUserDetail => ({
  type: GET_MAHALL_USER_DETAIL_SUCCESS,
  payload: mahallUserDetail,
})

export const getMahallUserDetailFail = error => ({
  type: GET_MAHALL_USER_DETAIL_FAIL,
  payload: error,
})

export const updateMahallUserDetail = (mahalluser, mahallUserId) => ({
  type: UPDATE_MAHALL_USER_DETAIL,
  payload: { mahalluser, mahallUserId },
})

export const updateMahallUserDetailSuccess = mahallUser => ({
  type: UPDATE_MAHALL_USER_DETAIL_SUCCESS,
  payload: mahallUser,
})

export const updateMahallUserDetailFail = error => ({
  type: UPDATE_MAHALL_USER_DETAIL_FAIL,
  payload: error,
})

export const createUser = (user, history) => ({
  type: CREATE_USER,
  payload: { user, history },
})

export const createUserSuccess = user => ({
  type: CREATE_USER_SUCCESS,
  payload: user,
})

export const createUserFail = error => ({
  type: CREATE_USER_FAIL,
  payload: error,
})

export const createMahallUser = (mahallUser, history, role) => ({
  type: CREATE_MAHALL_USER,
  payload: { mahallUser, history, role },
})

export const createMahallUserSuccess = mahallUser => ({
  type: CREATE_MAHALL_USER_SUCCESS,
  payload: mahallUser,
})

export const createMahallUserFail = error => ({
  type: CREATE_MAHALL_USER_FAIL,
  payload: error,
})

export const updateMahallUser = (mahallUser, mahallUserId, history) => ({
  type: UPDATE_MAHALL_USER,
  payload: { mahallUser, mahallUserId, history },
})

export const updateMahallUserSuccess = mahallUser => ({
  type: UPDATE_MAHALL_USER_SUCCESS,
  payload: mahallUser,
})

export const updateMahallUserFail = error => ({
  type: UPDATE_MAHALL_USER_FAIL,
  payload: error,
})

export const deleteMahallUser = (mahallUserId, history) => ({
  type: DELETE_MAHALL_USER,
  payload: { mahallUserId, history },
})

export const deleteMahallUserSuccess = mahallUser => ({
  type: DELETE_MAHALL_USER_SUCCESS,
  payload: mahallUser,
})

export const deleteMahallUserFail = error => ({
  type: DELETE_MAHALL_USER_FAIL,
  payload: error,
})
