/* FAMILY_MEMBER_VARISANGYA_ITEMS */
export const GET_FAMILY_MEMBER_VARISANGYA_ITEMS =
  "GET_FAMILY_MEMBER_VARISANGYA_ITEMS"
export const GET_FAMILY_MEMBER_VARISANGYA_ITEMS_SUCCESS =
  "GET_FAMILY_MEMBER_VARISANGYA_ITEMS_SUCCESS"
export const GET_FAMILY_MEMBER_VARISANGYA_ITEMS_FAIL =
  "GET_FAMILY_MEMBER_VARISANGYA_ITEMS_FAIL"

/**
 * add FAMILY_MEMBER_VARISANGYA_ITEM
 */
export const CREATE_FAMILY_MEMBER_VARISANGYA_ITEM =
  "CREATE_FAMILY_MEMBER_VARISANGYA_ITEM"
export const CREATE_FAMILY_MEMBER_VARISANGYA_ITEM_SUCCESS =
  "CREATE_FAMILY_MEMBER_VARISANGYA_ITEM_SUCCESS"
export const CREATE_FAMILY_MEMBER_VARISANGYA_ITEM_FAIL =
  "CREATE_FAMILY_MEMBER_VARISANGYA_ITEM_FAIL"

/**
 * Delete FAMILY_MEMBER_VARISANGYA_ITEM
 */
export const DELETE_FAMILY_MEMBER_VARISANGYA_ITEM =
  "DELETE_FAMILY_MEMBER_VARISANGYA_ITEM"
export const DELETE_FAMILY_MEMBER_VARISANGYA_ITEM_SUCCESS =
  "DELETE_FAMILY_MEMBER_VARISANGYA_ITEM_SUCCESS"
export const DELETE_FAMILY_MEMBER_VARISANGYA_ITEM_FAIL =
  "DELETE_FAMILY_MEMBER_VARISANGYA_ITEM_FAIL"
