/* FAMILY_VARISANGYA_WALLET */
export const GET_FAMILY_VARISANGYA_WALLETS = "GET_FAMILY_VARISANGYA_WALLETS"
export const GET_FAMILY_VARISANGYA_WALLETS_SUCCESS =
  "GET_FAMILY_VARISANGYA_WALLETS_SUCCESS"
export const GET_FAMILY_VARISANGYA_WALLETS_FAIL =
  "GET_FAMILY_VARISANGYA_WALLETS_FAIL"

/**
 * add FAMILY_VARISANGYA_WALLET
 */
export const CREATE_FAMILY_VARISANGYA_WALLET = "CREATE_FAMILY_VARISANGYA_WALLET"
export const CREATE_FAMILY_VARISANGYA_WALLET_SUCCESS =
  "CREATE_FAMILY_VARISANGYA_WALLET_SUCCESS"
export const CREATE_FAMILY_VARISANGYA_WALLET_FAIL =
  "CREATE_FAMILY_VARISANGYA_WALLET_FAIL"

/**
 * FAMILY_VARISANGYA_WALLET_DETAIL
 */
export const GET_FAMILY_VARISANGYA_WALLET_DETAIL =
  "GET_FAMILY_VARISANGYA_WALLET_DETAIL"
export const GET_FAMILY_VARISANGYA_WALLET_DETAIL_SUCCESS =
  "GET_FAMILY_VARISANGYA_WALLET_DETAIL_SUCCESS"
export const GET_FAMILY_VARISANGYA_WALLET_DETAIL_FAIL =
  "GET_FAMILY_VARISANGYA_WALLET_DETAIL_FAIL"

/**
 * FAMILY_VARISANGYA_WALLET_VIEW
 */
export const GET_FAMILY_VARISANGYA_WALLET_VIEW =
  "GET_FAMILY_VARISANGYA_WALLET_VIEW"
export const GET_FAMILY_VARISANGYA_WALLET_VIEW_SUCCESS =
  "GET_FAMILY_VARISANGYA_WALLET_VIEW_SUCCESS"
export const GET_FAMILY_VARISANGYA_WALLET_VIEW_FAIL =
  "GET_FAMILY_VARISANGYA_WALLET_VIEW_FAIL"

/**
 * add CLEAR_FAMILY_VARISANGYA_WALLET_DETAIL
 */
export const CLEAR_FAMILY_VARISANGYA_WALLET_DETAIL =
  "CLEAR_FAMILY_VARISANGYA_WALLET_DETAIL"

/* Delete FAMILY_VARISANGYA_WALLET */
export const DELETE_FAMILY_VARISANGYA_WALLET = "DELETE_FAMILY_VARISANGYA_WALLET"
export const DELETE_FAMILY_VARISANGYA_WALLET_SUCCESS =
  "DELETE_FAMILY_VARISANGYA_WALLET_SUCCESS"
export const DELETE_FAMILY_VARISANGYA_WALLET_FAIL =
  "DELETE_FAMILY_VARISANGYA_WALLET_FAIL"
