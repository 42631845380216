import {
  GET_COMMITTEE_MEETING_ATTENDANCES,
  GET_COMMITTEE_MEETING_ATTENDANCES_SUCCESS,
  GET_COMMITTEE_MEETING_ATTENDANCES_FAIL,
  GET_COMMITTEE_MEETING_ATTENDANCE_DETAIL,
  GET_COMMITTEE_MEETING_ATTENDANCE_DETAIL_SUCCESS,
  GET_COMMITTEE_MEETING_ATTENDANCE_DETAIL_FAIL,
  CREATE_COMMITTEE_MEETING_ATTENDANCE,
  CREATE_COMMITTEE_MEETING_ATTENDANCE_SUCCESS,
  CREATE_COMMITTEE_MEETING_ATTENDANCE_FAIL,
  UPDATE_COMMITTEE_MEETING_ATTENDANCE,
  UPDATE_COMMITTEE_MEETING_ATTENDANCE_SUCCESS,
  UPDATE_COMMITTEE_MEETING_ATTENDANCE_FAIL,
  DELETE_COMMITTEE_MEETING_ATTENDANCE,
  DELETE_COMMITTEE_MEETING_ATTENDANCE_SUCCESS,
  DELETE_COMMITTEE_MEETING_ATTENDANCE_FAIL,
} from "./actionTypes"

export const getCommitteeMeetingAttendances = (
  committeeMeetingId,
  searchText,
  page
) => ({
  type: GET_COMMITTEE_MEETING_ATTENDANCES,
  payload: { committeeMeetingId, searchText, page },
})

export const getCommitteeMeetingAttendancesSuccess =
  committeeMeetingAttendances => ({
    type: GET_COMMITTEE_MEETING_ATTENDANCES_SUCCESS,
    payload: committeeMeetingAttendances,
  })
export const getCommitteeMeetingAttendancesFail = error => ({
  type: GET_COMMITTEE_MEETING_ATTENDANCES_FAIL,
  payload: error,
})

export const getCommitteeMeetingAttendanceDetail =
  committeeMeetingAttendanceId => ({
    type: GET_COMMITTEE_MEETING_ATTENDANCE_DETAIL,
    committeeMeetingAttendanceId,
  })

export const getCommitteeMeetingAttendanceDetailSuccess =
  committeeMeetingAttendanceDetail => ({
    type: GET_COMMITTEE_MEETING_ATTENDANCE_DETAIL_SUCCESS,
    payload: committeeMeetingAttendanceDetail,
  })

export const getCommitteeMeetingAttendanceDetailFail = error => ({
  type: GET_COMMITTEE_MEETING_ATTENDANCE_DETAIL_FAIL,
  payload: error,
})

export const createCommitteeMeetingAttendance = (
  committeeMeetingAttendance,
  history
) => ({
  type: CREATE_COMMITTEE_MEETING_ATTENDANCE,
  payload: { committeeMeetingAttendance, history },
})

export const createCommitteeMeetingAttendanceSuccess =
  committeeMeetingAttendance => ({
    type: CREATE_COMMITTEE_MEETING_ATTENDANCE_SUCCESS,
    payload: committeeMeetingAttendance,
  })

export const createCommitteeMeetingAttendanceFail = error => ({
  type: CREATE_COMMITTEE_MEETING_ATTENDANCE_FAIL,
  payload: error,
})

export const updateCommitteeMeetingAttendance = (
  committeeMeetingAttendance,
  committeeMeetingAttendanceId
) => ({
  type: UPDATE_COMMITTEE_MEETING_ATTENDANCE,
  payload: { committeeMeetingAttendance, committeeMeetingAttendanceId },
})

export const updateCommitteeMeetingAttendanceSuccess =
  committeeMeetingAttendance => ({
    type: UPDATE_COMMITTEE_MEETING_ATTENDANCE_SUCCESS,
    payload: committeeMeetingAttendance,
  })

export const updateCommitteeMeetingAttendanceFail = error => ({
  type: UPDATE_COMMITTEE_MEETING_ATTENDANCE_FAIL,
  payload: error,
})

export const deleteCommitteeMeetingAttendance = (
  committeeMeetingAttendanceId,
  history,
  committeeMeetingId
) => ({
  type: DELETE_COMMITTEE_MEETING_ATTENDANCE,
  payload: { committeeMeetingAttendanceId, history, committeeMeetingId },
})

export const deleteCommitteeMeetingAttendanceSuccess =
  committeeMeetingAttendance => ({
    type: DELETE_COMMITTEE_MEETING_ATTENDANCE_SUCCESS,
    payload: committeeMeetingAttendance,
  })

export const deleteCommitteeMeetingAttendanceFail = error => ({
  type: DELETE_COMMITTEE_MEETING_ATTENDANCE_FAIL,
  payload: error,
})
