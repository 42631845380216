import {
  GET_STATES,
  GET_STATES_SUCCESS,
  GET_STATES_FAIL,
  GET_STATE_DETAIL,
  GET_STATE_DETAIL_SUCCESS,
  GET_STATE_DETAIL_FAIL,
  CREATE_STATE,
  CREATE_STATE_SUCCESS,
  CREATE_STATE_FAIL,
  UPDATE_STATE,
  UPDATE_STATE_SUCCESS,
  UPDATE_STATE_FAIL,
  DELETE_STATE,
  DELETE_STATE_SUCCESS,
  DELETE_STATE_FAIL,
} from "./actionTypes"

export const getStates = (searchText, page) => ({
  type: GET_STATES,
  payload: { searchText, page },
})

export const getStatesSuccess = states => ({
  type: GET_STATES_SUCCESS,
  payload: states,
})
export const getStatesFail = error => ({
  type: GET_STATES_FAIL,
  payload: error,
})

export const getStateDetail = stateId => ({
  type: GET_STATE_DETAIL,
  stateId,
})

export const getStateDetailSuccess = stateDetail => ({
  type: GET_STATE_DETAIL_SUCCESS,
  payload: stateDetail,
})

export const getStateDetailFail = error => ({
  type: GET_STATE_DETAIL_FAIL,
  payload: error,
})

export const createState = (state, history) => ({
  type: CREATE_STATE,
  payload: { state, history },
})

export const createStateSuccess = state => ({
  type: CREATE_STATE_SUCCESS,
  payload: state,
})

export const createStateFail = error => ({
  type: CREATE_STATE_FAIL,
  payload: error,
})

export const updateState = (state, stateId, history) => ({
  type: UPDATE_STATE,
  payload: { state, stateId, history },
})

export const updateStateSuccess = state => ({
  type: UPDATE_STATE_SUCCESS,
  payload: state,
})

export const updateStateFail = error => ({
  type: UPDATE_STATE_FAIL,
  payload: error,
})

export const deleteState = (stateId, history) => ({
  type: DELETE_STATE,
  payload: { stateId, history },
})

export const deleteStateSuccess = state => ({
  type: DELETE_STATE_SUCCESS,
  payload: state,
})

export const deleteStateFail = error => ({
  type: DELETE_STATE_FAIL,
  payload: error,
})
