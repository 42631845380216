import {
  GET_FAMILIES,
  GET_FAMILIES_SUCCESS,
  GET_FAMILIES_FAIL,
  GET_FAMILIES_FOR_DOWNLOAD,
  GET_FAMILIES_FOR_DOWNLOAD_SUCCESS,
  GET_FAMILIES_FOR_DOWNLOAD_FAIL,
  GET_FILTERED_FAMILIES,
  GET_FILTERED_FAMILIES_SUCCESS,
  GET_FILTERED_FAMILIES_FAIL,
  GET_FAMILIES_CARDS,
  GET_FAMILIES_CARDS_SUCCESS,
  GET_FAMILIES_CARDS_FAIL,
  GET_FAMILY_DROPDOWN,
  GET_FAMILY_DROPDOWN_SUCCESS,
  GET_FAMILY_DROPDOWN_FAIL,
  GET_ALL_FAMILY_DROPDOWN,
  GET_ALL_FAMILY_DROPDOWN_SUCCESS,
  GET_ALL_FAMILY_DROPDOWN_FAIL,
  GET_FAMILY_DETAIL,
  GET_FAMILY_DETAIL_SUCCESS,
  GET_FAMILY_DETAIL_FAIL,
  CREATE_FAMILY,
  CREATE_FAMILY_SUCCESS,
  CREATE_FAMILY_FAIL,
  UPDATE_FAMILY,
  UPDATE_FAMILY_SUCCESS,
  UPDATE_FAMILY_FAIL,
  DELETE_FAMILY,
  DELETE_FAMILY_SUCCESS,
  DELETE_FAMILY_FAIL,
} from "./actionTypes"

export const getFamilies = (searchText, page, mahallId, sort, pageSize) => ({
  type: GET_FAMILIES,
  payload: { searchText, page, mahallId, sort, pageSize },
})

export const getFamiliesSuccess = families => ({
  type: GET_FAMILIES_SUCCESS,
  payload: families,
})

export const getFamiliesFail = error => ({
  type: GET_FAMILIES_FAIL,
  payload: error,
})

export const getFamiliesForDownload = filterData => ({
  type: GET_FAMILIES_FOR_DOWNLOAD,
  payload: { filterData },
})

export const getFamiliesForDownloadSuccess = familiesForDownload => ({
  type: GET_FAMILIES_FOR_DOWNLOAD_SUCCESS,
  payload: familiesForDownload,
})

export const getFamiliesForDownloadFail = error => ({
  type: GET_FAMILIES_FOR_DOWNLOAD_FAIL,
  payload: error,
})

export const getFilteredFamilies = (page, pageSize, filterData) => ({
  type: GET_FILTERED_FAMILIES,
  payload: { page, pageSize, filterData },
})

export const getFilteredFamiliesSuccess = filteredFamilies => ({
  type: GET_FILTERED_FAMILIES_SUCCESS,
  payload: filteredFamilies,
})

export const getFilteredFamiliesFail = error => ({
  type: GET_FILTERED_FAMILIES_FAIL,
  payload: error,
})

export const getFamiliesCards = () => ({
  type: GET_FAMILIES_CARDS,
})

export const getFamiliesCardsSuccess = families => ({
  type: GET_FAMILIES_CARDS_SUCCESS,
  payload: families,
})

export const getFamiliesCardsFail = error => ({
  type: GET_FAMILIES_CARDS_FAIL,
  payload: error,
})

export const getFamilyDropdown = searchText => ({
  type: GET_FAMILY_DROPDOWN,
  payload: searchText,
})

export const getFamilyDropdownSuccess = families => ({
  type: GET_FAMILY_DROPDOWN_SUCCESS,
  payload: families,
})
export const getFamilyDropdownFail = error => ({
  type: GET_FAMILY_DROPDOWN_FAIL,
  payload: error,
})

export const getAllFamilyDropdown = (searchText, sort) => ({
  type: GET_ALL_FAMILY_DROPDOWN,
  payload: { searchText, sort },
})

export const getAllFamilyDropdownSuccess = allfamilies => ({
  type: GET_ALL_FAMILY_DROPDOWN_SUCCESS,
  payload: allfamilies,
})
export const getAllFamilyDropdownFail = error => ({
  type: GET_ALL_FAMILY_DROPDOWN_FAIL,
  payload: error,
})

export const getFamilyDetail = familyId => ({
  type: GET_FAMILY_DETAIL,
  familyId,
})

export const getFamilyDetailSuccess = familyDetail => ({
  type: GET_FAMILY_DETAIL_SUCCESS,
  payload: familyDetail,
})

export const getFamilyDetailFail = error => ({
  type: GET_FAMILY_DETAIL_FAIL,
  payload: error,
})

export const createFamily = family => ({
  type: CREATE_FAMILY,
  payload: { family },
})

export const createFamilySuccess = family => ({
  type: CREATE_FAMILY_SUCCESS,
  payload: family,
})

export const createFamilyFail = error => ({
  type: CREATE_FAMILY_FAIL,
  payload: error,
})

export const updateFamily = (family, familyId, history) => ({
  type: UPDATE_FAMILY,
  payload: { family, familyId, history },
})

export const updateFamilySuccess = family => ({
  type: UPDATE_FAMILY_SUCCESS,
  payload: family,
})

export const updateFamilyFail = error => ({
  type: UPDATE_FAMILY_FAIL,
  payload: error,
})

export const deleteFamily = (familyId, history) => ({
  type: DELETE_FAMILY,
  payload: { familyId, history },
})

export const deleteFamilySuccess = family => ({
  type: DELETE_FAMILY_SUCCESS,
  payload: family,
})

export const deleteFamilyFail = error => ({
  type: DELETE_FAMILY_FAIL,
  payload: error,
})
