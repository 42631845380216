import {
  GET_NOC_CERTIFICATES,
  GET_NOC_CERTIFICATES_SUCCESS,
  GET_NOC_CERTIFICATES_FAIL,
  GET_NOC_CERTIFICATE_DETAIL,
  GET_NOC_CERTIFICATE_DETAIL_SUCCESS,
  GET_NOC_CERTIFICATE_DETAIL_FAIL,
  CREATE_NOC_CERTIFICATE,
  CREATE_NOC_CERTIFICATE_SUCCESS,
  CREATE_NOC_CERTIFICATE_FAIL,
  UPDATE_NOC_CERTIFICATE,
  UPDATE_NOC_CERTIFICATE_SUCCESS,
  UPDATE_NOC_CERTIFICATE_FAIL,
  DELETE_NOC_CERTIFICATE,
  DELETE_NOC_CERTIFICATE_SUCCESS,
  DELETE_NOC_CERTIFICATE_FAIL,
  GET_NOC_CERTIFICATES_FOR_DOWNLOAD,
  GET_NOC_CERTIFICATES_FOR_DOWNLOAD_SUCCESS,
  GET_NOC_CERTIFICATES_FOR_DOWNLOAD_FAIL,
} from "./actionTypes"

export const getNocCertificates = (searchText, nocType, page) => ({
  type: GET_NOC_CERTIFICATES,
  payload: { searchText, nocType, page },
})
export const getNocCertificateSuccess = nocCertificate => ({
  type: GET_NOC_CERTIFICATES_SUCCESS,
  payload: nocCertificate,
})
export const getNocCertificateFail = error => ({
  type: GET_NOC_CERTIFICATES_FAIL,
  payload: error,
})

export const getNocCertificateForDownload = nocType => ({
  type: GET_NOC_CERTIFICATES_FOR_DOWNLOAD,
  payload: { nocType },
})
export const getNocCertificateForDownloadSuccess =
  nocCertificateForDownload => ({
    type: GET_NOC_CERTIFICATES_FOR_DOWNLOAD_SUCCESS,
    payload: nocCertificateForDownload,
  })
export const getNocCertificateForDownloadFail = error => ({
  type: GET_NOC_CERTIFICATES_FOR_DOWNLOAD_FAIL,
  payload: error,
})

export const getNocCertificateDetail = nocCertificateId => ({
  type: GET_NOC_CERTIFICATE_DETAIL,
  nocCertificateId,
})

export const getNocCertificateDetailSuccess = nocCertificateDetail => ({
  type: GET_NOC_CERTIFICATE_DETAIL_SUCCESS,
  payload: nocCertificateDetail,
})

export const getNocCertificateDetailFail = error => ({
  type: GET_NOC_CERTIFICATE_DETAIL_FAIL,
  payload: error,
})

export const createNocCertificate = (nocCertificate, history) => ({
  type: CREATE_NOC_CERTIFICATE,
  payload: { nocCertificate, history },
})

export const createNocCertificateSuccess = nocCertificate => ({
  type: CREATE_NOC_CERTIFICATE_SUCCESS,
  payload: nocCertificate,
})

export const createNocCertificateFail = error => ({
  type: CREATE_NOC_CERTIFICATE_FAIL,
  payload: error,
})

export const updateNocCertificate = (
  nocCertificate,
  nocCertificateId,
  history
) => ({
  type: UPDATE_NOC_CERTIFICATE,
  payload: { nocCertificate, nocCertificateId, history },
})

export const updateNocCertificateSuccess = nocCertificate => ({
  type: UPDATE_NOC_CERTIFICATE_SUCCESS,
  payload: nocCertificate,
})

export const updateNocCertificateFail = error => ({
  type: UPDATE_NOC_CERTIFICATE_FAIL,
  payload: error,
})

export const deleteNocCertificate = (nocCertificateId, history) => ({
  type: DELETE_NOC_CERTIFICATE,
  payload: { nocCertificateId, history },
})

export const deleteNocCertificateSuccess = nocCertificate => ({
  type: DELETE_NOC_CERTIFICATE_SUCCESS,
  payload: nocCertificate,
})

export const deleteNocCertificateFail = error => ({
  type: DELETE_NOC_CERTIFICATE_FAIL,
  payload: error,
})
