/* VILLAGES */
export const GET_VILLAGES = "GET_VILLAGES"
export const GET_VILLAGES_SUCCESS = "GET_VILLAGES_SUCCESS"
export const GET_VILLAGES_FAIL = "GET_VILLAGES_FAIL"

/* VILLAGE_DROPDOWN */
export const GET_VILLAGE_DROPDOWN = "GET_VILLAGE_DROPDOWN"
export const GET_VILLAGE_DROPDOWN_SUCCESS = "GET_VILLAGE_DROPDOWN_SUCCESS"
export const GET_VILLAGE_DROPDOWN_FAIL = "GET_VILLAGE_DROPDOWN_FAIL"

/* VILLAGES DETAIL*/
export const GET_VILLAGE_DETAIL = "GET_VILLAGE_DETAIL"
export const GET_VILLAGE_DETAIL_SUCCESS = "GET_VILLAGE_DETAIL_SUCCESS"
export const GET_VILLAGE_DETAIL_FAIL = "GET_VILLAGE_DETAIL_FAIL"

/**
 * add VILLAGE
 */
export const CREATE_VILLAGE = "CREATE_VILLAGE"
export const CREATE_VILLAGE_SUCCESS = "CREATE_VILLAGE_SUCCESS"
export const CREATE_VILLAGE_FAIL = "CREATE_VILLAGE_FAIL"

/**
 * Edit VILLAGE
 */
export const UPDATE_VILLAGE = "UPDATE_VILLAGE"
export const UPDATE_VILLAGE_SUCCESS = "UPDATE_VILLAGE_SUCCESS"
export const UPDATE_VILLAGE_FAIL = "UPDATE_VILLAGE_FAIL"

/**
 * Delete VILLAGE
 */
export const DELETE_VILLAGE = "DELETE_VILLAGE"
export const DELETE_VILLAGE_SUCCESS = "DELETE_VILLAGE_SUCCESS"
export const DELETE_VILLAGE_FAIL = "DELETE_VILLAGE_FAIL"
