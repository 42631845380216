import {
  GET_UNAPPROVED_FAMILY_MEMBERS,
  GET_UNAPPROVED_FAMILY_MEMBERS_SUCCESS,
  GET_UNAPPROVED_FAMILY_MEMBERS_FAIL,
  GET_UNAPPROVED_FAMILY_MEMBER_DETAIL,
  GET_UNAPPROVED_FAMILY_MEMBER_DETAIL_SUCCESS,
  GET_UNAPPROVED_FAMILY_MEMBER_DETAIL_FAIL,
  UPDATE_UNAPPROVED_FAMILY_MEMBER,
  UPDATE_UNAPPROVED_FAMILY_MEMBER_SUCCESS,
  UPDATE_UNAPPROVED_FAMILY_MEMBER_FAIL,
  DELETE_UNAPPROVED_FAMILY_MEMBER,
  DELETE_UNAPPROVED_FAMILY_MEMBER_SUCCESS,
  DELETE_UNAPPROVED_FAMILY_MEMBER_FAIL,
  GET_UNAPPROVED_FAMILY_MEMBER_DROPDOWN,
  GET_UNAPPROVED_FAMILY_MEMBER_DROPDOWN_SUCCESS,
  GET_UNAPPROVED_FAMILY_MEMBER_DROPDOWN_FAIL,
} from "./actionTypes"

export const getUnapprovedFamilyMembers = (searchText, page, familyId) => ({
  type: GET_UNAPPROVED_FAMILY_MEMBERS,
  payload: { searchText, page, familyId },
})

export const getUnapprovedFamilyMembersSuccess = unapprovedFamilyMembers => ({
  type: GET_UNAPPROVED_FAMILY_MEMBERS_SUCCESS,
  payload: unapprovedFamilyMembers,
})
export const getUnapprovedFamilyMembersFail = error => ({
  type: GET_UNAPPROVED_FAMILY_MEMBERS_FAIL,
  payload: error,
})

export const getUnapprovedFamilyMemberDetail = unapprovedFamilyMemberId => ({
  type: GET_UNAPPROVED_FAMILY_MEMBER_DETAIL,
  unapprovedFamilyMemberId,
})

export const getUnapprovedFamilyMemberDetailSuccess =
  unapprovedFamilyMemberDetail => ({
    type: GET_UNAPPROVED_FAMILY_MEMBER_DETAIL_SUCCESS,
    payload: unapprovedFamilyMemberDetail,
  })

export const getUnapprovedFamilyMemberDetailFail = error => ({
  type: GET_UNAPPROVED_FAMILY_MEMBER_DETAIL_FAIL,
  payload: error,
})

export const getUnapprovedFamilyMemberDropdown = (
  searchText,
  unapprovedFamilyId
) => ({
  type: GET_UNAPPROVED_FAMILY_MEMBER_DROPDOWN,
  payload: { searchText, unapprovedFamilyId },
})
export const getUnapprovedFamilyMemberDropdownSuccess =
  unapprovedFamilyMembers => ({
    type: GET_UNAPPROVED_FAMILY_MEMBER_DROPDOWN_SUCCESS,
    payload: unapprovedFamilyMembers,
  })
export const getUnapprovedFamilyMemberDropdownFail = error => ({
  type: GET_UNAPPROVED_FAMILY_MEMBER_DROPDOWN_FAIL,
  payload: error,
})

export const updateUnapprovedFamilyMember = (
  unapprovedFamilyMember,
  unapprovedFamilyMemberId,
  history
) => ({
  type: UPDATE_UNAPPROVED_FAMILY_MEMBER,
  payload: { unapprovedFamilyMember, unapprovedFamilyMemberId, history },
})

export const updateUnapprovedFamilyMemberSuccess = unapprovedFamilyMember => ({
  type: UPDATE_UNAPPROVED_FAMILY_MEMBER_SUCCESS,
  payload: unapprovedFamilyMember,
})

export const updateUnapprovedFamilyMemberFail = error => ({
  type: UPDATE_UNAPPROVED_FAMILY_MEMBER_FAIL,
  payload: error,
})

export const deleteUnapprovedFamilyMember = (
  unapprovedFamilyMemberId,
  history
) => ({
  type: DELETE_UNAPPROVED_FAMILY_MEMBER,
  payload: { unapprovedFamilyMemberId, history },
})

export const deleteUnapprovedFamilyMemberSuccess = unapprovedFamilyMember => ({
  type: DELETE_UNAPPROVED_FAMILY_MEMBER_SUCCESS,
  payload: unapprovedFamilyMember,
})

export const deleteUnapprovedFamilyMemberFail = error => ({
  type: DELETE_UNAPPROVED_FAMILY_MEMBER_FAIL,
  payload: error,
})
