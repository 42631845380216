import {
  CREATE_GALLERY,
  CREATE_GALLERY_FAIL,
  CREATE_GALLERY_SUCCESS,
  DELETE_GALLERY,
  DELETE_GALLERY_FAIL,
  DELETE_GALLERY_SUCCESS,
  GET_GALLERIES,
  GET_GALLERIES_FAIL,
  GET_GALLERIES_SUCCESS,
  GET_GALLERY_DETAIL,
  GET_GALLERY_DETAIL_FAIL,
  GET_GALLERY_DETAIL_SUCCESS,
  UPDATE_GALLERY,
  UPDATE_GALLERY_FAIL,
  UPDATE_GALLERY_SUCCESS,
} from "./actionTypes"

export const getGalleries = instituteId => ({
  type: GET_GALLERIES,
  payload: { instituteId },
})

export const getGalleriesSuccess = galleries => ({
  type: GET_GALLERIES_SUCCESS,
  payload: galleries,
})
export const getGalleriesFail = error => ({
  type: GET_GALLERIES_FAIL,
  payload: error,
})

export const getGalleryDetail = (galleryId, instituteId) => ({
  type: GET_GALLERY_DETAIL,
  payload: { galleryId, instituteId },
})

export const getGalleryDetailSuccess = galleryDetail => ({
  type: GET_GALLERY_DETAIL_SUCCESS,
  payload: galleryDetail,
})

export const getGalleryDetailFail = error => ({
  type: GET_GALLERY_DETAIL_FAIL,
  payload: error,
})

export const createGallery = (gallery, history) => ({
  type: CREATE_GALLERY,
  payload: { gallery, history },
})

export const createGallerySuccess = gallery => ({
  type: CREATE_GALLERY_SUCCESS,
  payload: gallery,
})

export const createGalleryFail = error => ({
  type: CREATE_GALLERY_FAIL,
  payload: error,
})

export const updateGallery = (gallery, galleryId, history) => ({
  type: UPDATE_GALLERY,
  payload: { gallery, galleryId, history },
})

export const updateGallerySuccess = gallery => ({
  type: UPDATE_GALLERY_SUCCESS,
  payload: gallery,
})

export const updateGalleryFail = error => ({
  type: UPDATE_GALLERY_FAIL,
  payload: error,
})

export const deleteGallery = (galleryId, history, instituteId) => ({
  type: DELETE_GALLERY,
  payload: { galleryId, history, instituteId },
})

export const deleteGallerySuccess = gallery => ({
  type: DELETE_GALLERY_SUCCESS,
  payload: gallery,
})

export const deleteGalleryFail = error => ({
  type: DELETE_GALLERY_FAIL,
  payload: error,
})
