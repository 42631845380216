/* LEDGER_ITEM_CATEGORIES */
export const GET_LEDGER_ITEM_CATEGORIES = "GET_LEDGER_ITEM_CATEGORIES"
export const GET_LEDGER_ITEM_CATEGORIES_SUCCESS =
  "GET_LEDGER_ITEM_CATEGORIES_SUCCESS"
export const GET_LEDGER_ITEM_CATEGORIES_FAIL = "GET_LEDGER_ITEM_CATEGORIES_FAIL"

/* LEDGER_ITEM_CATEGORIES DETAIL*/
export const GET_LEDGER_ITEM_CATEGORY_DETAIL = "GET_LEDGER_ITEM_CATEGORY_DETAIL"
export const GET_LEDGER_ITEM_CATEGORY_DETAIL_SUCCESS =
  "GET_LEDGER_ITEM_CATEGORY_DETAIL_SUCCESS"
export const GET_LEDGER_ITEM_CATEGORY_DETAIL_FAIL =
  "GET_LEDGER_ITEM_CATEGORY_DETAIL_FAIL"

/* LEDGER_ITEM_CATEGORIES_FOR_DOWNLOAD */
export const GET_LEDGER_ITEM_CATEGORIES_FOR_DOWNLOAD =
  "GET_LEDGER_ITEM_CATEGORIES_FOR_DOWNLOAD"
export const GET_LEDGER_ITEM_CATEGORIES_FOR_DOWNLOAD_SUCCESS =
  "GET_LEDGER_ITEM_CATEGORIES_FOR_DOWNLOAD_SUCCESS"
export const GET_LEDGER_ITEM_CATEGORIES_FOR_DOWNLOAD_FAIL =
  "GET_LEDGER_ITEM_CATEGORIES_FOR_DOWNLOAD_FAIL"

/* LEDGER_ITEM_CATEGORY_DROPDOWN*/
export const GET_LEDGER_ITEM_CATEGORY_DROPDOWN =
  "GET_LEDGER_ITEM_CATEGORY_DROPDOWN"
export const GET_LEDGER_ITEM_CATEGORY_DROPDOWN_SUCCESS =
  "GET_LEDGER_ITEM_CATEGORY_DROPDOWN_SUCCESS"
export const GET_LEDGER_ITEM_CATEGORY_DROPDOWN_FAIL =
  "GET_LEDGER_ITEM_CATEGORY_DROPDOWN_FAIL"

/**
 * add LEDGER_ITEM_CATEGORY
 */
export const CREATE_LEDGER_ITEM_CATEGORY = "CREATE_LEDGER_ITEM_CATEGORY"
export const CREATE_LEDGER_ITEM_CATEGORY_SUCCESS =
  "CREATE_LEDGER_ITEM_CATEGORY_SUCCESS"
export const CREATE_LEDGER_ITEM_CATEGORY_FAIL =
  "CREATE_LEDGER_ITEM_CATEGORY_FAIL"

/**
 * Edit LEDGER_ITEM_CATEGORY
 */
export const UPDATE_LEDGER_ITEM_CATEGORY = "UPDATE_LEDGER_ITEM_CATEGORY"
export const UPDATE_LEDGER_ITEM_CATEGORY_SUCCESS =
  "UPDATE_LEDGER_ITEM_CATEGORY_SUCCESS"
export const UPDATE_LEDGER_ITEM_CATEGORY_FAIL =
  "UPDATE_LEDGER_ITEM_CATEGORY_FAIL"

/**
 * Delete LEDGER_ITEM_CATEGORY
 */
export const DELETE_LEDGER_ITEM_CATEGORY = "DELETE_LEDGER_ITEM_CATEGORY"
export const DELETE_LEDGER_ITEM_CATEGORY_SUCCESS =
  "DELETE_LEDGER_ITEM_CATEGORY_SUCCESS"
export const DELETE_LEDGER_ITEM_CATEGORY_FAIL =
  "DELETE_LEDGER_ITEM_CATEGORY_FAIL"
