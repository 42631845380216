import React, { Fragment, useState, useEffect } from "react"
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Spinner,
  Badge,
  Collapse,
  Button,
  UncontrolledTooltip,
} from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { debounce, map, range } from "lodash"
import { Link, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import moment from "moment"

//Import Images
import men from "assets/images/icons/men.png"
import women from "assets/images/icons/woman.png"
import family from "assets/images/icons/family.png"
import facility from "assets/images/icons/facility.png"
import land from "assets/images/icons/land.png"
import car from "assets/images/icons/car.png"
import other from "assets/images/icons/other.png"
import seniorCitizen from "assets/images/icons/seniorcitizen.jpeg"
import patient from "assets/images/icons/patient.jpeg"
//componetns
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import MyPagination from "components/Common/MyPagination"
import { deleteFamily, getFamilyDetail, getFamilyMembers } from "store/actions"
import UiModalQrcode from "pages/common/UiModalQrcode"
import logo_mahallu_small from "../../../../assets/images/logo/logo-small.png"

const PrintFamilyDetails = () => {
  const dispatch = useDispatch()
  const params = useParams()

  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const [limit] = useState(10)

  const [minimusItems, setMinimusItems] = useState(false)

  const [modalQrcode, setModalQrcode] = useState(false)
  const [familyIdModal, setFamilyIdModal] = useState({})

  const {
    userDetail,
    familyDetail,
    loading,
    detailLoading,
    familyMembers,
    language,
  } = useSelector(state => ({
    loading: state.Families.loading,
    detailLoading: state.Families.detailLoading,
    language: state.Layout.language,
    familyDetail: state.Families.familyDetail,
    mahallDetail: state.Mahalls.mahallDetail,
    familyMembers: state.FamilyMembers.familyMembers,
    userDetail: state.Users.userDetail,
  }))

  const totalPages = Math.ceil(familyMembers?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  const [isOpen, setIsOpen] = useState(false)

  const handleDelete = () => {
    setIsOpen(true)
  }
  const handleDeleteEvent = () => {
    dispatch(deleteFamily(familyDetail.id, history))
    setIsOpen(false)
  }

  useEffect(() => {
    dispatch(getFamilyDetail(params.id))
  }, [dispatch, params.id])

  useEffect(() => {
    if (familyDetail?.id) {
      dispatch(getFamilyMembers(searchText, page, familyDetail?.id))
    }
  }, [searchText, page, familyDetail])

  const debounceMahallFamilyFamilyMemberSearch = debounce(
    value => setSearchText(value),
    600
  )

  const columns = [
    {
      dataField: "no",
      text: `${language === "mal" ? "നം." : "No."}`,
    },
    {
      dataField: "full_name",
      text: `${language === "mal" ? "പേര്" : "Name"}`,
    },
    {
      dataField: "mahall_custom_id",
      text: `${language === "mal" ? "ഐഡി" : "Mahall ID"}`,
    },
    {
      dataField: "ageGender",
      text: `${language === "mal" ? "പ്രായം / ലിംഗം" : "Age / Gender"}`,
      sort: true,
    },
    {
      dataField: "blood_group.english",
      text: `${language === "mal" ? "രക്ത ഗ്രൂപ്പ്" : "Blood Group"}`,
      sort: true,
    },
    {
      dataField: "father_name",
      text: `${language === "mal" ? "പിതാവിന്റെ പേര്" : "Father Name"}`,
      sort: true,
    },
    {
      dataField: "mother_name",
      text: `${language === "mal" ? "മാതാവിന്റെ പേര്" : "Mother Name"}`,
      sort: true,
    },
    // {
    //   dataField: "relation",
    //   text: `${language === "mal" ? "ബന്ധം" : "Relation"}`,
    //   //   text: `${
    //   //     language === "mal"
    //   //       ? "കുടുംബ  നാഥനുമായുള്ള  ബന്ധം"
    //   //       : "Relation to family head"
    //   //   }`,
    //   sort: true,
    // },
    {
      dataField: "healthStatus",
      text: `${language === "mal" ? "ആരോഗ്യ സ്ഥിതി" : "Health Status"}`,
      sort: true,
    },
    {
      dataField: "phone",
      text: `${language === "mal" ? "ഫോൺ" : "Phone"}`,
    },
    {
      dataField: "marital_status",
      text: `${language === "mal" ? "വൈവാഹിക നില" : "Marital Status"}`,
    },
    {
      dataField: "educational_qualification_islamic",
      text: `${
        language === "mal" ? "ഇസ്ലാമിക വിദ്യാഭ്യാസം" : "Islamic Education"
      }`,
    },
    {
      dataField: "educational_qualification_formal",
      text: `${language === "mal" ? "ഭൗധിക വിദ്യാഭ്യാസം" : "Formal Education"}`,
    },
    {
      dataField: "is_abroad",
      text: `${language === "mal" ? "വിദേശത്താണോ?" : "Is Abroad?"}`,
    },
    {
      dataField: "present_country",
      text: `${language === "mal" ? "രാജ്യം" : "Country"}`,
    },
    {
      dataField: "current_job",
      text: `${language === "mal" ? "ജോലി" : "Job"}`,
    },
  ]

  const familyMembersData = map(familyMembers?.results, (item, index) => ({
    ...item,
    key: index,
    no: (pageSend() - 1) * limit + index + 1,
    full_name: (
      <p>
        {item?.full_name}
        <span className="text-primary px-1">
          {item?.is_family_head && "(H)"}
        </span>
        <span className="text-danger">{` ${
          item?.is_alive && !item?.is_alive
            ? language === "mal"
              ? "(നിര്യാതനായി)"
              : "(Late)"
            : ""
        }`}</span>
      </p>
    ),
    ageGender: `${getAge(item?.dob && item?.dob != null ? item?.dob : 0)} / ${
      item?.gender && item?.gender != null ? item?.gender : ""
    }`,
    is_abroad: item?.is_abroad ? "Yes" : "No",
    relation:
      language === "mal"
        ? item?.relation?.malayalam
          ? item?.relation?.malayalam
          : item?.relation?.english
        : item?.relation?.english,
    educational_qualification_islamic:
      language === "mal"
        ? item?.educational_qualification_islamic?.malayalam
          ? item?.educational_qualification_islamic?.malayalam
          : item?.educational_qualification_islamic?.english
        : item?.educational_qualification_islamic?.english,
    educational_qualification_formal:
      language === "mal"
        ? item?.educational_qualification_formal?.malayalam
          ? item?.educational_qualification_formal?.malayalam
          : item?.educational_qualification_formal?.english
        : item?.educational_qualification_formal?.english,
    marital_status:
      language === "mal"
        ? item?.marital_status?.malayalam
          ? item?.marital_status?.malayalam
          : item?.marital_status?.english
        : item?.marital_status?.english,
    present_country:
      language === "mal"
        ? item?.present_country_malayalam
          ? item?.present_country_malayalam
          : item?.present_country_english
        : item?.present_country_english,
    healthStatus: (
      <>
        {item?.health_condition?.map((status, key) => {
          return (
            <Fragment key={key}>
              <Badge
                className={
                  "mx-1 font-size-12 badge-soft-" +
                  `${
                    status.english == "Patient"
                      ? "danger"
                      : status.english == "Satisfied"
                      ? "success"
                      : status.english == "Disabled"
                      ? "warning"
                      : ""
                  }`
                }
                color="black"
                pill
              >
                {status.english}
              </Badge>
            </Fragment>
          )
        })}
      </>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const selectRow = {
    mode: "checkbox",
  }

  function getAge(dateString) {
    var today = new Date()
    var birthDate = new Date(dateString)
    if (dateString) {
      const startDate = moment(today)
      const timeEnd = moment(birthDate)
      const diff = startDate.diff(timeEnd)
      var age = Math.floor(diff / (365 * 24 * 60 * 60 * 1000))
      if (age < 0) age = 0
      return age
    } else {
      return 0
    }
  }

  const changeYearsTillDate = year => {
    const thisYear = new Date().getFullYear()
    const difference = thisYear - year
    return difference
  }

  const [col1, setCol1] = useState(true)
  const [col2, setCol2] = useState(true)
  const [col3, setCol3] = useState(true)
  const [col4, setCol4] = useState(true)

  const familyIdQr = id => {
    setFamilyIdModal(id)
    setModalQrcode(true)
  }

  const basicFamilyData = [
    {
      englishTitle: "House Name",
      malayalamTitle: "വീടിൻ്റെ പേര്",
      value: familyDetail?.house_name || "",
    },
    {
      englishTitle: "House Number",
      malayalamTitle: "വീട്ട് നമ്പർ",
      value: familyDetail?.house_number || 0,
    },
    {
      englishTitle: "Ward Number",
      malayalamTitle: "വാർഡ് നമ്പർ",
      value: familyDetail?.ward_number || 0,
    },
    {
      englishTitle: "Year",
      malayalamTitle: "വർഷം",
      value: `${changeYearsTillDate(familyDetail?.year_of_residence)} Years`,
    },
    {
      englishTitle: "Area",
      malayalamTitle: "ഏരിയ",
      value: `${
        language === "mal"
          ? familyDetail?.area?.malayalam
            ? familyDetail?.area?.malayalam
            : familyDetail?.area?.english
          : familyDetail?.area?.english
      } (${
        language === "mal"
          ? familyDetail?.lsg_malayalam_name
          : familyDetail?.lsg_english_name
      })`,
    },
    {
      englishTitle: "Family Head",
      malayalamTitle: "കുടുംബനാഥൻ",
      value: familyDetail?.family_head_name,
    },
    {
      englishTitle: "Address",
      malayalamTitle: "വിലാസം",
      value: `${familyDetail?.place},${familyDetail?.post_office} Post - ${
        familyDetail?.pin_code
      } ,${
        language === "mal"
          ? familyDetail?.district_malayalam_name
          : familyDetail?.district_english_name
      }, ${
        language === "mal"
          ? familyDetail?.state_malayalam_name
          : familyDetail?.state_english_name
      }`,
    },
    {
      englishTitle: "Mobile Number ",
      malayalamTitle: "മൊബൈൽ നമ്പർ ",
      value: familyDetail?.contact_number || "",
    },
  ]

  const familyCounts = [
    {
      englishTitle: "Members",
      malayalamTitle: "അംഗങ്ങൾ",
      value: familyDetail?.no_of_members || 0,
    },
    {
      englishTitle: "Male",
      malayalamTitle: "ആൺ",
      value: familyDetail?.no_of_males || 0,
    },
    {
      englishTitle: "Female",
      malayalamTitle: "സ്ത്രീ",
      value: familyDetail?.no_of_females || 0,
    },
    {
      englishTitle: "Senior Citizen",
      malayalamTitle: "മുതിർന്ന പൗരൻ",
      value: familyDetail?.no_of_senior_citizens || 0,
    },
    {
      englishTitle: "Patients",
      malayalamTitle: "രോഗികൾ",
      value: familyDetail?.no_of_patients || 0,
    },
    {
      englishTitle: "Varisangya Amount ",
      malayalamTitle: "വരിസംഖ്യ തുക  ",
      value: familyDetail?.varisankya_amount || 0,
    },
    {
      englishTitle: "Wallet Balance ",
      malayalamTitle: "വാലറ്റ് ബാലൻസ്  ",
      value: familyDetail?.varisankya_wallet || 0,
    },
  ]

  const landArea = [
    {
      englishTitle: "Home",
      malayalamTitle: "വീട്",
      value: familyDetail?.land_area_home || 0,
    },
    {
      englishTitle: "Farm",
      malayalamTitle: "കൃഷി",
      value: familyDetail?.land_area_farm || 0,
    },
    {
      englishTitle: "Rent",
      malayalamTitle: "വാടക",
      value: familyDetail?.land_area_rent || 0,
    },
    {
      englishTitle: "Others",
      malayalamTitle: "മറ്റുള്ളവ",
      value: familyDetail?.land_area_others || 0,
    },
    {
      englishTitle: "Total Area",
      malayalamTitle: "ആകെ ആസ്തി",
      value: familyDetail?.total_land_area || 0,
    },
  ]

  const vehicles = [
    {
      englishTitle: "2 Wheelers",
      malayalamTitle: "2 വീലറുകൾ",
      value: familyDetail?.two_wheeler_vehicles || 0,
    },
    {
      englishTitle: "3 Wheelers",
      malayalamTitle: "3 വീലറുകൾ",
      value: familyDetail?.three_wheeler_vehicles || 0,
    },
    {
      englishTitle: "4 Wheelers",
      malayalamTitle: "4 വീലറുകൾ",
      value: familyDetail?.four_wheeler_vehicles || 0,
    },
    {
      englishTitle: "Taxi Vehicles",
      malayalamTitle: "ടാക്സികൾ",
      value: familyDetail?.taxi_vehicles || 0,
    },
    {
      englishTitle: "Total Vehicles",
      malayalamTitle: "ആകെ വാഹനങ്ങൾ",
      value: familyDetail?.total_vehicles || 0,
    },
  ]

  const facilities = [
    {
      englishTitle: "Water",
      malayalamTitle: "വെള്ളം",
      value:
        language === "mal"
          ? familyDetail?.water_facility?.malayalam
          : familyDetail?.water_facility?.english || "",
    },
    {
      englishTitle: "Electricity",
      malayalamTitle: "വൈദ്യുതി",
      value:
        language === "mal"
          ? familyDetail?.electric_facility?.malayalam
          : familyDetail?.electric_facility?.english || "",
    },
  ]

  const others = [
    {
      englishTitle: "House Type",
      malayalamTitle: "വീട്",
      value: `${
        language === "mal"
          ? familyDetail?.house_type?.malayalam
          : familyDetail?.house_type?.english
      } / ${
        language === "mal"
          ? familyDetail?.house_build_type?.malayalam
          : familyDetail?.house_build_type?.english
      }`,
    },
    {
      englishTitle: "Ration Card",
      malayalamTitle: "റേഷൻ കാർഡ്",
      value: `${
        language === "mal"
          ? familyDetail?.ration_card?.malayalam
          : familyDetail?.ration_card?.english
      } / ${familyDetail?.ration_card_number}`,
    },
    {
      englishTitle: "Financial Status",
      malayalamTitle: "സാമ്പത്തിക സ്ഥിതി",
      value: `${
        language === "mal"
          ? familyDetail?.financial_status?.malayalam
          : familyDetail?.financial_status?.english
      }`,
    },
    {
      englishTitle: "Annual Income",
      malayalamTitle: "വാർഷിക വരുമാനം",
      value: `${familyDetail?.annual_income?.english}`,
    },
    {
      englishTitle: "Bank Loan",
      malayalamTitle: "ബാങ്ക് വായ്പ",
      value: `${
        familyDetail?.bank_loan
          ?.map((item, key) => {
            return `${key !== 0 ? "," : ""} ${
              language === "mal" ? item.malayalam : item.english
            } `
          })
          .join("") // Join the array into a single string
      } ${
        familyDetail?.bank_loan_details
          ? `(${familyDetail?.bank_loan_details})`
          : ""
      }`,
    },
    {
      englishTitle: "Home Business",
      malayalamTitle: "ഹോം ബിസിനസ്സ്",
      value: `${
        familyDetail?.home_business
          ?.map((item, key) => {
            return `${key !== 0 ? "," : ""} ${
              language === "mal" ? item.malayalam : item.english
            } `
          })
          .join("") // Join the array into a single string
      } ${
        familyDetail?.home_business_details
          ? `(${familyDetail?.home_business_details})`
          : ""
      }`,
    },
  ]
  return (
    <>
      <UiModalQrcode
        show={modalQrcode}
        onCloseclick={() => setModalQrcode(false)}
        data={familyIdModal}
      />
      <DeleteModal
        show={isOpen}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteEvent}
      />

      <MetaTags>
        <title>Family | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "ഖാഫ്" : "Khaf"}
          breadcrumbItem={
            language === "mal" ? "കുടുംബ വിശദാംശങ്ങൾ" : "Family Details"
          }
        />
        <Container fluid>
          <div className="container-fluid ">
            <Row>
              <Col md={12}>
                {detailLoading ? (
                  <Spinner />
                ) : (
                  <Row>
                    <div className="d-flex align-items-center justify-content-end w-100 mb-3">
                      {/* <Button
                        id="printedId"
                        color=""
                        onClick={() => setMinimusItems(!minimusItems)}
                        style={{ width: "fit-content" }}
                        className="mr-auto me-3 btn-outline-success hide-print-item"
                      >
                        {language === "mal" ? "ചെറുതാകുക" : "Minimus"}
                      </Button>
                      <UncontrolledTooltip target={"printedId"}>
                        Remove unnecessary data from the printed document
                      </UncontrolledTooltip> */}
                      <Button
                        color="success"
                        style={{ width: "fit-content" }}
                        className="mr-auto hide-print-item"
                        onClick={() => window.print()}
                      >
                        {language === "mal" ? "പ്രിന്റ്" : "Print"}
                      </Button>
                    </div>
                    <Card className="sm:p-0 px-0 ">
                      {/* <div
                        className="card-header bg-khaf"
                        style={{
                          borderRadius: "10px 10px 0 0",
                        }}
                      >
                        <div className="d-flex justify-content-between mb-2">
                          {familyDetail?.mahall_custom_id && (
                            <Badge
                              className="rounded-pill bg-white"
                              style={{ fontSize: "12px", color: "black" }}
                            >
                              {familyDetail?.mahall_custom_id}
                            </Badge>
                          )}
                          <Badge
                            className="rounded-pill bg-dark"
                            style={{ fontSize: "12px" }}
                          >
                            {familyDetail?.custom_id}
                          </Badge>
                        </div>

                        <div className=" d-flex align-items-center justify-content-between align-items-center familycard">
                          <h5 className="align-self-start text-white mb-0  pt-1 housename">
                            {familyDetail?.house_name}{" "}
                            {familyDetail &&
                              familyDetail?.area &&
                              familyDetail?.area?.english && (
                                <span className="me-2">
                                  ({" "}
                                  {language === "mal"
                                    ? familyDetail?.area?.malayalam
                                    : familyDetail?.area?.english}
                                  )
                                </span>
                              )}
                            <span className="font-size-12 text-white fw-normal">
                              {familyDetail?.house_number
                                ? familyDetail?.house_number
                                : 0}
                              /
                              {familyDetail?.ward_number
                                ? familyDetail?.ward_number
                                : 0}
                            </span>
                            <span className="font-size-12 text-white fw-normal">
                              -{" "}
                              {language === "mal"
                                ? familyDetail?.lsg_malayalam_name
                                : familyDetail?.lsg_english_name}
                            </span>
                          </h5>
                          {familyDetail?.year_of_residence && (
                            <h5 className="text-white m-0 housename">
                              {changeYearsTillDate(
                                familyDetail?.year_of_residence
                              )}{" "}
                              yrs
                            </h5>
                          )}
                        </div>
                      </div> */}
                      <CardBody
                        style={{
                          backgroundColor: "#f4fff4",
                          borderRadius: "0 0 10px 10px",
                        }}
                      >
                        <div
                          className="py-2 mb-3 d-flex gap-2 justify-content-center align-items-center"
                          style={{ borderBottom: "1px solid black" }}
                        >
                          <img
                            src={logo_mahallu_small}
                            alt="logo"
                            height="35"
                          />
                          <h3 className="m-0">
                            {" "}
                            {language === "mal"
                              ? userDetail?.mahall?.mahall_malayalam_name
                                ? userDetail?.mahall?.mahall_malayalam_name
                                : userDetail?.mahall?.mahall_english_name
                              : userDetail?.mahall?.mahall_english_name}
                          </h3>
                        </div>
                        <Row>
                          <Col md={6}>
                            {basicFamilyData?.map((item, key) => (
                              <div key={key} className="d-flex mb-2">
                                <h5
                                  className="fs-5 text-black"
                                  style={{ width: 150 }}
                                >
                                  {" "}
                                  {language === "mal"
                                    ? item?.malayalamTitle
                                    : item?.englishTitle}
                                </h5>
                                <h5 className="text-muted fs-5">
                                  {" "}
                                  : {item?.value}
                                </h5>
                              </div>
                            ))}

                            {/* <div
                              className={`minimus-item ${
                                minimusItems && "active"
                              }`}
                            >
                              <div
                                className={`d-flex minimus-item  ${
                                  familyDetail?.family_head_name ? "mt-4" : ""
                                }`}
                                style={{ paddingRight: "8rem" }}
                              >
                                <div
                                  className="d-flex"
                                  style={{ width: "160px" }}
                                >
                                  <img
                                    src={men}
                                    alt={men}
                                    className="me-2"
                                    style={{ height: "17px", width: "17px" }}
                                  />
                                  <p className="fs-5 text-black">
                                    {language === "mal" ? "ആൺ" : "Male"}
                                  </p>
                                </div>

                                <div>
                                  <span className="fs-5 text-black">
                                    : {familyDetail?.no_of_males || 0}
                                  </span>
                                </div>
                              </div>

                              <div
                                className="d-flex mt-1"
                                style={{ paddingRight: "8rem" }}
                              >
                                <div
                                  className="d-flex"
                                  style={{ width: "160px" }}
                                >
                                  <img
                                    src={women}
                                    alt={women}
                                    className="me-2"
                                    style={{ height: "17px", width: "17px" }}
                                  />
                                  <p className="fs-5 text-black">
                                    {" "}
                                    {language === "mal" ? "സ്ത്രീ" : "Female"}
                                  </p>
                                </div>

                                <div>
                                  <span className="fs-5 text-black">
                                    : {familyDetail?.no_of_females || 0}
                                  </span>
                                </div>
                              </div>

                              <div
                                className="d-flex mt-1"
                                style={{ paddingRight: "8rem" }}
                              >
                                <div
                                  className="d-flex"
                                  style={{ width: "160px" }}
                                >
                                  <img
                                    src={family}
                                    alt={family}
                                    className="me-2"
                                    style={{ height: "17px", width: "17px" }}
                                  />
                                  <p className="fs-5 text-black">
                                    {" "}
                                    {language === "mal" ? "അംഗങ്ങൾ" : "Members"}
                                  </p>
                                </div>

                                <div>
                                  <span className="fs-5 text-black">
                                    : {familyDetail?.no_of_members || 0}
                                  </span>
                                </div>
                              </div>

                              <div
                                className="d-flex mt-1"
                                style={{ paddingRight: "8rem" }}
                              >
                                <div
                                  className="d-flex"
                                  style={{ width: "160px" }}
                                >
                                  <img
                                    src={seniorCitizen}
                                    alt={seniorCitizen}
                                    className="me-2"
                                    style={{ height: "17px", width: "17px" }}
                                  />
                                  <p className="fs-5 text-black">
                                    {language === "mal"
                                      ? "മുതിർന്ന പൗരൻ"
                                      : "Senior Citizen"}
                                  </p>
                                </div>

                                <div>
                                  <span className="fs-5 text-black">
                                    : {familyDetail?.no_of_senior_citizens || 0}
                                  </span>
                                </div>
                              </div>

                              <div
                                className="d-flex mt-1"
                                style={{ paddingRight: "8rem" }}
                              >
                                <div
                                  className="d-flex"
                                  style={{ width: "160px" }}
                                >
                                  <img
                                    src={patient}
                                    alt={patient}
                                    className="me-2"
                                    style={{ height: "17px", width: "17px" }}
                                  />
                                  <p className="fs-5 text-black">
                                    {language === "mal" ? "രോഗികൾ" : "Patients"}
                                  </p>
                                </div>

                                <div>
                                  <span className="fs-5 text-black">
                                    : {familyDetail?.no_of_patients || 0}
                                  </span>
                                </div>
                              </div>
                            </div> */}
                          </Col>
                          <Col md={6}>
                            <div className="d-flex gap-5">
                              <div>
                                {familyCounts?.map((item, key) => (
                                  <div key={key} className="d-flex mb-2">
                                    <h5
                                      className="fs-5 text-black"
                                      style={{ width: 150 }}
                                    >
                                      {" "}
                                      {language === "mal"
                                        ? item?.malayalamTitle
                                        : item?.englishTitle}
                                    </h5>
                                    <h5 className="text-muted fs-5">
                                      {" "}
                                      : {item?.value}
                                    </h5>
                                  </div>
                                ))}
                              </div>
                              {familyDetail?.house_image ? (
                                <img
                                  style={{
                                    width: "100%",
                                    height: "270px",
                                    objectFit: "contain",
                                  }}
                                  src={familyDetail?.house_image}
                                  alt="house-image"
                                />
                              ) : (
                                `${
                                  language === "mal"
                                    ? "ഫോട്ടോ ചേർത്തിട്ടില്ല"
                                    : "No image added"
                                }`
                              )}
                            </div>
                          </Col>
                          {/* <Col
                            md={2}
                            className={`minimus-item ${
                              minimusItems && "active"
                            }`}
                          >
                            {familyDetail?.house_image ? (
                              <img
                                style={{
                                  width: "100%",
                                  height: "270px",
                                  objectFit: "contain",
                                }}
                                src={familyDetail?.house_image}
                                alt="house-image"
                              />
                            ) : (
                              `${
                                language === "mal"
                                  ? "ഫോട്ടോ ചേർത്തിട്ടില്ല"
                                  : "No image added"
                              }`
                            )}
                          </Col> */}
                        </Row>
                        <Row>
                          <Col md="6">
                            <div
                              className="mt-4"
                              style={{ borderRight: "1px solid gray" }}
                            >
                              <h4 className="pb-2 text-muted">
                                {language === "mal"
                                  ? "ആസ്തി വിവരം (സെന്റിൽ) :"
                                  : "Land Area (In Cents) :"}
                              </h4>
                              <div className="d-flex gap-5">
                                {landArea?.map((item, key) => (
                                  <div key={key} className="text-center">
                                    <h5 className="text-black">
                                      {language === "mal"
                                        ? item?.malayalamTitle
                                        : item?.englishTitle}
                                    </h5>
                                    <p
                                      className="m-0 text-black"
                                      style={{ fontSize: 15 }}
                                    >
                                      {item?.value}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </div>
                            {/* <div
                              className="mt-2"
                              style={{ border: "1px solid gray" }}
                            /> */}
                          </Col>
                          <Col md="6">
                            <div className="mt-4">
                              <h4 className="pb-2 text-muted">
                                {language === "mal"
                                  ? "വാഹനങ്ങൾ : "
                                  : "Vehicles : "}
                              </h4>
                              <div className="d-flex gap-5">
                                {vehicles?.map((item, key) => (
                                  <div key={key} className="text-center">
                                    <h5 className="text-black">
                                      {language === "mal"
                                        ? item?.malayalamTitle
                                        : item?.englishTitle}
                                    </h5>
                                    <p
                                      className="m-0 text-black"
                                      style={{ fontSize: 15 }}
                                    >
                                      {item?.value}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Col>
                          <div
                            className="mt-2"
                            style={{ borderBottom: "1px solid gray" }}
                          />
                          <Col md="3">
                            <div
                              className="mt-4"
                              style={{ borderRight: "1px solid gray" }}
                            >
                              <h4 className="pb-2 text-muted">
                                {language === "mal"
                                  ? "സൗകര്യങ്ങൾ : "
                                  : "Facilities : "}
                              </h4>
                              <div className="d-flex gap-5">
                                {facilities?.map((item, key) => (
                                  <div key={key} className="text-center">
                                    <h5 className="text-black">
                                      {language === "mal"
                                        ? item?.malayalamTitle
                                        : item?.englishTitle}
                                    </h5>
                                    <p
                                      className="m-0 text-black"
                                      style={{ fontSize: 15 }}
                                    >
                                      {item?.value}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </div>
                            {/* <div
                              className="mt-2"
                              style={{ border: "1px solid gray" }}
                            /> */}
                          </Col>
                          <Col md="9">
                            <div className="mt-4">
                              <h4 className="pb-2">
                                {language === "mal"
                                  ? "മറ്റ് വിശദാംശങ്ങൾ :"
                                  : "Other Details :"}
                              </h4>
                              <div className="d-flex gap-5">
                                {others?.map((item, key) => (
                                  <div key={key} className="text-center">
                                    <h5 className="text-black">
                                      {language === "mal"
                                        ? item?.malayalamTitle
                                        : item?.englishTitle}
                                    </h5>
                                    <p
                                      className="m-0 text-black"
                                      style={{ fontSize: 15 }}
                                    >
                                      {item?.value}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </div>
                            {/* <div
                              className="mt-2"
                              style={{ border: "1px solid gray" }}
                            /> */}
                          </Col>
                        </Row>
                        {/* <p
                          style={{
                            fontSize: "14px",
                            cursor: "pointer",
                          }}
                          className={`d-flex justify-content-end text-primary pt-1 minimus-item ${
                            minimusItems && "active"
                          }`}
                          onClick={() => familyIdQr(familyDetail?.id)}
                        >
                          {" "}
                          {language === "mal"
                            ? "<> QR കോഡ് കാണുക"
                            : "<> View QR Code"}
                        </p> */}
                        {/* <Card
                          className="sm:p-0 mt-4"
                          style={{ position: "relative" }}
                        >
                          <Row>
                            <Col md={6}>
                              <div
                                className="d-flex flex-column mt-3 justify-content-between"
                                style={{ paddingLeft: "16px" }}
                              >
                                <div className="mb-4">
                                  <h5 className="text-khaf">
                                    {" "}
                                    {language === "mal" ? "വിലാസം" : "Address"}
                                  </h5>
                                  <p className="text-black m-0 fs-5">
                                    {familyDetail?.place},{" "}
                                    {familyDetail?.post_office} Post -
                                    {familyDetail?.pin_code},{" "}
                                    {language === "mal"
                                      ? familyDetail?.district_malayalam_name
                                      : familyDetail?.district_english_name}
                                    ,{" "}
                                    {language === "mal"
                                      ? familyDetail?.state_malayalam_name
                                      : familyDetail?.state_english_name}
                                  </p>
                                </div>
                                <div className="mb-4">
                                  <h5 className="text-khaf">
                                    {language === "mal"
                                      ? "മൊബൈൽ നമ്പർ"
                                      : "Mobile Number"}
                                  </h5>
                                  <p className="text-black m-0 fs-5">
                                    {familyDetail?.contact_number || ""}
                                  </p>
                                </div>
                              </div>
                            </Col>
                            {familyDetail?.varisankya_amount &&
                              familyDetail?.varisankya_amount !== "0.00" && (
                                <Col
                                  md={6}
                                  className={`minimus-item ${
                                    minimusItems && "active"
                                  }`}
                                >
                                  <span
                                    className="spandemmy"
                                    style={{
                                      height: "120px",
                                      width: "2px",
                                      backgroundColor: "#f2f1f1",
                                      display: "inline-block",
                                      position: "absolute",
                                      borderRadius: "10px",
                                      top: "15px",
                                    }}
                                  ></span>
                                  <div
                                    className="d-flex flex-column justify-content-between varisnkya"
                                    style={{ paddingLeft: "10rem" }}
                                  >
                                    {familyDetail?.varisankya_amount && (
                                      <div
                                        className="d-flex"
                                        style={{ paddingLeft: "15px" }}
                                      >
                                        <h5 className="text-black mt-1 varisangyadiv">
                                          {language === "mal"
                                            ? "വരിസംഖ്യ തുക :"
                                            : "Varisangya Amount "}
                                        </h5>
                                        <p className="fs-5">
                                          <span className="mx-2">:</span>
                                          <span className="text-khaf fw-bolder">
                                            {familyDetail?.varisankya_amount}
                                          </span>{" "}
                                        </p>
                                      </div>
                                    )}
                                    {familyDetail?.varisankya_wallet && (
                                      <div
                                        className="d-flex"
                                        style={{ paddingLeft: "15px" }}
                                      >
                                        <h5 className="text-black varisangyadiv">
                                          {language === "mal"
                                            ? "വാലറ്റ് ബാലൻസ് : "
                                            : "Wallet Balance  "}
                                        </h5>
                                        <p className="fs-5">
                                          <span className="mx-2">:</span>
                                          <span className="text-danger fw-bolder">
                                            {familyDetail?.varisankya_wallet}
                                          </span>{" "}
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </Col>
                              )}
                          </Row>
                        </Card> */}
                        {/* 
                        <div
                          className={`minimus-item ${minimusItems && "active"}`}
                        >
                          <Row>
                            <Col lg={6}>
                              <Card className="sm:p-0">
                                <div className="accordion" id="accordion">
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingOne"
                                    >
                                      <div
                                        className="d-flex align-items-center justify-content-between py-1"
                                        style={{
                                          borderBottom: `${
                                            col2 ? "1px solid green" : "none"
                                          }`,
                                        }}
                                      >
                                        <div
                                          className="d-flex"
                                          style={{ marginLeft: "12px" }}
                                        >
                                          <img
                                            src={land}
                                            alt={land}
                                            className="me-2"
                                            style={{
                                              height: "15px",
                                              width: "15px",
                                            }}
                                          />
                                          <h5
                                            className=" mb-0 text-black "
                                            style={{ marginLeft: "2px" }}
                                          >
                                            {language === "mal"
                                              ? "ആസ്തി വിവരം (സെന്റിൽ) :"
                                              : "Land Area (In Cents)"}
                                          </h5>
                                        </div>
                                        <button
                                          className="border-0 bg-white text-khaf "
                                          type="button"
                                          onClick={() => setCol2(!col2)}
                                          style={{
                                            cursor: "pointer",
                                            borderRadius: "10px",
                                            boxShadow: "none",
                                          }}
                                        >
                                          {" "}
                                          <i
                                            className={`${
                                              col2
                                                ? "bx bx-minus"
                                                : "bx bx-plus"
                                            }`}
                                          />
                                        </button>
                                      </div>
                                    </h2>

                                    <Collapse
                                      isOpen={col2}
                                      className="accordion-collapse"
                                    >
                                      <div className="accordion-body">
                                        <div className="d-flex flex-column justify-content-between ">
                                          <div className="d-flex">
                                            <h5 className="text-black mt-1 cardleft">
                                              {language === "mal"
                                                ? "വീട്"
                                                : "Home"}{" "}
                                            </h5>
                                            <p className="fs-5">
                                              <span className="me-2">:</span>
                                              <span className="text-black ">
                                                {familyDetail?.land_area_home ||
                                                  0}
                                              </span>{" "}
                                            </p>
                                          </div>
                                          <div className="d-flex">
                                            <h5 className="text-black cardleft">
                                              {language === "mal"
                                                ? "കൃഷി"
                                                : "Farm"}{" "}
                                            </h5>
                                            <p className="fs-5">
                                              <span className="me-2">:</span>
                                              <span className="text-black">
                                                {familyDetail?.land_area_farm ||
                                                  0}
                                              </span>{" "}
                                            </p>
                                          </div>
                                          <div className="d-flex">
                                            <h5 className="text-black cardleft">
                                              {language === "mal"
                                                ? "വാടക"
                                                : "Rent"}{" "}
                                            </h5>
                                            <p className="fs-5">
                                              <span className="me-2">:</span>
                                              <span className="text-black">
                                                {familyDetail?.land_area_rent ||
                                                  0}
                                              </span>{" "}
                                            </p>
                                          </div>
                                          <div className="d-flex">
                                            <h5 className="text-black cardleft">
                                              {language === "mal"
                                                ? "മറ്റുള്ളവ"
                                                : "Others"}
                                            </h5>
                                            <p className="fs-5">
                                              <span className="me-2">:</span>
                                              <span className="text-black">
                                                {familyDetail?.land_area_others ||
                                                  0}
                                              </span>{" "}
                                            </p>
                                          </div>
                                          <div className="d-flex">
                                            <h5 className="text-black fw-bold cardleft">
                                              {language === "mal"
                                                ? "ആകെ ആസ്തി"
                                                : "Total Area"}{" "}
                                            </h5>
                                            <p className="fs-5">
                                              <span className="me-2">:</span>
                                              <span className="text-black fw-bold">
                                                {familyDetail?.total_land_area ||
                                                  0}
                                              </span>{" "}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </Collapse>
                                  </div>
                                </div>
                              </Card>
                            </Col>
                            <Col lg={6}>
                              <Card className="sm:p-0">
                                <div className="accordion" id="accordion">
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingOne"
                                    >
                                      <div
                                        className="d-flex align-items-center justify-content-between py-1"
                                        style={{
                                          borderBottom: `${
                                            col3 ? "1px solid green" : "none"
                                          }`,
                                        }}
                                      >
                                        <div
                                          className="d-flex"
                                          style={{ marginLeft: "12px" }}
                                        >
                                          <img
                                            src={car}
                                            alt={car}
                                            className="me-2"
                                            style={{
                                              height: "15px",
                                              width: "15px",
                                            }}
                                          />
                                          <h5
                                            className=" mb-0 text-black "
                                            style={{ marginLeft: "2px" }}
                                          >
                                            {language === "mal"
                                              ? "വാഹനങ്ങൾ :"
                                              : "Vehicles "}
                                          </h5>
                                        </div>
                                        <button
                                          className="border-0 bg-white text-khaf "
                                          type="button"
                                          onClick={() => setCol3(!col3)}
                                          style={{
                                            cursor: "pointer",
                                            borderRadius: "10px",
                                            boxShadow: "none",
                                          }}
                                        >
                                          {" "}
                                          <i
                                            className={`${
                                              col3
                                                ? "bx bx-minus"
                                                : "bx bx-plus"
                                            }`}
                                          />
                                        </button>
                                      </div>
                                    </h2>

                                    <Collapse
                                      isOpen={col3}
                                      className="accordion-collapse"
                                    >
                                      <div className="accordion-body">
                                        <div className="d-flex flex-column justify-content-between ">
                                          <div className="d-flex ">
                                            <h5 className="text-black mt-1 cardleft">
                                              {language === "mal"
                                                ? "2 വീലറുകൾ"
                                                : "2 Wheelers"}{" "}
                                            </h5>
                                            <p className="fs-5">
                                              <span className="me-2">:</span>
                                              <span className="text-black ">
                                                {familyDetail?.two_wheeler_vehicles ||
                                                  0}
                                              </span>{" "}
                                            </p>
                                          </div>
                                          <div className="d-flex ">
                                            <h5 className="text-black cardleft">
                                              {language === "mal"
                                                ? "3 വീലറുകൾ"
                                                : "3 Wheelers"}{" "}
                                            </h5>
                                            <p className="fs-5">
                                              <span className="me-2">:</span>
                                              <span className="text-black">
                                                {familyDetail?.three_wheeler_vehicles ||
                                                  0}
                                              </span>{" "}
                                            </p>
                                          </div>
                                          <div className="d-flex ">
                                            <h5 className="text-black cardleft">
                                              {language === "mal"
                                                ? "4 വീലറുകൾ"
                                                : "4 Wheelers"}{" "}
                                            </h5>
                                            <p className="fs-5">
                                              <span className="me-2">:</span>
                                              <span className="text-black">
                                                {familyDetail?.four_wheeler_vehicles ||
                                                  0}
                                              </span>{" "}
                                            </p>
                                          </div>
                                          <div className="d-flex ">
                                            <h5 className="text-black cardleft">
                                              {language === "mal"
                                                ? "ടാക്സികൾ"
                                                : "Taxi Vehicles"}{" "}
                                            </h5>
                                            <p className="fs-5">
                                              <span className="me-2">:</span>
                                              <span className="text-black">
                                                {familyDetail?.taxi_vehicles ||
                                                  0}
                                              </span>{" "}
                                            </p>
                                          </div>
                                          <div className="d-flex">
                                            <h5 className="text-black fw-bold cardleft">
                                              {language === "mal"
                                                ? "ആകെ വാഹനങ്ങൾ"
                                                : "Total Vehicles"}{" "}
                                            </h5>
                                            <p className="fs-5">
                                              <span className="me-2">:</span>
                                              <span className="text-black fw-bold">
                                                {familyDetail?.total_vehicles ||
                                                  0}
                                              </span>{" "}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </Collapse>
                                  </div>
                                </div>
                              </Card>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={6}>
                              <Card className="sm:p-0">
                                <div className="accordion" id="accordion">
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingOne"
                                    >
                                      <div
                                        className="d-flex align-items-center justify-content-between py-1"
                                        style={{
                                          borderBottom: `${
                                            col1 ? "1px solid green" : "none"
                                          }`,
                                        }}
                                      >
                                        <div
                                          className="d-flex"
                                          style={{ marginLeft: "12px" }}
                                        >
                                          <img
                                            src={facility}
                                            alt={facility}
                                            className="me-2"
                                            style={{
                                              height: "15px",
                                              width: "15px",
                                            }}
                                          />
                                          <h5
                                            className=" mb-0 text-black"
                                            style={{ marginLeft: "2px" }}
                                          >
                                            {language === "mal"
                                              ? "സൗകര്യങ്ങൾ :"
                                              : "Facilities "}
                                          </h5>
                                        </div>
                                        <button
                                          className="border-0 bg-white text-khaf "
                                          type="button"
                                          onClick={() => setCol1(!col1)}
                                          style={{
                                            cursor: "pointer",
                                            borderRadius: "10px",
                                            boxShadow: "none",
                                          }}
                                        >
                                          {" "}
                                          <i
                                            className={`${
                                              col1
                                                ? "bx bx-minus"
                                                : "bx bx-plus"
                                            }`}
                                          />
                                        </button>
                                      </div>
                                    </h2>

                                    <Collapse
                                      isOpen={col1}
                                      className="accordion-collapse"
                                    >
                                      <div className="accordion-body">
                                        <div className="d-flex flex-column justify-content-between ">
                                          <div className="d-flex">
                                            <h5 className="text-black mt-1 cardleft">
                                              {language === "mal"
                                                ? "വെള്ളം"
                                                : "Water"}
                                            </h5>
                                            <p className="fs-5">
                                              <span className="me-2">:</span>
                                              <span className="text-black ">
                                                {" "}
                                                {language === "mal"
                                                  ? familyDetail?.water_facility
                                                      ?.malayalam
                                                  : familyDetail?.water_facility
                                                      ?.english || ""}
                                              </span>{" "}
                                            </p>
                                          </div>
                                          <div className="d-flex">
                                            <h5 className="text-black cardleft">
                                              {language === "mal"
                                                ? "വൈദ്യുതി"
                                                : "Electricity"}{" "}
                                            </h5>
                                            <p className="fs-5">
                                              <span className="me-2">:</span>
                                              <span className="text-black">
                                                {" "}
                                                {language === "mal"
                                                  ? familyDetail
                                                      ?.electric_facility
                                                      ?.malayalam
                                                  : familyDetail
                                                      ?.electric_facility
                                                      ?.english || ""}
                                              </span>{" "}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </Collapse>
                                  </div>
                                </div>
                              </Card>
                            </Col>
                            <Col lg={6}>
                              <Card className="sm:p-0">
                                <div className="accordion" id="accordion">
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingOne"
                                    >
                                      <div
                                        className="d-flex align-items-center justify-content-between py-1"
                                        style={{
                                          borderBottom: `${
                                            col4 ? "1px solid green" : "none"
                                          }`,
                                        }}
                                      >
                                        <div
                                          className="d-flex"
                                          style={{ marginLeft: "12px" }}
                                        >
                                          <img
                                            src={other}
                                            alt={other}
                                            className="me-2"
                                            style={{
                                              height: "15px",
                                              width: "15px",
                                            }}
                                          />
                                          <h5
                                            className=" mb-0 text-black "
                                            style={{ marginLeft: "2px" }}
                                          >
                                            {language === "mal"
                                              ? "മറ്റ് വിശദാംശങ്ങൾ :"
                                              : "Other Details "}
                                          </h5>
                                        </div>
                                        <button
                                          className="border-0 bg-white text-khaf "
                                          type="button"
                                          onClick={() => setCol4(!col4)}
                                          style={{
                                            cursor: "pointer",
                                            borderRadius: "10px",
                                            boxShadow: "none",
                                          }}
                                        >
                                          {" "}
                                          <i
                                            className={`${
                                              col4
                                                ? "bx bx-minus"
                                                : "bx bx-plus"
                                            }`}
                                          />
                                        </button>
                                      </div>
                                    </h2>

                                    <Collapse
                                      isOpen={col4}
                                      className="accordion-collapse"
                                    >
                                      <div className="accordion-body">
                                        <div className="d-flex flex-column justify-content-between ">
                                          <div className="d-flex">
                                            <h5 className="text-black mt-1 cardleft">
                                              {language === "mal"
                                                ? "വീട്"
                                                : "House Type"}{" "}
                                            </h5>
                                            <p className="fs-5">
                                              <span className="me-2">:</span>
                                              <span className="text-black ">
                                                {language === "mal"
                                                  ? familyDetail?.house_type
                                                      ?.malayalam
                                                  : familyDetail?.house_type
                                                      ?.english}{" "}
                                                /{" "}
                                                {language === "mal"
                                                  ? familyDetail
                                                      ?.house_build_type
                                                      ?.malayalam
                                                  : familyDetail
                                                      ?.house_build_type
                                                      ?.english}
                                              </span>{" "}
                                            </p>
                                          </div>
                                          <div className="d-flex">
                                            <h5 className="text-black cardleft">
                                              {language === "mal"
                                                ? "റേഷൻ കാർഡ്"
                                                : "Ration Card"}
                                            </h5>
                                            <p className="fs-5">
                                              <span className="me-2">:</span>
                                              <span className="text-black">
                                                {language === "mal"
                                                  ? familyDetail?.ration_card
                                                      ?.malayalam
                                                  : familyDetail?.ration_card
                                                      ?.english}{" "}
                                                /{" "}
                                                {
                                                  familyDetail?.ration_card_number
                                                }
                                              </span>{" "}
                                            </p>
                                          </div>

                                          <div className="d-flex">
                                            <h5 className="text-black cardleft">
                                              {language === "mal"
                                                ? "സാമ്പത്തിക സ്ഥിതി"
                                                : "Financial Status"}
                                            </h5>
                                            <p className="fs-5">
                                              <span className="me-2">:</span>
                                              <span className="text-black">
                                                {language === "mal"
                                                  ? familyDetail
                                                      ?.financial_status
                                                      ?.malayalam
                                                  : familyDetail
                                                      ?.financial_status
                                                      ?.english}
                                              </span>{" "}
                                            </p>
                                          </div>
                                          <div className="d-flex">
                                            <h5 className="text-black cardleft">
                                              {language === "mal"
                                                ? "വാർഷിക വരുമാനം"
                                                : "Annual Income"}
                                            </h5>
                                            <p className="fs-5">
                                              <span className="me-2">:</span>
                                              <span className="text-black">
                                                {
                                                  familyDetail?.annual_income
                                                    ?.english
                                                }
                                              </span>{" "}
                                            </p>
                                          </div>
                                          <div className="d-flex">
                                            <h5 className="text-black cardleft">
                                              {language === "mal"
                                                ? "ബാങ്ക് വായ്പ"
                                                : "Bank Loan"}
                                            </h5>
                                            <p className="fs-5">
                                              <span className="me-2">:</span>
                                              <span className="text-black">
                                                {familyDetail?.bank_loan?.map(
                                                  (item, key) => {
                                                    return (
                                                      <Fragment key={key}>{`${
                                                        key != 0 ? "," : ""
                                                      } ${
                                                        language === "mal"
                                                          ? item.malayalam
                                                          : item.english
                                                      } `}</Fragment>
                                                    )
                                                  }
                                                )}
                                                {familyDetail?.bank_loan_details
                                                  ? `(${familyDetail?.bank_loan_details})`
                                                  : ""}
                                              </span>{" "}
                                            </p>
                                          </div>
                                          <div className="d-flex">
                                            <h5 className="text-black cardleft">
                                              {language === "mal"
                                                ? "ഹോം ബിസിനസ്സ്"
                                                : "Home Business"}
                                            </h5>
                                            <p className="fs-5">
                                              <span className="me-2">:</span>
                                              <span className="text-black">
                                                {familyDetail?.home_business?.map(
                                                  (item, key) => {
                                                    return (
                                                      <Fragment key={key}>{`${
                                                        key != 0 ? "," : ""
                                                      } ${
                                                        language === "mal"
                                                          ? item.malayalam
                                                          : item.english
                                                      } `}</Fragment>
                                                    )
                                                  }
                                                )}
                                                {familyDetail?.home_business_details
                                                  ? `(${familyDetail?.home_business_details})`
                                                  : ""}
                                              </span>{" "}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </Collapse>
                                  </div>
                                </div>
                              </Card>
                            </Col>
                          </Row>

                          <Row>
                            <Row className="d-flex align-items-center">
                              <Col md="4">
                                <div className="d-flex flex-wrap ">
                                  <div className="d-flex">
                                    <p className="text-khaf fw-bold fs-5">
                                      {language === "mal"
                                        ? "മഹല്ലിൽ നിന്നുള്ള സഹായം "
                                        : "Help From Mahall "}
                                    </p>
                                  </div>

                                  <div className="mx-2">
                                    <span className="text-black fw-bold fs-5">
                                      :{" "}
                                      {familyDetail?.help_receiving_from_mahall
                                        ? language === "mal"
                                          ? "ഉണ്ട്"
                                          : "Yes"
                                        : language === "mal"
                                        ? "ഇല്ല"
                                        : "No"}
                                    </span>
                                  </div>
                                </div>
                              </Col>
                              {familyDetail &&
                                familyDetail?.help_receiving_from_mahall &&
                                familyDetail?.help_receiving_from_mahall_details !==
                                  null && (
                                  <Col md={8}>
                                    <Card className="mt-1">
                                      <h5 className="text-muted px-3 py-3 m-0">
                                        {familyDetail?.help_receiving_from_mahall
                                          ? `${
                                              language === "mal"
                                                ? "വിശദാംശങ്ങൾ :"
                                                : "Details :"
                                            } ${
                                              familyDetail?.help_receiving_from_mahall_details
                                            }`
                                          : ""}
                                      </h5>
                                    </Card>
                                  </Col>
                                )}
                            </Row>
                            <Row className="d-flex align-items-center">
                              <Col md="4">
                                <div className="d-flex flex-wrap ">
                                  <div className="d-flex">
                                    <p className="text-khaf fw-bold fs-5">
                                      {language === "mal"
                                        ? "മറ്റുള്ളവരിൽ നിന്നുള്ള സഹായം "
                                        : "Help From Others "}
                                    </p>
                                  </div>

                                  <div className="mx-2">
                                    <span className="text-black fw-bold fs-5">
                                      :{" "}
                                      {familyDetail?.help_receiving_from_others
                                        ? language === "mal"
                                          ? "ഉണ്ട്"
                                          : "Yes"
                                        : language === "mal"
                                        ? "ഇല്ല"
                                        : "No"}
                                    </span>
                                  </div>
                                </div>
                              </Col>
                              {familyDetail &&
                                familyDetail?.help_receiving_from_others &&
                                familyDetail?.help_receiving_from_others_details !==
                                  null && (
                                  <Col md={8}>
                                    <Card className="mt-1">
                                      <h5 className="text-muted px-3 py-3 m-0">
                                        {familyDetail?.help_receiving_from_others
                                          ? `${
                                              language === "mal"
                                                ? "വിശദാംശങ്ങൾ :"
                                                : "Details :"
                                            } ${
                                              familyDetail?.help_receiving_from_others_details
                                            }`
                                          : ""}
                                      </h5>
                                    </Card>
                                  </Col>
                                )}
                            </Row>
                            <Row className="d-flex align-items-center">
                              <Col md={4}>
                                <div className="d-flex flex-wrap">
                                  <div className="d-flex zakat-div">
                                    <p className="text-khaf fw-bold fs-5">
                                      {language === "mal"
                                        ? "സകാത്തിനുള്ള അർഹത :"
                                        : "Zakat Eligible "}
                                    </p>
                                  </div>

                                  <div className="mx-2">
                                    <span className="text-black fw-bold fs-5">
                                      :{" "}
                                      {familyDetail?.is_zakat_eligible
                                        ? language === "mal"
                                          ? "ഉണ്ട്"
                                          : "Yes"
                                        : language === "mal"
                                        ? "ഇല്ല"
                                        : "No"}
                                    </span>
                                  </div>
                                </div>
                              </Col>
                              {familyDetail &&
                                familyDetail?.is_zakat_eligible &&
                                familyDetail?.zakat_eligibility_reason !==
                                  null && (
                                  <Col md={8}>
                                    <Card className="mt-1">
                                      <h5 className="text-muted  px-3 py-3 m-0">
                                        {familyDetail?.is_zakat_eligible
                                          ? `${
                                              language === "mal"
                                                ? "കാരണം :"
                                                : "Reason :"
                                            } ${
                                              familyDetail?.zakat_eligibility_reason ===
                                              null
                                                ? ""
                                                : familyDetail?.zakat_eligibility_reason
                                            }`
                                          : ""}
                                      </h5>
                                    </Card>
                                  </Col>
                                )}
                            </Row>
                          </Row>

                          <Row className="mt-3">
                            <Col md="6">
                              <div className="d-flex align-items-end mb-3">
                                <p className="text-black fs-6 m-0">
                                  Listed By :{" "}
                                  <span className="text-black px-1">
                                    {familyDetail?.creator_name}
                                  </span>
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </div> */}
                      </CardBody>
                    </Card>
                  </Row>
                )}
              </Col>
            </Row>
            <Row>
              <Col className="col-12 mt-2 sm:p-0">
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      columns={columns}
                      data={familyMembersData}
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row
                            className={`mb-2 minimus-item ${
                              minimusItems && "active"
                            }`}
                          >
                            <Col md="4">
                              {/* <div className="no-print search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <form
                                    className="app-search d-lg-block"
                                    onChange={e =>
                                      debounceMahallFamilyFamilyMemberSearch(
                                        e.target.value
                                      )
                                    }
                                  >
                                    <div className="position-relative">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                        defaultValue={searchText}
                                      />
                                      <span className="bx bx-search-alt" />
                                    </div>
                                  </form>
                                </div>
                              </div> */}
                              <h4>Family Members</h4>
                            </Col>
                          </Row>
                          {loading ? (
                            <Spinner
                              color="secondary"
                              className="d-block m-auto"
                            />
                          ) : (
                            <>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      selectRow={selectRow}
                                      classes={
                                        "table align-middle table-wrap-print"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <div
                                className={` no-print minimus-item ${
                                  minimusItems && "active"
                                }`}
                              >
                                <MyPagination
                                  totalPages={pages}
                                  page={page}
                                  setPage={setPage}
                                />
                              </div>
                            </>
                          )}
                          {!loading &&
                            familyMembers &&
                            familyMembers?.results &&
                            familyMembers?.results?.length <= 0 && (
                              <p
                                style={{ fontSize: "15px" }}
                                className="text-center text-info"
                              >
                                {language === "mal"
                                  ? "അംഗങ്ങൾ ഒന്നും ഇല്ല"
                                  : "No Members Yet"}
                              </p>
                            )}
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default PrintFamilyDetails

PrintFamilyDetails.propTypes = {
  history: PropTypes.object,
}
