/* LEDGER_ITEMS */
export const GET_LEDGER_ITEMS = "GET_LEDGER_ITEMS"
export const GET_LEDGER_ITEMS_SUCCESS = "GET_LEDGER_ITEMS_SUCCESS"
export const GET_LEDGER_ITEMS_FAIL = "GET_LEDGER_ITEMS_FAIL"

/* LEDGER_ITEMS_FOR_DOWNLOAD */
export const GET_LEDGER_ITEMS_FOR_DOWNLOAD = "GET_LEDGER_ITEMS_FOR_DOWNLOAD"
export const GET_LEDGER_ITEMS_FOR_DOWNLOAD_SUCCESS =
  "GET_LEDGER_ITEMS_FOR_DOWNLOAD_SUCCESS"
export const GET_LEDGER_ITEMS_FOR_DOWNLOAD_FAIL =
  "GET_LEDGER_ITEMS_FOR_DOWNLOAD_FAIL"

/* LEDGER_ITEMS DETAIL*/
export const GET_LEDGER_ITEM_DETAIL = "GET_LEDGER_ITEM_DETAIL"
export const GET_LEDGER_ITEM_DETAIL_SUCCESS = "GET_LEDGER_ITEM_DETAIL_SUCCESS"
export const GET_LEDGER_ITEM_DETAIL_FAIL = "GET_LEDGER_ITEM_DETAIL_FAIL"

/**
 * add LEDGER_ITEM
 */
export const CREATE_LEDGER_ITEM = "CREATE_LEDGER_ITEM"
export const CREATE_LEDGER_ITEM_SUCCESS = "CREATE_LEDGER_ITEM_SUCCESS"
export const CREATE_LEDGER_ITEM_FAIL = "CREATE_LEDGER_ITEM_FAIL"

/**
 * Edit LEDGER_ITEM
 */
export const UPDATE_LEDGER_ITEM = "UPDATE_LEDGER_ITEM"
export const UPDATE_LEDGER_ITEM_SUCCESS = "UPDATE_LEDGER_ITEM_SUCCESS"
export const UPDATE_LEDGER_ITEM_FAIL = "UPDATE_LEDGER_ITEM_FAIL"

/**
 * Delete LEDGER_ITEM
 */
export const DELETE_LEDGER_ITEM = "DELETE_LEDGER_ITEM"
export const DELETE_LEDGER_ITEM_SUCCESS = "DELETE_LEDGER_ITEM_SUCCESS"
export const DELETE_LEDGER_ITEM_FAIL = "DELETE_LEDGER_ITEM_FAIL"
