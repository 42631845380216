import {
  GET_NIKAH_REGISTRATIONS,
  GET_NIKAH_REGISTRATIONS_SUCCESS,
  GET_NIKAH_REGISTRATIONS_FAIL,
  GET_NIKAH_REGISTRATIONS_CARDS,
  GET_NIKAH_REGISTRATIONS_CARDS_SUCCESS,
  GET_NIKAH_REGISTRATIONS_CARDS_FAIL,
  GET_NIKAH_REGISTRATION_DETAIL,
  GET_NIKAH_REGISTRATION_DETAIL_SUCCESS,
  GET_NIKAH_REGISTRATION_DETAIL_FAIL,
  CREATE_NIKAH_REGISTRATION,
  CREATE_NIKAH_REGISTRATION_SUCCESS,
  CREATE_NIKAH_REGISTRATION_FAIL,
  UPDATE_NIKAH_REGISTRATION,
  UPDATE_NIKAH_REGISTRATION_SUCCESS,
  UPDATE_NIKAH_REGISTRATION_FAIL,
  DELETE_NIKAH_REGISTRATION,
  DELETE_NIKAH_REGISTRATION_SUCCESS,
  DELETE_NIKAH_REGISTRATION_FAIL,
  GET_NIKAH_REGISTRATIONS_FOR_DOWNLOAD,
  GET_NIKAH_REGISTRATIONS_FOR_DOWNLOAD_SUCCESS,
  GET_NIKAH_REGISTRATIONS_FOR_DOWNLOAD_FAIL,
} from "./actionTypes"

export const getNikahRegistrations = (searchText, page) => ({
  type: GET_NIKAH_REGISTRATIONS,
  payload: { searchText, page },
})
export const getNikahRegistrationsSuccess = nikahRegistrations => ({
  type: GET_NIKAH_REGISTRATIONS_SUCCESS,
  payload: nikahRegistrations,
})
export const getNikahRegistrationsFail = error => ({
  type: GET_NIKAH_REGISTRATIONS_FAIL,
  payload: error,
})

export const getNikahRegistrationsCards = () => ({
  type: GET_NIKAH_REGISTRATIONS_CARDS,
})
export const getNikahRegistrationsCardsSuccess = nikahRegistrationCards => ({
  type: GET_NIKAH_REGISTRATIONS_CARDS_SUCCESS,
  payload: nikahRegistrationCards,
})
export const getNikahRegistrationsCardsFail = error => ({
  type: GET_NIKAH_REGISTRATIONS_CARDS_FAIL,
  payload: error,
})

export const getNikahRegistrationsForDownload = () => ({
  type: GET_NIKAH_REGISTRATIONS_FOR_DOWNLOAD,
})

export const getNikahRegistrationsForDownloadSuccess =
  nikahRegistrationsForDownload => ({
    type: GET_NIKAH_REGISTRATIONS_FOR_DOWNLOAD_SUCCESS,
    payload: nikahRegistrationsForDownload,
  })

export const getNikahRegistrationsForDownloadFail = error => ({
  type: GET_NIKAH_REGISTRATIONS_FOR_DOWNLOAD_FAIL,
  payload: error,
})

export const getNikahRegistrationDetail = nikahRegistrationId => ({
  type: GET_NIKAH_REGISTRATION_DETAIL,
  nikahRegistrationId,
})

export const getNikahRegistrationDetailSuccess = nikahRegistrationDetail => ({
  type: GET_NIKAH_REGISTRATION_DETAIL_SUCCESS,
  payload: nikahRegistrationDetail,
})

export const getNikahRegistrationDetailFail = error => ({
  type: GET_NIKAH_REGISTRATION_DETAIL_FAIL,
  payload: error,
})

export const createNikahRegistration = (nikahRegistration, history) => ({
  type: CREATE_NIKAH_REGISTRATION,
  payload: { nikahRegistration, history },
})

export const createNikahRegistrationSuccess = nikahRegistration => ({
  type: CREATE_NIKAH_REGISTRATION_SUCCESS,
  payload: nikahRegistration,
})

export const createNikahRegistrationFail = error => ({
  type: CREATE_NIKAH_REGISTRATION_FAIL,
  payload: error,
})

export const updateNikahRegistration = (
  nikahRegistration,
  nikahRegistrationId,
  history
) => ({
  type: UPDATE_NIKAH_REGISTRATION,
  payload: { nikahRegistration, nikahRegistrationId, history },
})

export const updateNikahRegistrationSuccess = nikahRegistration => ({
  type: UPDATE_NIKAH_REGISTRATION_SUCCESS,
  payload: nikahRegistration,
})

export const updateNikahRegistrationFail = error => ({
  type: UPDATE_NIKAH_REGISTRATION_FAIL,
  payload: error,
})

export const deleteNikahRegistration = (nikahRegistrationId, history) => ({
  type: DELETE_NIKAH_REGISTRATION,
  payload: { nikahRegistrationId, history },
})

export const deleteNikahRegistrationSuccess = nikahRegistration => ({
  type: DELETE_NIKAH_REGISTRATION_SUCCESS,
  payload: nikahRegistration,
})

export const deleteNikahRegistrationFail = error => ({
  type: DELETE_NIKAH_REGISTRATION_FAIL,
  payload: error,
})
