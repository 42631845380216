/* COMMITTEEMEETINGS */
export const GET_COMMITTEE_MEETINGS = "GET_COMMITTEE_MEETINGS"
export const GET_COMMITTEE_MEETINGS_SUCCESS = "GET_COMMITTEE_MEETINGS_SUCCESS"
export const GET_COMMITTEE_MEETINGS_FAIL = "GET_COMMITTEE_MEETINGS_FAIL"

/* COMMITTEEMEETINGS */
export const GET_COMMITTEE_MEETING_DROPDOWN = "GET_COMMITTEE_MEETING_DROPDOWN"
export const GET_COMMITTEE_MEETING_DROPDOWN_SUCCESS =
  "GET_COMMITTEE_MEETING_DROPDOWN_SUCCESS"
export const GET_COMMITTEE_MEETING_DROPDOWN_FAIL =
  "GET_COMMITTEE_MEETING_DROPDOWN_FAIL"

/* COMMITTEEMEETINGS DETAIL*/
export const GET_COMMITTEE_MEETING_DETAIL = "GET_COMMITTEE_MEETING_DETAIL"
export const GET_COMMITTEE_MEETING_DETAIL_SUCCESS =
  "GET_COMMITTEE_MEETING_DETAIL_SUCCESS"
export const GET_COMMITTEE_MEETING_DETAIL_FAIL =
  "GET_COMMITTEE_MEETING_DETAIL_FAIL"

/**
 * add COMMITTEEMEETING
 */
export const CREATE_COMMITTEE_MEETING = "CREATE_COMMITTEE_MEETING"
export const CREATE_COMMITTEE_MEETING_SUCCESS =
  "CREATE_COMMITTEE_MEETING_SUCCESS"
export const CREATE_COMMITTEE_MEETING_FAIL = "CREATE_COMMITTEE_MEETING_FAIL"

/**
 * Edit COMMITTEEMEETING
 */
export const UPDATE_COMMITTEE_MEETING = "UPDATE_COMMITTEE_MEETING"
export const UPDATE_COMMITTEE_MEETING_SUCCESS =
  "UPDATE_COMMITTEE_MEETING_SUCCESS"
export const UPDATE_COMMITTEE_MEETING_FAIL = "UPDATE_COMMITTEE_MEETING_FAIL"

/**
 * Delete COMMITTEEMEETING
 */
export const DELETE_COMMITTEE_MEETING = "DELETE_COMMITTEE_MEETING"
export const DELETE_COMMITTEE_MEETING_SUCCESS =
  "DELETE_COMMITTEE_MEETING_SUCCESS"
export const DELETE_COMMITTEE_MEETING_FAIL = "DELETE_COMMITTEE_MEETING_FAIL"
