/* INSTITUTEACCOUNTS */
export const GET_INSTITUTE_ACCOUNTS = "GET_INSTITUTE_ACCOUNTS"
export const GET_INSTITUTE_ACCOUNTS_SUCCESS = "GET_INSTITUTE_ACCOUNTS_SUCCESS"
export const GET_INSTITUTE_ACCOUNTS_FAIL = "GET_INSTITUTE_ACCOUNTS_FAIL"

/* INSTITUTEACCOUNTS DETAIL*/
export const GET_INSTITUTE_ACCOUNT_DETAIL = "GET_INSTITUTE_ACCOUNT_DETAIL"
export const GET_INSTITUTE_ACCOUNT_DETAIL_SUCCESS =
  "GET_INSTITUTE_ACCOUNT_DETAIL_SUCCESS"
export const GET_INSTITUTE_ACCOUNT_DETAIL_FAIL =
  "GET_INSTITUTE_ACCOUNT_DETAIL_FAIL"

/**
 * add INSTITUTEACCOUNT
 */
export const CREATE_INSTITUTE_ACCOUNT = "CREATE_INSTITUTE_ACCOUNT"
export const CREATE_INSTITUTE_ACCOUNT_SUCCESS =
  "CREATE_INSTITUTE_ACCOUNT_SUCCESS"
export const CREATE_INSTITUTE_ACCOUNT_FAIL = "CREATE_INSTITUTE_ACCOUNT_FAIL"

/**
 * Edit INSTITUTEACCOUNT
 */
export const UPDATE_INSTITUTE_ACCOUNT = "UPDATE_INSTITUTE_ACCOUNT"
export const UPDATE_INSTITUTE_ACCOUNT_SUCCESS =
  "UPDATE_INSTITUTE_ACCOUNT_SUCCESS"
export const UPDATE_INSTITUTE_ACCOUNT_FAIL = "UPDATE_INSTITUTE_ACCOUNT_FAIL"

/**
 * Delete INSTITUTEACCOUNT
 */
export const DELETE_INSTITUTE_ACCOUNT = "DELETE_INSTITUTE_ACCOUNT"
export const DELETE_INSTITUTE_ACCOUNT_SUCCESS =
  "DELETE_INSTITUTE_ACCOUNT_SUCCESS"
export const DELETE_INSTITUTE_ACCOUNT_FAIL = "DELETE_INSTITUTE_ACCOUNT_FAIL"

/* ACCOUNTITEMS DETAIL*/
export const GET_ACCOUNT_ITEM_DETAIL = "GET_ACCOUNT_ITEM_DETAIL"
export const GET_ACCOUNT_ITEM_DETAIL_SUCCESS = "GET_ACCOUNT_ITEM_DETAIL_SUCCESS"
export const GET_ACCOUNT_ITEM_DETAIL_FAIL = "GET_ACCOUNT_ITEM_DETAIL_FAIL"

/**
 * add ACCOUNTITEM
 */
export const CREATE_ACCOUNT_ITEM = "CREATE_ACCOUNT_ITEM"
export const CREATE_ACCOUNT_ITEM_SUCCESS = "CREATE_ACCOUNT_ITEM_SUCCESS"
export const CREATE_ACCOUNT_ITEM_FAIL = "CREATE_ACCOUNT_ITEM_FAIL"

/**
 * Edit ACCOUNTITEM
 */
export const UPDATE_ACCOUNT_ITEM = "UPDATE_ACCOUNT_ITEM"
export const UPDATE_ACCOUNT_ITEM_SUCCESS = "UPDATE_ACCOUNT_ITEM_SUCCESS"
export const UPDATE_ACCOUNT_ITEM_FAIL = "UPDATE_ACCOUNT_ITEM_FAIL"

/**
 * Delete ACCOUNTITEM
 */
export const DELETE_ACCOUNT_ITEM = "DELETE_ACCOUNT_ITEM"
export const DELETE_ACCOUNT_ITEM_SUCCESS = "DELETE_ACCOUNT_ITEM_SUCCESS"
export const DELETE_ACCOUNT_ITEM_FAIL = "DELETE_ACCOUNT_ITEM_FAIL"
