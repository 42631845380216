import {
  GET_FAMILY_MEMBERS,
  GET_FAMILY_MEMBERS_SUCCESS,
  GET_FAMILY_MEMBERS_FAIL,
  GET_FAMILY_MEMBERS_FOR_DOWNLOAD,
  GET_FAMILY_MEMBERS_FOR_DOWNLOAD_SUCCESS,
  GET_FAMILY_MEMBERS_FOR_DOWNLOAD_FAIL,
  GET_FILTERED_FAMILY_MEMBERS,
  GET_FILTERED_FAMILY_MEMBERS_SUCCESS,
  GET_FILTERED_FAMILY_MEMBERS_FAIL,
  GET_FAMILY_MEMBERS_CARDS,
  GET_FAMILY_MEMBERS_CARDS_SUCCESS,
  GET_FAMILY_MEMBERS_CARDS_FAIL,
  GET_FAMILY_MEMBER_DROPDOWN,
  GET_FAMILY_MEMBER_DROPDOWN_SUCCESS,
  GET_FAMILY_MEMBER_DROPDOWN_FAIL,
  GET_ALL_FAMILY_MEMBERS_DROPDOWN,
  GET_ALL_FAMILY_MEMBERS_DROPDOWN_SUCCESS,
  GET_ALL_FAMILY_MEMBERS_DROPDOWN_FAIL,
  GET_FAMILY_MEMBER_DETAIL,
  GET_FAMILY_MEMBER_DETAIL_SUCCESS,
  GET_FAMILY_MEMBER_DETAIL_FAIL,
  UPDATE_PHONE_FAMILY_MEMBER,
  UPDATE_PHONE_FAMILY_MEMBER_SUCCESS,
  UPDATE_PHONE_FAMILY_MEMBER_FAIL,
  CREATE_FAMILY_MEMBER,
  CREATE_FAMILY_MEMBER_SUCCESS,
  CREATE_FAMILY_MEMBER_FAIL,
  UPDATE_FAMILY_MEMBER,
  UPDATE_FAMILY_MEMBER_SUCCESS,
  UPDATE_FAMILY_MEMBER_FAIL,
  DELETE_FAMILY_MEMBER,
  DELETE_FAMILY_MEMBER_SUCCESS,
  DELETE_FAMILY_MEMBER_FAIL,
  CLEAR_MEMBER_DETAILS,
} from "./actionTypes"

export const getFamilyMembers = (searchText, page, familyId, sort) => ({
  type: GET_FAMILY_MEMBERS,
  payload: { searchText, page, familyId, sort },
})

export const getFamilyMembersSuccess = familyMembers => ({
  type: GET_FAMILY_MEMBERS_SUCCESS,
  payload: familyMembers,
})
export const getFamilyMembersFail = error => ({
  type: GET_FAMILY_MEMBERS_FAIL,
  payload: error,
})

export const getFamilyMembersForDownload = filterData => ({
  type: GET_FAMILY_MEMBERS_FOR_DOWNLOAD,
  payload: { filterData },
})

export const getFamilyMembersForDownloadSuccess = familyMembersForDownload => ({
  type: GET_FAMILY_MEMBERS_FOR_DOWNLOAD_SUCCESS,
  payload: familyMembersForDownload,
})

export const getFamilyMembersForDownloadFail = error => ({
  type: GET_FAMILY_MEMBERS_FOR_DOWNLOAD_FAIL,
  payload: error,
})

export const getFilteredFamilyMembers = (page, pageSize, filterData) => ({
  type: GET_FILTERED_FAMILY_MEMBERS,
  payload: { page, pageSize, filterData },
})

export const getFilteredFamilyMembersSuccess = filteredFamilyMembers => ({
  type: GET_FILTERED_FAMILY_MEMBERS_SUCCESS,
  payload: filteredFamilyMembers,
})

export const getFilteredFamilyMembersFail = error => ({
  type: GET_FILTERED_FAMILY_MEMBERS_FAIL,
  payload: error,
})

export const getFamilyMembersCards = () => ({
  type: GET_FAMILY_MEMBERS_CARDS,
})

export const getFamilyMembersCardsSuccess = familyMembersCards => ({
  type: GET_FAMILY_MEMBERS_CARDS_SUCCESS,
  payload: familyMembersCards,
})
export const getFamilyMembersCardsFail = error => ({
  type: GET_FAMILY_MEMBERS_CARDS_FAIL,
  payload: error,
})

export const getFamilyMemberDropdown = (searchText, familyId) => ({
  type: GET_FAMILY_MEMBER_DROPDOWN,
  payload: { searchText, familyId },
})
export const getFamilyMemberDropdownSuccess = familyMembers => ({
  type: GET_FAMILY_MEMBER_DROPDOWN_SUCCESS,
  payload: familyMembers,
})
export const getFamilyMemberDropdownFail = error => ({
  type: GET_FAMILY_MEMBER_DROPDOWN_FAIL,
  payload: error,
})

export const getAllFamilyMembersDropdown = searchText => ({
  type: GET_ALL_FAMILY_MEMBERS_DROPDOWN,
  payload: { searchText },
})
export const getAllFamilyMembersDropdownSuccess = allFamilyMembers => ({
  type: GET_ALL_FAMILY_MEMBERS_DROPDOWN_SUCCESS,
  payload: allFamilyMembers,
})
export const getAllFamilyMembersDropdownFail = error => ({
  type: GET_ALL_FAMILY_MEMBERS_DROPDOWN_FAIL,
  payload: error,
})

export const getFamilyMemberDetail = familyMemberId => ({
  type: GET_FAMILY_MEMBER_DETAIL,
  familyMemberId,
})

export const getFamilyMemberDetailSuccess = familyMemberDetail => ({
  type: GET_FAMILY_MEMBER_DETAIL_SUCCESS,
  payload: familyMemberDetail,
})

export const getFamilyMemberDetailFail = error => ({
  type: GET_FAMILY_MEMBER_DETAIL_FAIL,
  payload: error,
})

export const updatePhoneFamilyMember = phone => ({
  type: UPDATE_PHONE_FAMILY_MEMBER,
  phone,
})

export const updatePhoneFamilyMemberSuccess = otp => ({
  type: UPDATE_PHONE_FAMILY_MEMBER_SUCCESS,
  payload: otp,
})

export const updatePhoneFamilyMemberFail = error => ({
  type: UPDATE_PHONE_FAMILY_MEMBER_FAIL,
  payload: error,
})

export const createFamilyMember = (familyMember, history) => ({
  type: CREATE_FAMILY_MEMBER,
  payload: { familyMember, history },
})

export const createFamilyMemberSuccess = familyMember => ({
  type: CREATE_FAMILY_MEMBER_SUCCESS,
  payload: familyMember,
})

export const createFamilyMemberFail = error => ({
  type: CREATE_FAMILY_MEMBER_FAIL,
  payload: error,
})

export const updateFamilyMember = (familyMember, familyMemberId, history) => ({
  type: UPDATE_FAMILY_MEMBER,
  payload: { familyMember, familyMemberId, history },
})

export const updateFamilyMemberSuccess = familyMember => ({
  type: UPDATE_FAMILY_MEMBER_SUCCESS,
  payload: familyMember,
})

export const updateFamilyMemberFail = error => ({
  type: UPDATE_FAMILY_MEMBER_FAIL,
  payload: error,
})

export const deleteFamilyMember = (familyMemberId, history) => ({
  type: DELETE_FAMILY_MEMBER,
  payload: { familyMemberId, history },
})

export const deleteFamilyMemberSuccess = familyMember => ({
  type: DELETE_FAMILY_MEMBER_SUCCESS,
  payload: familyMember,
})

export const deleteFamilyMemberFail = error => ({
  type: DELETE_FAMILY_MEMBER_FAIL,
  payload: error,
})

export const clearMemberDetail = () => ({
  type: CLEAR_MEMBER_DETAILS,
})
