import {
  GET_ASSETS,
  GET_ASSETS_SUCCESS,
  GET_ASSETS_FAIL,
  GET_ASSET_DETAIL,
  GET_ASSET_DETAIL_SUCCESS,
  GET_ASSET_DETAIL_FAIL,
  CREATE_ASSET,
  CREATE_ASSET_SUCCESS,
  CREATE_ASSET_FAIL,
  UPDATE_ASSET,
  UPDATE_ASSET_SUCCESS,
  UPDATE_ASSET_FAIL,
  DELETE_ASSET,
  DELETE_ASSET_SUCCESS,
  DELETE_ASSET_FAIL,
} from "./actionTypes"

export const getAssets = (instituteId, searchText, page) => ({
  type: GET_ASSETS,
  payload: { instituteId, searchText, page },
})
export const getAssetsSuccess = assets => ({
  type: GET_ASSETS_SUCCESS,
  payload: assets,
})
export const getAssetsFail = error => ({
  type: GET_ASSETS_FAIL,
  payload: error,
})

// export const getVarisangyaCards = () => ({
//   type: GET_VARISANGYA_CARDS,
// })
// export const getVarisangyaCardsSuccess = varisangyaCards => ({
//   type: GET_VARISANGYA_CARDS_SUCCESS,
//   payload: varisangyaCards,
// })
// export const getVarisangyaCardsFail = error => ({
//   type: GET_VARISANGYA_CARDS_FAIL,
//   payload: error,
// })

export const getAssetDetail = assetId => ({
  type: GET_ASSET_DETAIL,
  assetId,
})

export const getAssetDetailSuccess = assetDetail => ({
  type: GET_ASSET_DETAIL_SUCCESS,
  payload: assetDetail,
})

export const getAssetDetailFail = error => ({
  type: GET_ASSET_DETAIL_FAIL,
  payload: error,
})

export const createAsset = (asset, history) => ({
  type: CREATE_ASSET,
  payload: { asset, history },
})

export const createAssetSuccess = asset => ({
  type: CREATE_ASSET_SUCCESS,
  payload: asset,
})

export const createAssetFail = error => ({
  type: CREATE_ASSET_FAIL,
  payload: error,
})

export const updateAsset = (asset, assetId) => ({
  type: UPDATE_ASSET,
  payload: { asset, assetId },
})

export const updateAssetSuccess = asset => ({
  type: UPDATE_ASSET_SUCCESS,
  payload: asset,
})

export const updateAssetFail = error => ({
  type: UPDATE_ASSET_FAIL,
  payload: error,
})

export const deleteAsset = (assetId, history) => ({
  type: DELETE_ASSET,
  payload: { assetId, history },
})

export const deleteAssetSuccess = asset => ({
  type: DELETE_ASSET_SUCCESS,
  payload: asset,
})

export const deleteAssetFail = error => ({
  type: DELETE_ASSET_FAIL,
  payload: error,
})
