/* FAMILY MEMBERS */
export const GET_FAMILY_MEMBERS = "GET_FAMILY_MEMBERS"
export const GET_FAMILY_MEMBERS_SUCCESS = "GET_FAMILY_MEMBERS_SUCCESS"
export const GET_FAMILY_MEMBERS_FAIL = "GET_FAMILY_MEMBERS_FAIL"

/* FAMILY MEMBERS FOR DOWNLOAD*/
export const GET_FAMILY_MEMBERS_FOR_DOWNLOAD = "GET_FAMILY_MEMBERS_FOR_DOWNLOAD"
export const GET_FAMILY_MEMBERS_FOR_DOWNLOAD_SUCCESS =
  "GET_FAMILY_MEMBERS_FOR_DOWNLOAD_SUCCESS"
export const GET_FAMILY_MEMBERS_FOR_DOWNLOAD_FAIL =
  "GET_FAMILY_MEMBERS_FOR_DOWNLOAD_FAIL"

/* FILTERED_FAMILY MEMBERS */
export const GET_FILTERED_FAMILY_MEMBERS = "GET_FILTERED_FAMILY_MEMBERS"
export const GET_FILTERED_FAMILY_MEMBERS_SUCCESS =
  "GET_FILTERED_FAMILY_MEMBERS_SUCCESS"
export const GET_FILTERED_FAMILY_MEMBERS_FAIL =
  "GET_FILTERED_FAMILY_MEMBERS_FAIL"

/* FAMILY MEMBERS CARD*/
export const GET_FAMILY_MEMBERS_CARDS = "GET_FAMILY_MEMBERS_CARDS"
export const GET_FAMILY_MEMBERS_CARDS_SUCCESS =
  "GET_FAMILY_MEMBERS_CARDS_SUCCESS"
export const GET_FAMILY_MEMBERS_CARDS_FAIL = "GET_FAMILY_MEMBERS_CARDS_FAIL"

/* FAMILY MEMBER (BY FAMILY ID) DROPDOWN */
export const GET_FAMILY_MEMBER_DROPDOWN = "GET_FAMILY_MEMBER_DROPDOWN"
export const GET_FAMILY_MEMBER_DROPDOWN_SUCCESS =
  "GET_FAMILY_MEMBER_DROPDOWN_SUCCESS"
export const GET_FAMILY_MEMBER_DROPDOWN_FAIL = "GET_FAMILY_MEMBER_DROPDOWN_FAIL"

/* FAMILY MEMBER_DROPDOWN */
export const GET_ALL_FAMILY_MEMBERS_DROPDOWN = "GET_ALL_FAMILY_MEMBERS_DROPDOWN"
export const GET_ALL_FAMILY_MEMBERS_DROPDOWN_SUCCESS =
  "GET_ALL_FAMILY_MEMBERS_DROPDOWN_SUCCESS"
export const GET_ALL_FAMILY_MEMBERS_DROPDOWN_FAIL =
  "GET_ALL_FAMILY_MEMBERS_DROPDOWN_FAIL"

/* UPDATE_PHONE_FAMILY_MEMBER */
export const UPDATE_PHONE_FAMILY_MEMBER = "UPDATE_PHONE_FAMILY_MEMBER"
export const UPDATE_PHONE_FAMILY_MEMBER_SUCCESS =
  "UPDATE_PHONE_FAMILY_MEMBER_SUCCESS"
export const UPDATE_PHONE_FAMILY_MEMBER_FAIL = "UPDATE_PHONE_FAMILY_MEMBER_FAIL"

/* FAMILY MEMBERS DETAIL*/
export const GET_FAMILY_MEMBER_DETAIL = "GET_FAMILY_MEMBER_DETAIL"
export const GET_FAMILY_MEMBER_DETAIL_SUCCESS =
  "GET_FAMILY_MEMBER_DETAIL_SUCCESS"
export const GET_FAMILY_MEMBER_DETAIL_FAIL = "GET_FAMILY_MEMBER_DETAIL_FAIL"

/*
 * add FAMILY MEMBER
 */
export const CREATE_FAMILY_MEMBER = "CREATE_FAMILY_MEMBER"
export const CREATE_FAMILY_MEMBER_SUCCESS = "CREATE_FAMILY_MEMBER_SUCCESS"
export const CREATE_FAMILY_MEMBER_FAIL = "CREATE_FAMILY_MEMBER_FAIL"

/**
 * Edit FAMILY MEMBER
 */
export const UPDATE_FAMILY_MEMBER = "UPDATE_FAMILY_MEMBER"
export const UPDATE_FAMILY_MEMBER_SUCCESS = "UPDATE_FAMILY_MEMBER_SUCCESS"
export const UPDATE_FAMILY_MEMBER_FAIL = "UPDATE_FAMILY_MEMBER_FAIL"

/**
 * Delete FAMILY MEMBER
 */
export const DELETE_FAMILY_MEMBER = "DELETE_FAMILY_MEMBER"
export const DELETE_FAMILY_MEMBER_SUCCESS = "DELETE_FAMILY_MEMBER_SUCCESS"
export const DELETE_FAMILY_MEMBER_FAIL = "DELETE_FAMILY_MEMBER_FAIL"

export const CLEAR_MEMBER_DETAILS = "CLEAR_MEMBER_DETAILS"
