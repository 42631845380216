/* COMMITTEEMEETINGATTENDANCES */
export const GET_COMMITTEE_MEETING_ATTENDANCES =
  "GET_COMMITTEE_MEETING_ATTENDANCES"
export const GET_COMMITTEE_MEETING_ATTENDANCES_SUCCESS =
  "GET_COMMITTEE_MEETING_ATTENDANCES_SUCCESS"
export const GET_COMMITTEE_MEETING_ATTENDANCES_FAIL =
  "GET_COMMITTEE_MEETING_ATTENDANCES_FAIL"

/* COMMITTEEMEETINGATTENDANCES DETAIL*/
export const GET_COMMITTEE_MEETING_ATTENDANCE_DETAIL =
  "GET_COMMITTEE_MEETING_ATTENDANCE_DETAIL"
export const GET_COMMITTEE_MEETING_ATTENDANCE_DETAIL_SUCCESS =
  "GET_COMMITTEE_MEETING_ATTENDANCE_DETAIL_SUCCESS"
export const GET_COMMITTEE_MEETING_ATTENDANCE_DETAIL_FAIL =
  "GET_COMMITTEE_MEETING_ATTENDANCE_DETAIL_FAIL"

/**
 * add COMMITTEEMEETINGATTENDANCE
 */
export const CREATE_COMMITTEE_MEETING_ATTENDANCE =
  "CREATE_COMMITTEE_MEETING_ATTENDANCE"
export const CREATE_COMMITTEE_MEETING_ATTENDANCE_SUCCESS =
  "CREATE_COMMITTEE_MEETING_ATTENDANCE_SUCCESS"
export const CREATE_COMMITTEE_MEETING_ATTENDANCE_FAIL =
  "CREATE_COMMITTEE_MEETING_ATTENDANCE_FAIL"

/**
 * Edit COMMITTEEMEETINGATTENDANCE
 */
export const UPDATE_COMMITTEE_MEETING_ATTENDANCE =
  "UPDATE_COMMITTEE_MEETING_ATTENDANCE"
export const UPDATE_COMMITTEE_MEETING_ATTENDANCE_SUCCESS =
  "UPDATE_COMMITTEE_MEETING_ATTENDANCE_SUCCESS"
export const UPDATE_COMMITTEE_MEETING_ATTENDANCE_FAIL =
  "UPDATE_COMMITTEE_MEETING_ATTENDANCE_FAIL"

/**
 * Delete COMMITTEEMEETINGATTENDANCE
 */
export const DELETE_COMMITTEE_MEETING_ATTENDANCE =
  "DELETE_COMMITTEE_MEETING_ATTENDANCE"
export const DELETE_COMMITTEE_MEETING_ATTENDANCE_SUCCESS =
  "DELETE_COMMITTEE_MEETING_ATTENDANCE_SUCCESS"
export const DELETE_COMMITTEE_MEETING_ATTENDANCE_FAIL =
  "DELETE_COMMITTEE_MEETING_ATTENDANCE_FAIL"
