import {
  GET_CONSTITUENCIES,
  GET_CONSTITUENCIES_SUCCESS,
  GET_CONSTITUENCIES_FAIL,
  GET_CONSTITUENCY_DROPDOWN,
  GET_CONSTITUENCY_DROPDOWN_SUCCESS,
  GET_CONSTITUENCY_DROPDOWN_FAIL,
  GET_CONSTITUENCY_DETAIL,
  GET_CONSTITUENCY_DETAIL_SUCCESS,
  GET_CONSTITUENCY_DETAIL_FAIL,
  CREATE_CONSTITUENCY,
  CREATE_CONSTITUENCY_SUCCESS,
  CREATE_CONSTITUENCY_FAIL,
  UPDATE_CONSTITUENCY,
  UPDATE_CONSTITUENCY_SUCCESS,
  UPDATE_CONSTITUENCY_FAIL,
  DELETE_CONSTITUENCY,
  DELETE_CONSTITUENCY_SUCCESS,
  DELETE_CONSTITUENCY_FAIL,
} from "./actiontypes"

export const getConstituencies = (searchText, page) => ({
  type: GET_CONSTITUENCIES,
  payload: { searchText, page },
})

export const getConstituenciesSuccess = constituencies => ({
  type: GET_CONSTITUENCIES_SUCCESS,
  payload: constituencies,
})
export const getConstituenciesFail = error => ({
  type: GET_CONSTITUENCIES_FAIL,
  payload: error,
})

export const getConstituencyDropdown = searchText => ({
  type: GET_CONSTITUENCY_DROPDOWN,
  payload: searchText,
})

export const getConstituencyDropdownSuccess = constituencies => ({
  type: GET_CONSTITUENCY_DROPDOWN_SUCCESS,
  payload: constituencies,
})
export const getConstituencyDropdownFail = error => ({
  type: GET_CONSTITUENCY_DROPDOWN_FAIL,
  payload: error,
})

export const getConstituencyDetail = constituencyId => ({
  type: GET_CONSTITUENCY_DETAIL,
  constituencyId,
})

export const getConstituencyDetailSuccess = constituencyDetail => ({
  type: GET_CONSTITUENCY_DETAIL_SUCCESS,
  payload: constituencyDetail,
})

export const getConstituencyDetailFail = error => ({
  type: GET_CONSTITUENCY_DETAIL_FAIL,
  payload: error,
})

export const createConstituency = (constituency, history) => ({
  type: CREATE_CONSTITUENCY,
  payload: { constituency, history },
})

export const createConstituencySuccess = constituency => ({
  type: CREATE_CONSTITUENCY_SUCCESS,
  payload: constituency,
})

export const createConstituencyFail = error => ({
  type: CREATE_CONSTITUENCY_FAIL,
  payload: error,
})

export const updateConstituency = (constituency, constituencyId) => ({
  type: UPDATE_CONSTITUENCY,
  payload: { constituency, constituencyId },
})

export const updateConstituencySuccess = constituency => ({
  type: UPDATE_CONSTITUENCY_SUCCESS,
  payload: constituency,
})

export const updateConstituencyFail = error => ({
  type: UPDATE_CONSTITUENCY_FAIL,
  payload: error,
})

export const deleteConstituency = (constituencyId, history) => ({
  type: DELETE_CONSTITUENCY,
  payload: { constituencyId, history },
})

export const deleteConstituencySuccess = constituency => ({
  type: DELETE_CONSTITUENCY_SUCCESS,
  payload: constituency,
})

export const deleteConstituencyFail = error => ({
  type: DELETE_CONSTITUENCY_FAIL,
  payload: error,
})
