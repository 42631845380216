import {
  GET_VILLAGES,
  GET_VILLAGES_SUCCESS,
  GET_VILLAGES_FAIL,
  GET_VILLAGE_DROPDOWN,
  GET_VILLAGE_DROPDOWN_SUCCESS,
  GET_VILLAGE_DROPDOWN_FAIL,
  GET_VILLAGE_DETAIL,
  GET_VILLAGE_DETAIL_SUCCESS,
  GET_VILLAGE_DETAIL_FAIL,
  CREATE_VILLAGE,
  CREATE_VILLAGE_SUCCESS,
  CREATE_VILLAGE_FAIL,
  UPDATE_VILLAGE,
  UPDATE_VILLAGE_SUCCESS,
  UPDATE_VILLAGE_FAIL,
  DELETE_VILLAGE,
  DELETE_VILLAGE_SUCCESS,
  DELETE_VILLAGE_FAIL,
} from "./actionTypes"

export const getVillages = (searchText, page) => ({
  type: GET_VILLAGES,
  payload: { searchText, page },
})

export const getVillagesSuccess = villages => ({
  type: GET_VILLAGES_SUCCESS,
  payload: villages,
})
export const getVillagesFail = error => ({
  type: GET_VILLAGES_FAIL,
  payload: error,
})

export const getVillageDropdown = searchText => ({
  type: GET_VILLAGE_DROPDOWN,
  payload: searchText,
})

export const getVillageDropdownSuccess = villages => ({
  type: GET_VILLAGE_DROPDOWN_SUCCESS,
  payload: villages,
})
export const getVillageDropdownFail = error => ({
  type: GET_VILLAGE_DROPDOWN_FAIL,
  payload: error,
})

export const getVillageDetail = villageId => ({
  type: GET_VILLAGE_DETAIL,
  villageId,
})

export const getVillageDetailSuccess = villageDetail => ({
  type: GET_VILLAGE_DETAIL_SUCCESS,
  payload: villageDetail,
})

export const getVillageDetailFail = error => ({
  type: GET_VILLAGE_DETAIL_FAIL,
  payload: error,
})

export const createVillage = (village, history) => ({
  type: CREATE_VILLAGE,
  payload: { village, history },
})

export const createVillageSuccess = village => ({
  type: CREATE_VILLAGE_SUCCESS,
  payload: village,
})

export const createVillageFail = error => ({
  type: CREATE_VILLAGE_FAIL,
  payload: error,
})

export const updateVillage = (village, villageId, history) => ({
  type: UPDATE_VILLAGE,
  payload: { village, villageId, history },
})

export const updateVillageSuccess = village => ({
  type: UPDATE_VILLAGE_SUCCESS,
  payload: village,
})

export const updateVillageFail = error => ({
  type: UPDATE_VILLAGE_FAIL,
  payload: error,
})

export const deleteVillage = (villageId, history) => ({
  type: DELETE_VILLAGE,
  payload: { villageId, history },
})

export const deleteVillageSuccess = village => ({
  type: DELETE_VILLAGE_SUCCESS,
  payload: village,
})

export const deleteVillageFail = error => ({
  type: DELETE_VILLAGE_FAIL,
  payload: error,
})
