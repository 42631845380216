/* DISTRICTS */
export const GET_DISTRICTS = "GET_DISTRICTS"
export const GET_DISTRICTS_SUCCESS = "GET_DISTRICTS_SUCCESS"
export const GET_DISTRICTS_FAIL = "GET_DISTRICTS_FAIL"

/* DISTRICT_DROPDOWN */
export const GET_DISTRICT_DROPDOWN = "GET_DISTRICT_DROPDOWN"
export const GET_DISTRICT_DROPDOWN_SUCCESS = "GET_DISTRICT_DROPDOWN_SUCCESS"
export const GET_DISTRICT_DROPDOWN_FAIL = "GET_DISTRICT_DROPDOWN_FAIL"

/* DISTRICTS DETAIL*/
export const GET_DISTRICT_DETAIL = "GET_DISTRICT_DETAIL"
export const GET_DISTRICT_DETAIL_SUCCESS = "GET_DISTRICT_DETAIL_SUCCESS"
export const GET_DISTRICT_DETAIL_FAIL = "GET_DISTRICT_DETAIL_FAIL"

/**
 * add DISTRICT
 */
export const CREATE_DISTRICT = "CREATE_DISTRICT"
export const CREATE_DISTRICT_SUCCESS = "CREATE_DISTRICT_SUCCESS"
export const CREATE_DISTRICT_FAIL = "CREATE_DISTRICT_FAIL"

/**
 * Edit DISTRICT
 */
export const UPDATE_DISTRICT = "UPDATE_DISTRICT"
export const UPDATE_DISTRICT_SUCCESS = "UPDATE_DISTRICT_SUCCESS"
export const UPDATE_DISTRICT_FAIL = "UPDATE_DISTRICT_FAIL"

/**
 * Delete DISTRICT
 */
export const DELETE_DISTRICT = "DELETE_DISTRICT"
export const DELETE_DISTRICT_SUCCESS = "DELETE_DISTRICT_SUCCESS"
export const DELETE_DISTRICT_FAIL = "DELETE_DISTRICT_FAIL"
