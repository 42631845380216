import {
  GET_GROUP_ADMIN,
  GET_GROUP_ADMIN_SUCCESS,
  GET_GROUP_ADMIN_FAIL,
  GET_GROUP_ADMIN_DETAIL,
  GET_GROUP_ADMIN_DETAIL_SUCCESS,
  GET_GROUP_ADMIN_DETAIL_FAIL,
  CHANGE_GROUP_ADMIN_STATUS,
  CHANGE_GROUP_ADMIN_STATUS_SUCCESS,
  CHANGE_GROUP_ADMIN_STATUS_FAIL,
  CREATE_GROUP_ADMIN,
  CREATE_GROUP_ADMIN_SUCCESS,
  CREATE_GROUP_ADMIN_FAIL,
  UPDATE_GROUP_ADMIN,
  UPDATE_GROUP_ADMIN_SUCCESS,
  UPDATE_GROUP_ADMIN_FAIL,
  DELETE_GROUP_ADMIN,
  DELETE_GROUP_ADMIN_SUCCESS,
  DELETE_GROUP_ADMIN_FAIL,
} from "./actionTypes"

export const getGroupAdmin = (searchText, page) => ({
  type: GET_GROUP_ADMIN,
  payload: { searchText, page },
})

export const getGroupAdminSuccess = groupAdmins => ({
  type: GET_GROUP_ADMIN_SUCCESS,
  payload: groupAdmins,
})
export const getGroupAdminFail = error => ({
  type: GET_GROUP_ADMIN_FAIL,
  payload: error,
})

export const getGroupAdminDetail = groupAdminId => ({
  type: GET_GROUP_ADMIN_DETAIL,
  groupAdminId,
})

export const getGroupAdminDetailSuccess = groupAdminDetail => ({
  type: GET_GROUP_ADMIN_DETAIL_SUCCESS,
  payload: groupAdminDetail,
})

export const getGroupAdminDetailFail = error => ({
  type: GET_GROUP_ADMIN_DETAIL_FAIL,
  payload: error,
})

export const changeGroupAdminStatus = (groupAdminId, checked) => ({
  type: CHANGE_GROUP_ADMIN_STATUS,
  payload: { groupAdminId, checked },
})
export const changeGroupAdminStatusSuccess = groupAdminStatus => ({
  type: CHANGE_GROUP_ADMIN_STATUS_SUCCESS,
  payload: groupAdminStatus,
})
export const changeGroupAdminStatusFail = error => ({
  type: CHANGE_GROUP_ADMIN_STATUS_FAIL,
  payload: error,
})

export const createGroupAdmin = (groupAdmin, history) => ({
  type: CREATE_GROUP_ADMIN,
  payload: { groupAdmin, history },
})

export const createGroupAdminSuccess = groupAdmin => ({
  type: CREATE_GROUP_ADMIN_SUCCESS,
  payload: groupAdmin,
})

export const createGroupAdminFail = error => ({
  type: CREATE_GROUP_ADMIN_FAIL,
  payload: error,
})

export const updateGroupAdmin = (groupAdmin, groupAdminId, history) => ({
  type: UPDATE_GROUP_ADMIN,
  payload: { groupAdmin, groupAdminId, history },
})

export const updateGroupAdminSuccess = groupAdmin => ({
  type: UPDATE_GROUP_ADMIN_SUCCESS,
  payload: groupAdmin,
})

export const updateGroupAdminFail = error => ({
  type: UPDATE_GROUP_ADMIN_FAIL,
  payload: error,
})

export const deleteGroupAdmin = (groupAdminId, history) => ({
  type: DELETE_GROUP_ADMIN,
  payload: { groupAdminId, history },
})

export const deleteGroupAdminSuccess = groupAdmin => ({
  type: DELETE_GROUP_ADMIN_SUCCESS,
  payload: groupAdmin,
})

export const deleteGroupAdminFail = error => ({
  type: DELETE_GROUP_ADMIN_FAIL,
  payload: error,
})
