import {
  GET_VARISANGYAS,
  GET_VARISANGYAS_SUCCESS,
  GET_VARISANGYAS_FAIL,
  GET_VARISANGYA_CARDS,
  GET_VARISANGYA_CARDS_SUCCESS,
  GET_VARISANGYA_CARDS_FAIL,
  GET_VARISANGYA_DETAIL,
  GET_VARISANGYA_DETAIL_SUCCESS,
  GET_VARISANGYA_DETAIL_FAIL,
  CREATE_VARISANGYA,
  CREATE_VARISANGYA_SUCCESS,
  CREATE_VARISANGYA_FAIL,
  UPDATE_VARISANGYA,
  UPDATE_VARISANGYA_SUCCESS,
  UPDATE_VARISANGYA_FAIL,
  DELETE_VARISANGYA,
  DELETE_VARISANGYA_SUCCESS,
  DELETE_VARISANGYA_FAIL,
} from "./actionTypes"

export const getVarisangyas = (searchText, page) => ({
  type: GET_VARISANGYAS,
  payload: { searchText, page },
})
export const getVarisangyasSuccess = varisangya => ({
  type: GET_VARISANGYAS_SUCCESS,
  payload: varisangya,
})
export const getVarisangyasFail = error => ({
  type: GET_VARISANGYAS_FAIL,
  payload: error,
})

export const getVarisangyaCards = () => ({
  type: GET_VARISANGYA_CARDS,
})
export const getVarisangyaCardsSuccess = varisangyaCards => ({
  type: GET_VARISANGYA_CARDS_SUCCESS,
  payload: varisangyaCards,
})
export const getVarisangyaCardsFail = error => ({
  type: GET_VARISANGYA_CARDS_FAIL,
  payload: error,
})

export const getVarisangyaDetail = varisangyaId => ({
  type: GET_VARISANGYA_DETAIL,
  varisangyaId,
})

export const getVarisangyaDetailSuccess = varisangyaDetail => ({
  type: GET_VARISANGYA_DETAIL_SUCCESS,
  payload: varisangyaDetail,
})

export const getVarisangyaDetailFail = error => ({
  type: GET_VARISANGYA_DETAIL_FAIL,
  payload: error,
})

export const createVarisangya = (varisangya, history) => ({
  type: CREATE_VARISANGYA,
  payload: { varisangya, history },
})

export const createVarisangyaSuccess = varisangya => ({
  type: CREATE_VARISANGYA_SUCCESS,
  payload: varisangya,
})

export const createVarisangyaFail = error => ({
  type: CREATE_VARISANGYA_FAIL,
  payload: error,
})

export const updateVarisangya = (varisangya, varisangyaId, history) => ({
  type: UPDATE_VARISANGYA,
  payload: { varisangya, varisangyaId, history },
})

export const updateVarisangyaSuccess = varisangya => ({
  type: UPDATE_VARISANGYA_SUCCESS,
  payload: varisangya,
})

export const updateVarisangyaFail = error => ({
  type: UPDATE_VARISANGYA_FAIL,
  payload: error,
})

export const deleteVarisangya = (varisangyaId, history) => ({
  type: DELETE_VARISANGYA,
  payload: { varisangyaId, history },
})

export const deleteVarisangyaSuccess = varisangya => ({
  type: DELETE_VARISANGYA_SUCCESS,
  payload: varisangya,
})

export const deleteVarisangyaFail = error => ({
  type: DELETE_VARISANGYA_FAIL,
  payload: error,
})
