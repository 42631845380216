import {
  GET_DISTRICTS,
  GET_DISTRICTS_SUCCESS,
  GET_DISTRICTS_FAIL,
  GET_DISTRICT_DROPDOWN,
  GET_DISTRICT_DROPDOWN_SUCCESS,
  GET_DISTRICT_DROPDOWN_FAIL,
  GET_DISTRICT_DETAIL,
  GET_DISTRICT_DETAIL_SUCCESS,
  GET_DISTRICT_DETAIL_FAIL,
  CREATE_DISTRICT,
  CREATE_DISTRICT_SUCCESS,
  CREATE_DISTRICT_FAIL,
  UPDATE_DISTRICT,
  UPDATE_DISTRICT_SUCCESS,
  UPDATE_DISTRICT_FAIL,
  DELETE_DISTRICT,
  DELETE_DISTRICT_SUCCESS,
  DELETE_DISTRICT_FAIL,
} from "./actionTypes"

export const getDistricts = (searchText, page) => ({
  type: GET_DISTRICTS,
  payload: { searchText, page },
})

export const getDistrictsSuccess = districts => ({
  type: GET_DISTRICTS_SUCCESS,
  payload: districts,
})
export const getDistrictsFail = error => ({
  type: GET_DISTRICTS_FAIL,
  payload: error,
})

export const getDistrictDropdown = searchText => ({
  type: GET_DISTRICT_DROPDOWN,
  payload: searchText,
})

export const getDistrictDropdownSuccess = districts => ({
  type: GET_DISTRICT_DROPDOWN_SUCCESS,
  payload: districts,
})

export const getDistrictDropdownFail = error => ({
  type: GET_DISTRICT_DROPDOWN_FAIL,
  payload: error,
})

export const getDistrictDetail = districtId => ({
  type: GET_DISTRICT_DETAIL,
  districtId,
})

export const getDistrictDetailSuccess = districtDetail => ({
  type: GET_DISTRICT_DETAIL_SUCCESS,
  payload: districtDetail,
})

export const getDistrictDetailFail = error => ({
  type: GET_DISTRICT_DETAIL_FAIL,
  payload: error,
})

export const createDistrict = (district, history) => ({
  type: CREATE_DISTRICT,
  payload: { district, history },
})

export const createDistrictSuccess = district => ({
  type: CREATE_DISTRICT_SUCCESS,
  payload: district,
})

export const createDistrictFail = error => ({
  type: CREATE_DISTRICT_FAIL,
  payload: error,
})

export const updateDistrict = (district, districtId, history) => ({
  type: UPDATE_DISTRICT,
  payload: { district, districtId, history },
})

export const updateDistrictSuccess = district => ({
  type: UPDATE_DISTRICT_SUCCESS,
  payload: district,
})

export const updateDistrictFail = error => ({
  type: UPDATE_DISTRICT_FAIL,
  payload: error,
})

export const deleteDistrict = (districtId, history) => ({
  type: DELETE_DISTRICT,
  payload: { districtId, history },
})

export const deleteDistrictSuccess = district => ({
  type: DELETE_DISTRICT_SUCCESS,
  payload: district,
})

export const deleteDistrictFail = error => ({
  type: DELETE_DISTRICT_FAIL,
  payload: error,
})
