/* SURVEY_USER */
export const GET_SURVEY_USER = "GET_SURVEY_USER"
export const GET_SURVEY_USER_SUCCESS = "GET_SURVEY_USER_SUCCESS"
export const GET_SURVEY_USER_FAIL = "GET_SURVEY_USER_FAIL"

/* SURVEY_USER DETAIL*/
export const GET_SURVEY_USER_DETAIL = "GET_SURVEY_USER_DETAIL"
export const GET_SURVEY_USER_DETAIL_SUCCESS = "GET_SURVEY_USER_DETAIL_SUCCESS"
export const GET_SURVEY_USER_DETAIL_FAIL = "GET_SURVEY_USER_DETAIL_FAIL"

/* SURVEY_USER_STATUS*/
export const CHANGE_SURVEY_USER_STATUS = "CHANGE_SURVEY_USER_STATUS"
export const CHANGE_SURVEY_USER_STATUS_SUCCESS =
  "CHANGE_SURVEY_USER_STATUS_SUCCESS"
export const CHANGE_SURVEY_USER_STATUS_FAIL = "CHANGE_SURVEY_USER_STATUS_FAIL"

/**
 * add SURVEY_USER
 */
export const CREATE_SURVEY_USER = "CREATE_SURVEY_USER"
export const CREATE_SURVEY_USER_SUCCESS = "CREATE_SURVEY_USER_SUCCESS"
export const CREATE_SURVEY_USER_FAIL = "CREATE_SURVEY_USER_FAIL"

/**
 * Edit SURVEY_USER
 */
export const UPDATE_SURVEY_USER = "UPDATE_SURVEY_USER"
export const UPDATE_SURVEY_USER_SUCCESS = "UPDATE_SURVEY_USER_SUCCESS"
export const UPDATE_SURVEY_USER_FAIL = "UPDATE_SURVEY_USER_FAIL"

/**
 * Delete SURVEY_USER
 */
export const DELETE_SURVEY_USER = "DELETE_SURVEY_USER"
export const DELETE_SURVEY_USER_SUCCESS = "DELETE_SURVEY_USER_SUCCESS"
export const DELETE_SURVEY_USER_FAIL = "DELETE_SURVEY_USER_FAIL"
