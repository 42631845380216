import {
  GET_VARISANGYA_SPLIT_LEDGERS,
  GET_VARISANGYA_SPLIT_LEDGERS_SUCCESS,
  GET_VARISANGYA_SPLIT_LEDGERS_FAIL,
  CREATE_VARISANGYA_SPLIT_LEDGER,
  CREATE_VARISANGYA_SPLIT_LEDGER_SUCCESS,
  CREATE_VARISANGYA_SPLIT_LEDGER_FAIL,
} from "./actionTypes"

export const getVarisangyaSplitLedgers = (searchText, page) => ({
  type: GET_VARISANGYA_SPLIT_LEDGERS,
  payload: { searchText, page },
})
export const getVarisangyaSplitLedgersSuccess = varisangyaSplitLedger => ({
  type: GET_VARISANGYA_SPLIT_LEDGERS_SUCCESS,
  payload: varisangyaSplitLedger,
})
export const getVarisangyaSplitLedgersFail = error => ({
  type: GET_VARISANGYA_SPLIT_LEDGERS_FAIL,
  payload: error,
})

export const createVarisangyaSplitLedger = (
  varisangyaSplitLedger,
  history
) => ({
  type: CREATE_VARISANGYA_SPLIT_LEDGER,
  payload: { varisangyaSplitLedger, history },
})

export const createVarisangyaSplitLedgerSuccess = varisangyaSplitLedger => ({
  type: CREATE_VARISANGYA_SPLIT_LEDGER_SUCCESS,
  payload: varisangyaSplitLedger,
})

export const createVarisangyaSplitLedgerFail = error => ({
  type: CREATE_VARISANGYA_SPLIT_LEDGER_FAIL,
  payload: error,
})
