/* LSGS */
export const GET_LSGS = "GET_LSGS"
export const GET_LSGS_SUCCESS = "GET_LSGS_SUCCESS"
export const GET_LSGS_FAIL = "GET_LSGS_FAIL"

/* LSG_DROPDOWN */
export const GET_LSG_DROPDOWN = "GET_LSG_DROPDOWN"
export const GET_LSG_DROPDOWN_SUCCESS = "GET_LSG_DROPDOWN_SUCCESS"
export const GET_LSG_DROPDOWN_FAIL = "GET_LSG_DROPDOWN_FAIL"

/* LSGS DETAIL*/
export const GET_LSG_DETAIL = "GET_LSG_DETAIL"
export const GET_LSG_DETAIL_SUCCESS = "GET_LSG_DETAIL_SUCCESS"
export const GET_LSG_DETAIL_FAIL = "GET_LSG_DETAIL_FAIL"

/**
 * add LSG
 */
export const CREATE_LSG = "CREATE_LSG"
export const CREATE_LSG_SUCCESS = "CREATE_LSG_SUCCESS"
export const CREATE_LSG_FAIL = "CREATE_LSG_FAIL"

/**
 * Edit LSG
 */
export const UPDATE_LSG = "UPDATE_LSG"
export const UPDATE_LSG_SUCCESS = "UPDATE_LSG_SUCCESS"
export const UPDATE_LSG_FAIL = "UPDATE_LSG_FAIL"

/**
 * Delete LSG
 */
export const DELETE_LSG = "DELETE_LSG"
export const DELETE_LSG_SUCCESS = "DELETE_LSG_SUCCESS"
export const DELETE_LSG_FAIL = "DELETE_LSG_FAIL"
