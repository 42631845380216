/* LEDGERS */
export const GET_LEDGERS = "GET_LEDGERS"
export const GET_LEDGERS_SUCCESS = "GET_LEDGERS_SUCCESS"
export const GET_LEDGERS_FAIL = "GET_LEDGERS_FAIL"

/* LEDGERS FOR DOWNLOAD */
export const GET_LEDGERS_FOR_DOWNLOAD = "GET_LEDGERS_FOR_DOWNLOAD"
export const GET_LEDGERS_FOR_DOWNLOAD_SUCCESS =
  "GET_LEDGERS_FOR_DOWNLOAD_SUCCESS"
export const GET_LEDGERS_FOR_DOWNLOAD_FAIL = "GET_LEDGERS_FOR_DOWNLOAD_FAIL"

/* LEDGERS DETAIL*/
export const GET_LEDGER_DETAIL = "GET_LEDGER_DETAIL"
export const GET_LEDGER_DETAIL_SUCCESS = "GET_LEDGER_DETAIL_SUCCESS"
export const GET_LEDGER_DETAIL_FAIL = "GET_LEDGER_DETAIL_FAIL"

/* LEDGERS_DROPDOWN*/
export const GET_LEDGER_DROPDOWN = "GET_LEDGER_DROPDOWN"
export const GET_LEDGER_DROPDOWN_SUCCESS = "GET_LEDGER_DROPDOWN_SUCCESS"
export const GET_LEDGER_DROPDOWN_FAIL = "GET_LEDGER_DROPDOWN_FAIL"

/**
 * add LEDGER
 */
export const CREATE_LEDGER = "CREATE_LEDGER"
export const CREATE_LEDGER_SUCCESS = "CREATE_LEDGER_SUCCESS"
export const CREATE_LEDGER_FAIL = "CREATE_LEDGER_FAIL"

/**
 * Edit LEDGER
 */
export const UPDATE_LEDGER = "UPDATE_LEDGER"
export const UPDATE_LEDGER_SUCCESS = "UPDATE_LEDGER_SUCCESS"
export const UPDATE_LEDGER_FAIL = "UPDATE_LEDGER_FAIL"

/**
 * Delete LEDGER
 */
export const DELETE_LEDGER = "DELETE_LEDGER"
export const DELETE_LEDGER_SUCCESS = "DELETE_LEDGER_SUCCESS"
export const DELETE_LEDGER_FAIL = "DELETE_LEDGER_FAIL"
