/* FAMILY_MEMBER_VARISANGYAS */
export const GET_FAMILY_MEMBER_VARISANGYAS = "GET_FAMILY_MEMBER_VARISANGYAS"
export const GET_FAMILY_MEMBER_VARISANGYAS_SUCCESS =
  "GET_FAMILY_MEMBER_VARISANGYAS_SUCCESS"
export const GET_FAMILY_MEMBER_VARISANGYAS_FAIL =
  "GET_FAMILY_MEMBER_VARISANGYAS_FAIL"

/* FAMILY_MEMBER_VARISANGYAS CARDS*/
export const GET_FAMILY_MEMBER_VARISANGYA_CARDS =
  "GET_FAMILY_MEMBER_VARISANGYA_CARDS"
export const GET_FAMILY_MEMBER_VARISANGYA_CARDS_SUCCESS =
  "GET_FAMILY_MEMBER_VARISANGYA_CARDS_SUCCESS"
export const GET_FAMILY_MEMBER_VARISANGYA_CARDS_FAIL =
  "GET_FAMILY_MEMBER_VARISANGYA_CARDS_FAIL"

/* FAMILY_MEMBER_VARISANGYA  DETAIL*/
export const GET_FAMILY_MEMBER_VARISANGYA_DETAIL =
  "GET_FAMILY_MEMBER_VARISANGYA_DETAIL"
export const GET_FAMILY_MEMBER_VARISANGYA_DETAIL_SUCCESS =
  "GET_FAMILY_MEMBER_VARISANGYA_DETAIL_SUCCESS"
export const GET_FAMILY_MEMBER_VARISANGYA_DETAIL_FAIL =
  "GET_FAMILY_MEMBER_VARISANGYA_DETAIL_FAIL"

/**
 * add FAMILY_MEMBER_VARISANGYA
 */
export const CREATE_FAMILY_MEMBER_VARISANGYA = "CREATE_FAMILY_MEMBER_VARISANGYA"
export const CREATE_FAMILY_MEMBER_VARISANGYA_SUCCESS =
  "CREATE_FAMILY_MEMBER_VARISANGYA_SUCCESS"
export const CREATE_FAMILY_MEMBER_VARISANGYA_FAIL =
  "CREATE_FAMILY_MEMBER_VARISANGYA_FAIL"

/**
 * Edit FAMILY_MEMBER_VARISANGYA
 */
export const UPDATE_FAMILY_MEMBER_VARISANGYA = "UPDATE_FAMILY_MEMBER_VARISANGYA"
export const UPDATE_FAMILY_MEMBER_VARISANGYA_SUCCESS =
  "UPDATE_FAMILY_MEMBER_VARISANGYA_SUCCESS"
export const UPDATE_FAMILY_MEMBER_VARISANGYA_FAIL =
  "UPDATE_FAMILY_MEMBER_VARISANGYA_FAIL"

/**
 * Delete FAMILY_MEMBER_VARISANGYA
 */
export const DELETE_FAMILY_MEMBER_VARISANGYA = "DELETE_FAMILY_MEMBER_VARISANGYA"
export const DELETE_FAMILY_MEMBER_VARISANGYA_SUCCESS =
  "DELETE_FAMILY_MEMBER_VARISANGYA_SUCCESS"
export const DELETE_FAMILY_MEMBER_VARISANGYA_FAIL =
  "DELETE_FAMILY_MEMBER_VARISANGYA_FAIL"

// AUTO_GENERATE_FAMILY_MEMBER_VARISANGYAS
export const AUTO_GENERATE_FAMILY_MEMBER_VARISANGYAS =
  "AUTO_GENERATE_FAMILY_MEMBER_VARISANGYAS"
export const AUTO_GENERATE_FAMILY_MEMBER_VARISANGYAS_SUCCESS =
  "AUTO_GENERATE_FAMILY_MEMBER_VARISANGYAS_SUCCESS"
export const AUTO_GENERATE_FAMILY_MEMBER_VARISANGYAS_FAIL =
  "AUTO_GENERATE_FAMILY_MEMBER_VARISANGYAS_FAIL"

// REFRESH_FAMILY_MEMBER_VARISANGYA
export const REFRESH_FAMILY_MEMBER_VARISANGYA =
  "REFRESH_FAMILY_MEMBER_VARISANGYA"
export const REFRESH_FAMILY_MEMBER_VARISANGYA_SUCCESS =
  "REFRESH_FAMILY_MEMBER_VARISANGYA_SUCCESS"
export const REFRESH_FAMILY_MEMBER_VARISANGYA_FAIL =
  "REFRESH_FAMILY_MEMBER_VARISANGYA_FAIL"

// UPDATE_FAMILY_MEMBER_VARISANGYA_AMOUNT_BY_FILTER
export const UPDATE_FAMILY_MEMBER_VARISANGYA_AMOUNT_BY_FILTER =
  "UPDATE_FAMILY_MEMBER_VARISANGYA_AMOUNT_BY_FILTER"
export const UPDATE_FAMILY_MEMBER_VARISANGYA_AMOUNT_BY_FILTER_SUCCESS =
  "UPDATE_FAMILY_MEMBER_VARISANGYA_AMOUNT_BY_FILTER_SUCCESS"
export const UPDATE_FAMILY_MEMBER_VARISANGYA_AMOUNT_BY_FILTER_FAIL =
  "UPDATE_FAMILY_MEMBER_VARISANGYA_AMOUNT_BY_FILTER_FAIL"
