// ------------------------------------MAHALL NOTIFICATIONS----------------------------------------

/* MAHALL_NOTIFICATIONS */
export const GET_MAHALL_NOTIFICATIONS = "GET_MAHALL_NOTIFICATIONS"
export const GET_MAHALL_NOTIFICATIONS_SUCCESS =
  "GET_MAHALL_NOTIFICATIONS_SUCCESS"
export const GET_MAHALL_NOTIFICATIONS_FAIL = "GET_MAHALL_NOTIFICATIONS_FAIL"

/* MAHALL_NOTIFICATIONS CARDS*/
export const GET_MAHALL_NOTIFICATIONS_CARDS = "GET_MAHALL_NOTIFICATIONS_CARDS"
export const GET_MAHALL_NOTIFICATIONS_CARDS_SUCCESS =
  "GET_MAHALL_NOTIFICATIONS_CARDS_SUCCESS"
export const GET_MAHALL_NOTIFICATIONS_CARDS_FAIL =
  "GET_MAHALL_NOTIFICATIONS_CARDS_FAIL"

/* MAHAL_NOTIFICATIONLS DETAIL*/
export const GET_MAHALL_NOTIFICATION_DETAIL = "GET_MAHALL_NOTIFICATION_DETAIL"
export const GET_MAHALL_NOTIFICATION_DETAIL_SUCCESS =
  "GET_MAHALL_NOTIFICATION_DETAIL_SUCCESS"
export const GET_MAHALL_NOTIFICATION_DETAIL_FAIL =
  "GET_MAHALL_NOTIFICATION_DETAIL_FAIL"

/**
 * add MAHALL_NOTIFICATION
 */
export const CREATE_MAHALL_NOTIFICATION = "CREATE_MAHALL_NOTIFICATION"
export const CREATE_MAHALL_NOTIFICATION_SUCCESS =
  "CREATE_MAHALL_NOTIFICATION_SUCCESS"
export const CREATE_MAHALL_NOTIFICATION_FAIL = "CREATE_MAHALL_NOTIFICATION_FAIL"

/**
 * Edit MAHALL_NOTIFICATION
 */
export const UPDATE_MAHALL_NOTIFICATION = "UPDATE_MAHALL_NOTIFICATION"
export const UPDATE_MAHALL_NOTIFICATION_SUCCESS =
  "UPDATE_MAHALL_NOTIFICATION_SUCCESS"
export const UPDATE_MAHALL_NOTIFICATION_FAIL = "UPDATE_MAHALL_NOTIFICATION_FAIL"

/**
 * Delete MAHALL_NOTIFICATION
 */
export const DELETE_MAHALL_NOTIFICATION = "DELETE_MAHALL_NOTIFICATION"
export const DELETE_MAHALL_NOTIFICATION_SUCCESS =
  "DELETE_MAHALL_NOTIFICATION_SUCCESS"
export const DELETE_MAHALL_NOTIFICATION_FAIL = "DELETE_MAHALL_NOTIFICATION_FAIL"

// ------------------------------------FAMILY MEMBER COLLECTION NOTIFICATIONS----------------------------------------

/* FAMILY_MEMBER_COLLECTION_NOTIFICATIONS */
export const GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS =
  "GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS"
export const GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS_SUCCESS =
  "GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS_SUCCESS"
export const GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS_FAIL =
  "GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS_FAIL"

/* FAMILY_MEMBER_COLLECTION_NOTIFICATIONS CARDS*/
export const GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS_CARDS =
  "GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS_CARDS"
export const GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS_CARDS_SUCCESS =
  "GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS_CARDS_SUCCESS"
export const GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS_CARDS_FAIL =
  "GET_FAMILY_MEMBER_COLLECTIONNOTIFICATIONS_CARDS_FAIL"

/* FAMILY_MEMBER_COLLECTION_NOTIFICATIONLS DETAIL*/
export const GET_FAMILY_MEMBER_COLLECTION_NOTIFICATION_DETAIL =
  "GET_FAMILY_MEMBER_COLLECTION_NOTIFICATION_DETAIL"
export const GET_FAMILY_MEMBER_COLLECTION_NOTIFICATION_DETAIL_SUCCESS =
  "GET_FAMILY_MEMBER_COLLECTION_NOTIFICATION_DETAIL_SUCCESS"
export const GET_FAMILY_MEMBER_COLLECTION_NOTIFICATION_DETAIL_FAIL =
  "GET_FAMILY_MEMBER_COLLECTION_NOTIFICATION_DETAIL_FAIL"

/**
 * add FAMILY_MEMBER_COLLECTION_NOTIFICATION
 */
export const CREATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION =
  "CREATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION"
export const CREATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_SUCCESS =
  "CREATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_SUCCESS"
export const CREATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_FAIL =
  "CREATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_FAIL"

/**
 * Edit FAMILY_MEMBER_COLLECTION_NOTIFICATION
 */
export const UPDATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION =
  "UPDATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION"
export const UPDATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_SUCCESS =
  "UPDATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_SUCCESS"
export const UPDATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_FAIL =
  "UPDATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_FAIL"

/**
 * Delete FAMILY_MEMBER_COLLECTION_NOTIFICATION
 */
export const DELETE_FAMILY_MEMBER_COLLECTION_NOTIFICATION =
  "DELETE_FAMILY_MEMBER_COLLECTION_NOTIFICATION"
export const DELETE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_SUCCESS =
  "DELETE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_SUCCESS"
export const DELETE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_FAIL =
  "DELETE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_FAIL"

// ------------------------------------INDIVIDUAL NOTIFICATIONS----------------------------------------

/* INDIVIDUAL_NOTIFICATIONS */
export const GET_INDIVIDUAL_NOTIFICATIONS = "GET_INDIVIDUAL_NOTIFICATIONS"
export const GET_INDIVIDUAL_NOTIFICATIONS_SUCCESS =
  "GET_INDIVIDUAL_NOTIFICATIONS_SUCCESS"
export const GET_INDIVIDUAL_NOTIFICATIONS_FAIL =
  "GET_INDIVIDUAL_NOTIFICATIONS_FAIL"

/* INDIVIDUAL_NOTIFICATIONS CARDS*/
export const GET_INDIVIDUAL_NOTIFICATIONS_CARDS =
  "GET_INDIVIDUAL_NOTIFICATIONS_CARDS"
export const GET_INDIVIDUAL_NOTIFICATIONS_CARDS_SUCCESS =
  "GET_INDIVIDUAL_NOTIFICATIONS_CARDS_SUCCESS"
export const GET_INDIVIDUAL_NOTIFICATIONS_CARDS_FAIL =
  "GET_INDIVIDUALNOTIFICATIONS_CARDS_FAIL"

/* INDIVIDUAL_NOTIFICATIONLS DETAIL*/
export const GET_INDIVIDUAL_NOTIFICATION_DETAIL =
  "GET_INDIVIDUAL_NOTIFICATION_DETAIL"
export const GET_INDIVIDUAL_NOTIFICATION_DETAIL_SUCCESS =
  "GET_INDIVIDUAL_NOTIFICATION_DETAIL_SUCCESS"
export const GET_INDIVIDUAL_NOTIFICATION_DETAIL_FAIL =
  "GET_INDIVIDUAL_NOTIFICATION_DETAIL_FAIL"

/**
 * add INDIVIDUAL_NOTIFICATION
 */
export const CREATE_INDIVIDUAL_NOTIFICATION = "CREATE_INDIVIDUAL_NOTIFICATION"
export const CREATE_INDIVIDUAL_NOTIFICATION_SUCCESS =
  "CREATE_INDIVIDUAL_NOTIFICATION_SUCCESS"
export const CREATE_INDIVIDUAL_NOTIFICATION_FAIL =
  "CREATE_INDIVIDUAL_NOTIFICATION_FAIL"

/**
 * Edit INDIVIDUAL_NOTIFICATION
 */
export const UPDATE_INDIVIDUAL_NOTIFICATION = "UPDATE_INDIVIDUAL_NOTIFICATION"
export const UPDATE_INDIVIDUAL_NOTIFICATION_SUCCESS =
  "UPDATE_INDIVIDUAL_NOTIFICATION_SUCCESS"
export const UPDATE_INDIVIDUAL_NOTIFICATION_FAIL =
  "UPDATE_INDIVIDUAL_NOTIFICATION_FAIL"

/**
 * Delete INDIVIDUAL_NOTIFICATION
 */
export const DELETE_INDIVIDUAL_NOTIFICATION = "DELETE_INDIVIDUAL_NOTIFICATION"
export const DELETE_INDIVIDUAL_NOTIFICATION_SUCCESS =
  "DELETE_INDIVIDUAL_NOTIFICATION_SUCCESS"
export const DELETE_INDIVIDUAL_NOTIFICATION_FAIL =
  "DELETE_INDIVIDUAL_NOTIFICATION_FAIL"
