/* USER */
export const GET_USER_DETAIL = "GET_USER_DETAIL"
export const GET_USER_DETAIL_SUCCESS = "GET_USER_DETAIL_SUCCESS"
export const GET_USER_DETAIL_FAIL = "GET_USER_DETAIL_FAIL"

export const CHECK_USER_PHONE_NUMBER = "CHECK_USER_PHONE_NUMBER"
export const CHECK_USER_PHONE_NUMBER_SUCCESS = "CHECK_USER_PHONE_NUMBER_SUCCESS"
export const CHECK_USER_PHONE_NUMBER_FAIL = "CHECK_USER_PHONE_NUMBER_FAIL"

export const CHECK_FAMILY_MEMBER_PHONE_NUMBER =
  "CHECK_FAMILY_MEMBER_PHONE_NUMBER"
export const CHECK_FAMILY_MEMBER_PHONE_NUMBER_SUCCESS =
  "CHECK_FAMILY_MEMBER_PHONE_NUMBER_SUCCESS"
export const CHECK_FAMILY_MEMBER_PHONE_NUMBER_FAIL =
  "CHECK_FAMILY_MEMBER_PHONE_NUMBER_FAIL"

export const CHECK_FAMILY_MEMBER_PHONE_NUMBER_UPDATE =
  "CHECK_FAMILY_MEMBER_PHONE_NUMBER_UPDATE"
export const CHECK_FAMILY_MEMBER_PHONE_NUMBER_UPDATE_SUCCESS =
  "CHECK_FAMILY_MEMBER_PHONE_NUMBER_UPDATE_SUCCESS"
export const CHECK_FAMILY_MEMBER_PHONE_NUMBER_UPDATE_FAIL =
  "CHECK_FAMILY_MEMBER_PHONE_NUMBER_UPDATE_FAIL"

export const CHECK_CUSTOM_ID = "CHECK_CUSTOM_ID"
export const CHECK_CUSTOM_ID_SUCCESS = "CHECK_CUSTOM_ID_SUCCESS"
export const CHECK_CUSTOM_ID_FAIL = "CHECK_CUSTOM_ID_FAIL"

export const CHECK_SURVEY_USER_PHONE_NUMBER = "CHECK_SURVEY_USER_PHONE_NUMBER"
export const CHECK_SURVEY_USER_PHONE_NUMBER_SUCCESS =
  "CHECK_SURVEY_USER_PHONE_NUMBER_SUCCESS"
export const CHECK_SURVEY_USER_PHONE_NUMBER_FAIL =
  "CHECK_SURVEY_USER_PHONE_NUMBER_FAIL"

export const CHECK_GROUP_ADMIN_PHONE_NUMBER = "CHECK_GROUP_ADMIN_PHONE_NUMBER"
export const CHECK_GROUP_ADMIN_PHONE_NUMBER_SUCCESS =
  "CHECK_GROUP_ADMIN_PHONE_NUMBER_SUCCESS"
export const CHECK_GROUP_ADMIN_PHONE_NUMBER_FAIL =
  "CHECK_GROUP_ADMIN_PHONE_NUMBER_FAIL"

export const CHECK_INSTITUTE_USER_PHONE_NUMBER =
  "CHECK_INSTITUTE_USER__PHONE_NUMBER"
export const CHECK_INSTITUTE_USER__PHONE_NUMBER_SUCCESS =
  "CHECK_INSTITUTE_USER__PHONE_NUMBER_SUCCESS"
export const CHECK_INSTITUTE_USER__PHONE_NUMBER_FAIL =
  "CHECK_INSTITUTE_USER__PHONE_NUMBER_FAIL"

export const SET_USER_ROLE = "SET_USER_ROLE"
export const SET_USER_ROLE_SUCCESS = "SET_USER_ROLE_SUCCESS"
export const SET_USER_ROLE_FAIL = "SET_USER_ROLE_FAIL"
