/* ASSETS */
export const GET_ASSETS = "GET_ASSETS"
export const GET_ASSETS_SUCCESS = "GET_ASSETS_SUCCESS"
export const GET_ASSETS_FAIL = "GET_ASSETS_FAIL"

/* ASSET  DETAIL*/
export const GET_ASSET_DETAIL = "GET_ASSET_DETAIL"
export const GET_ASSET_DETAIL_SUCCESS = "GET_ASSET_DETAIL_SUCCESS"
export const GET_ASSET_DETAIL_FAIL = "GET_ASSET_DETAIL_FAIL"

/**
 * add ASSET
 */
export const CREATE_ASSET = "CREATE_ASSET"
export const CREATE_ASSET_SUCCESS = "CREATE_ASSET_SUCCESS"
export const CREATE_ASSET_FAIL = "CREATE_ASSET_FAIL"

/**
 * Edit ASSET
 */
export const UPDATE_ASSET = "UPDATE_ASSET"
export const UPDATE_ASSET_SUCCESS = "UPDATE_ASSET_SUCCESS"
export const UPDATE_ASSET_FAIL = "UPDATE_ASSET_FAIL"

/**
 * Delete ASSET
 */
export const DELETE_ASSET = "DELETE_ASSET"
export const DELETE_ASSET_SUCCESS = "DELETE_ASSET_SUCCESS"
export const DELETE_ASSET_FAIL = "DELETE_ASSET_FAIL"
