// ------------------------------------FAMILY MEMBER COLLECTIONS----------------------------------------

export const GET_FAMILY_MEMBER_COLLECTIONS = "GET_FAMILY_MEMBER_COLLECTIONS"
export const GET_FAMILY_MEMBER_COLLECTIONS_SUCCESS =
  "GET_FAMILY_MEMBER_COLLECTIONS_SUCCESS"
export const GET_FAMILY_MEMBER_COLLECTIONS_FAIL =
  "GET_FAMILY_MEMBER_COLLECTIONS_FAIL"

export const GET_FAMILY_MEMBER_COLLECTIONS_CARDS =
  "GET_FAMILY_MEMBER_COLLECTIONS_CARDS"
export const GET_FAMILY_MEMBER_COLLECTIONS_CARDS_SUCCESS =
  "GET_FAMILY_MEMBER_COLLECTIONS_CARDS_SUCCESS"
export const GET_FAMILY_MEMBER_COLLECTIONS_CARDS_FAIL =
  "GET_FAMILY_MEMBER_COLLECTIONS_CARDS_FAIL"

export const GET_FAMILY_MEMBER_COLLECTION_DETAIL =
  "GET_FAMILY_MEMBER_COLLECTION_DETAIL"
export const GET_FAMILY_MEMBER_COLLECTION_DETAIL_SUCCESS =
  "GET_FAMILY_MEMBER_COLLECTION_DETAIL_SUCCESS"
export const GET_FAMILY_MEMBER_COLLECTION_DETAIL_FAIL =
  "GET_FAMILY_MEMBER_COLLECTION_DETAIL_FAIL"

export const CREATE_FAMILY_MEMBER_COLLECTION = "CREATE_FAMILY_MEMBER_COLLECTION"
export const CREATE_FAMILY_MEMBER_COLLECTION_SUCCESS =
  "CREATE_FAMILY_MEMBER_COLLECTION_SUCCESS"
export const CREATE_FAMILY_MEMBER_COLLECTION_FAIL =
  "CREATE_FAMILY_MEMBER_COLLECTION_FAIL"

export const UPDATE_FAMILY_MEMBER_COLLECTION = "UPDATE_FAMILY_MEMBER_COLLECTION"
export const UPDATE_FAMILY_MEMBER_COLLECTION_SUCCESS =
  "UPDATE_FAMILY_MEMBER_COLLECTION_SUCCESS"
export const UPDATE_FAMILY_MEMBER_COLLECTION_FAIL =
  "UPDATE_FAMILY_MEMBER_COLLECTION_FAIL"

export const DELETE_FAMILY_MEMBER_COLLECTION = "DELETE_FAMILY_MEMBER_COLLECTION"
export const DELETE_FAMILY_MEMBER_COLLECTION_SUCCESS =
  "DELETE_FAMILY_MEMBER_COLLECTION_SUCCESS"
export const DELETE_FAMILY_MEMBER_COLLECTION_FAIL =
  "DELETE_FAMILY_MEMBER_COLLECTION_FAIL"

// ------------------------------------FAMILY MEMBER COLLECTION MEMBERS----------------------------------------

export const GET_FAMILY_MEMBER_COLLECTION_MEMBERS =
  "GET_FAMILY_MEMBER_COLLECTION_MEMBERS"
export const GET_FAMILY_MEMBER_COLLECTION_MEMBERS_SUCCESS =
  "GET_FAMILY_MEMBER_COLLECTION_MEMBERS_SUCCESS"
export const GET_FAMILY_MEMBER_COLLECTION_MEMBERS_FAIL =
  "GET_FAMILY_MEMBER_COLLECTION_MEMBERS_FAIL"

export const GET_FAMILY_MEMBER_COLLECTION_MEMBERS_CARDS =
  "GET_FAMILY_MEMBER_COLLECTION_MEMBERS_CARDS"
export const GET_FAMILY_MEMBER_COLLECTION_MEMBERS_CARDS_SUCCESS =
  "GET_FAMILY_MEMBER_COLLECTION_MEMBERS_CARDS_SUCCESS"
export const GET_FAMILY_MEMBER_COLLECTION_MEMBERS_CARDS_FAIL =
  "GET_FAMILY_MEMBER_COLLECTION_MEMBERS_CARDS_FAIL"

export const GET_FAMILY_MEMBER_COLLECTION_MEMBER_DETAIL =
  "GET_FAMILY_MEMBER_COLLECTION_MEMBER_DETAIL"
export const GET_FAMILY_MEMBER_COLLECTION_MEMBER_DETAIL_SUCCESS =
  "GET_FAMILY_MEMBER_COLLECTION_MEMBER_DETAIL_SUCCESS"
export const GET_FAMILY_MEMBER_COLLECTION_MEMBER_DETAIL_FAIL =
  "GET_FAMILY_MEMBER_COLLECTION_MEMBER_DETAIL_FAIL"

export const CREATE_FAMILY_MEMBER_COLLECTION_MEMBER =
  "CREATE_FAMILY_MEMBER_COLLECTION_MEMBER"
export const CREATE_FAMILY_MEMBER_COLLECTION_MEMBER_SUCCESS =
  "CREATE_FAMILY_MEMBER_COLLECTION_MEMBER_SUCCESS"
export const CREATE_FAMILY_MEMBER_COLLECTION_MEMBER_FAIL =
  "CREATE_FAMILY_MEMBER_COLLECTION_MEMBER_FAIL"

export const UPDATE_FAMILY_MEMBER_COLLECTION_MEMBER =
  "UPDATE_FAMILY_MEMBER_COLLECTION_MEMBER"
export const UPDATE_FAMILY_MEMBER_COLLECTION_MEMBER_SUCCESS =
  "UPDATE_FAMILY_MEMBER_COLLECTION_MEMBER_SUCCESS"
export const UPDATE_FAMILY_MEMBER_COLLECTION_MEMBER_FAIL =
  "UPDATE_FAMILY_MEMBER_COLLECTION_MEMBER_FAIL"

export const DELETE_FAMILY_MEMBER_COLLECTION_MEMBER =
  "DELETE_FAMILY_MEMBER_COLLECTION_MEMBER"
export const DELETE_FAMILY_MEMBER_COLLECTION_MEMBER_SUCCESS =
  "DELETE_FAMILY_MEMBER_COLLECTION_MEMBER_SUCCESS"
export const DELETE_FAMILY_MEMBER_COLLECTION_MEMBER_FAIL =
  "DELETE_FAMILY_MEMBER_COLLECTION_MEMBER_FAIL"

// ------------------------------------FAMILY MEMBER FILTER COLLECTION----------------------------------------

export const GET_FAMILY_MEMBER_FILTER_COLLECTIONS =
  "GET_FAMILY_MEMBER_FILTER_COLLECTIONS"
export const GET_FAMILY_MEMBER_FILTER_COLLECTIONS_SUCCESS =
  "GET_FAMILY_MEMBER_FILTER_COLLECTIONS_SUCCESS"
export const GET_FAMILY_MEMBER_FILTER_COLLECTIONS_FAIL =
  "GET_FAMILY_MEMBER_FILTER_COLLECTIONS_FAIL"

export const GET_FAMILY_MEMBER_FILTER_COLLECTIONS_CARDS =
  "GET_FAMILY_MEMBER_FILTER_COLLECTIONS_CARDS"
export const GET_FAMILY_MEMBER_FILTER_COLLECTIONS_CARDS_SUCCESS =
  "GET_FAMILY_MEMBER_FILTER_COLLECTIONS_CARDS_SUCCESS"
export const GET_FAMILY_MEMBER_FILTER_COLLECTIONS_CARDS_FAIL =
  "GET_FAMILY_MEMBER_FILTER_COLLECTIONS_CARDS_FAIL"

export const GET_FAMILY_MEMBER_FILTER_COLLECTION_DETAIL =
  "GET_FAMILY_MEMBER_FILTER_COLLECTION_DETAIL"
export const GET_FAMILY_MEMBER_FILTER_COLLECTION_DETAIL_SUCCESS =
  "GET_FAMILY_MEMBER_FILTER_COLLECTION_DETAIL_SUCCESS"
export const GET_FAMILY_MEMBER_FILTER_COLLECTION_DETAIL_FAIL =
  "GET_FAMILY_MEMBER_FILTER_COLLECTION_DETAIL_FAIL"

export const CREATE_FAMILY_MEMBER_FILTER_COLLECTION =
  "CREATE_FAMILY_MEMBER_FILTER_COLLECTION"
export const CREATE_FAMILY_MEMBER_FILTER_COLLECTION_SUCCESS =
  "CREATE_FAMILY_MEMBER_FILTER_COLLECTION_SUCCESS"
export const CREATE_FAMILY_MEMBER_FILTER_COLLECTION_FAIL =
  "CREATE_FAMILY_MEMBER_FILTER_COLLECTION_FAIL"

export const UPDATE_FAMILY_MEMBER_FILTER_COLLECTION =
  "UPDATE_FAMILY_MEMBER_FILTER_COLLECTION"
export const UPDATE_FAMILY_MEMBER_FILTER_COLLECTION_SUCCESS =
  "UPDATE_FAMILY_MEMBER_FILTER_COLLECTION_SUCCESS"
export const UPDATE_FAMILY_MEMBER_FILTER_COLLECTION_FAIL =
  "UPDATE_FAMILY_MEMBER_FILTER_COLLECTION_FAIL"

export const DELETE_FAMILY_MEMBER_FILTER_COLLECTION =
  "DELETE_FAMILY_MEMBER_FILTER_COLLECTION"
export const DELETE_FAMILY_MEMBER_FILTER_COLLECTION_SUCCESS =
  "DELETE_FAMILY_MEMBER_FILTER_COLLECTION_SUCCESS"
export const DELETE_FAMILY_MEMBER_FILTER_COLLECTION_FAIL =
  "DELETE_FAMILY_MEMBER_FILTER_COLLECTION_FAIL"
