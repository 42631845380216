import {
  GET_LEDGERS,
  GET_LEDGERS_SUCCESS,
  GET_LEDGERS_FAIL,
  GET_LEDGER_DETAIL,
  GET_LEDGER_DETAIL_SUCCESS,
  GET_LEDGER_DETAIL_FAIL,
  CREATE_LEDGER,
  CREATE_LEDGER_SUCCESS,
  CREATE_LEDGER_FAIL,
  UPDATE_LEDGER,
  UPDATE_LEDGER_SUCCESS,
  UPDATE_LEDGER_FAIL,
  DELETE_LEDGER,
  DELETE_LEDGER_SUCCESS,
  DELETE_LEDGER_FAIL,
  GET_LEDGER_DROPDOWN,
  GET_LEDGER_DROPDOWN_SUCCESS,
  GET_LEDGER_DROPDOWN_FAIL,
  GET_LEDGERS_FOR_DOWNLOAD,
  GET_LEDGERS_FOR_DOWNLOAD_SUCCESS,
  GET_LEDGERS_FOR_DOWNLOAD_FAIL,
} from "./actionTypes"

export const getLedgers = (
  instituteId,
  searchText,
  page,
  categoryId,
  instituteType
) => ({
  type: GET_LEDGERS,
  payload: {
    instituteId,
    searchText,
    page,
    categoryId,
    instituteType,
  },
})

export const getLedgersSuccess = ledgers => ({
  type: GET_LEDGERS_SUCCESS,
  payload: ledgers,
})
export const getLedgersFail = error => ({
  type: GET_LEDGERS_FAIL,
  payload: error,
})

export const getLedgersForDownload = (
  instituteId,
  searchText,
  categoryId,
  instituteType
) => ({
  type: GET_LEDGERS_FOR_DOWNLOAD,
  payload: { instituteId, searchText, categoryId, instituteType },
})

export const getLedgersForDownloadSuccess = ledgersForDownload => ({
  type: GET_LEDGERS_FOR_DOWNLOAD_SUCCESS,
  payload: ledgersForDownload,
})
export const getLedgersForDownloadFail = error => ({
  type: GET_LEDGERS_FOR_DOWNLOAD_FAIL,
  payload: error,
})

export const getLedgerDetail = ledgerId => ({
  type: GET_LEDGER_DETAIL,
  ledgerId,
})

export const getLedgerDetailSuccess = ledgerDetail => ({
  type: GET_LEDGER_DETAIL_SUCCESS,
  payload: ledgerDetail,
})

export const getLedgerDetailFail = error => ({
  type: GET_LEDGER_DETAIL_FAIL,
  payload: error,
})

export const getLedgerDropdown = (instituteId, searchText) => ({
  type: GET_LEDGER_DROPDOWN,
  payload: { instituteId, searchText },
})

export const getLedgerDropdownSuccess = ledgers => ({
  type: GET_LEDGER_DROPDOWN_SUCCESS,
  payload: ledgers,
})

export const getLedgerDropdownFail = error => ({
  type: GET_LEDGER_DROPDOWN_FAIL,
  payload: error,
})

export const createLedger = ledger => ({
  type: CREATE_LEDGER,
  payload: { ledger },
})

export const createLedgerSuccess = ledger => ({
  type: CREATE_LEDGER_SUCCESS,
  payload: ledger,
})

export const createLedgerFail = error => ({
  type: CREATE_LEDGER_FAIL,
  payload: error,
})

export const updateLedger = (ledger, ledgerId, history) => ({
  type: UPDATE_LEDGER,
  payload: { ledger, ledgerId, history },
})

export const updateLedgerSuccess = ledger => ({
  type: UPDATE_LEDGER_SUCCESS,
  payload: ledger,
})

export const updateLedgerFail = error => ({
  type: UPDATE_LEDGER_FAIL,
  payload: error,
})

export const deleteLedger = (ledgerId, history) => ({
  type: DELETE_LEDGER,
  payload: { ledgerId, history },
})

export const deleteLedgerSuccess = ledger => ({
  type: DELETE_LEDGER_SUCCESS,
  payload: ledger,
})

export const deleteLedgerFail = error => ({
  type: DELETE_LEDGER_FAIL,
  payload: error,
})
