import {
  GET_LEDGER_ITEM_CATEGORIES,
  GET_LEDGER_ITEM_CATEGORIES_SUCCESS,
  GET_LEDGER_ITEM_CATEGORIES_FAIL,
  GET_LEDGER_ITEM_CATEGORY_DETAIL,
  GET_LEDGER_ITEM_CATEGORY_DETAIL_SUCCESS,
  GET_LEDGER_ITEM_CATEGORY_DETAIL_FAIL,
  CREATE_LEDGER_ITEM_CATEGORY,
  CREATE_LEDGER_ITEM_CATEGORY_SUCCESS,
  CREATE_LEDGER_ITEM_CATEGORY_FAIL,
  UPDATE_LEDGER_ITEM_CATEGORY,
  UPDATE_LEDGER_ITEM_CATEGORY_SUCCESS,
  UPDATE_LEDGER_ITEM_CATEGORY_FAIL,
  DELETE_LEDGER_ITEM_CATEGORY,
  DELETE_LEDGER_ITEM_CATEGORY_SUCCESS,
  DELETE_LEDGER_ITEM_CATEGORY_FAIL,
  GET_LEDGER_ITEM_CATEGORY_DROPDOWN,
  GET_LEDGER_ITEM_CATEGORY_DROPDOWN_SUCCESS,
  GET_LEDGER_ITEM_CATEGORY_DROPDOWN_FAIL,
  GET_LEDGER_ITEM_CATEGORIES_FOR_DOWNLOAD,
  GET_LEDGER_ITEM_CATEGORIES_FOR_DOWNLOAD_SUCCESS,
  GET_LEDGER_ITEM_CATEGORIES_FOR_DOWNLOAD_FAIL,
} from "./actionTypes"

export const getLedgerItemCategories = (instituteId, searchText, page) => ({
  type: GET_LEDGER_ITEM_CATEGORIES,
  payload: { instituteId, searchText, page },
})

export const getLedgerItemCategoriesSuccess = ledgerItemCategories => ({
  type: GET_LEDGER_ITEM_CATEGORIES_SUCCESS,
  payload: ledgerItemCategories,
})
export const getLedgerItemCategoriesFail = error => ({
  type: GET_LEDGER_ITEM_CATEGORIES_FAIL,
  payload: error,
})

export const getLedgerItemCategoriesForDownload = (
  instituteId,
  searchText
) => ({
  type: GET_LEDGER_ITEM_CATEGORIES_FOR_DOWNLOAD,
  payload: { instituteId, searchText },
})

export const getLedgerItemCategoriesForDownloadSuccess =
  ledgerItemCategoriesForDownload => ({
    type: GET_LEDGER_ITEM_CATEGORIES_FOR_DOWNLOAD_SUCCESS,
    payload: ledgerItemCategoriesForDownload,
  })

export const getLedgerItemCategoriesForDownloadFail = error => ({
  type: GET_LEDGER_ITEM_CATEGORIES_FOR_DOWNLOAD_FAIL,
  payload: error,
})

export const getLedgerItemCategoryDetail = ledgerItemCategoryId => ({
  type: GET_LEDGER_ITEM_CATEGORY_DETAIL,
  ledgerItemCategoryId,
})

export const getLedgerItemCategoryDetailSuccess = ledgerItemCategoryDetail => ({
  type: GET_LEDGER_ITEM_CATEGORY_DETAIL_SUCCESS,
  payload: ledgerItemCategoryDetail,
})

export const getLedgerItemCategoryDetailFail = error => ({
  type: GET_LEDGER_ITEM_CATEGORY_DETAIL_FAIL,
  payload: error,
})

export const getLedgerItemCategoryDropdown = (instituteId, searchText) => ({
  type: GET_LEDGER_ITEM_CATEGORY_DROPDOWN,
  payload: { instituteId, searchText },
})

export const getLedgerItemCategoryDropdownSuccess = ledgerItemCategories => ({
  type: GET_LEDGER_ITEM_CATEGORY_DROPDOWN_SUCCESS,
  payload: ledgerItemCategories,
})
export const getLedgerItemCategoryDropdownFail = error => ({
  type: GET_LEDGER_ITEM_CATEGORY_DROPDOWN_FAIL,
  payload: error,
})

export const createLedgerItemCategory = (ledgerItemCategory, history) => ({
  type: CREATE_LEDGER_ITEM_CATEGORY,
  payload: { ledgerItemCategory, history },
})

export const createLedgerItemCategorySuccess = ledgerItemCategory => ({
  type: CREATE_LEDGER_ITEM_CATEGORY_SUCCESS,
  payload: ledgerItemCategory,
})

export const createLedgerItemCategoryFail = error => ({
  type: CREATE_LEDGER_ITEM_CATEGORY_FAIL,
  payload: error,
})

export const updateLedgerItemCategory = (
  ledgerItemCategory,
  ledgerItemCategoryId
) => ({
  type: UPDATE_LEDGER_ITEM_CATEGORY,
  payload: { ledgerItemCategory, ledgerItemCategoryId },
})

export const updateLedgerItemCategorySuccess = ledgerItemCategory => ({
  type: UPDATE_LEDGER_ITEM_CATEGORY_SUCCESS,
  payload: ledgerItemCategory,
})

export const updateLedgerItemCategoryFail = error => ({
  type: UPDATE_LEDGER_ITEM_CATEGORY_FAIL,
  payload: error,
})

export const deleteLedgerItemCategory = (
  ledgerItemCategoryId,
  history,
  instituteId
) => ({
  type: DELETE_LEDGER_ITEM_CATEGORY,
  payload: { ledgerItemCategoryId, history, instituteId },
})

export const deleteLedgerItemCategorySuccess = ledgerItemCategory => ({
  type: DELETE_LEDGER_ITEM_CATEGORY_SUCCESS,
  payload: ledgerItemCategory,
})

export const deleteLedgerItemCategoryFail = error => ({
  type: DELETE_LEDGER_ITEM_CATEGORY_FAIL,
  payload: error,
})
