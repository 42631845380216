import {
  GET_FAMILY_VARISANGYAS,
  GET_FAMILY_VARISANGYAS_SUCCESS,
  GET_FAMILY_VARISANGYAS_FAIL,
  GET_FAMILY_VARISANGYA_CARDS,
  GET_FAMILY_VARISANGYA_CARDS_SUCCESS,
  GET_FAMILY_VARISANGYA_CARDS_FAIL,
  GET_FAMILY_VARISANGYA_DETAIL,
  GET_FAMILY_VARISANGYA_DETAIL_SUCCESS,
  GET_FAMILY_VARISANGYA_DETAIL_FAIL,
  CREATE_FAMILY_VARISANGYA,
  CREATE_FAMILY_VARISANGYA_SUCCESS,
  CREATE_FAMILY_VARISANGYA_FAIL,
  UPDATE_FAMILY_VARISANGYA,
  UPDATE_FAMILY_VARISANGYA_SUCCESS,
  UPDATE_FAMILY_VARISANGYA_FAIL,
  DELETE_FAMILY_VARISANGYA,
  DELETE_FAMILY_VARISANGYA_SUCCESS,
  DELETE_FAMILY_VARISANGYA_FAIL,
  GET_FAMILY_VARISANGYA_ITEMS,
  GET_FAMILY_VARISANGYA_ITEMS_SUCCESS,
  GET_FAMILY_VARISANGYA_ITEMS_FAIL,
  AUTO_GENERATE_FAMILY_VARISANGYAS,
  AUTO_GENERATE_FAMILY_VARISANGYAS_SUCCESS,
  AUTO_GENERATE_FAMILY_VARISANGYAS_FAIL,
  REFRESH_FAMILY_VARISANGYA,
  REFRESH_FAMILY_VARISANGYA_SUCCESS,
  REFRESH_FAMILY_VARISANGYA_FAIL,
  UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER,
  UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER_SUCCESS,
  UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER_FAIL,
  DELETE_FAMILY_VARISANGYA_ITEM,
  DELETE_FAMILY_VARISANGYA_ITEM_SUCCESS,
  DELETE_FAMILY_VARISANGYA_ITEM_FAIL,
} from "./actionTypes"

export const getFamilyVarisangyas = (searchText, page, limit) => ({
  type: GET_FAMILY_VARISANGYAS,
  payload: { searchText, page, limit },
})
export const getFamilyVarisangyasSuccess = familyVarisangya => ({
  type: GET_FAMILY_VARISANGYAS_SUCCESS,
  payload: familyVarisangya,
})
export const getFamilyVarisangyasFail = error => ({
  type: GET_FAMILY_VARISANGYAS_FAIL,
  payload: error,
})

export const getFamilyVarisangyaCards = () => ({
  type: GET_FAMILY_VARISANGYA_CARDS,
})
export const getFamilyVarisangyaCardsSuccess = familyVarisangyaCards => ({
  type: GET_FAMILY_VARISANGYA_CARDS_SUCCESS,
  payload: familyVarisangyaCards,
})
export const getFamilyVarisangyaCardsFail = error => ({
  type: GET_FAMILY_VARISANGYA_CARDS_FAIL,
  payload: error,
})

export const getFamilyVarisangyaDetail = familyVarisangyaId => ({
  type: GET_FAMILY_VARISANGYA_DETAIL,
  familyVarisangyaId,
})

export const getFamilyVarisangyaDetailSuccess = familyVarisangyaDetail => ({
  type: GET_FAMILY_VARISANGYA_DETAIL_SUCCESS,
  payload: familyVarisangyaDetail,
})

export const getFamilyVarisangyaDetailFail = error => ({
  type: GET_FAMILY_VARISANGYA_DETAIL_FAIL,
  payload: error,
})

export const createFamilyVarisangya = (familyVarisangya, history) => ({
  type: CREATE_FAMILY_VARISANGYA,
  payload: { familyVarisangya, history },
})

export const createFamilyVarisangyaSuccess = familyVarisangya => ({
  type: CREATE_FAMILY_VARISANGYA_SUCCESS,
  payload: familyVarisangya,
})

export const createFamilyVarisangyaFail = error => ({
  type: CREATE_FAMILY_VARISANGYA_FAIL,
  payload: error,
})

export const updateFamilyVarisangya = (
  familyVarisangya,
  familyVarisangyaId,
  history
) => ({
  type: UPDATE_FAMILY_VARISANGYA,
  payload: { familyVarisangya, familyVarisangyaId, history },
})

export const updateFamilyVarisangyaSuccess = familyVarisangya => ({
  type: UPDATE_FAMILY_VARISANGYA_SUCCESS,
  payload: familyVarisangya,
})

export const updateFamilyVarisangyaFail = error => ({
  type: UPDATE_FAMILY_VARISANGYA_FAIL,
  payload: error,
})

export const deleteFamilyVarisangya = (familyVarisangyaId, history) => ({
  type: DELETE_FAMILY_VARISANGYA,
  payload: { familyVarisangyaId, history },
})

export const deleteFamilyVarisangyaSuccess = familyVarisangya => ({
  type: DELETE_FAMILY_VARISANGYA_SUCCESS,
  payload: familyVarisangya,
})

export const deleteFamilyVarisangyaFail = error => ({
  type: DELETE_FAMILY_VARISANGYA_FAIL,
  payload: error,
})

export const autoGenerateFamilyVarisangyas = () => ({
  type: AUTO_GENERATE_FAMILY_VARISANGYAS,
})

export const autoGenerateFamilyVarisangyasSuccess = familyVarisangya => ({
  type: AUTO_GENERATE_FAMILY_VARISANGYAS_SUCCESS,
  payload: familyVarisangya,
})

export const autoGenerateFamilyVarisangyasFail = error => ({
  type: AUTO_GENERATE_FAMILY_VARISANGYAS_FAIL,
  payload: error,
})

export const refreshFamilyVarisangya = familyVarisangyaId => ({
  type: REFRESH_FAMILY_VARISANGYA,
  payload: { familyVarisangyaId },
})

export const refreshFamilyVarisangyaSuccess = familyVarisangya => ({
  type: REFRESH_FAMILY_VARISANGYA_SUCCESS,
  payload: familyVarisangya,
})

export const refreshFamilyVarisangyaFail = error => ({
  type: REFRESH_FAMILY_VARISANGYA_FAIL,
  payload: error,
})

export const updateFamilyVarisangyaAmountByFilter = (
  familyVarisangyaAmuontByFilter,
  history
) => ({
  type: UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER,
  payload: { familyVarisangyaAmuontByFilter, history },
})

export const updateFamilyVarisangyaAmountByFilterSuccess =
  familyVarisangyaAmuontByFilter => ({
    type: UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER_SUCCESS,
    payload: familyVarisangyaAmuontByFilter,
  })

export const updateFamilyVarisangyaAmountByFilterFail = error => ({
  type: UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER_FAIL,
  payload: error,
})
